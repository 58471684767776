import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs';

@Component({
  selector: 'app-error-page',
  standalone: false,
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss',
})
export class ErrorPageComponent {
  constructor(private router: Router, private params: ActivatedRoute) {}

  public errorDescription!: string;

  public goHome() {
    this.router.navigateByUrl('/home');
  }

  ngOnInit(): void {
    this.params.queryParams
      .pipe(
        tap((params: any) => {
          let errorDescription = params['error_description'];
          if (errorDescription) {
            this.errorDescription = errorDescription.replace(
              'Try logging in again from the application and if the problem persists please contact the administrator.',
              ''
            );
          } else {
            this.errorDescription = 'Something went wrong.';
          }
        })
      )
      .subscribe();
  }
}
