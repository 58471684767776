import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ToastrFactoryService } from './toastr-factory.service';
import { qrApi } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QrCodeService {
  constructor(
    private toastrService: ToastrService,
    private translate: TranslateService,
    private toastrFactory: ToastrFactoryService
  ) {}

  getQrCodeUrl(url: string, shortId: string): string {
    return qrApi + url + shortId;
  }

  async buildQRCodeImageBlob(qrCodeUrl: string): Promise<Blob> {
    const response = await fetch(qrCodeUrl);
    return await response.blob();
  }

  async copyQrToClipboard(url: string, shortId: string): Promise<void> {
    const qrCodeUrl = this.getQrCodeUrl(url, shortId);
    const imageBlob = await this.buildQRCodeImageBlob(qrCodeUrl);
    await this.copyQrToCliboard(imageBlob, shortId);
  }

  async copyQrToCliboard(imageBlob: Blob, shortId: string): Promise<void> {
    if (!('ClipboardItem' in window)) {
      const imageURL = URL.createObjectURL(imageBlob);
      const link = document.createElement('a');
      link.href = imageURL;
      link.download = this.translate.instant('DASHBOARD.paymentRequest') + shortId;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      try {
        const data = [new ClipboardItem({ [imageBlob.type]: imageBlob })];
        await navigator.clipboard.write(data);
        this.toastrService.success(this.translate.instant('qrHasBeenCopied'), this.translate.instant('success'), {
          timeOut: 3000
        });
      } catch (e: any) {
        this.toastrFactory.unknownError(this.translate.instant('unknownError'));
        console.log(e);
      }
    }
  }

  async shareQrCode(url: string, shortId: string): Promise<void> {
    try {
      const qrCodeUrl = this.getQrCodeUrl(url, shortId);
      const imageBlob = await this.buildQRCodeImageBlob(qrCodeUrl);
      const text = this.translate.instant('DASHBOARD.clickOnThisLink') + url + shortId + this.translate.instant('DASHBOARD.toBeRedirectedTo');
      if (navigator.share) {
        await navigator.share({
          title: this.translate.instant('DASHBOARD.shareTitle'),
          text: text,
          url: window.location.hostname + '/home?paymentId=' + shortId,
          files: [new File([imageBlob], 'qr_code.png', { type: 'image/png' })],
        });
      } else {
        this.toastrService.error(this.translate.instant('DASHBOARD.shareError'));
      }
    } catch (error) {
      console.log('Error sharing:', error);
      this.toastrService.error(this.translate.instant('unfortunatelyThisBrowserDoesNotSupportTheShareFunction'));
    }
  }
}
