import { formatDate as _formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import FormatCurrency from '../utils/format-currency-utils';
import { createHttpOptions } from '../utils/http-utils';

@Injectable({
  providedIn: 'root',
})

export class FilterService {
  private toastr = inject(ToastrService);
  private formatCurrency = inject(FormatCurrency);
  private http = inject(HttpClient);

  getAllPaymentReportsWithoutFilters(
    pageSize: any,
    pageIndex: any,
    emails: any[],
    filterForm?: FormGroup<any>,
    mustOrderByDescending: boolean = true,
  ): Observable<any> {
    const receiverNicks = this.formatEmails(emails);
    // const dateStart = this.formatDate(filterForm?.controls['dateStart'].value);
    // const dateEnd = this.formatDate(filterForm?.controls['dateEnd'].value);
    // const amountMoreThan = this.formatAmount(filterForm?.controls['amountMoreThan'].value);
    // const amountLessThan = this.formatAmount(filterForm?.controls['amountLessThan'].value);
    // const assetFilters = filterForm ? this.getAssetFilters(filterForm) : undefined;
    // const transactionId = filterForm?.controls['transactionId'].value;
    // const status = filterForm?.controls['status'].value;

    const body = {
      direction: 'In',
      orderBy: 'createdDate',
      orderByDescending: mustOrderByDescending,
      pageIndex: pageIndex > 0 ? pageIndex - 1 : 0,
      pageSize: pageSize || undefined,
      // dateStart: dateStart || undefined,
      // dateEnd: dateEnd || undefined,
      // amountMoreThan: amountMoreThan || undefined,
      // amountLessThan: amountLessThan || undefined,
      // blockChainTransactionId: transactionId || undefined,
      email: receiverNicks || undefined,
      // blockchainAssets: assetFilters || undefined,
      // blockchainCheckStatus: status ? [status] : undefined,
    };

    return this.http.post(environment.api + 'PaymentReport/all', body, createHttpOptions());
  }

  getAllPaymentReports(
    pageSize: any,
    pageIndex: any,
    emails: any[],
    filterForm?: FormGroup<any>,
    mustOrderByDescending: boolean = true,
  ): Observable<any> {
    const receiverNicks = this.formatEmails(emails);
    const dateStart = this.formatDate(filterForm?.controls['dateStart'].value);
    const dateEnd = this.formatDate(filterForm?.controls['dateEnd'].value);
    const amountMoreThan = this.formatAmount(filterForm?.controls['amountMoreThan'].value);
    const amountLessThan = this.formatAmount(filterForm?.controls['amountLessThan'].value);
    const assetFilters = filterForm ? this.getAssetFilters(filterForm) : undefined;
    const transactionId = filterForm?.controls['transactionId'].value;
    const status = filterForm?.controls['status'].value;

    const body = {
      direction: 'In',
      orderBy: 'createdDate',
      orderByDescending: mustOrderByDescending,
      pageIndex: pageIndex > 0 ? pageIndex - 1 : 0,
      pageSize: pageSize || undefined,
      dateStart: dateStart || undefined,
      dateEnd: dateEnd || undefined,
      amountMoreThan: amountMoreThan || undefined,
      amountLessThan: amountLessThan || undefined,
      blockChainTransactionId: transactionId || undefined,
      email: receiverNicks || undefined,
      blockchainAssets: assetFilters || undefined,
      blockchainCheckStatus: status ? [status] : undefined,
    };

    return this.http.post(environment.api + 'PaymentReport/all', body, createHttpOptions());
  }

  getAllPaymentRequests(
    filterForm: FormGroup<any>,
    mustOrderByDescending: boolean,
    emails: any,
    pageSize: any,
    pageIndex: any,
    statusFilter: string[],
  ) {
    const dateStart = this.formatDate(filterForm?.controls['dateStart'].value);
    const dateEnd = this.formatDate(filterForm?.controls['dateEnd'].value);
    const amountMoreThan = this.formatAmount(filterForm?.controls['amountMoreThan'].value);
    const amountLessThan = this.formatAmount(filterForm?.controls['amountLessThan'].value);
    const assetFilter = filterForm?.controls['assetFilter'].value;
    const shortId = filterForm?.controls['paymentId'].value;
    const status = statusFilter.length > 0 ? statusFilter : undefined;
    const filteredEmails = emails[0] || filterForm.controls['receiverNick'].value;

    const body = {
      pageIndex: pageIndex > 0 ? pageIndex - 1 : 0,
      pageSize: pageSize || 10,
      dateStart: dateStart || undefined,
      dateEnd: dateEnd || undefined,
      orderBy: 'createdDate',
      orderByDescending: mustOrderByDescending,
      amountMoreThan: amountMoreThan || undefined,
      amountLessThan: amountLessThan || undefined,
      blockchainAssets: assetFilter ? [assetFilter, '', '', ''] : undefined,
      status: status,
      requesterUserEmail: filteredEmails || undefined,
      shortId: shortId || undefined,
      blockchainAssetId: assetFilter || undefined,
    };

    return this.http.post(environment.api + 'PaymentRequest/all', body, createHttpOptions());
  }

  hasSomePaymentRequest() {
    const body = {
      pageIndex: 0,
      pageSize: 5,
      orderBy: 'createdDate',
    };

    return this.http.post(environment.api + 'PaymentRequest/all', body, createHttpOptions());
  }

  private formatEmails(emails: any[]): string | undefined {
    return emails.length > 0 ? emails.join(',') : undefined;
  }

  private formatAmount(amount: any): string | undefined {
    if (!amount) return undefined;
    const isCommaDecimal = this.formatCurrency.commaDecimalCountries.includes(navigator.language);
    amount = isCommaDecimal ? amount.replace(/\./g, '') : amount.replace(/\,/g, '');
    return amount.replace(/,/g, '.');
  }

  private formatDate(date: string): string | undefined {
    if (!date) return undefined;
    try {
      const formattedDate = _formatDate(date, 'yyyy-MM-dd', navigator.language);
      if (formattedDate === null) {
        throw new Error('Invalid date format, it must be yyyy/MM/dd');
      }
      return formattedDate;
    } catch (e) {
      console.error(e);
      this.toastr.error(e as string, '', { timeOut: 5000 });
      return undefined;
    }
  }

  private getAssetFilters(filterForm: FormGroup<any>): string[] | undefined {
    const assetFilters = {
      bitcoinFilter: filterForm?.controls['bitcoinFilter'].value,
      ethereumFilter: filterForm?.controls['ethereumFilter'].value,
      usdcFilter: filterForm?.controls['usdcFilter'].value,
      usdtFilter: filterForm?.controls['usdtFilter'].value,
    };

    if (this.hasAssetFilter(assetFilters)) {
      return this.mapAssetFilters(assetFilters);
    }
    return undefined;
  }

  private hasAssetFilter(assetFilters: any): boolean {
    return Object.values(assetFilters).some((filter) => filter);
  }

  private mapAssetFilters(assetFilters: any): string[] {
    const assetFilterArray = [];
    if (assetFilters.ethereumFilter) assetFilterArray.push('ETH.ETH');
    if (assetFilters.bitcoinFilter) assetFilterArray.push('BTC.BTC');
    if (assetFilters.usdcFilter) assetFilterArray.push('ETH.USDC', 'MATIC.USDC');
    if (assetFilters.usdtFilter) assetFilterArray.push('ETH.USDT', 'MATIC.USDt');
    return assetFilterArray;
  }
}
