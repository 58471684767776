import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';


@Component({
  selector: 'app-crypto-select',
  standalone: true,
  imports: [CommonModule, FormsModule, MatIconModule],
  templateUrl: './crypto-select.component.html'
})
export class CryptoSelectComponent {
  availableCryptos = [
    { name: 'Bitcoin', symbol: 'BTC' },
    { name: 'Ethereum', symbol: 'ETH' },
    { name: 'Polkadot', symbol: 'POL' },
    // Add more cryptocurrencies as needed
  ];

  selectedCrypto = this.availableCryptos[0].symbol;
} 