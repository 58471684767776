<div class="flex items-center justify-center min-h-screen p-4">
  <div class="bg-white rounded-lg px-6 py-8 text-center min-h-[400px] flex flex-col">
    <!-- Profile Not Found Icon -->
    <div class="mb-6">
      @if (title === 'Profile Not Found') {
        <svg
          class="mx-auto h-16 w-16 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
            />
        </svg>
      }

      <!-- Default Error Icon -->
      @if (title !== 'Profile Not Found') {
        <svg
          class="mx-auto h-16 w-16 text-gray-400"
          width="107"
          height="107"
          viewBox="0 0 107 107"
          fill="none"
          >
          <circle cx="53.5" cy="53.5" r="53.5" fill="#FFEDEC" />
          <path
            d="M53 17.875C46.2507 17.875 39.653 19.8764 34.0412 23.6261C28.4294 27.3758 24.0555 32.7054 21.4726 38.9409C18.8898 45.1765 18.214 52.0379 19.5307 58.6575C20.8474 65.2771 24.0975 71.3576 28.87 76.13C33.6425 80.9025 39.723 84.1526 46.3426 85.4693C52.9622 86.786 59.8236 86.1102 66.0591 83.5274C72.2946 80.9445 77.6242 76.5707 81.3739 70.9588C85.1236 65.347 87.125 58.7493 87.125 52C87.1155 42.9524 83.5171 34.2781 77.1195 27.8805C70.7219 21.4829 62.0476 17.8846 53 17.875ZM50.375 36.25C50.375 35.5538 50.6516 34.8861 51.1439 34.3938C51.6361 33.9016 52.3038 33.625 53 33.625C53.6962 33.625 54.3639 33.9016 54.8562 34.3938C55.3485 34.8861 55.625 35.5538 55.625 36.25V54.625C55.625 55.3212 55.3485 55.9889 54.8562 56.4812C54.3639 56.9734 53.6962 57.25 53 57.25C52.3038 57.25 51.6361 56.9734 51.1439 56.4812C50.6516 55.9889 50.375 55.3212 50.375 54.625V36.25ZM53 70.375C52.2213 70.375 51.46 70.1441 50.8125 69.7114C50.1649 69.2788 49.6603 68.6638 49.3622 67.9443C49.0642 67.2248 48.9862 66.4331 49.1382 65.6693C49.2901 64.9055 49.6651 64.2039 50.2158 63.6533C50.7665 63.1026 51.468 62.7276 52.2318 62.5757C52.9956 62.4237 53.7873 62.5017 54.5068 62.7997C55.2263 63.0977 55.8413 63.6024 56.2739 64.2499C56.7066 64.8975 56.9375 65.6587 56.9375 66.4375C56.9375 67.4818 56.5227 68.4833 55.7842 69.2217C55.0458 69.9602 54.0443 70.375 53 70.375Z"
            fill="url(#paint0_linear_1992_9389)"
            />
          <defs>
            <linearGradient
              id="paint0_linear_1992_9389"
              x1="30"
              y1="23"
              x2="68.5"
              y2="81.5"
              gradientUnits="userSpaceOnUse"
              >
              <stop stop-color="#FFD0CD" />
              <stop offset="0.14" stop-color="#FFB0AA" />
              <stop offset="1" stop-color="#F46363" />
            </linearGradient>
          </defs>
        </svg>
      }
    </div>

    <h2 class="text-2xl font-semibold text-gray-900 mb-2">
      {{ title || 'Something went wrong' }}
    </h2>

    <p class="text-gray-600 mb-2">
      {{ subtitle || 'We encountered an error while processing your request.' }}
    </p>

    <div class="my-auto flex gap-4 justify-center items-center">
      <ng-container *ngTemplateOutlet="errorButtons"></ng-container>
      @if (!hasCustomButtons) {
        <button
          (click)="goBack()"
          class="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
          >
          Go Back
        </button>
      }
    </div>
  </div>
</div>
