import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { User, UserClass } from 'src/app/components/models/user.mode';
import { FavoritesService } from 'src/app/services/favorites.service';
import { SearchService } from 'src/app/services/search.service';
import { AuthStore } from 'src/app/stores/auth.store';
import { environment } from 'src/environments/environment';
import { catchError, EMPTY, firstValueFrom, tap } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { Asset } from 'src/app/components/models/asset.model';
import { NICKY_URLS } from 'src/app/utils/urls.constants';
import { UserProfilePictureComponent } from 'src/app/components/user-profile-picture/user-profile-picture.component';
import { ModalsService } from 'src/app/services/modals.service';
import { ShareModalComponent } from 'src/app/components/modals/share-modal/share-modal.component';



@Component({
  selector: 'app-public-profile',
  standalone: true,
  imports: [TranslateModule, MatTooltipModule, MatIconModule, RouterModule, UserProfilePictureComponent],
  templateUrl: './public-profile.component.html'
})
export class PublicProfileComponent implements OnInit {


  termsOfUseUrl = NICKY_URLS.TERMS_OF_USE;
  privacyPolicyUrl = NICKY_URLS.PRIVACY_POLICY;


  protected translate = inject(TranslateService);
  protected searchService = inject(SearchService);
  protected modalService = inject(ModalsService);
  protected favoritesService = inject(FavoritesService);
  protected toastrService = inject(ToastrService);

  
  public environment = environment;
  public alreadyFavorite = false;
  public profileInfo: any;


  protected authStore = inject(AuthStore);

  @Input('paymentProfile') paymentProfile: boolean = false;

  public profileNotFound: boolean = false;

  @Input('userId') userId!: string;

  @Input('target') target!: User;
  @Input('targetNick') targetNick!: string;
  @Input('targetCompany') targetCompany!: string;

  @Input('companyWebsite') companyWebsite!: string;

  @Input('paymentRequestInfo') paymentRequestInfo!: any;
  @Input('targetDomains') targetDomains: any;



  @Output() onCloseModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  public targetUser!: UserClass;
  selectedDomain!: any;

  targetInfo = {
    userId: this.userId,
    targetNick: this.targetNick,
  };

  publicProfile: boolean = true;



  private route = inject(ActivatedRoute);
  private router = inject(Router);
  protected userService = inject(UserService);
  userAcceptedAssets:Asset[] = [];
  profileImage: any;

  public url = environment.websiteUrl;

  ngOnInit(): void {
    this.authStore.currentUser();
    const shortId = this.route.snapshot.paramMap.get('shortId');

    if (shortId) {
      this.loadPublicProfile(shortId);
    }
  }

  private loadPublicProfile(shortId: string): void {
    this.searchService.getPublicProfileByShortId(shortId)
      .pipe(
        tap((response: any) => {
          if (!response) {
            this.router.navigate(['/error']);
            return;
          }
          this.profileInfo = {
            ...response,
            avatar: `${this.environment.api}User/get-public-profile-picture?userId=${response.id}`
          };
          this.loadProfileData();
        }),
        catchError((error) => {
          console.error('Failed to load public profile:', error);
          this.router.navigate(['/error']);
          return EMPTY;
        })
      )
      .subscribe();
  }

  private async loadProfileData(): Promise<void> {
    if (!this.profileInfo) return;
    
    await Promise.all([
      this.getTargetDomains(),
      this.getAcceptedUserAssets(),
      this.isAlreadyFavorite()
    ]);
  }

  async getAcceptedUserAssets() {
    const assets: Asset[] = await firstValueFrom(this.userService.getReceiverConnections(this.profileInfo.id));
    const savedAssetTickers = new Set<string>();

    this.userAcceptedAssets = assets.filter(asset => {
      if (!savedAssetTickers.has(asset.assetTicker)) {
        savedAssetTickers.add(asset.assetTicker);
        return true;
      }
      return false;
    });
  }

  async getTargetDomains() {
    const user = (await firstValueFrom(this.searchService.generalSearch(this.profileInfo.email))) as any[];
    this.targetDomains = user[0].domains;

    this.profileInfo.domains = this.targetDomains;
  }

  copyProfileUrl() {
    const profileUrl = `${window.location.origin}/s/${this.profileInfo.shortId}`;

    navigator.clipboard.writeText(profileUrl);
    this.toastrService.success(
      this.translate.instant('public-profile.theLinkToYourNickyPublicProfileHasBeenCopied'),
      '',
      {
        timeOut: 3000,
      },
    );
  }

  openPaymentFlow() {
    this.router.navigateByUrl(`/payment-report/${this.profileInfo.shortId}`)
  }

  deleteFavorite(email: string) {
    this.favoritesService.deleteFavorite(email).pipe(
      tap((response: any) => {
        this.toastrService.success(this.translate.instant('theNickHasBeenSuccessfullyRemoved'), '', {
          timeOut: 3000
        }),
        this.alreadyFavorite = false;
      }),
      catchError((error:any) => {
        console.log(error)
        return EMPTY;
      })
    ).subscribe();
  }

  isMyProfile() {
    return this.authStore.authenticated() && this.profileInfo.id === this.authStore.profile()?.id;
  }

  async openShareModal() {
    const res = await this.modalService.openModal(ShareModalComponent, { data: {profileInfo: this.profileInfo} })
    // this.modalService.openModal()
  }

  handleImageError(event: any, userName?: string) {
    event.target.onerror = null;
    // event.target.src = this.avatarService.getInitialAvatar(userName);
  }

  getPublicProfileInfo() {
    if (this.target && !this.target?.shortId) {
      this.getPublicProfileByUUID();
      return;
    } 
  }

  getPublicProfileByUUID() {
    this.searchService
      .uuidPublicProfile(this.userId)
      .pipe(
        tap((response: any) => {
          this.profileInfo = response;
          console.log(response);
          this.profileInfo.avatar = `${this.environment.api}User/get-public-profile-picture?userId=${this.userId}`
          this.getTargetDomains();
        }),
        catchError((error: any) => {
          console.log(error);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  public addFavorite() {
    if (!this.authStore.authenticated()) {
      this.toastrService.error('You have to login to add a favorite.');
      return;
    }
    this.alreadyFavorite = false;
    this.favoritesService
      .addFavorite(this.profileInfo.email)
      .pipe(
        tap((response: any) => {
          this.toastrService.success(this.translate.instant('userAddedToYourFavoritesSuccessfully'), '', {
            timeOut: 3000,
          });
          this.alreadyFavorite = true;
        }),
        catchError((error: any) => {
          console.log(error);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  isAlreadyFavorite() {
    this.favoritesService
      .searchFavorites(this.profileInfo.email)
      .pipe(
        tap((response: any) => {
          if (response.length > 0) {
            this.alreadyFavorite = true;
          } else {
            this.alreadyFavorite = false;
          }
          console.log(response);
        }),
        catchError((error: any) => {
          this.alreadyFavorite = false;
          return EMPTY;
        }),
      )
      .subscribe();
  }

 
}
