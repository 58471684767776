import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import * as auth0 from 'auth0-js';
import { environment } from 'src/environments/environment';
import { createHttpOptions } from '../utils/http-utils';

@Injectable()
export class FavoritesService {
  private webAuth = new auth0.WebAuth({
    domain: environment.auth0Domain,
    clientID: environment.auth0ID
  });

  constructor(
    public route: Router,
    private http: HttpClient,
    public auth: AuthService
  ) { }


  getUserFavorites() {
    return this.http.get(
      `${environment.api}FavoriteNick`,
      createHttpOptions()
    );
  }

  deleteFavorite(nick: string) {
    return this.http.delete(
      `${environment.api}FavoriteNick?nick=${nick}`,
      createHttpOptions()
    );
  }

  async favoriteByNick(nick: string) {
    return this.http.get(
      `${environment.api}FavoriteNick/search?q=${nick}`,
      createHttpOptions()
    );
  }

  addFavorite(nick: string) {
    const body = {};
    return this.http.post(
      `${environment.api}FavoriteNick?nick=${nick}&type=Email`,
      body,
      createHttpOptions('*/*')
    );
  }

  searchFavorites(nick: string) {
    return this.http.get(
      `${environment.api}FavoriteNick/search?q=${nick}`,
      createHttpOptions()
    );
  }
}





