
<div class="modal-backdrop">
    <div class="modal wallet-modal" *ngIf="currentOptionsSelects.fiats.length > 0 &&  currentOptionsSelects.currentOffRampSettings.length > 0">
        <div class="modal-header">
            <h3> {{ "PROFILE.chooseTheOffRampConnectionAndTheAmountToProceed" | translate }} </h3>
        </div>
        <div class="exchange-wrapper">
            <div class="wallet-input-wrapper off-ramp-setting-selection mobile-wrap">
                <!-- <label class="only-desktop" [ngClass]="{'label-min-width': configurationExchange == 'private'}">{{ 'PROFILE.iWishToReceiveItHere' | translate }}</label> -->
                <app-custom-option-select [configurationExchange]="currentOptionsSelects.currentOffRampSettings"
                [currentValue]="this.manualOffRampSettings.cryptoConnectionId"
                (changedValue)="this.manualOffRampSettings.cryptoConnectionId = $event; getManualOffRampFee();"
                ></app-custom-option-select>
            </div>
            <div>
                <div class="wallet-input-wrapper target-fiat">
                    <label class="label-min-width">{{ "PROFILE.targetFiat" | translate }}:</label>
                    <app-custom-option-select [configurationExchange]="currentOptionsSelects.fiats"
                    [currentValue]="this.manualOffRampSettings.assetId"
                    (changedValue)="this.manualOffRampSettings.assetId = $event; getManualOffRampFee();"
                ></app-custom-option-select>
                <div class="wallet-input-wrapper off-ramp-info-wrapper flex-wrap" *ngIf="currentFee">
                    <label class="off-ramp-info"> 
                      {{ 'PROFILE.fees' | translate }}: <br>{{ '$' + formatCurrency.convertToLocal(currentFee.toString() || '0',getDecimalPlaces('USD.USD'))  }}
                    </label>
                    <!-- <label class="off-ramp-info">                          
                        {{ 'available' | translate }}: <br>{{ '$' + formatCurrency.convertToLocal(maxTransferAmount?.toString() || '0',getDecimalPlaces('USD.USD'))  }}
                    </label> -->
                </div>
                </div>
                <label class="off-ramp-description doesnt-support-label" *ngIf="notSupportManual">
                    {{ 'PROFILE.thisConnectionDoesntSupport' | translate }} 
                    {{ this.manualOffRampSettings.assetId ? this.manualOffRampSettings.assetId.split('.')[0] : '' }}.
                </label>
                <div class="configuration-inputs" *ngIf="!notSupportManual">
                    <div class="wallet-input-wrapper">
                      
                        <label *ngIf="!withdrawAll">{{ 'DASHBOARD.amount' | translate }}: </label>
                        <input *ngIf="!withdrawAll" class="manual-off-ramp-input"
                        currencyMask
                        [options]="customCurrencyMaskConfig"
                        type="text"
                        inputmode="numeric"
                        [(ngModel)]="this.manualOffRampSettings.amount"
                        name="USD"
                        [disabled]="withdrawAll || false"
                        placeholder="{{'0' + (isCommaDecimal ? ',' : '.') + '00'}}" >
                        <div [ngStyle]="{'margin-left': withdrawAll ? '0px' : ''}" class="withdraw-all-wrapper flex-centered">
                            <input (change)="setWithdrawAll();" type="checkbox">
                            {{ 'withdrawAll' | translate }}
                        </div>
                    </div>
                    <label class="fee-inform">{{ 'feesMayVary' | translate }}</label>
                </div>
            </div>
            <div class="buttons-wrapper">
                <a (click)="closeModal()" class="clickable"> {{ 'cancel' | translate }} </a>
                <button class="save-changes" [ngClass]="{'disabled-save': !enabledOffRampButton()}"
                [disabled]="!enabledOffRampButton()"
                (click)="startOffRamp()"> {{ 'PROFILE.startOffRamp' | translate }} </button>
            </div>
        </div>
    </div>
</div>
