// src/app/services/wallet-validator.service.ts
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import cryptoAddressValidator from '@yieldapp/crypto-address-detector';
import { validate } from 'crypto-address-validator-ts';
import { firstValueFrom } from 'rxjs';
import { tronApiValidateAddress } from 'src/environments/environment';
import { formatEthOrMaticAddress } from '../utils/format-eth-matic-address.utils';
import { createHttpOptions } from '../utils/http-utils';

@Injectable({
  providedIn: 'root'
})
export class WalletValidatorService {

  constructor(private http: HttpClient) { }

  async validateAddress(address: string): Promise<string[]> {
    let enabledNetworks = await this.detectAddress(address);

    if (enabledNetworks.length === 0) {
      enabledNetworks = await this.detectTronAddress(address);
    }

    if (!address?.startsWith('0x') && enabledNetworks.length === 0) {
      enabledNetworks = await this.detectAddress(formatEthOrMaticAddress(address));
    }

    return enabledNetworks;
  }

  private async detectTronAddress(address: string): Promise<string[]> {
    try {
      const validTronWallet = await firstValueFrom(this.validateTronAddress(address)) as any;
      if (validTronWallet.result) {
        return ['TRC'];
      }
    } catch (error) {
      console.error('Error validating Tron address:', error);
    }
    return [];
  }

  private async detectAddress(address: string): Promise<string[]> {
    const acceptedNetworks = await cryptoAddressValidator.detect(address.trim());
    return acceptedNetworks?.map((network: string) => this.getNetworkName(network, address)) || [];
  }

  private getNetworkName(network: string, address: string): string {
    switch (network) {
      case 'polygon':
        return 'MATIC';
      case 'btc':
      case 'bitcoin':
        return validate(address, 'btc', { chainType: 'btc', networkType: 'prod' }) ? 'BTC' : network;
      case 'eth':
      case 'ethereum':
        console.log(validate(address, 'ethereum', { chainType: 'ethereum', networkType: 'prod' }), 'ethereum');
        return 'ETH';
      default:
        return network;
    }
  }

  private validateTronAddress(address: string) {

    const body = { address };

    return this.http.post(tronApiValidateAddress, body, createHttpOptions());
  }
}
