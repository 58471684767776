<div
  class="profile-wrapper"
  id="welcome-profile"
  [ngClass]="{ 'payment-profile-wrapper': paymentProfile }"
  #profileWrapper
  *ngIf="!profileNotFound"
  [ngStyle]="{ 'margin-top': overHeightProfile(profileWrapper) ? '28px' : '' }"
>
  <label class="short-id" style="visibility: hidden" id="get-short-id">{{ profileInfo?.shortId }}</label>
  <div *ngIf="!isMobile" class="gradient-bg green-gradient"></div>
  <div class="public-profile" *ngIf="finishAssetsLoad">
    <div *ngIf="this.selectedDomain" class="open-domain-info">
      <mat-icon class="back-button clickable" (click)="this.selectedDomain = undefined" aria-label="back">
        arrow_back
      </mat-icon>
      <div class="showing-domain">
        <a [href]="url + '/XPTO123'"> {{ url + '/XPTO123' }} </a>
        <div class="copy-button">
          <mat-icon class="clickable" [svgIcon]="'copy-icon'" [matTooltip]="'copy'"></mat-icon>
        </div>
      </div>
      <div>
        <mat-icon class="clickable" aria-label="share on twitter" role="img"> tag </mat-icon>

        <mat-icon class="clickable" aria-label="share on facebook" role="img"> facebook </mat-icon>

        <mat-icon class="clickable" aria-label="share on whatsapp" role="img"> whatsapp </mat-icon>

        <mat-icon class="clickable" aria-label="share via email" role="img"> email </mat-icon>
      </div>
    </div>
    <div class="user-info">
      <div class="personal-info">
        <img
          class="user-img"
          [src]="environment.api + 'User/get-public-profile-picture?userId=' + profileInfo.id"
          alt="user-image"
          (error)="handleImageError($event, profileInfo.publicName)"
        />
        <div class="common-info">
          <div>
            <h3 title="{{ profileInfo?.publicName }}" class="public-name">{{ profileInfo.publicName }}</h3>
          </div>
          <div class="nick-types">
            <div class="info-toggle">
              <i
                class="ph-fill ph-envelope"
                #tooltip="matTooltip"
                (click)="tooltip.toggle()"
                [matTooltipPosition]="'above'"
                matTooltip="{{ 'weHaveVerifiedTheOwnershipOfThisUserEmail' | translate }}"
              ></i>

              <div *ngIf="profileInfo.infoToggle" class="domain-info" (click)="toggleInfo()">
                <label>
                  {{ 'weHaveVerifiedTheOwnershipOfThisUserEmail' | translate }}
                </label>
              </div>
            </div>

            <div class="info-toggle domain-toggle" *ngFor="let domain of profileInfo.domains">
              <div *ngIf="domain.infoToggle" class="domain-info">
                <label>
                  {{
                    'weHaveVerifiedThatThisUserIsTheOwnerOfTheDomain'
                      | translate
                        : {
                            domainName: domain.name,
                          }
                  }}
                </label>
              </div>
              <i
                class="ph ph-globe-simple"
                #tooltip="matTooltip"
                (click)="tooltip.toggle()"
                [matTooltipPosition]="'above'"
                matTooltip="{{
                  'weHaveVerifiedThatThisUserIsTheOwnerOfTheDomain'
                    | translate
                      : {
                          domainName: domain.name,
                        }
                }} "
              ></i>
            </div>
          </div>
        </div>
      </div>

      <div class="user-actions" *ngIf="!paymentProfile">
        <mat-icon svgIcon="help-book" class="help-book clickable" id="bookIcon" (click)="startTour()"></mat-icon>
        <i
          id="share-profile"
          class="pay button ph ph-share-network clickable"
          (click)="shareModal = true"
          [matTooltipPosition]="'above'"
          matTooltip="{{ 'share' | translate }}"
        ></i>

        <i
          id="edit-general-section"
          *ngIf="isMyProfile()"
          class="ph button clickable ph-pencil-simple-line"
          (click)="openSettings()"
        ></i>
        <button
          class="secondary-button"
          (click)="addAsFavorite()"
          *ngIf="!isMyProfile() && !alreadyFavorite && isAuthenticated"
        >
          <i class="ph ph-plus"></i>
          {{ 'public_profile.connect' | translate }}
        </button>
        <div
          class="toggle-selection selected-toggle clickable"
          (click)="deleteNick(profileInfo.email)"
          *ngIf="!isMyProfile() && alreadyFavorite && isAuthenticated"
        >
          <div class="toggle-icons">
            <i class="ph ph-check"></i>
          </div>
          <h2>{{ 'public_profile.connected' | translate }}</h2>
        </div>
      </div>

      <label
        id="short-id"
        class="short-id"
        #tooltip="matTooltip"
        (click)="!isMobile || tooltip.toggle()"
        [matTooltipPosition]="'above'"
        matTooltip="{{ 'theUserShortIdIsAShort' | translate }}"
        >{{ profileInfo?.shortId }}</label
      >
    </div>
    <!-- <hr class="spacement"> -->
    <div class="connectivity-info">
      <div class="upper-content">
        <div class="buttons-wrapper" *ngIf="publicProfile && finishAssetsLoad" id="register-payment">
          <button
            [ngClass]="{ disabled: noWallets() }"
            class="clickable pay-me"
            (click)="noWallets() || openPaymentFlow()"
          >
            {{ 'public-profile.pay' | translate }}
          </button>
          <div class="bottom-content doesnt-accept-wrapper">
            <label class="user-description doesnt-accept" *ngIf="noWallets()">
              {{ 'thisUserIsNotCurrentlyAcceptingPayments' | translate }}</label
            >
          </div>
        </div>
        <div class="user-nicks" id="check-nicks">
          <div *ngIf="!noWallets()" id="check-assets" class="user-active-assets">
            <label class="property-name pay-me-with">{{ 'public-profile.payMeWith' | translate }}</label>
            <mat-icon
              *ngFor="let asset of activeAssets"
              #tooltip="matTooltip"
              (click)="!isMobile || tooltip.toggle()"
              [matTooltip]="asset"
              [matTooltipPosition]="'above'"
              [svgIcon]="asset"
            ></mat-icon>
          </div>
        </div>
      </div>

      <div class="bottom-content">
        <div class="user-description clickable">
          <pre>{{ profileInfo?.bio?.trim() }}</pre>
        </div>

        <button *ngIf="paymentProfile" class="clickable pay-me back-to-payment" (click)="backToPayment()">
          {{ 'backToPayment' | translate }}
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="shareModal" class="modal-backdrop">
    <div class="modal share-modal">
      <div class="modal-header flex-centered">
        <h3>{{ 'share' | translate }}</h3>
        <!-- <i class="ph-bold ph-x clickable" (click)="shareModal = false"></i> -->
      </div>
      <hr class="spacement" />
      <div class="modal-body">
        <div class="qr-code-wrapper">
          <div class="nick-wallet-code">
            <div class="copy-code">
              <!-- <label *ngIf="qrError" class="qr-error"> {{ 'PAYMENT_REPORT.sorryQrCodeGenerationIsUnavailable' | translate }} </label> -->
              <qrcode
                id="qr-code"
                class="qr-code"
                [qrdata]="getProfileUrl()"
                [allowEmptyString]="true"
                [cssClass]="'center'"
                [colorDark]="'#000000ff'"
                [colorLight]="'#ffffffff'"
                [elementType]="'canvas'"
                [errorCorrectionLevel]="'M'"
                [margin]="4"
                [scale]="1"
                [width]="isMobile ? 120 : 173"
                [title]="'A custom title attribute'"
              ></qrcode>
            </div>
          </div>
        </div>

        <div class="input-wrapper">
          <label> {{ 'public_profile.copyProfileLink' | translate }} </label>
          <div class="input-container" (click)="copyProfileUrl()" id="profile-link">
            <i id="copy-link" class="ph ph-copy"></i>
            <input [disabled]="true" type="text" [value]="getProfileUrl()" />
          </div>
        </div>
      </div>
      <hr class="spacement" />
      <div class="modal-buttons-wrapper">
        <!-- <button  (click)="shareModal = false" class="cancel-button"> {{ 'cancel' | translate }} </button> -->
        <button (click)="shareModal = false" id="close-button">{{ 'close' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div class="profile-wrapper" *ngIf="profileNotFound">
  <div class="public-profile profile-not-found">
    <mat-icon svgIcon="profile-not-found"></mat-icon>

    <h1>
      {{ 'profileNotFound' | translate }}
    </h1>
    <h3>
      {{ 'theProfileThatYoureSearchingDoesNotExist' | translate }}
      <br *ngIf="!isMobile" />{{ 'mayHaveBeenDeletedOrChangedToADifferenteAddress' | translate }}
    </h3>
  </div>
</div>

<div class="terms" *ngIf="finishAssetsLoad && !paymentProfile">
  <a class="clickable" [href]="privacyPolicyUrl" target="_blank">{{ 'PUBLIC_PROFILE.privacy' | translate }}</a>
  <a class="clickable" [href]="tearmsOfUseUrl" target="_blank">{{ 'PUBLIC_PROFILE.terms' | translate }} </a>
</div>
