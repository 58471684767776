import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

export const loginGuard: CanActivateFn = (route, state) => {
  const auth = inject(AuthService);
  
  auth.loginWithRedirect({
    appState: {
      target: '/overview',
      language: localStorage.getItem('defaultLang'),
    },
    authorizationParams: {
      ui_locales: navigator.language || 'en',
    }
  });

  return false; // Return false to prevent route activation while redirecting
}; 