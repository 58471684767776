import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { createHttpOptions } from '../utils/http-utils';



@Injectable()
export class NotificationService {

    constructor(private http: HttpClient) { }

    getNotifications() {
        return this.http.get(
            `${environment.api}/Notification/all?onlyUnread=true`,
            createHttpOptions()
        );
    }

    markAsRead(notificationId: string) {
        return this.http.post(
            `${environment.api}/Notification/mark-read?notificationId=${notificationId}`,
            '',
            createHttpOptions('*/*')
        );
    }
}
