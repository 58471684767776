import {
  Component,
  inject,
  OnInit,
  Input,
} from '@angular/core';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map } from 'rxjs/internal/operators/map';
import { take } from 'rxjs/internal/operators/take';

@Component({
  selector: 'app-header-public',
  standalone: true,
  imports: [],
  templateUrl: './header-public.component.html',
})
export class HeaderPublicComponent implements OnInit {
  protected router = inject(Router);
  protected activatedRoute = inject(ActivatedRoute);

  @Input() fullWidthMode = false;
  routeTitle: string = '';
  routerSubscription: any;

  ngOnInit(): void {
    // Set the     // Set the initial title
    this.routeTitle = this.getRouteTitle(this.activatedRoute);

    // Subscribe to NavigationEnd events to update the title on route changes
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      startWith(null),  // Triggers the subscription logic immediately
      map(() => this.getRouteTitle(this.activatedRoute))
    ).subscribe(title => {
      this.routeTitle = title;
    });
  }


  /**
   * Traverses the route tree to find the deepest child route and retrieves the title.
   * @param route The current activated route.
   * @returns The title of the route or 'Default Title' if not found.
   */

  private getRouteTitle(route: ActivatedRoute): string {
    let currentRoute: ActivatedRoute = route;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }

    // Attempt to retrieve the title from routeConfig or data
    const routeConfigTitle = currentRoute.snapshot.routeConfig?.title;
    let dataTitle = '';
    currentRoute.title.pipe(take(1)).subscribe((title) => {
      dataTitle = title || '';
    });

    if (typeof routeConfigTitle === 'string') {
      return routeConfigTitle;
    } else if (typeof dataTitle === 'string') {
      return dataTitle;
    } else {
      return 'Default Title';
    }
  }

  goHome() {
    this.router.navigate(['/overview']);
  }

}
