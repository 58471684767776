import { HttpHeaders } from '@angular/common/http';

export function createHttpOptions(
  acceptType: string = 'text/plain',
  contentType: string = 'application/json',
  accessToken?: string,
) {
  let headersConfig: { [key: string]: string } = {
    'Content-Type': contentType,
    Accept: acceptType,
  };

  return {
    headers: new HttpHeaders(headersConfig),
  };
}
