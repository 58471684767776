<app-payment
  [isAuthenticated]="isAuthenticated"
  [paymentModal]="paymentFlow"
  [assets]="assets"
  (onCloseModal)="closeModal($event)"
  [targetUser]="targetUser"
  *ngIf="paymentFlow"
  [user]="user"
  [paymentRequestInfo]="requestInfo"
></app-payment>

<app-public-profile
  [profileNotFound]="profileNotFound"
  [targetDomains]="targetDomains"
  [target]="target"
  [selectedLanguage]="selectedLanguage"
  [isAuthenticated]="isAuthenticated"
  [userId]="userId"
  [targetNick]="targetNick"
  [targetCompany]="targetCompany"
  [companyWebsite]="companyWebsite"
  [user]="user"
  [paymentRequestInfo]="requestInfo"
  *ngIf="userProfile || this.profileNotFound"
  [assets]="assets"
  (onCloseModal)="closeModal($event)"
></app-public-profile>

<app-modal
  [modalTittle]="modalTittle"
  [modalMessage]="modalMessage"
  [dashBoardOptions]="dashBoardOptions"
  [isAuthenticated]="isAuthenticated"
  [fullEmailModal]="fullEmailModal"
  [modalPublicName]="modalPublicName"
  [modalNick]="modalNick"
  [exchangeModal]="exchangeModal"
  (onCloseModal)="closeModal($event)"
  (onClickFavorite)="addFavorite($event)"
  *ngIf="showModal"
></app-modal>

<app-header
  (closeProfile)="closeModal($event)"
  *ngIf="paymentFlow || userProfile || profileNotFound"
  (userSelectedLanguage)="selectedLanguage = $event"
  [fromHome]="true"
></app-header>

<div class="modal-backdrop" *ngIf="manyUsersModal">
  <div class="modal message-modal">
    <div class="modal-header">
      <h3>{{ modalTittle }}</h3>
      <!-- <h3 class="close"> X </h3> -->
    </div>
    <div class="modal-body">
      <p [innerHTML]="modalMessage"></p>
      <div class="check-email clickable">
        <input type="checkbox" [(ngModel)]="notify" (onChange)="notify = !notify" checked="true" />
        <label (click)="notify = !notify"> {{ 'iWantToBeNotified' | translate }}</label>
      </div>
    </div>
    <div class="modal-footer">
      <button (click)="logoutUser()">{{ 'backToOurWebsite' | translate }}</button>
    </div>
  </div>
</div>

<div class="home" (click)="toggleMenu()"></div>
