import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import * as auth0 from 'auth0-js';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CryptoConnectionDTO } from '../dtos/cryto-connection.dto';
import { ProfileDto } from '../dtos/user-profile.dto';
import { ToastrFactoryService } from './toastr-factory.service';
import { createHttpOptions } from '../utils/http-utils';
import { subscribe } from 'diagnostics_channel';
import { tap, catchError, EMPTY } from 'rxjs';

@Injectable()
export class UserService {
  private webAuth = new auth0.WebAuth({
    domain: environment.auth0Domain,
    clientID: environment.auth0ID,
    responseType: 'token id_token',
    audience: environment.auth0Domain + '/api/v2/',
    scope: 'openid profile email update:current_user_metadata',
    redirectUri: window.location.origin,
  });

  constructor(
    public route: Router,
    private http: HttpClient,
    private toastrFactory: ToastrFactoryService,
    private toastrService: ToastrService
  ) {}

  private success!: boolean;
  private JWTToken!: string;

  getUser() {
    return this.http.get(environment.api + 'user/Profile', createHttpOptions());
  }

  updateProfilePicture(image: any) {
    const formData = new FormData();
    formData.append('file', image);
    return this.http.post(
      environment.api + 'User/set-profile-picture',
      formData
    );
  }

  getProfilePicture() {
    return this.http.get(environment.api + 'User/get-profile-picture', {
      ...createHttpOptions('image/*'),
      responseType: 'blob' as 'json',
    });
  }

  getDomain() {
    return this.http.get(environment.api + 'Domain/all', createHttpOptions());
  }

  addDomain(domain: string) {
    const body = JSON.stringify(domain);
    return this.http.post(
      environment.api + 'Domain/add',
      body,
      createHttpOptions('*/*', 'application/json')
    );
  }

  public editGeneralUserInfo(userProfile: ProfileDto) {
    return this.http.post(
      environment.api + 'User/profile',
      userProfile,
      createHttpOptions(undefined, 'application/json')
    );
  }

  getUserImageById(userId: string) {
    return this.http.get(
      environment.api + 'User/get-public-profile-picture?userId=' + userId,
      createHttpOptions('*/*')
    );
  }

  public checkDNS(domain: any) {
    const body = JSON.stringify(domain);
    return this.http.post(
      environment.api + 'Domain/force-check',
      body,
      createHttpOptions('*/*', 'application/json')
    );
  }

  public getCryptoConnections() {
    return this.http.get(
      environment.api + 'CryptoConnection/all',
      createHttpOptions()
    );
  }

  getReceiverConnections(userId: string) {
    return this.http.get(
      environment.api +
        'UserAssetConnection/all-assets-for-userid?userId=' +
        userId,
      createHttpOptions()
    );
  }

  checkExchangeConnection(exchangeId: string) {
    return this.http.post(
      environment.api + 'CryptoConnection/check-if-valid?id=' + exchangeId,
      {},
      createHttpOptions(undefined, 'application/json')
    );
  }

  deleteUserAssetConnection(connectionId: string) {
    return this.http.delete(
      environment.api + 'UserAssetConnection?id=' + connectionId,
      createHttpOptions(undefined, 'application/json')
    );
  }

  public allUserConnections() {
    return this.http.get(
      environment.api + 'UserAssetConnection/all',
      createHttpOptions()
    );
  }

  public addCryptoConnection(connectionDto: CryptoConnectionDTO) {
    return this.http.post(
      environment.api + 'CryptoConnection/add',
      connectionDto,
      createHttpOptions(undefined, 'application/json')
    );
  }

  public getAcceptedAssets() {
    return this.http.get(
      environment.api + 'AcceptedAsset/get',
      createHttpOptions()
    );
  }

  public getAcceptableAssetsByExchange() {
    return this.http.get(
      environment.api + 'CryptoConnection/available',
      createHttpOptions()
    );
  }

  public getAcceptedAssetsById(userId: any) {
    return this.http.get(
      environment.api + 'AcceptedAsset/get-for-user?userId=' + userId,
      createHttpOptions()
    );
  }

  public setAcceptedAssets(assets: string[], accessToken?: string) {
    return this.http.post(
      environment.api + 'AcceptedAsset/set',
      assets,
      createHttpOptions(undefined, 'application/json', accessToken)
    );
  }

  public associateConection(
    blockchainAssetId: String,
    cryptoConnectionId: string
  ) {
    const body = {
      blockchainAssetId: blockchainAssetId,
      cryptoConnectionId: cryptoConnectionId,
    };
    return this.http.post(
      environment.api + 'UserAssetConnection/add',
      body,
      createHttpOptions()
    );
  }

  public removeCryptoConnection(connectionId: string) {
    return this.http.delete(
      environment.api + 'CryptoConnection?id=' + connectionId,
      createHttpOptions(undefined, 'application/json')
    );
  }

  fullyLogout(url: string) {
    return this.http.get(
      environment.auth0Domain +
        `/v2/logout?returnTo=${url}&client_id=${environment.auth0ID}`
    );
  }

  public passwordlessAuthentication(email: any) {
    this.userIsValidated(email).pipe(
      tap((response: any) => {
        if (response == false) {
          this.toastrService.error(
            'We did not find any valid user with this e-mail. If you have registered this e-mail as yours, please check your mailbox for validation directions or try to login with your password to receive the directions again',
            '',
            {
              timeOut: 10000,
            }
          );
          return;
        }
        if (response == true) {
          this.webAuth.passwordlessStart(
            {
              connection: 'email',
              send: 'link',
              email: email,
              authParams: {
                redirectUri: window.location.href.replace('login', 'overview'),
              },
            },
            (err, authResult) => {
              if (authResult) {
                this.toastrService.success(
                  'We have sent a login link to the given e-mail, please check your mailbox.'
                );
                return;
              } else {
                console.log(err);
                if (err?.code == 'bad.email') {
                  this.toastrService.error(
                    'Invalid email, check if the email is correct.'
                  );
                  return;
                }
                this.toastrService.error(
                  'Something went wrong sending you a magic link'
                );
                return;
              }
            }
          );
        }
      }),
      catchError((error:any) => {
        console.log(error);
        return EMPTY;
      })
    ).subscribe();
  }

  public deleteUser() {
    return this.http.delete(environment.api + 'User/delete');
  }

  public setUserPreferedLanguage(ISOlanguage: string, accessToken?: string) {
    const body = `"${ISOlanguage}"`;
    return this.http.post(
      environment.api + 'User/set-language',
      body,
      createHttpOptions('text/plain', 'application/json', accessToken)
    );
  }

  public userIsValidated(email: any) {
    return this.http.post(
      environment.api + 'User/isValidated?email=' + email,
      createHttpOptions()
    );
  }

  public userIsValidatedWithDetails(email: any) {
    return this.http.post(
      environment.api + 'User/isValidatedWithDetails?email=' + email,
      createHttpOptions()
    );
  }

  public getBalances() {
    return this.http.post(
      environment.api + 'Balance/all',
      '',
      createHttpOptions('text/plain', 'application/json')
    );
  }
}
