import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-shared-input',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SharedInputComponent,
      multi: true
    }
  ],
  templateUrl: './shared-input.component.html'
})
export class SharedInputComponent implements ControlValueAccessor {
  @Input() label: string = '';
  @Input() type: string = 'text';
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Input() control: any;
  @Input() errorMessage: string = 'Invalid input';
  @Input() inputClass: string = 'w-full px-4 py-2.5 border border-primary-500 rounded-lg focus:ring-1 focus:ring-primary focus:border-primary bg-white';
  @Input() icon?: string;
  @Input() iconPosition: 'left' | 'right' = 'left';

  public onChange: (value: any) => void = () => {};
  public onTouched: () => void = () => {};

  writeValue(value: any): void {
    if (this.control) {
      this.control.setValue(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      this.onChange(inputElement.value);
    }
  }

  get isRequired(): boolean {
    if (this.control?.hasValidator(Validators.required)) {
      return true;
    }
    return false;
  }

  get inputContainerClass(): string {
    return `relative ${this.icon ? 'flex items-center' : ''}`;
  }

  get modifiedInputClass(): string {
    if (!this.icon) return this.inputClass;
    
    return `${this.inputClass} ${
      this.iconPosition === 'left' ? 'pl-10' : 'pr-10'
    }`;
  }
} 