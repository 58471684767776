import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  private assetTickers: string[] = ['BRL', 'BTC', 'DKK', 'ETH', 'EUR', 'GBP', 'OTHER', 'SEK', 'USD', 'USDC', 'USDT'];
  private balanceIcons: string[] = this.assetTickers.map(ticker => `balance-${ticker}`);

  private payingAssets: string[] = [
    'BTC',
    'ETH.USDC',
    'MATIC.POL',
    'USD',
    'BTC',
    'ETH.USDT',
    'MATIC.USDC',
    'USDT',
    'DKK',
    'ETH',
    'MATIC.USDT',
    'EUR',
    'POL',
    'USDC',
    'BRL',
    'GBP', 
    'OTHER',
    'SEK'
  ];

  private dataSources: string[]  = ['coinbase', 'kraken', 'manual', 'manualwallet', 'foxbit']; 


  private flags: string[] = ['en', 
    'hl',
    'ar',  
    'da',
    'de',
    'es',
    'he',
    'hi',
    'nl',
    'pt-br'
  ];

  private socialIcons: string[] = ['facebook', 'instagram', 'twitter', 'linkedin', 'youtube', 'apple', 'github', 'discord', 'microsoft', 'google'];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.registerIcons();
    this.registerAssetIcons();
    this.registerPayingAssetIcons();
    this.registerDataSourceIcons();
    this.registerFlagsIcons();
    this.registerBalanceIcons();
    this.registerSocialIcons();
  }

  private registerIcons(): void {
    const iconNames = [
      'help-book',  
      'copy-icon',
      'profile-not-found',
      'add-wallet',
      'success-payment',
      'no-contacts',
      'full-transactions',
      'pay',
      'blank-pay',
      'error',
      'checked-user',
      'paid-filter',
      'paid-filter-active',
      'filter',
      'active-filter',
      'seal-check',
      'no-transactions',
      'drop-button',
      'facebook',
      'instagram',
      'twitter',
      'linkedin',
      'youtube',
      'apple',
      'github',
      'discord',
      'microsoft',
      'google'
    ];

    const svgSupported = this.isSvgSupported();

    for (const iconName of iconNames) {
      const iconUrl = svgSupported ? `assets/icons/${iconName}.svg` : `assets/icons/${iconName}.png`;
      this.matIconRegistry.addSvgIcon(
        iconName, 
        this.domSanitizer.bypassSecurityTrustResourceUrl(iconUrl)
      );
    }
  }

  private registerAssetIcons(): void {
    const svgSupported = this.isSvgSupported();

    for (const ticker of this.assetTickers) {
      const iconUrl = svgSupported 
        ? `assets/icons/paying-asset/${ticker}.svg` 
        : `assets/icons/paying-asset/${ticker}.png`;
      
      this.matIconRegistry.addSvgIcon(
        `${ticker}`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(iconUrl)
      );
    }
  }

  private registerFlagsIcons(): void {
    const svgSupported = this.isSvgSupported();

    for (const ticker of this.flags) {
      const iconUrl = svgSupported 
        ? `assets/icons/flags/${ticker}.svg` 
        : `assets/icons/flags/${ticker}.png`;
      
      this.matIconRegistry.addSvgIcon(
        ticker,
        this.domSanitizer.bypassSecurityTrustResourceUrl(iconUrl)
      );
    }
  }

  private registerPayingAssetIcons(): void {
    const svgSupported = this.isSvgSupported();

    for (const payingAsset of this.payingAssets) {
      const iconUrl = svgSupported 
        ? `assets/icons/paying-asset/${payingAsset}.svg` 
        : `assets/icons/paying-asset/${payingAsset}.png`;

      this.matIconRegistry.addSvgIcon(
        payingAsset,
        this.domSanitizer.bypassSecurityTrustResourceUrl(iconUrl)
      );
    }
  } 

  private registerDataSourceIcons(): void {
    const svgSupported = this.isSvgSupported();

    this.dataSources.forEach(source => {
      const lowercaseSource = source.toLowerCase();
      const iconUrl = svgSupported 
        ? `assets/icons/data-source/${lowercaseSource}.svg` 
        : `assets/icons/data-source/${lowercaseSource}.png`;

      this.matIconRegistry.addSvgIcon(
        lowercaseSource,
        this.domSanitizer.bypassSecurityTrustResourceUrl(iconUrl)
      );
    });
  } 

  private registerBalanceIcons(): void {
    const svgSupported = this.isSvgSupported();

    this.balanceIcons.forEach(ticker => {
      const iconUrl = svgSupported 
        ? `assets/icons/balance/${ticker}.svg` 
        : `assets/icons/balance/${ticker}.png`;

      this.matIconRegistry.addSvgIcon(
        `${ticker}`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(iconUrl)
      );
    });
  }

  private registerSocialIcons(): void {
    const svgSupported = this.isSvgSupported();

    this.socialIcons.forEach(ticker => {
      const iconUrl = svgSupported 
        ? `assets/icons/social/${ticker}.svg` 
        : `assets/icons/social/${ticker}.png`;

      this.matIconRegistry.addSvgIcon(
        `${ticker}`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(iconUrl)
      );
    });
  } 

  private isSvgSupported(): boolean {
    const SVG_NS = 'http://www.w3.org/2000/svg';
    return !!document.createElementNS && !!document.createElementNS(SVG_NS, 'svg').createSVGRect;
  }
}
