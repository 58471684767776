import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { ToastrFactoryService } from 'src/app/services/toastr-factory.service';

@Component({
  selector: 'app-share-modal',
  standalone: true,
  imports: [TranslateModule, QRCodeModule],
  templateUrl: './share-modal.component.html'
})
export class ShareModalComponent {
  protected toastrFactory = inject(ToastrFactoryService);
  protected translate = inject(TranslateService);

  protected dialogRef = inject(DialogRef);
  protected data = inject(DIALOG_DATA);

  copyProfileUrl() {
    const profileUrl = `${window.location.origin}/s/${this.data.profileInfo.shortId}`;

    navigator.clipboard.writeText(profileUrl);
    this.toastrFactory.success(
      this.translate.instant('public-profile.theLinkToYourNickyPublicProfileHasBeenCopied'),
      '',
      {
        timeOut: 3000,
      },
    );
  }


  getProfileUrl() {
    return `${window.location.origin}/s/${this.data.profileInfo.shortId}`;
  }
}
