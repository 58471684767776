import { Component } from '@angular/core';

@Component({
  selector: 'app-confirmation-step',
  standalone: true,
  imports: [],
  templateUrl: './confirmation-step.component.html',
  styleUrl: './confirmation-step.component.scss'
})
export class ConfirmationStepComponent {

}
