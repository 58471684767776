import { inject, Injectable } from '@angular/core';
import { AssetStore } from '../stores/asset.store';
// import { decimalPlacesFor } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AssetIdFormatterUtil {
  private assetStore = inject(AssetStore);

  formatAssetId(asset: any) {
    if (asset.blockchainAsset.id === 'POL') {
      asset.blockchainAsset.id = 'MATIC.POL';
    } else if (asset.blockchainAsset.id === 'USDC' || asset.blockchainAsset.id === 'USDT') {
      asset.blockchainAsset.id = 'ETH.' + asset.blockchainAsset.id;
    } else if (!asset.blockchainAsset.id.includes('.')) {
      asset.blockchainAsset.id = asset.blockchainAsset.id + '.' + asset.blockchainAsset.id;
    }
    return asset;
  }

  getAssetTicker(assetId:string) {
    if (!assetId) {
      return '';
    }
    return assetId.split('.')[1];
  }

  compareAssetIds(assetA: any, assetB: any) {
    const result = assetA.blockchainAsset.id.split('.')[1]?.localeCompare(assetB.blockchainAsset.id.split('.')[1]);
    if (result === 0) {
      return assetA.blockchainAsset.id.split('.')[0]?.localeCompare(assetB.blockchainAsset.id.split('.')[0]);
    }
    return result;
  }

  formatBlockchainAssetId(input: any): string {
    let assetId = typeof input === 'string' ? input : input.blockchainAsset.id;

    switch (assetId) {
      case 'BTC':
        return 'BTC.BTC';
      case 'ETH':
        return 'ETH.ETH';
      case 'USDC':
        return 'ETH.USDC';
      case 'USDT':
        return 'ETH.USDT';
      case 'POL':
        return 'MATIC.POL';
      default:
        return `${assetId}.${assetId}`;
    }
  }

  // getDecimalPlaces(assetId: string) {
  //   return decimalPlacesFor[assetId?.split('.')[1] || 'BTC'];
  // }

  generatePlaceholderZerosForAsset(assetTicker: string) {
    let amountZero = null;
    if (assetTicker != '') {
      let decimalPlaces = this.assetStore.decimalPlacesFor()[assetTicker];
      amountZero = '0'.repeat(decimalPlaces);
    } else {
      amountZero = '0000000';
    }
    return amountZero;
  }
}


const formatAssetId = (asset: any) => {
    if (asset.blockchainAsset.id === 'POL') {
      asset.blockchainAsset.id = 'MATIC.POL';
    } else if (asset.blockchainAsset.id === 'USDC' || asset.blockchainAsset.id === 'USDT') {
      asset.blockchainAsset.id = 'ETH.' + asset.blockchainAsset.id;
    } else if (!asset.blockchainAsset.id.includes('.')) {
      asset.blockchainAsset.id = asset.blockchainAsset.id + '.' + asset.blockchainAsset.id;
    }
    return asset;
  }

 const compareAssetIds = (assetA: any, assetB: any) => {
    const result = assetA.blockchainAsset.id.split('.')[1]?.localeCompare(assetB.blockchainAsset.id.split('.')[1]);
    if (result === 0) {
      return assetA.blockchainAsset.id.split('.')[0]?.localeCompare(assetB.blockchainAsset.id.split('.')[0]);
    }
    return result;
  }

export { formatAssetId, compareAssetIds };
