<div class="max-w-2xl mx-auto p-6">
  <form [formGroup]="identificationForm" class="space-y-6">
    <!-- Name Field -->

    <app-shared-input
      [icon]="'ph-solid ph-user'"
      [label]="'Name'"
      [control]="identificationForm.get('name')!"
      [placeholder]="'Enter your name'"
      [errorMessage]="'VALIDATION.nameRequired' | translate"
    ></app-shared-input>

    <!-- Email Field -->

    <app-shared-input
      [icon]="'ph-solid ph-envelope'"
      [label]="'Email'"
      [control]="identificationForm.get('email')!"
      [placeholder]="'Enter your email'"
      [errorMessage]="'VALIDATION.emailRequired' | translate"
    ></app-shared-input>

    <!-- User Type Selection -->
    <app-option-button-group
      [control]="identificationForm.get('userType')!"
      [label]="'User Type'"
      [errorMessage]="'VALIDATION.userTypeRequired' | translate"
    ></app-option-button-group>
  </form>
</div>
