// src/app/services/loading.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  private loadingCount = 0;

  private debounceDelay = 100; // milliseconds
  private extraDuration = 300; // milliseconds
  private minDuration = 300; // milliseconds

  constructor() {
    this.loading$ = this.loadingSubject.pipe(
      debounceTime(this.debounceDelay),
      switchMap((loading) => {
        if (loading) {
          return timer(this.minDuration).pipe(() => this.loadingSubject);
        } else {
          return timer(this.extraDuration).pipe(() => this.loadingSubject);
        }
      }),
    );
  }

  setLoading(loading: boolean): void {
    if (loading) {
      this.loadingCount++;
    } else {
      this.loadingCount = Math.max(0, this.loadingCount - 1);
    }
    this.loadingSubject.next(this.loadingCount > 0);
  }
}
