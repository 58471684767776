import { Component, inject, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { OptionButtonGroupComponent } from 'src/app/components/form/option-button-group/option-button-group.component';
import { SharedInputComponent } from 'src/app/components/form/shared-input/shared-input.component';

@Component({
  selector: 'app-identification-step',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharedInputComponent, TranslateModule, OptionButtonGroupComponent],
  templateUrl: './identification-step.component.html'
})

export class IdentificationStepComponent implements OnInit, OnDestroy {
  private fb = inject(FormBuilder);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private formSubscription: Subscription | undefined;

  identificationForm = this.fb.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    userType: ['individual', Validators.required]
  });


  ngOnInit() {
    this.formSubscription = this.identificationForm.valueChanges.subscribe(values => {
      // Update URL with form values
      console.log('values', values);
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          ...this.route.snapshot.queryParams,  // Preserve existing query params
          ...values  // Add form values
        },
        queryParamsHandling: 'merge',
        replaceUrl: true
      });
    });

    // Initialize form with URL values if they exist
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams) {
      const formValues = {
        name: queryParams['name'] || '',
        email: queryParams['email'] || '',
        userType: queryParams['userType'] || 'individual'
      };
      this.identificationForm.patchValue(formValues, { emitEvent: false });
    }
  }

  ngOnDestroy() {
    console.log('ngOnDestroy');
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  isValidStep(): boolean {
    return this.identificationForm.valid;
  }
}
