import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { setAllEntities, withEntities } from '@ngrx/signals/entities';

import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { pipe, switchMap, tap, forkJoin, of } from 'rxjs';
import { FavoritesService } from '../services/favorites.service';
import { Contact } from '../pages/private/contact-card/contact-card.component';
import { UserService } from '../services/user.service';
import { map, catchError } from 'rxjs/operators';

type ContactsStoreState = {
  loaded: boolean;
};

const initialState: ContactsStoreState = {
  loaded: false,
};

export const ContactsStore = signalStore(
  {
    providedIn: 'root',
  },
  withEntities<Contact>(),
  withState(initialState),
  withMethods((store, favoritesService = inject(FavoritesService), userService = inject(UserService)) => ({
    loadContacts: rxMethod<void>(
      pipe(
        switchMap(() =>
          favoritesService.getUserFavorites().pipe(
            switchMap((favorites: any) => {
              const contactsWithId: Contact[] = Object.values(favorites).map((contact: any) => ({
                ...contact,
                id: contact.userId,
              }));

              // Create an array of observables to fetch each contact's profile image and also contact crypto connections. Filtering by assetTicker
              const contactsWithAssetsAndImages = contactsWithId.map(contact =>
                forkJoin({
                  profileImageUrl: userService.getUserImageById(contact.id).pipe(
                    map(imageBlob => URL.createObjectURL(imageBlob as Blob)),
                    catchError(() => of(contact))
                  ),
                  acceptedAssets: userService.getReceiverConnections(contact.id).pipe(
                    map((assets: any[]) => {
                      const uniqueAssets: any[] = [];
                      assets.forEach(asset => {
                        if (!uniqueAssets.some(existing => existing.assetTicker === asset.assetTicker)) {
                          uniqueAssets.push(asset);
                        }
                      });
                      return uniqueAssets;
                    }),
                    catchError(() => of([]))
                  )
                }).pipe(
                  map(({ profileImageUrl, acceptedAssets }) => ({
                    ...contact,
                    profileImageUrl: profileImageUrl || contact.profileImageUrl,
                    acceptedAssets: acceptedAssets || []
                  }))
                )
              );

              // Wait for all image and assets fetches to complete
              return forkJoin(contactsWithAssetsAndImages).pipe(
                tapResponse({
                  next: (updatedContacts: Contact[]) => {
                    patchState(store, setAllEntities(updatedContacts));
                    patchState(store, { loaded: true });
                  },
                  error: (error: { message: string }) => {
                    console.error(error);
                  }
                })
              );
            }),
            tapResponse({
              next: (updatedContacts: Contact[]) => {
                patchState(store, setAllEntities(updatedContacts));
                patchState(store, { loaded: true });
              },
              error: (error: { message: string }) => {
                // Handle error appropriately
                console.log(error);
              },
            }),
          ),
        ),
      ),
    ),
  })),
);
