import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/services/notifications.service';
import { ToastrFactoryService } from 'src/app/services/toastr-factory.service';
import { TranslateService } from '@ngx-translate/core';
import { tap, catchError, EMPTY } from 'rxjs';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {


  constructor( private notificationService:NotificationService,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private toastrFactory: ToastrFactoryService
    ){
  }
  

  public showMessages:boolean = false;
  public notifications:any;

  ngOnInit(): void {
    this.getNotifications()
  }


  public getNotifications(){
    this.notificationService.getNotifications().pipe(
      tap((response: any) => {
        this.notifications = response.data;        
      }),
      catchError((error:any) => {
        console.log(error);  
        return EMPTY;
      })
    ).subscribe();
    }


    public markAsRead(notificationId:string){
      this.notificationService.markAsRead(notificationId).pipe(
        tap((response: any) => {
          this.getNotifications();
        }),
        catchError((error:any) => {
          this.toastrFactory.unknownError(this.translate.instant('unknownError'));
          console.log(error)
          return EMPTY;
        })
      ).subscribe();
      }
    
  
  }
