import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import * as auth0 from 'auth0-js';
import { environment } from 'src/environments/environment';
import { createHttpOptions } from '../utils/http-utils';

@Injectable()
export class SearchService {
  private webAuth = new auth0.WebAuth({
    domain: environment.auth0Domain,
    clientID: environment.auth0ID,
  });

  constructor(
    public route: Router,
    private http: HttpClient,
    public auth: AuthService
  ) { }

  private buildUrl(endpoint: string, queryParam: string, queryValue: string): string {
    return `${environment.api}${endpoint}?${queryParam}=${queryValue}`;
  }

  generalSearch(query: string) {
    return this.http.get(
      this.buildUrl('Nick/search', 'q', query),
      createHttpOptions()
    );
  }

  favoritesSearch(query: string) {
    return this.http.get(
      this.buildUrl('FavoriteNick/search', 'q', query),
      createHttpOptions()
    );
  }

  getPublicProfileByShortId(shortId: string) {
    return this.http.get(
      this.buildUrl('User/profile-public-shortid', 'shortId', shortId),
      createHttpOptions()
    );
  }

  getPublicProfileByDomain(domain: string) {
    return this.http.get(
      this.buildUrl('User/profile-public-domain', 'domain', domain),
      createHttpOptions()
    );
  }

  uuidPublicProfile(UUID: string) {
    return this.http.get(
      this.buildUrl('User/profile-public', 'userId', UUID),
      createHttpOptions()
    );
  }
}










