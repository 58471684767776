export async function convertImageUrlToFile(imageUrl: string | undefined, fileName: string = 'image/jpg'): Promise<File | undefined> {
    if (!imageUrl) {
      return undefined;
    }
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      return new File([blob], fileName, { type: blob.type });
    } catch (error) {
      console.error('Error fetching image:', error);
      return undefined;
    }
  }