<div
  class="modal-backdrop"
  *ngIf="!exchangeModal && !dashBoardOptions && !fullEmailModal && !transactionWarningsModal && !paymentIdModal"
>
  <div class="modal message-modal">
    <div class="modal-header">
      <h3>{{ modalTittle }}</h3>
      <h3 class="close" (click)="logoutUser()">X</h3>
    </div>
    <div class="modal-body">
      <p>{{ modalMessage }}</p>
    </div>
    <div class="modal-footer">
      <button (click)="logoutUser()">{{ 'tryToLogin' | translate }}</button>
    </div>
  </div>
</div>

<div class="modal-backdrop" *ngIf="dashBoardOptions">
  <div class="modal options-modal">
    <div class="modal-header">
      <h3>{{ modalTittle }}</h3>
      <h3 class="close" (click)="closeModal()">X</h3>
    </div>
    <div class="modal-body">
      <p>Nick: {{ modalNick }}</p>
      <p>{{ 'publicName' | translate }}: {{ modalPublicName }}</p>
    </div>
    <div class="modal-footer">
      <mat-icon
        *ngIf="isAuthenticated"
        (click)="clickFavorite()"
        class="icon favorite"
        aria-label="add to favorites"
        role="img"
      >
        favorite
      </mat-icon>
      <button>
        <mat-icon svgIcon="blank-pay" class="icon"> favorite </mat-icon>
      </button>
    </div>
  </div>
</div>

<div class="modal-backdrop full-email-backdrop" *ngIf="fullEmailModal" (click)="closeModal()">
  <div class="modal options-modal">
    <div class="full-mail-header">
      <h3 class="full-mail-modal-close" (click)="closeModal()">X</h3>
    </div>
    <div class="modal-body">
      <p class="full-mail-message">
        {{ modalMessage }}
        <i class="ph ph-copy icon clickable copy-icon" (click)="emailCopy()"> </i>
      </p>
    </div>
  </div>
</div>

<div class="modal-backdrop full-email-backdrop" *ngIf="exchangeModal" (click)="closeModal()">
  <div class="modal options-modal">
    <div class="full-mail-header">
      <h3 class="full-mail-modal-close" (click)="closeModal()">X</h3>
    </div>
    <div class="modal-body">
      <p class="exchange-info">{{ modalMessage }}</p>
    </div>
  </div>
</div>

<div
  class="modal-backdrop full-email-backdrop"
  *ngIf="paymentIdModal"
  (click)="closeModal(); this.onConfirmModal.emit(true)"
>
  <div class="modal options-modal payment-id-modal">
    <div class="full-mail-header">
      <h3 class="full-mail-modal-close" (click)="closeModal(); this.onConfirmModal.emit(true)">X</h3>
    </div>
    <div class="modal-body">
      <p class="exchange-info payment-id-info">
        {{ 'DASHBOARD.paymentId' | translate }} <label class="payment-id"> {{ modalMessage }} </label>

        <mat-icon
          class="icon clickable copy-icon"
          *ngIf="!hideCopyButton"
          (click)="paymentIdCopy()"
          [matTooltipPosition]="'above'"
          matTooltip="Click to copy the payment ID"
          aria-label="copy payment ID"
        >
          content_copy
        </mat-icon>
      </p>
    </div>
  </div>
</div>

<div class="modal-backdrop" *ngIf="deleteAccount">
  <div class="modal options-modal">
    <div class="modal-header">
      <h3>{{ 'PROFILE.deleteYourAccount' | translate }}?</h3>
      <h3 class="close" (click)="closeModal()">X</h3>
    </div>
    <div class="modal-body">
      {{ 'PROFILE.areYouSureYouWantToDeleteYourAccount' | translate }}
    </div>
    <div class="modal-footer">
      <label
        ><a class="clickable cancel" (click)="closeModal()"> {{ 'cancel' | translate }} </a></label
      >
      <button (click)="userDeleteConfirmation()">{{ 'delete' | translate }}</button>
    </div>
  </div>
</div>

<div class="modal-backdrop" *ngIf="modalDeleteExchange">
  <div class="modal options-modal">
    <div class="modal-header exchange-header">
      <h3>{{ 'deleteThisExchange' | translate }}</h3>
      <h3 class="close" (click)="closeModal()">X</h3>
      <!-- <label class="alert-info">Are you sure you want to delete this exchange?</label> -->
    </div>
    <div class="modal-body delete-exchange-body">
      <span class="alert-info">{{ 'attentionIfYouDeleteThisExchange' | translate }}</span>
    </div>
    <div class="modal-footer exchange-footer">
      <label
        ><a class="clickable cancel" (click)="closeModal()">{{ 'cancel' | translate }}</a></label
      >
      <button (click)="exchangeDeletionConfirmation()">{{ 'delete' | translate }}</button>
    </div>
  </div>
</div>

<div class="modal-backdrop" *ngIf="transactionWarningsModal">
  <div class="modal options-modal set-as-paid-modal">
    <div class="modal-header warning-modal-header">
      <h3>{{ 'transactionWarnings' | translate }}</h3>
      <h3 class="close" (click)="closeModal()">X</h3>
      <label> {{ 'theFollowingIssuesHaveBeenIdentified' | translate }}:</label>
    </div>
    <div class="modal-body">
      <ul *ngFor="let warning of transactionWarning" class="warning-wrapper">
        <li>
          <label class="warning-label">
            <i
              class="ph ph-warning"
              alt="Pending and Something in your transaction won't match"
              [matTooltipPosition]="'above'"
              matTooltip="Something in your transaction won't match"
            ></i>
            {{ warning | translate }}
          </label>
        </li>
      </ul>
    </div>
    <div class="modal-footer"></div>
  </div>
</div>
