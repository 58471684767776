import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Shepherd from 'shepherd.js';
import { TOUR_STEPS } from './tour.config';

interface TourStep {
  id: string;
  title: string;
  text: string;
  position: 'top' | 'bottom' | 'left' | 'right';
}

@Injectable({
  providedIn: 'root'
})
export class TourService {
  private tour!: Shepherd.Tour;
  private currentStep$ = new BehaviorSubject<number>(1);
  private totalSteps = 7;

  constructor() {
    this.initTour();
  }

  private initTour(): void {
    this.tour = new Shepherd.Tour({
      defaultStepOptions: {
        cancelIcon: {
          enabled: true
        },
        classes: 'shepherd-nicky-tour',
        scrollTo: true,
        buttons: [
          {
            text: 'Back',
            action: () => this.tour.back(),
            classes: 'shepherd-button-secondary'
          },
          {
            text: 'Next',
            action: () => this.tour.next()
          }
        ],
      },
      useModalOverlay: true,
      modalContainer: document.body
    });

    this.setupEventListeners();
  }

  private setupEventListeners(): void {
    this.tour.on('show', (e: any) => {
      const step = e.step.id;
      const stepNumber = this.getStepNumber(step);
      this.currentStep$.next(stepNumber);
    });
  }

  startTour(tourKey: string): void {
    // Clear existing steps
    this.tour.steps = [];
    
    // Get the tour steps configuration
    const steps = this.getTourSteps(tourKey);
    
    if (steps) {
      // Add steps from configuration
      Object.values(steps).forEach((step: TourStep) => {
        this.tour.addStep({
          id: step.id,
          title: step.title,
          text: step.text,
          attachTo: {
            element: `#${step.id}`,
            on: step.position as 'top' | 'bottom' | 'left' | 'right'
          }
        });
      });
      
      this.totalSteps = this.tour.steps.length;
      this.tour.start();
    } else {
      console.warn(`Tour configuration not found for key: ${tourKey}`);
    }
  }

  private getTourSteps(tourKey: string): Record<string, TourStep> | null {
    // Split the tour key by dots to traverse the configuration
    const keys = tourKey.split('.');
    let config: any = TOUR_STEPS;
    
    for (const key of keys) {
      if (config && typeof config === 'object' && key in config) {
        config = config[key];
      } else {
        return null;
      }
    }
    
    return config as Record<string, TourStep>;
  }

  private getStepNumber(stepId: string): number {
    const allSteps = this.tour.steps;
    return allSteps.findIndex(step => step.id === stepId) + 1;
  }

  getCurrentStep() {
    return this.currentStep$.asObservable();
  }

  getTotalSteps(): number {
    return this.totalSteps;
  }
} 