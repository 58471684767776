import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import * as ExcelJS from 'exceljs';
import { ToastrService } from 'ngx-toastr';
import { catchError, EMPTY, firstValueFrom, tap } from 'rxjs';
import { toggleMenu } from 'src/app/app.component';
import { Asset } from 'src/app/components/models/asset.model';
import { User, UserClass } from 'src/app/components/models/user.mode';
import { AvatarService } from 'src/app/services/avatar.service';
import { FavoritesService } from 'src/app/services/favorites.service';
import { PaymentService } from 'src/app/services/payment.service';
import { SearchService } from 'src/app/services/search.service';
import { ToastrFactoryService } from 'src/app/services/toastr-factory.service';
import { UserService } from 'src/app/services/user.service';
import { AssetIdFormatterUtil } from 'src/app/utils/asset-id-utils';
import ConvertAsset from 'src/app/utils/conversion-utils';
import FormatCurrency from "src/app/utils/format-currency-utils";
import { decimalPlacesFor, environment } from 'src/environments/environment';


@Component({
  selector: 'app-favorite-nicks',
  templateUrl: './favorite-nicks.component.html',
  styleUrls: ['./favorite-nicks.component.scss', './favorites.firefox.scss'],
})
export class FavoriteNicksComponent implements OnInit {
  public favoritesList: any;
  public myNick: any;
  public itemsPerPage = 10;
  public p: number = 1;
  public loaded!: boolean;
  public isMobile!: boolean;
  public fullEmailModal!: boolean;
  public balances!: any;
  public targetNick!: string;
  public targetDomains: any;
  public user: any;
  public target!: UserClass;
  public userId!: string;
  public convertedAsset!: { [key: string]: { value: number; asset: string } };
  public alreadyConverted: boolean = false;
  public targetCompany!: string;
  public companyWebsite!: string;
  public userProfile!: boolean;
  public environment = environment;
  public invalidSearch: boolean = false;
  public minimumCharMessage!: string;
  public selectedLanguage!: string;
  public defaultBackgroundPosition: number = 40;
  public noSearchResult!: boolean;
  private decimalPlaces = decimalPlacesFor;

  public showModal!: boolean;
  public modalMessage!: string;
  public modalTittle!: string;
  public dashBoardOptions!: boolean;
  public modalNick!: string;
  public modalPublicName!: string;
  public assets!: any[];
  public alreadyChecked: { [key: string]: string[] } = {};
  public favoriteSearchList!: any;
  public userActiveWallets: string[] = [];
  public newPaymentRequest!: boolean;
  public targetPaymentRequestUser!: User;

  constructor(
    private favoritesService: FavoritesService,
    private auth: AuthService,
    private toastrService: ToastrService,
    private searchService: SearchService,
    public formatCurrency: FormatCurrency,
    private userService: UserService,
    private convertAsset: ConvertAsset,
    private paymentService: PaymentService,
    private sanitize: DomSanitizer,
    public translate: TranslateService,
    private toastrFactory: ToastrFactoryService,
    private assetIdFormatterUtil: AssetIdFormatterUtil,
    public avatarService: AvatarService
  ) {}

  public items: any;
  public paymentFlow: boolean = false;
  public authenticated: boolean = false;
  public currentPage: number = 1;
  public searchResults: any;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWindowSize();
  }

  @ViewChild('search') search!: ElementRef<HTMLInputElement>;
  @ViewChild('searchDesktop') searchDesktop!: ElementRef<HTMLInputElement>;

  ngOnInit(): void {
    this.getSystemAssets();
    this.getCurrentUserNick();
    this.loadFavorites();
    // this.loadAccountBalances()
    this.isAuthenticated();
    this.getCryptoConnections();
    this.userService
      .getUser()
      .pipe(
        tap((response: any) => {
          this.user = response;
        }),
        catchError((error: any) => {
          this.toastrFactory.handleError(error);
          return EMPTY;
        })
      )
      .subscribe();
    this.checkWindowSize();
  }

  toggleMenu() {
    toggleMenu();
  }

  updateSearchResults() {
    this.searchResults = '';
  }

  moreThanOneAt(input: string) {
    const regex = /@/g;
    const matches = input.match(regex);
    return matches ? matches.length : 0;
  }

  getDecimalPlaces(assetId: string) {
    return decimalPlacesFor[assetId.split('.')[1]];
  }

  formatAsset(asset: string) {
    if (asset === 'USDC' || asset === 'USDT') return 'ETH.' + asset;
    if (asset === 'MATIC') return 'MATIC.' + asset;
    return asset;
  }

  onSearch(searchInput: HTMLInputElement) {
    const minWidth: number = 3;
    const inputLength: number = searchInput.value
      .replaceAll('@', '')
      .trim().length;
    const atLength: number = this.moreThanOneAt(searchInput.value);

    if (searchInput.value.replaceAll('@', '').trim().length < minWidth) {
      const remainingChar = minWidth - inputLength;
      this.minimumCharMessage =
        this.translate.instant('HOME.youNeedToType') +
        remainingChar +
        this.translate.instant('HOME.moreChar') +
        (remainingChar == 1 ? '' : 's');
      if (atLength > 1) {
        this.minimumCharMessage += this.translate.instant('HOME.doesNotCount');
      }
      this.searchResults = [];
      return;
    }
    this.minimumCharMessage = '';
    this.searchService.generalSearch(searchInput.value).pipe(
      tap((response: any) => {
        this.searchResults = response;
        if (this.searchResults?.length == 1) {
          if (searchInput.value.trim() == this.searchResults[0]?.nick) {
            this.searchResults[0].exactlyMatch = true;
          }
        }
      }),
      catchError((error:any) => {
        this.toastrFactory.handleError(error)
        return EMPTY;
      })
    ).subscribe()
  }

  getAcceptedAssets(favorite: any): void {
    if (this.alreadyChecked[favorite.userId]) {
      return;
    }

    this.userService.getReceiverConnections(favorite.userId).pipe(
      tap((response: any) => {
        let acceptedAssets: Asset[] = [];

        for (const asset of response) {
          try {
            acceptedAssets.push(asset);
          } catch (e) {
            // console.log(e);
          }
        }

        this.processReceiverConnections(acceptedAssets, favorite);
      }),
      catchError((error:any) => {
        console.error(error);
        this.markAsChecked(favorite.userId, []);
        return EMPTY;
      })
    ).subscribe()
  }

  private processReceiverConnections(response: any, favorite: any): void {
    const activeAssets = this.extractUniqueAssets(response);
    favorite.acceptedAssets = activeAssets;
    this.markAsChecked(favorite.userId, activeAssets);
  }

  private extractUniqueAssets(response: any): string[] {
    const activeAssets = response
      .map((asset: Asset) => asset.assetTicker)
      .filter(
        (asset: string, index: number, self: string[]) =>
          self.indexOf(asset) === index
      )
      .sort((a: string, b: string) => a.localeCompare(b));

    return activeAssets;
  }

  private markAsChecked(userId: string, assets: string[]): void {
    if (!this.alreadyChecked[userId]) {
      this.alreadyChecked[userId] = [];
    }
    this.alreadyChecked[userId] = assets;
  }

  isContactAssetsChecked(contactUserId: string) {
    return !!this.alreadyChecked[contactUserId];
  }

  public loadFavorites() {
    this.favoritesService.getUserFavorites().pipe(
      tap((response: any) => {
        this.favoritesList = response;
        this.favoritesList?.forEach((favorite: any) => {
          if (!this.alreadyChecked[favorite.userId]) {
            this.getReceiverConnections(favorite);
            return;
          } else {
            favorite.acceptedAssets = this.alreadyChecked[favorite.userId];
          }
        });
      }),
      catchError((error:any) => {
        this.toastrFactory.handleError(error)
        return EMPTY;
      })
    ).subscribe()
  }

  getReceiverConnections(receiver: any) {
    this.userService.getReceiverConnections(receiver.userId).pipe(
      tap((response: any) => {
        if (response.length > 0) {
          receiver.hasWallets = true;
        }
        this.getAcceptedAssets(receiver);
      })
    ).subscribe()
  }

  handleImageError(event: any, userName?: string) {
    event.target.onerror = null;
    event.target.src = this.avatarService.getInitialAvatar(userName);
  }

  public getSystemAssets() {
    this.paymentService.getAllAssets().pipe(
      tap((response: any) => {
        this.assets = response;
      }),
      catchError((error:any) => {
        console.log(error);
        this.toastrFactory.unknownError(this.translate.instant('unknownError'));
        return EMPTY;
      })
    ).subscribe()
  }

  openPayment(target: any) {
    if (target.acceptedAssets.length < 1) {
      return;
    }
    this.buildTargetObject(target);
    this.paymentFlow = true;
  }

  createNamedPaymentRequest(target: User) {
    this.targetPaymentRequestUser = target;
    this.openPaymentRequestFlow();
  }

  openPaymentRequestFlow() {
    if (this.userActiveWallets.length > 0) {
      this.newPaymentRequest = true;
    } else
      this.toastrService.error(
        this.translate.instant(
          'DASHBOARD.itsNecessaryToHaveAnVerifiedExchageToCreateAPaymentRequest'
        ),
        'Something went wrong',
        {
          timeOut: 3000,
        }
      );
  }

  getCryptoConnections() {
    this.userService.getCryptoConnections().pipe(
      tap((walletsConections: any) => {
        walletsConections.forEach((wallet: any) => {
          this.userActiveWallets.push(wallet.cryptoDataSource);
        });
      }),
      catchError((error:any) => {
        console.log(error);
        return EMPTY;
      })
    ).subscribe()
  }

  openProfile(target: any) {
    this.buildTargetObject(target);
    this.userProfile = true;
  }

  setFavoriteSearchList(favoriteSearchList: any) {
    this.noSearchResult = favoriteSearchList.length === 0;
    this.favoritesList = favoriteSearchList;
    this.favoritesList?.forEach((favorite: any) => {
      if (!this.alreadyChecked[favorite.userId]) {
        this.getReceiverConnections(favorite);
        return;
      } else {
        favorite.acceptedAssets = this.alreadyChecked[favorite.userId];
      }
    });
  }

  buildTargetObject(target: any) {
    this.target = new UserClass(target);
    // this.targetDomains = target.domains
    // this.userId = target.userId;
    // this.targetNick = target.nick;
    // this.targetCompany = target.publicName;
    // this.companyWebsite = target.websiteUrl;
  }

  getPagesNumber(totalItems: number) {
    let pages = totalItems / this.itemsPerPage;
    if (!Number.isInteger(pages)) {
      return Number.parseInt((pages + 1).toString());
    }
    return pages;
  }

  favoriteNameLink(favorite: any) {
    if (favorite.websiteUrl) {
      this.openWebsite(favorite.websiteUrl);
      return;
    }

    this.openPayment(favorite);
  }

  openWebsite(websiteUrl: string) {
    window.open(websiteUrl, '_blank')?.focus();
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage -= 1;
    }
  }

  nextPage(totalItems: number) {
    if (this.currentPage < this.getPagesNumber(totalItems)) {
      this.currentPage += 1;
    }
  }

  showingNicks() {
    if (!this.p) this.p = 1;

    let start = (this.p - 1) * this.itemsPerPage;
    let end = start + this.itemsPerPage;
    if (end > this.favoritesList?.length) {
      end = this.favoritesList?.length;
    }
    if (end == 0) return start + 0 + ' - ' + end;
    return start + 1 + ' - ' + end;
  }

  checkWindowSize() {
    if (window.innerWidth <= 850) {
      this.itemsPerPage = 5;
    } else {
      this.itemsPerPage = 10;
    }
    if (window.innerWidth <= 1000) {
      this.isMobile = true;
    }
  }

  isAssetEnabled(asset: string) {
    if (
      asset == 'BTC' ||
      asset == 'ETH' ||
      asset == 'USDC' ||
      asset == 'USDT'
    ) {
      return true;
    }
    return false;
  }

  private loadAccountBalances() {
    this.userService.getBalances().pipe(
      tap((response: any) => {
        (this.balances = response.total),
          this.balances.sort((assetA: any, assetB: any) => {
            assetA = this.assetIdFormatterUtil.formatAssetId(assetA);
            assetB = this.assetIdFormatterUtil.formatAssetId(assetB);
            return this.assetIdFormatterUtil.compareAssetIds(assetA, assetB);
          });
        this.getAllAssets();
      }),
      catchError((error:any) => {
        console.log(error);
        const connectionsUrl =
          'https://' + window.location.host + '/settings?connectionsTab=true';
        const routesUrl =
          'https://' + window.location.host + '/settings?wallet=true';
        this.toastrService.error(
          this.translate.instant('unableToRetrieveBalances', {
            connectionsUrl: connectionsUrl,
            routesUrl: routesUrl,
          }),
          '',
          {
            enableHtml: true,
          }
        );
        
        return EMPTY;
      })
    ).subscribe()
  }

  exportTable() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Contacts table');

    const table = document.getElementById(`contacts-table`) as HTMLTableElement;

    this.exportContactsTable(table, worksheet);

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      const tableName = 'contacts';
      a.download = this.translate.instant(tableName) + '.xlsx';
      a.click();

      URL.revokeObjectURL(url);
    });
  }

  exportContactsTable(table: HTMLTableElement, worksheet: ExcelJS.Worksheet) {
    const headerRow = table.querySelector(
      'tr.only-desktop, tr.hide-portrait'
    ) as HTMLTableRowElement;
    const headers = Array.from(headerRow?.cells || [])
      .map((cell) => cell.innerText.trim())
      .filter((text) => text !== this.translate.instant('Actions'));

    const nickIndex = headers.indexOf(this.translate.instant('Nick'));
    const statusIndex = headers.indexOf(
      this.translate.instant('PROFILE.status')
    );
    const shortIdIndex = headers.indexOf(
      this.translate.instant('DASHBOARD.shortId')
    );
    const transactionIdIndex = headers.indexOf(
      this.translate.instant('DASHBOARD.transactionId')
    );

    const headerRowExcel = worksheet.addRow(headers);

    const columnWidths: number[] = headers.map((header) => header.length);

    const rows = Array.from(
      table.querySelectorAll('tr.only-desktop, tr.hide-portrait')
    ).slice(1);
    rows.forEach((row) => {
      let rowData = Array.from((row as HTMLTableRowElement).cells).map(
        (cell) => {
          let cellText = cell.innerText || '--';
          return cellText.replaceAll('&nbsp', ' ');
        }
      );

      const nickCell = rowData[nickIndex];

      const [name, email] = nickCell.split('\n');

      rowData[nickIndex] = email;

      // rowData.splice(nickIndex + 1, 0, email);
      // rowData.pop();

      rowData.forEach((data, i) => {
        columnWidths[i] = Math.max(columnWidths[i], data?.length);
      });

      const rowExcel = worksheet.addRow(rowData);

      rowExcel.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } },
        };
      });
    });

    headerRowExcel.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0000FF' },
      };
      cell.font = { color: { argb: 'FFFFFFFF' }, bold: true };
      // cell.alignment = { horizontal: 'center' };
      cell.border = {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } },
      };
    });

    worksheet.columns.forEach((column, i) => {
      column.width = columnWidths[i] + 4;
    });
  }

  public async getAllAssets() {
    // this.convertedAsset = await this.convertAsset.getConvertedAssets(this.balances)
    this.alreadyConverted = true;
  }

  public async addFavorite(nick: string) {
    let alreadyFavorite: any = await firstValueFrom(
      await this.favoritesService.favoriteByNick(nick)
    );

    if (alreadyFavorite?.length > 0) {
      this.toastrService.success(
        this.translate.instant('userIsAlreadyInYourFavorites'),
        '',
        {
          timeOut: 3000,
        }
      );
    } else {
      this.favoritesService.addFavorite(nick).pipe(
        tap((response: any) => {
          this.searchResults = response;
          this.loadFavorites();
        }),
        catchError((error:any) => {
          this.toastrFactory.handleError(error)
          return EMPTY;
        })
      ).subscribe();
    }
  }

  isAuthenticated() {
    this.auth.isAuthenticated$.pipe(
      tap((response: any) => {
        this.authenticated = response;
      })
     ).subscribe();
  }

  public deleteNick(favorite: any) {
    this.favoritesService.deleteFavorite(favorite).pipe(
      tap((response: any) => {
        this.loadFavorites();
        this.toastrService.success(
          this.translate.instant('theNickHasBeenSuccessfullyRemoved'),
          '',
          {
            timeOut: 3000,
          }
        )
      }),
      catchError((error:any) => {
        this.toastrFactory.handleError(error);
        return EMPTY;
      })
    ).subscribe()
  }

  getCurrentUserNick() {
    this.auth.user$.pipe(
      tap((user) => (this.myNick = user?.email))
     ).subscribe();
  }

  closeModal(event: any) {
    this.showModal = false;
    this.dashBoardOptions = false;
    this.fullEmailModal = false;
    this.paymentFlow = false;
    this.userProfile = false;
    this.newPaymentRequest = false;
  }

  public showEmailModal(fullMail: string) {
    this.showModal = true;
    this.modalMessage = fullMail;
    this.fullEmailModal = true;
  }

  public showDashboardOptionsModal(favorite: any) {
    this.showModal = true;
    this.modalNick = favorite.nick;
    this.modalPublicName = favorite.publicName;
    this.modalTittle = this.translate.instant('weFoundIt');
    this.dashBoardOptions = true;
  }
}
