<app-payment-requests
  *ngIf="newPaymentRequest"
  [targetUser]="targetPaymentRequestUser"
  [isAuthenticated]="authenticated"
  [paymentModal]="paymentFlow"
  [assets]="assets"
  (onCloseModal)="closeModal($event)"
  [userId]="userId"
  [user]="user"
  (successfullyTransaction)="getAllPaymentRequests()"
  [targetNick]="targetNick"
></app-payment-requests>

<app-modal
  [modalTittle]="modalTittle"
  [modalMessage]="modalMessage"
  [dashBoardOptions]="dashBoardOptions"
  [fullEmailModal]="fullEmailModal"
  [modalPublicName]="modalPublicName"
  [transactionWarning]="transactionWarnings"
  [modalNick]="modalNick"
  [transactionWarningsModal]="transactionWarningsModal"
  (onCloseModal)="closeModal($event)"
  (onClickFavorite)="addFavorite($event)"
  *ngIf="showModal"
></app-modal>

<app-public-profile
  [targetDomains]="targetDomains"
  [selectedLanguage]="selectedLanguage"
  [isAuthenticated]="authenticated"
  [target]="target"
  [userId]="userId"
  [targetNick]="targetNick"
  [targetCompany]="targetCompany"
  [companyWebsite]="companyWebsite"
  [user]="user"
  [paymentRequestInfo]="requestInfo"
  *ngIf="userProfile"
  [assets]="assets"
  (onCloseModal)="closeModal($event)"
></app-public-profile>

<app-payment
  [isAuthenticated]="authenticated"
  [paymentModal]="paymentFlow"
  [assets]="assets"
  (onCloseModal)="closeModal($event)"
  [userId]="userId"
  *ngIf="paymentFlow"
  [user]="user"
  (successfullyTransaction)="getAllPaymentReports()"
  [targetUser]="targetUser"
></app-payment>

<app-manual-withdraw *ngIf="manualWithdraw" (onCloseModal)="manualWithdraw = false"></app-manual-withdraw>

<app-header
  (closeProfile)="closeModal($event)"
  [assets]="assets"
  (userSelectedLanguage)="selectedLanguage = $event"
></app-header>

<div class="dashboard" (click)="toggleMenu()">
  <div class="side-content" *ngIf="currentRoute.includes('/overview')">
    <div class="dashboard-wrapper balances-wrapper common-border" id="balances">
      <div class="dashboard-wrapper-tittle flex-centered">
        <h1>{{ 'accountBalances' | translate }}</h1>
      </div>
      <div class="user-balances-wrapper">
        <div class="info-balances">
          <div class="info-bg" *ngIf="totalBalances">
            <mat-icon class="icon common-icon" svgIcon="balance-OTHER"></mat-icon>
            <div class="balance-asset-info flex-centered">
              <label class="blockchain-asset">
                <span>
                  {{ 'allAssets' | translate }}
                </span>
              </label>
              <label> ${{ formatCurrency.convertToLocal(getTotalBalances(), getDecimalPlaces('USD.USD')) }} </label>
            </div>
          </div>
          <div
            class="info-bg"
            *ngFor="let balance of balances?.slice(0, 4); index as i"
            [ngStyle]="{ background: getBalancesBackgroundColor(i + 1) }"
          >
            <mat-icon
              class="icon"
              *ngIf="isAssetEnabled(balance.blockchainAsset.assetTicker)"
              [svgIcon]="'balance-' + balance.blockchainAsset.assetTicker"
            ></mat-icon>
            <mat-icon
              class="icon common-icon"
              *ngIf="!isAssetEnabled(balance.blockchainAsset.assetTicker)"
              svgIcon="balance-OTHER"
            ></mat-icon>
            <div class="balance-asset-info flex-centered">
              <label class="blockchain-asset">
                {{ balance.blockchainAsset.assetTicker }}
                <span>
                  {{
                    formatCurrency.convertToLocal(balance.amountNative, getDecimalPlaces(balance.blockchainAsset.id))
                  }}
                </span>
              </label>
              <label> ${{ balancesPostConversion(balance) }} </label>
            </div>
          </div>

          <div class="info-bg add-wallet-bg">
            <mat-icon svgIcon="add-wallet" class="icon common-icon"></mat-icon>

            <div class="balance-asset-info flex-centered">
              <button mat-button (click)="newWallet()" id="wallets">
                <i class="ph ph-plus"></i>
                {{ 'addWallet' | translate }}
              </button>
            </div>
          </div>

          @if (balances?.length === 0) {
            <div class="configuration-inform">
              <h1 class="no-balances-yet">{{ 'noBalancesYet' | translate }}</h1>
              {{ 'pleaseGoToSettingsAndConfigureRoutesToEnsure' | translate }}
            </div>
          }
        </div>
      </div>
    </div>

    <div class="dashboard-wrapper favorites-wrapper common-border" id="contacts-list">
      <div
        id="contacts"
        (click)="goToRoute('/contacts')"
        class="secondary-button absolute-button"
        matTooltip="{{ 'contacts' | translate }}"
        [matTooltipPosition]="'above'"
        (click)="navigateTo('/contacts')"
      >
        {{ 'seeAll' | translate }}
      </div>
      <div class="dashboard-wrapper-tittle flex-centered">
        <h1>{{ 'contacts' | translate }}</h1>
      </div>
      <div class="info-favorites">
        <div *ngFor="let favorite of favoritesList?.slice(0, 5)" class="info-bg">
          <div class="favorite-wrapper flex-centered">
            @if (favorite.hasProfilePicture) {
              <img
                class="company-logo"
                [src]="environment.api + 'User/get-public-profile-picture?userId=' + favorite.userId"
                [matTooltipPosition]="'above'"
                matTooltip="{{ favorite.publicName || favorite.name }}"
                (error)="handleImageError($event, favorite.publicName || favorite.name)"
              />
            } @else {
              <img
                class="company-logo"
                [src]="this.avatarService.getInitialAvatar(favorite.publicName || favorite.name)"
                alt="favorite-img"
              />
            }
            <div class="favorite-info clickable" (click)="openProfile(favorite)">
              <label class="public-name">{{ favorite.publicName }}</label>
              <label class="secondary-text">
                <span
                  [ngClass]="{ 'you-favorite': favorite?.nick == myNick }"
                  class="favorite-nick"
                  [matTooltipPosition]="'above'"
                  matTooltip="{{ favorite.nick }}"
                  >{{
                    favorite.nick +
                      '
                  '
                  }}</span
                ><span
                  class="my-nick"
                  *ngIf="favorite?.nick == myNick"
                  [matTooltipPosition]="'above'"
                  matTooltip="This nick belongs to you"
                  >({{ 'you' | translate }})</span
                >
              </label>
            </div>
          </div>
          <div class="actions">
            @if (!favorite?.loading) {
              <i
                class="pay ph ph-share-fat"
                [ngClass]="{ disabled: !favorite.hasWallets }"
                (click)="!favorite.hasWallets || openPayment(favorite)"
                [matTooltipPosition]="'above'"
                [matTooltip]="getFavoritePayTooltip(favorite)"
                #tooltip="matTooltip"
                (click)="!isMobile || tooltip.toggle()"
              ></i>
            }
            <i
              class="ph ph-invoice clickable"
              [matTooltipPosition]="'above'"
              #tooltip="matTooltip"
              [matTooltip]="'createAPaymentRequestForThisUser' | translate"
              (click)="createNamedPaymentRequest(favorite); !isMobile || tooltip.toggle()"
            ></i>
            <i
              class="ph ph-trash clickable"
              (click)="deleteNick(favorite.nick)"
              [matTooltipPosition]="'above'"
              matTooltip="Remove favorite"
            ></i>
          </div>
        </div>
        <div class="no-transactions no-contacts" *ngIf="favoritesList?.length == 0">
          <mat-icon svgIcon="no-contacts"> </mat-icon>
          <div class="description-wrapper">
            {{ 'noFavoritesToBeShown' | translate }}
            <label>{{ 'DASHBOARD.startBySearchingAnEmail' | translate }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="check-transactions" class="dashboard-wrapper table-wrapper common-border">
    <div class="dashboard-buttons-wrapper">
      <div (click)="exportTable()" class="secondary-button export-button absolute-button">
        {{ 'export' | translate }}
      </div>
      <div
        class="secondary-button absolute-button"
        *ngIf="currentRoute.includes('/overview') && (tab != 'requests' || isMobile)"
        id="all-transactions"
        (click)="goToRoute('/transactions')"
      >
        {{ 'seeAll' | translate }}
      </div>

      <div
        class="new-button clickable flex-centered"
        (click)="openPaymentRequestFlow()"
        *ngIf="tab == 'requests' && !isMobile"
        [ngClass]="{ 'no-see-all': !currentRoute.includes('/overview') }"
        id="create-requests"
      >
        <i class="ph ph-plus"></i>
        <label>{{ 'DASHBOARD.paymentRequest' | translate }}</label>
      </div>
    </div>

    <h3 [ngClass]="{ 'payment-requests-tittle': tab === 'requests' }">
      {{ 'transactions' | translate }}

      <mat-icon svgIcon="help-book" class="help-book clickable" id="bookIcon" (click)="transactionsTour()"></mat-icon>
    </h3>

    <div class="dashboard-tab-menu">
      <!-- tabs-selector -->
      <div class="tabs-toggle">
        <h1
          (click)="changeTab('transactions')"
          id="payment-reports"
          class="clickable"
          [ngClass]="{ 'not-selected-tab': tab != 'transactions' }"
        >
          {{ 'DASHBOARD.paymentReport' | translate }}
        </h1>
        <span class="divisor">|</span>
        <h1
          class="clickable payment-request-tittle"
          id="payment-requests"
          (click)="changeTab('requests')"
          [ngClass]="{ 'not-selected-tab': tab != 'requests' }"
        >
          {{ 'DASHBOARD.paymentRequests' | translate }}
        </h1>
      </div>

      <div
        *ngIf="isMobile && tab == 'requests'"
        class="new-button clickable flex-centered"
        (click)="openPaymentRequestFlow()"
        [ngClass]="{ 'no-see-all': !(currentRoute === '/overview') }"
        id="create-requests"
      >
        <i class="ph-bold ph-plus"></i>
      </div>
    </div>

    <div
      class="table-limit"
      [ngStyle]="{ 'min-height': transactions.length == 0 ? 'unset' : '' }"
      *ngIf="tab == 'transactions'"
    >
      <!-- payment reports table -->

      <table id="transactions-table">
        <tr class="only-desktop">
          <th class="payer-info-th">{{ 'DASHBOARD.sender' | translate }}</th>
          <th class="date-th clickable" (click)="ascending = !ascending; submitFilter()">
            <div class="fit-th-content">
              {{ 'DASHBOARD.date' | translate }}
              <div class="sorting">
                <i [ngClass]="{ 'active-sorter': ascending }" class="ph-fill ph-triangle"></i>
                <i [ngClass]="{ 'active-sorter': !ascending }" class="down-triangle ph-fill ph-triangle"></i>
              </div>
            </div>
          </th>
          <th>{{ 'DASHBOARD.amount' | translate }}</th>
          <th class="reference-header">{{ 'DASHBOARD.reference' | translate }}</th>
          <th class="id-th">{{ 'DASHBOARD.shortId' | translate }}</th>
          <th class="status-header">{{ 'PROFILE.status' | translate }}</th>
          <th class="id-th">{{ 'DASHBOARD.requestId' | translate }}</th>
          <th class="tx-id-th">{{ 'DASHBOARD.transactionId' | translate }}</th>
          <th></th>
        </tr>

        <tr
          class="hide-portrait"
          *ngFor="
            let transactions of transactions
              | paginate
                : { itemsPerPage: itemsPerPage, currentPage: currentPage[tab].page, totalItems: totalTransactions }
          "
        >
          <td>
            <div class="sender-info">
              <div class="sender-info-labels">
                <label
                  class="receiver-dashboard overflow-label"
                  [ngClass]="{ 'is-nicky-user': isNickUser(transactions.payerUser) }"
                  (click)="openProfile(transactions.payerUser)"
                >
                  {{ transactions.payerUser.publicName || transactions.payerUser.name }}
                </label>
                {{ this.isMobile ? '\n' : '' }}
                <label class="receiver-dashboard secondary-text overflow-label">
                  {{ transactions.payerUser.email }}
                </label>
              </div>
            </div>
          </td>

          <td>
            {{ translateDate(transactions?.createdDate | date: 'mediumDate' : '' : 'en-US') }}
          </td>

          <td>
            <label class="amount-label">
              <mat-icon
                [ngClass]="{
                  'alert-warning-icon': !transactions.paymentTransactions[0]?.blockchainAssetId,
                  clickable: !transactions.paymentTransactions[0]?.blockchainAssetId,
                  icon: transactions.paymentTransactions[0]?.blockchainAssetId,
                  'asset-icon': transactions.paymentTransactions[0]?.blockchainAssetId,
                }"
                (click)="
                  transactions.paymentTransactions[0]?.blockchainAssetId || openTransactionWarningsModal(transactions)
                "
                [svgIcon]="
                  formatAssetToUi(transactions.paymentTransactions[0]?.blockchainAssetId || 'transaction-warning')
                "
                matTooltip="{{ transactions.paymentTransactions[0]?.blockchainAssetId || ('Error' | translate) }}"
                [matTooltipPosition]="'above'"
              ></mat-icon>
              {{
                formatCurrency.convertToLocal(
                  transactions.paymentTransactions[0]?.paymentTransfers[0]?.amountNative,
                  getDecimalPlaces(transactions.paymentTransactions[0]?.paymentTransfers[0]?.blockchainAssetId)
                ) == 'NaN'
                  ? '--'
                  : formatCurrency.convertToLocal(
                      transactions.paymentTransactions[0]?.paymentTransfers[0]?.amountNative,
                      getDecimalPlaces(transactions.paymentTransactions[0]?.paymentTransfers[0]?.blockchainAssetId)
                    )
              }}
            </label>
          </td>

          <td class="reference-column">
            <label class="reference-label" [ngClass]="{ 'secondary-text': !transactions.bill.invoiceReference }">
              {{ transactions.bill.invoiceReference || '--' }}
            </label>
          </td>

          <td>
            <div class="father-wrapper requests-father-wrapper">
              <div class="td-wrapper requests-td-wrapper">
                <span class="id-span">{{ transactions.bill?.shortId }}</span>
                <a class="icon" *ngIf="!isMobile">
                  <div class="transactionId" *ngIf="transactions.bill.shortId" #transactionId>
                    <span class="id-span"> {{ transactions.bill?.shortId }} </span>
                  </div>
                  <i
                    class="ph ph-copy icon clickable copy-icon"
                    [matTooltipPosition]="'above'"
                    matTooltip="{{ 'copy' | translate }}"
                    (click)="onCopyPaymentId(transactions.bill?.shortId)"
                  ></i>
                </a>
              </div>
            </div>
          </td>

          <td class="pending-icon">
            <a (click)="paymentReportClick(transactions)" [class]="getPaymentReportStatus(transactions) + ' clickable'">
              {{ getPaymentReportStatus(transactions) | translate }}
            </a>
          </td>

          <td>
            <a
              *ngIf="!(transactions.bill.paymentRequest != null)"
              class="add-here clickable"
              (click)="openIdInput = true; paymentReportId = transactions.bill?.shortId"
              >{{ 'DASHBOARD.addHere' | translate }}
            </a>
            <div class="father-wrapper requests-father-wrapper" *ngIf="transactions.bill.paymentRequest != null">
              <div class="td-wrapper requests-td-wrapper">
                <span class="id-span">{{ transactions.bill.shortId }}</span>
                <a class="icon" *ngIf="!isMobile">
                  <div class="transactionId" *ngIf="transactions.bill.shortId" #transactionId>
                    {{ transactions.bill.shortId }}
                  </div>
                  <i
                    class="ph ph-copy icon clickable copy-icon"
                    [matTooltipPosition]="'above'"
                    matTooltip="{{ 'copyRequestLink' | translate }}"
                    (click)="onCopyRequestId(transactions.bill.shortId)"
                  ></i>
                </a>
              </div>
            </div>
          </td>

          <td>
            <div class="transaction-wrapper">
              <a class="icon">
                <div
                  class="transactionId"
                  *ngIf="transactions.paymentTransactions[0]?.blockchainTransactionId"
                  #transactionId
                >
                  {{ transactions.paymentTransactions[0]?.blockchainTransactionId.toString() || '--' }}
                </div>
                <i
                  *ngIf="transactions.paymentTransactions[0]?.blockchainTransactionId"
                  class="ph ph-copy icon clickable copy-icon"
                  [matTooltipPosition]="'above'"
                  matTooltip="{{ 'copyTransactionId' | translate }}"
                  (click)="onCopyTransactionId(transactions.paymentTransactions[0]?.blockchainTransactionId)"
                >
                </i>
              </a>
              <div class="transaction-icons-wrapper">
                <a
                  [href]="getScanAddress(transactions)"
                  target="_blank"
                  *ngIf="transactions.paymentTransactions[0]?.blockchainTransactionId"
                >
                  <i
                    class="ph ph-arrow-line-up-right icon clickable share-icon"
                    [matTooltipPosition]="'above'"
                    matTooltip="{{ 'openBlockChainExplorer' | translate }}"
                  ></i>
                </a>
              </div>
            </div>
          </td>

          <td class="see-more-column">
            <i
              class="ph ph-eye more-details-icon clickable"
              (click)="showPaymentReportDetails(transactions)"
              [matTooltipPosition]="'above'"
              matTooltip="{{ 'public-profile.clickHereToSeeMore' | translate }}"
            ></i>
          </td>
        </tr>

        <!-- here i'm doing the new mobile version -->
        @if (isMobile) {
          <tr
            class="only-mobile"
            *ngFor="
              let transactions of transactions
                | paginate
                  : { itemsPerPage: itemsPerPage, currentPage: currentPage[tab].page, totalItems: totalTransactions }
            "
          >
            <div class="info-container">
              <div class="payer-info">
                <label class="payment-status">
                  <a
                    (click)="paymentReportClick(transactions)"
                    [class]="getPaymentReportStatus(transactions) + ' clickable'"
                  >
                    {{ getPaymentReportStatus(transactions) | translate }}
                  </a>
                </label>
                <div class="bottom-content">
                  <div class="sender-info">
                    <div class="sender-info-labels">
                      <label
                        class="receiver-dashboard overflow-label margin-bottom-label"
                        [ngClass]="{ 'is-nicky-user': isNickUser(transactions.payerUser) }"
                        (click)="openProfile(transactions.payerUser)"
                      >
                        {{ transactions.payerUser.publicName || transactions.payerUser.name }}
                      </label>
                      <label
                        class="receiver-dashboard secondary-text overflow-label"
                        (click)="isMobile ? showEmailModal(transactions.payerUser.email) : ''"
                      >
                        {{ transactions.payerUser.email }}
                      </label>
                    </div>
                  </div>
                  <div class="amount-info">
                    <label class="secondary-text margin-bottom-label"> {{ 'amount' | translate }} </label>
                    <label class="amount-label">
                      <mat-icon
                        [ngClass]="{
                          'alert-warning-icon': !transactions.paymentTransactions[0]?.blockchainAssetId,
                          clickable: !transactions.paymentTransactions[0]?.blockchainAssetId,
                          icon: transactions.paymentTransactions[0]?.blockchainAssetId,
                          'asset-icon': transactions.paymentTransactions[0]?.blockchainAssetId,
                        }"
                        (click)="
                          transactions.paymentTransactions[0]?.blockchainAssetId ||
                            openTransactionWarningsModal(transactions)
                        "
                        [svgIcon]="
                          formatAssetToUi(
                            transactions.paymentTransactions[0]?.blockchainAssetId || 'transaction-warning'
                          )
                        "
                        matTooltip="{{
                          transactions.paymentTransactions[0]?.blockchainAssetId || ('Error' | translate)
                        }}"
                        [matTooltipPosition]="'above'"
                      ></mat-icon>
                      {{
                        formatCurrency.convertToLocal(
                          transactions.paymentTransactions[0]?.paymentTransfers[0]?.amountNative,
                          getDecimalPlaces(transactions.paymentTransactions[0]?.paymentTransfers[0]?.blockchainAssetId)
                        ) == 'NaN'
                          ? '--'
                          : formatCurrency.convertToLocal(
                              transactions.paymentTransactions[0]?.paymentTransfers[0]?.amountNative,
                              getDecimalPlaces(
                                transactions.paymentTransactions[0]?.paymentTransfers[0]?.blockchainAssetId
                              )
                            )
                      }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="additional-info">
                <div class="property-wrapper">
                  <label class="property-name secondary-text"> Date </label>
                  <label class="property-value">
                    {{ translateDate(transactions?.createdDate | date: 'mediumDate' : '' : 'en-US') }}
                  </label>
                </div>
                <div class="property-wrapper">
                  <label class="property-name secondary-text">
                    {{ 'DASHBOARD.reference' | translate }}
                  </label>
                  <label class="property-name secondary-text reference">
                    <label
                      class="reference-label"
                      [ngClass]="{ 'secondary-text': !transactions.bill.invoiceReference }"
                    >
                      {{ transactions.bill.invoiceReference || '--' }}
                    </label>
                  </label>
                </div>
                <div class="property-wrapper">
                  <label class="property-name secondary-text"> Id </label>
                  <label class="property-value">
                    <span class="id-span">{{ transactions.bill?.shortId }}</span>
                    <a class="icon">
                      <div class="transactionId" *ngIf="transactions.bill.shortId" #transactionId>
                        <span class="id-span"> {{ transactions.bill?.shortId }} </span>
                      </div>
                      <i
                        class="ph ph-copy icon clickable copy-icon"
                        [matTooltipPosition]="'above'"
                        matTooltip="{{ 'copy' | translate }}"
                        (click)="onCopyPaymentId(transactions.bill?.shortId)"
                      ></i>
                    </a>
                  </label>
                </div>
                <div class="property-wrapper">
                  <label class="property-name secondary-text">
                    {{ 'DASHBOARD.requestId' | translate }}
                  </label>
                  <label class="property-value">
                    <a
                      *ngIf="!(transactions.bill.paymentRequest != null)"
                      class="add-here clickable"
                      (click)="openIdInput = true; paymentReportId = transactions.bill?.shortId"
                      >{{ 'DASHBOARD.addHere' | translate }}
                    </a>
                    <div
                      class="father-wrapper requests-father-wrapper"
                      *ngIf="transactions.bill.paymentRequest != null"
                    >
                      <div class="td-wrapper requests-td-wrapper">
                        <span class="id-span">{{ transactions.bill.shortId }}</span>

                        <a class="icon">
                          <div class="transactionId" *ngIf="transactions.bill.shortId" #transactionId>
                            {{ transactions.bill.shortId }}
                          </div>
                          <i
                            class="ph ph-copy icon clickable copy-icon"
                            [matTooltipPosition]="'above'"
                            matTooltip="{{ 'copyRequestLink' | translate }}"
                            (click)="onCopyRequestId(transactions.bill.shortId)"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </label>
                </div>
                <div class="property-wrapper">
                  <label class="property-name secondary-text">
                    {{ 'DASHBOARD.transactionId' | translate }}
                  </label>
                  <label class="property-value">
                    <div class="transaction-wrapper">
                      <a class="icon">
                        <div
                          class="transactionId"
                          *ngIf="transactions.paymentTransactions[0]?.blockchainTransactionId"
                          #transactionId
                        >
                          {{ transactions.paymentTransactions[0]?.blockchainTransactionId.toString() || '--' }}
                        </div>
                        <i
                          *ngIf="transactions.paymentTransactions[0]?.blockchainTransactionId"
                          class="ph ph-copy icon clickable copy-icon"
                          [matTooltipPosition]="'above'"
                          matTooltip="{{ 'copyTransactionId' | translate }}"
                          (click)="onCopyTransactionId(transactions.paymentTransactions[0]?.blockchainTransactionId)"
                        >
                        </i>
                      </a>
                      <div class="transaction-icons-wrapper">
                        <a
                          [href]="getScanAddress(transactions)"
                          target="_blank"
                          *ngIf="transactions.paymentTransactions[0]?.blockchainTransactionId"
                        >
                          <i
                            class="ph ph-arrow-line-up-right icon clickable share-icon"
                            [matTooltipPosition]="'above'"
                            matTooltip="{{ 'openBlockChainExplorer' | translate }}"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </tr>
        }
      </table>
      <!-- end of payment reports table -->
    </div>
    <div
      [ngClass]="{ 'more-details': moreInfoPage() }"
      class="no-transactions"
      *ngIf="transactions.length == 0 && tab == 'transactions'"
    >
      @if (moreInfoPage()) {
        <mat-icon svgIcon="full-transactions"> </mat-icon>
      } @else {
        <mat-icon svgIcon="no-transactions"> </mat-icon>
      }
      <div class="description-wrapper">
        {{ 'DASHBOARD.noTransactionToBeShown' | translate }}.
        <label>{{ 'DASHBOARD.connectYourWallets' | translate }}</label>
      </div>
    </div>

    <div class="pagination-wrapper" *ngIf="!(transactions.length == 0) && tab == 'transactions'">
      <div class="items-per-page-wrapper">
        <label class="pagination-legend"> {{ showingNicks() }} of {{ totalTransactions }} </label>
      </div>

      <div class="flex-centered">
        <label class="pagination-legend">{{ 'rowsPerPage' | translate }}:</label>
        <select
          class="items-per-page"
          [(ngModel)]="itemsPerPage"
          (ngModelChange)="itemsPerPage = $event"
          (ngModelChange)="itemsPerPage = $event; submitFilter()"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
        </select>
        <div class="pagination-controls flex-centered">
          <a class="previous-page clickable" (click)="previousPage()">
            <i class="ph-bold ph-caret-left"></i>
          </a>

          <label class="pagination-legend">
            <span class="current-page">{{ currentPage[tab].page }} </span> / {{ getPagesNumber(totalTransactions) }}
          </label>

          <a class="next-page clickable" (click)="nextPage(totalTransactions)">
            <i class="ph-bold ph-caret-right"></i>
          </a>
        </div>
      </div>
    </div>

    <!-- disabled filters -->
    <div class="filters requests-filters" *ngIf="false && tab == 'requests'">
      <div>
        <a>
          <mat-icon
            *ngIf="!requestFilter.includes('Finished')"
            (click)="requestFilter.push('Finished'); submitFilter()"
            class="icon request-filter-icon filter-icon"
            svgIcon="paid-filter"
            [matTooltipPosition]="'above'"
            matTooltip="{{ 'Paid' | translate }}"
          ></mat-icon>

          <mat-icon
            *ngIf="requestFilter.includes('Finished')"
            (click)="removeFilter('Finished'); submitFilter()"
            class="icon request-filter-icon filter-icon"
            svgIcon="paid-filter-active"
            [matTooltipPosition]="'above'"
            matTooltip="{{ 'Paid' | translate }}"
          ></mat-icon>
        </a>
      </div>
      <div *ngIf="!isRequestFilterActive">
        <a
          ><mat-icon
            (click)="openFilter = true; fillFilterButtons()"
            class="icon filter-icon"
            svgIcon="filter"
          ></mat-icon>
        </a>
      </div>
      <div *ngIf="isRequestFilterActive">
        <a
          ><mat-icon
            (click)="openFilter = true; fillFilterButtons()"
            class="icon filter-icon"
            svgIconsrc="active-filter"
          ></mat-icon
        ></a>
      </div>
    </div>

    <div
      class="table-limit"
      [ngStyle]="{ 'min-height': paymentRequests.length == 0 ? 'unset' : '' }"
      *ngIf="tab == 'requests'"
    >
      <!-- payment request table -->
      <table id="requests-table" class="requests-table">
        <tr class="only-desktop">
          <th class="payer-info-th">{{ 'DASHBOARD.payer' | translate }}</th>
          <th class="date-th clickable" (click)="ascending = !ascending; submitFilter()">
            <div class="fit-th-content">
              {{ 'DASHBOARD.creationDate' | translate }}
              <div class="sorting">
                <i [ngClass]="{ 'active-sorter': ascending }" class="ph-fill ph-triangle"></i>
                <i [ngClass]="{ 'active-sorter': !ascending }" class="down-triangle ph-fill ph-triangle"></i>
              </div>
            </div>
          </th>
          <th>{{ 'DASHBOARD.amount' | translate }}</th>
          <th class="reference-header">{{ 'DASHBOARD.reference' | translate }}</th>
          <th>ID</th>
          <th class="asset-header">{{ 'PROFILE.status' | translate }}</th>
          <th class="actions-header">{{ 'Actions' | translate }}</th>
          <th></th>
        </tr>

        <!-- Desktop Requests table -->
        <tr
          class="hide-portrait"
          *ngFor="
            let request of paymentRequests
              | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage[tab].page, totalItems: totalRequests }
          "
        >
          <td>
            <div class="sender-info">
              <div class="sender-info-labels">
                <label
                  class="receiver-dashboard overflow-label"
                  [ngClass]="{ 'is-nicky-user': isNickUser(request.requester) }"
                  (click)="openProfile(request.requester)"
                >
                  {{ request.requester.publicName || request.requester.name }}
                </label>
                {{ this.isMobile ? '\n' : '' }}
                <label
                  class="receiver-dashboard secondary-text overflow-label"
                  (click)="isMobile ? showEmailModal(request.requester.email) : ''"
                >
                  {{ request.requester.email }}
                </label>
              </div>
            </div>
          </td>

          <td>
            <!-- <label class="only-mobile"  *ngIf="isMobile">
              {{ 'creationDate' | translate }}
            </label> -->
            {{
              translateDate(
                request.createdDate ? (request.createdDate | date: 'mediumDate' : 'DD-Mmm-YYYY' : 'en-US') : ''
              )
            }}
          </td>

          <td>
            <!-- <label class="only-mobile"  *ngIf="isMobile">{{ 'DASHBOARD.amount' | translate }} </label> -->
            <label class="amount-asset-request flex-centered">
              <mat-icon
                class="icon asset-icon"
                [svgIcon]="formatAssetToUi(request.blockchainAssetId)"
                [attr.aria-label]="formatAssetToUi(request.blockchainAssetId)"
              ></mat-icon>
              {{
                formatCurrency.convertToLocal(
                  request.amountNative.toString(),
                  getDecimalPlaces(request.blockchainAssetId)
                )
              }}
            </label>
          </td>

          <td class="reference-column">
            <!-- <label class="only-mobile"  *ngIf="isMobile">{{ 'DASHBOARD.reference' | translate }}</label> -->
            <label class="reference-label">{{ request.bill.invoiceReference || '--' }}</label>
          </td>

          <td>
            <!-- <label class="only-mobile"  *ngIf="isMobile">ID</label> -->
            <div class="father-wrapper requests-father-wrapper">
              <div class="td-wrapper requests-td-wrapper" *ngIf="request.bill.shortId">
                <span class="id-span" *ngIf="request.bill.shortId.length">{{ request.bill.shortId }}</span>
                <a *ngIf="!isMobile">
                  <div class="transactionId" *ngIf="request.bill.shortId" #paymentId>{{ request.bill.shortId }}</div>
                  <i
                    class="ph-bold ph-link icon clickable copy-icon"
                    [matTooltipPosition]="'above'"
                    matTooltip="{{ 'copyRequestLink' | translate }}"
                    (click)="onCopyRequestLink(request.bill.shortId)"
                  ></i>
                </a>
                <a class="icon" *ngIf="!isMobile">
                  <div class="transactionId" *ngIf="request.bill.shortId" #paymentId>{{ request.bill.shortId }}</div>
                  <i
                    class="ph-bold ph-qr-code icon clickable"
                    [matTooltipPosition]="'above'"
                    matTooltip="{{ 'clickToGetQRCodeForThisPaymentRequest' | translate }}"
                    (click)="copyQrCode(request.bill.shortId)"
                  ></i>
                </a>
              </div>
            </div>
          </td>

          <td class="pending-icon">
            <a (click)="requestClick(request)" [class]="formatPaymentRequestStatusFromView(request) + ' clickable'">
              {{ formatPaymentRequestStatusFromView(request) | translate }}
            </a>
          </td>

          <td class="actions-td">
            <div class="flex-centered flex-end">
              <i
                [ngClass]="{
                  'disabled-resend-button':
                    request.status == 'Canceled' ||
                    request.status == 'Finished' ||
                    formatPaymentRequestStatusFromView(request) === 'PaymentValidationRequired',
                }"
                class="ph ph-envelope-simple clickable icon"
                (click)="
                  request.status == 'Canceled' ||
                  request.status == 'Finished' ||
                  formatPaymentRequestStatusFromView(request) === 'PaymentValidationRequired'
                    ? ''
                    : openResendEmailModal(request.requester.email, request.bill.shortId)
                "
                [matTooltipPosition]="'above'"
                matTooltip="{{
                  request.status == 'Canceled' || request.status == 'Finished'
                    ? ('alreadyFinished' | translate)
                    : ('DASHBOARD.resendEmail' | translate)
                }}"
              >
              </i>

              <i
                [ngClass]="{ 'disabled-resend-button': request.status == 'Canceled' || request.status == 'Finished' }"
                class="ph ph-trash icon clickable"
                (click)="
                  request.status == 'Canceled' || request.status == 'Finished' ? '' : (modalRequestCancel = true);
                  cancelPaymentId = request.bill.shortId
                "
              >
              </i>

              <div class="more-actions" [ngStyle]="{ 'top.px': actionsElement.layerY + 15 }" *ngIf="request.visible">
                <a class="clickable" (click)="modalRequestCancel = true; cancelPaymentId = request.bill.shortId">
                  <i class="ph ph-x"></i>
                  {{ 'DASHBOARD.cancelThisPaymentRequestId' | translate }}
                </a>
              </div>
            </div>
          </td>

          <td class="see-more-column">
            <i
              class="ph ph-eye more-details-icon clickable"
              (click)="showPaymentRequestDetails(request)"
              [matTooltipPosition]="'above'"
              matTooltip="{{ 'public-profile.clickHereToSeeMore' | translate }}"
            ></i>
          </td>
        </tr>
        <!-- End of Desktop Requests table -->

        <!-- Mobile Requests table -->
        @if (isMobile) {
          <tr
            class="only-mobile"
            *ngFor="
              let request of paymentRequests
                | paginate
                  : { itemsPerPage: itemsPerPage, currentPage: currentPage[tab].page, totalItems: totalRequests }
            "
          >
            <div class="info-container">
              <div class="payer-info">
                <label class="payment-status">
                  <a
                    (click)="requestClick(request)"
                    [class]="formatPaymentRequestStatusFromView(request) + ' clickable'"
                  >
                    {{ formatPaymentRequestStatusFromView(request) | translate }}
                  </a>
                </label>
                <div class="bottom-content">
                  <div class="sender-info">
                    <div class="sender-info-labels">
                      <label
                        class="receiver-dashboard overflow-label margin-bottom-label"
                        [ngClass]="{ 'is-nicky-user': isNickUser(request.requester) }"
                        (click)="openProfile(request.requester)"
                      >
                        {{ request.requester.publicName || request.requester.name }}
                      </label>
                      <label
                        class="receiver-dashboard secondary-text overflow-label"
                        (click)="isMobile ? showEmailModal(request.requester.email) : ''"
                      >
                        {{ request.requester.email }}
                      </label>
                    </div>
                  </div>
                  <div class="amount-info">
                    <!-- <label class="secondary-text margin-bottom-label"> {{ 'amount' | translate }} </label> -->
                    <label class="amount-label flex-centered">
                      <mat-icon
                        class="icon asset-icon"
                        [svgIcon]="formatAssetToUi(request.blockchainAssetId)"
                        [attr.aria-label]="formatAssetToUi(request.blockchainAssetId)"
                      ></mat-icon>
                      {{
                        formatCurrency.convertToLocal(
                          request.amountNative.toString(),
                          getDecimalPlaces(request.blockchainAssetId)
                        )
                      }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="additional-info">
                <div class="property-wrapper">
                  <label class="property-name secondary-text">
                    {{ 'DASHBOARD.date' | translate }}
                  </label>
                  <label class="property-value">
                    {{
                      translateDate(
                        request.createdDate ? (request.createdDate | date: 'mediumDate' : 'DD-Mmm-YYYY' : 'en-US') : ''
                      )
                    }}
                  </label>
                </div>
                <div class="property-wrapper">
                  <label class="property-name secondary-text">
                    {{ 'DASHBOARD.reference' | translate }}
                  </label>
                  <label class="property-name secondary-text reference">
                    <label class="reference-label"> {{ request.bill.invoiceReference || '--' }} </label>
                  </label>
                </div>
                <div class="property-wrapper">
                  <label class="property-name secondary-text"> Id </label>
                  <label class="property-value">
                    <span class="id-span">{{ request.bill.shortId }}</span>
                    <a class="icon">
                      <div class="transactionId" *ngIf="request.bill.shortId" #transactionId>
                        <span class="id-span"> {{ request.bill.shortId }} </span>
                      </div>
                      <i
                        class="ph-bold ph-link icon clickable copy-icon"
                        [matTooltipPosition]="'above'"
                        matTooltip="{{ 'copyRequestLink' | translate }}"
                        (click)="onCopyRequestLink(request.bill.shortId)"
                      ></i>
                    </a>
                    <i
                      class="ph-bold ph-qr-code icon clickable"
                      [matTooltipPosition]="'above'"
                      matTooltip="{{ 'clickToGetQRCodeForThisPaymentRequest' | translate }}"
                      (click)="copyQrCode(request.bill.shortId)"
                    ></i>
                  </label>
                </div>
                <div class="property-wrapper">
                  <label class="property-name secondary-text">
                    {{ 'Actions' | translate }}
                  </label>
                  <label class="property-value">
                    <div class="flex-centered flex-end">
                      <i
                        [ngClass]="{
                          'disabled-resend-button': request.status == 'Canceled' || request.status == 'Finished',
                        }"
                        class="ph ph-envelope-simple clickable icon"
                        (click)="
                          request.status == 'Canceled' || request.status == 'Finished'
                            ? ''
                            : openResendEmailModal(request.requester.email, request.bill.shortId)
                        "
                        [matTooltipPosition]="'above'"
                        matTooltip="{{
                          request.status == 'Canceled' || request.status == 'Finished'
                            ? ('alreadyFinished' | translate)
                            : ('DASHBOARD.resendEmail' | translate)
                        }}"
                      >
                      </i>

                      <i
                        [ngClass]="{
                          'disabled-resend-button':
                            request.status == 'Canceled' ||
                            request.status == 'Finished' ||
                            formatPaymentRequestStatusFromView(request) === 'PaymentValidationRequired',
                        }"
                        class="ph ph-trash icon clickable"
                        (click)="
                          request.status == 'Canceled' || request.status == 'Finished'
                            ? ''
                            : (modalRequestCancel = true);
                          cancelPaymentId = request.bill.shortId
                        "
                      >
                      </i>

                      <div
                        class="more-actions"
                        [ngStyle]="{ 'top.px': !isMobile ? actionsElement.pageY - 80 : actionsElement.layerY + 10 }"
                        *ngIf="request.visible"
                      >
                        <a
                          class="clickable"
                          (click)="modalRequestCancel = true; cancelPaymentId = request.bill.shortId"
                        >
                          <i class="ph ph-x"></i>
                          {{ 'DASHBOARD.cancelThisPaymentRequestId' | translate }}
                        </a>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </tr>
        }

        <!-- End of Mobile Requests table -->
      </table>
      <!-- end of payment request table -->
    </div>

    <div
      class="no-transactions"
      [ngClass]="{ 'more-details': moreInfoPage() }"
      *ngIf="totalRequests == 0 && tab == 'requests'"
    >
      @if (moreInfoPage()) {
        <mat-icon class="full-screen-img" svgIcon="full-transactions"></mat-icon>
      } @else {
        <mat-icon class="full-screen-img" svgIcon="no-transactions"></mat-icon>
      }
      <div class="description-wrapper">
        {{ 'DASHBOARD.noRequestToBeShown' | translate }}.
        <label>{{ 'DASHBOARD.sendAPaymentRequestNow' | translate }}</label>
      </div>
      <div
        class="new-button clickable flex-centered"
        (click)="openPaymentRequestFlow()"
        [ngClass]="{ 'no-see-all': !currentRoute.includes('/overview') }"
      >
        <i class="ph ph-plus"></i>
        <label>{{ 'DASHBOARD.paymentRequest' | translate }}</label>
      </div>
    </div>

    <div class="pagination-wrapper" *ngIf="!(totalRequests == 0) && tab == 'requests'">
      <div class="items-per-page-wrapper">
        <label class="pagination-legend"> {{ showingNicks() }} of {{ totalRequests }} </label>
      </div>

      <div class="flex-centered">
        <label class="pagination-legend">{{ 'rowsPerPage' | translate }}:</label>
        <select
          class="items-per-page"
          [(ngModel)]="itemsPerPage"
          (ngModelChange)="itemsPerPage = $event"
          (ngModelChange)="itemsPerPage = $event; submitFilter()"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
        </select>
        <div class="pagination-controls flex-centered">
          <a class="previous-page clickable" (click)="previousPage()">
            <i class="ph-bold ph-caret-left"></i>
          </a>

          <label class="pagination-legend">
            <span class="current-page">{{ currentPage[tab].page }} </span> / {{ getPagesNumber(totalRequests) }}
          </label>

          <a class="next-page clickable" (click)="nextPage(totalRequests)">
            <i class="ph-bold ph-caret-right"></i>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- dashboard modals -->

  <div *ngIf="openFilter && tab == 'requests'" class="modal-backdrop">
    <div class="modal filter-modal" [ngClass]="{ 'email-added-wrapper': requestsfilteredEmails.length > 0 }">
      <div class="modal-header flex-centered" [ngClass]="{ 'email-added-wrapper': filteredEmails.length > 0 }">
        <h3>{{ 'Filter' | translate }}</h3>
        <i class="ph-bold ph-x clickable" (click)="openFilter = false"></i>
      </div>
      <div class="modal-body">
        <form [formGroup]="requestFilterForm" class="general-filter">
          <div class="input-wrapper">
            <h3>{{ 'DASHBOARD.date' | translate }}</h3>
            <div class="half-inputs">
              <div class="input-container">
                <mat-datepicker-toggle class="picker" matSuffix [for]="initialPicker"></mat-datepicker-toggle>
                <mat-datepicker class="picker" #initialPicker></mat-datepicker>
                <input
                  formControlName="dateStart"
                  (click)="toggleDatePicker(initialPicker)"
                  class="mat-mdc-button-touch-target"
                  matInput
                  [matDatepicker]="initialPicker"
                  placeholder="{{ 'DASHBOARD.initialDate' | translate }}"
                  (dateChange)="dateChanged($event)"
                  readonly
                />
              </div>

              <div class="input-container">
                <mat-datepicker-toggle class="picker" matSuffix [for]="finalPicker"></mat-datepicker-toggle>
                <mat-datepicker class="picker" #finalPicker></mat-datepicker>
                <input
                  formControlName="dateEnd"
                  (click)="toggleDatePicker(finalPicker)"
                  class="mat-mdc-button-touch-target"
                  matInput
                  [matDatepicker]="finalPicker"
                  placeholder="{{ 'DASHBOARD.finalDate' | translate }}"
                  (dateChange)="dateChanged($event)"
                  readonly
                />
              </div>
            </div>
          </div>

          <div class="assets-wrapper requests-assets">
            <app-custom-option-select
              [availableExchanges]="currentOptionsSelects.choosenAsset"
              [currentValue]="choosenAsset"
              (changedValue)="setAssetFilter($event || '')"
            ></app-custom-option-select>

            <div class="input-wrapper">
              <div class="half-inputs">
                <div class="input-container">
                  <i class="ph ph-currency-circle-dollar"></i>
                  <input
                    type="text"
                    #currencyInput
                    placeholder="{{ isMobile ? '' : '<' }} {{ 'DASHBOARD.lessThan' | translate }}"
                    formControlName="amountLessThan"
                    autocomplete="transaction-amount"
                    appNumberFormat
                  />
                </div>

                <div class="input-container">
                  <i class="ph ph-currency-circle-dollar"></i>
                  <input
                    type="text"
                    #currencyInput
                    placeholder="{{ 'DASHBOARD.moreThan' | translate }} {{ isMobile ? '' : '>' }}"
                    amountMoreThan
                    formControlName="amountMoreThan"
                    appNumberFormat
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            [ngClass]="{ 'added-email': requestsfilteredEmails.length > 0 }"
            class="input-wrapper"
            id="receiver-input"
          >
            <h3>{{ 'payers' }}</h3>

            <div class="input-container">
              <i class="ph ph-user-circle"></i>
              <input
                type="text"
                placeholder="{{ 'DASHBOARD.addOneOrMorePayersHere' | translate }}"
                (keyup)="addEmailToFilter($event)"
                (blur)="isMobile ? addEmailToFilter({ code: 'Enter' }) : ''"
                (input)="isMobile ? addEmailToFilter($event) : ''"
                formControlName="receiverNick"
              />
            </div>

            <div class="filtering-emails-wrapper" *ngIf="requestsfilteredEmails.length > 0">
              <div *ngFor="let email of requestsfilteredEmails" class="filtering-emails">
                {{ email }} <span class="remove-email" (click)="removeEmail(email)">x</span>
              </div>
            </div>
          </div>

          <div class="input-wrapper">
            <h3>{{ 'DASHBOARD.paymentRequestId' | translate }}</h3>
            <div class="input-container">
              <i class="ph-bold ph-arrows-left-right"></i>
              <input
                type="text"
                placeholder="{{ 'DASHBOARD.transactionIdMaximumOfTwo' | translate }}"
                formControlName="paymentId"
              />
            </div>
          </div>

          <div class="input-wrapper">
            <h3>{{ 'DASHBOARD.status' | translate }}</h3>
            <div class="status-wrapper">
              <div
                class="status {{ status.value }} clickable"
                *ngFor="let status of paymentRequestFilterStatus"
                (click)="setChoosenStatus(status.value)"
              >
                <div class="status-check" [ngClass]="{ 'status-checked': choosenStatus === status.value }"></div>
                <span>
                  <i class="ph-fill ph-circle"></i>
                  {{ status.name }}
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="filter-buttons-wrapper">
        <div class="set-filter-buttons">
          <button (click)="openFilter = false; clearFilter()" class="cancel-button">{{ 'cancel' | translate }}</button>
          <button (click)="submitFilter(true)">{{ 'filter' | translate }}</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="openFilter && tab == 'transactions'" class="modal-backdrop">
    <div class="modal filter-modal" [ngClass]="{ 'email-added-wrapper': filteredEmails.length > 0 }">
      <div class="modal-header flex-centered" [ngClass]="{ 'email-added-wrapper': filteredEmails.length > 0 }">
        <h3>{{ 'Filter' | translate }}</h3>
        <i class="ph-bold ph-x clickable" (click)="openFilter = false"></i>
      </div>
      <div class="modal-body">
        <form [formGroup]="filterForm" class="general-filter">
          <div class="input-wrapper">
            <h3>{{ 'DASHBOARD.date' | translate }}</h3>
            <div class="half-inputs">
              <div class="input-container">
                <mat-datepicker-toggle class="picker" matSuffix [for]="initialPicker"></mat-datepicker-toggle>
                <mat-datepicker class="picker" #initialPicker></mat-datepicker>

                <input
                  formControlName="dateStart"
                  (click)="toggleDatePicker(initialPicker)"
                  class="mat-mdc-button-touch-target"
                  matInput
                  [matDatepicker]="initialPicker"
                  placeholder="{{ 'DASHBOARD.initialDate' | translate }}"
                  (dateChange)="dateChanged($event)"
                  readonly
                />
              </div>

              <div class="input-container">
                <mat-datepicker-toggle class="picker" matSuffix [for]="finalPicker"></mat-datepicker-toggle>
                <mat-datepicker class="picker" #finalPicker></mat-datepicker>
                <input
                  formControlName="dateEnd"
                  (click)="toggleDatePicker(finalPicker)"
                  class="mat-mdc-button-touch-target"
                  matInput
                  [matDatepicker]="finalPicker"
                  placeholder="{{ 'DASHBOARD.finalDate' | translate }}"
                  (dateChange)="dateChanged($event)"
                  readonly
                />
              </div>
            </div>
          </div>

          <div class="assets-wrapper">
            <div class="assets-icon">
              <h3>{{ 'Assets' | translate }}</h3>
              <div class="filter-assets-wrapper">
                <a>
                  <img
                    (click)="modalBitcoinFilter = !modalBitcoinFilter"
                    class="icon filter-icon"
                    [ngClass]="{ 'active-filter': modalBitcoinFilter }"
                    src="{{ getFilterIcon('Bitcoin', modalBitcoinFilter, true) }}"
                  />
                </a>
                <div>
                  <a>
                    <img
                      (click)="modalEthereumFilter = !modalEthereumFilter"
                      class="icon filter-icon"
                      [ngClass]="{ 'active-filter': modalEthereumFilter }"
                      src="{{ getFilterIcon('Ethereum', modalEthereumFilter, true) }}"
                    />
                  </a>
                </div>
                <div>
                  <a>
                    <img
                      (click)="modalUsdcFilter = !modalUsdcFilter"
                      class="icon filter-icon"
                      [ngClass]="{ 'active-filter': modalUsdcFilter }"
                      src="{{ getFilterIcon('USDC', modalUsdcFilter, true) }}"
                    />
                  </a>
                </div>
                <div>
                  <a>
                    <img
                      (click)="modalUsdtFilter = !modalUsdtFilter"
                      class="icon filter-icon"
                      [ngClass]="{ 'active-filter': modalUsdtFilter }"
                      src="{{ getFilterIcon('USDT', modalUsdtFilter, true) }}"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="input-wrapper">
              <div class="half-inputs">
                <div class="input-container">
                  <i class="ph ph-currency-circle-dollar"></i>
                  <input
                    type="text"
                    #currencyInput
                    placeholder="{{ isMobile ? '' : '<' }} {{ 'DASHBOARD.lessThan' | translate }}"
                    autocomplete="off"
                    formControlName="amountLessThan"
                    appNumberFormat
                  />
                </div>
                <div class="input-container">
                  <i class="ph ph-currency-circle-dollar"></i>
                  <input
                    type="text"
                    #currencyInput
                    placeholder="{{ 'DASHBOARD.moreThan' | translate }} {{ isMobile ? '' : '>' }}"
                    autocomplete="off"
                    amountMoreThan
                    formControlName="amountMoreThan"
                    appNumberFormat
                  />
                </div>
              </div>
            </div>
          </div>

          <div [ngClass]="{ 'added-email': filteredEmails.length > 0 }" class="input-wrapper" id="receiver-input">
            <h3>{{ 'payers' | translate }}</h3>
            <div class="input-container">
              <i class="ph ph-user-circle"></i>
              <input
                type="text"
                placeholder="{{ 'DASHBOARD.addOneOrMoreReceiversHere' | translate }}"
                (keyup)="addEmailToFilter($event)"
                (blur)="isMobile ? addEmailToFilter({ code: 'Enter' }) : ''"
                (input)="isMobile ? addEmailToFilter($event) : ''"
                formControlName="receiverNick"
              />
            </div>

            <div class="filtering-emails-wrapper" *ngIf="filteredEmails.length > 0">
              <div *ngFor="let email of filteredEmails" class="filtering-emails">
                {{ email }}
                <span class="remove-email" (click)="removeEmail(email)">x</span>
              </div>
            </div>
          </div>

          <div class="input-wrapper">
            <h3>{{ 'DASHBOARD.transactionId' | translate }}</h3>
            <div class="input-container">
              <i class="ph-bold ph-arrows-left-right"></i>
              <input
                type="text"
                placeholder="{{ 'DASHBOARD.transactionIdMaximumOfTwo' | translate }}"
                formControlName="transactionId"
              />
            </div>
          </div>

          <div class="input-wrapper">
            <h3>{{ 'PROFILE.status' | translate }}</h3>
            <div class="status-wrapper">
              <div
                class="status {{ status.value }} clickable"
                *ngFor="let status of paymentReportsFilterStatus"
                (click)="setChoosenStatus(status.value)"
              >
                <div class="status-check" [ngClass]="{ 'status-checked': choosenStatus === status.value }"></div>
                <span>
                  <i class="ph-fill ph-circle"></i>
                  {{ status.name }}
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="filter-buttons-wrapper">
        <div class="set-filter-buttons">
          <button (click)="openFilter = false; clearFilter()" class="cancel-button">{{ 'cancel' | translate }}</button>
          <button (click)="submitFilter(true)">{{ 'Apply Filters' | translate }}</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="openIdInput" class="modal-backdrop">
    <div class="modal insert-id-modal">
      <div class="modal-header">
        <h3 *ngIf="tab == 'requests'">{{ 'DASHBOARD.linkAPaymentReportToItsPaymentRequest' | translate }}</h3>
        <h3 *ngIf="tab != 'requests'">{{ 'DASHBOARD.insertThePaymentIdOfTheTransaction' | translate }}</h3>

        <label *ngIf="tab != 'requests'"> {{ 'DASHBOARD.linkARequestToThisPayment' | translate }}.</label>

        <div
          [ngClass]="{ 'not-valid': invalidRequestId }"
          *ngIf="tab != 'requests'"
          class="input-container receiver-input"
        >
          <input [(ngModel)]="targetPaymentId" type="text" placeholder="{{ 'paymentId' | translate }}" />
        </div>
        <label *ngIf="invalidRequestId" class="warning-label">
          {{ 'DASHBOARD.invalidPaymentRequestId' | translate }}
        </label>

        <div class="buttons-wrapper">
          <button (click)="closeLinkPaymentModal()" class="cancel-button">{{ 'cancel' | translate }}</button>
          <button class="submit-button" (click)="linkToAPayment()">{{ 'DASHBOARD.submit' | translate }}</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="modalRequestCancel" class="modal-backdrop">
    <div class="modal insert-id-modal">
      <div class="modal-header">
        <h3>{{ 'DASHBOARD.cancelThisPaymentRequestId' | translate }} ?</h3>
        <!-- <h3 class="close" (click)="modalRequestCancel = false;"> X </h3> -->
        <label> {{ 'DASHBOARD.areYouSureYouWantToCancelThisPayment' | translate }}?</label>
        <div class="buttons-wrapper">
          <button (click)="modalRequestCancel = false" class="cancel-button">{{ 'no' | translate }}</button>
          <button (click)="cancelRequest(cancelPaymentId); modalRequestCancel = false">
            {{ 'yesCancel' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="resendEmailModal" class="modal-backdrop">
    <div class="modal insert-id-modal">
      <div class="modal-header">
        <h3>{{ 'DASHBOARD.resendEmail' | translate }} ?</h3>
        <h3 class="close" (click)="resendEmailModal = false">X</h3>
        <label> {{ 'DASHBOARD.aNewEmailWillBeSentTo' | translate }} {{ currentSentEmail }}</label>
        <div class="buttons-wrapper">
          <button (click)="resendEmailModal = false" class="cancel-button">{{ 'cancel' | translate }}</button>
          <button (click)="resendPaymentRequest()">{{ 'confirm' | translate }}</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="modalMarkAsPaid" class="modal-backdrop">
    <div class="modal insert-id-modal set-as-paid-modal">
      <div class="modal-header">
        <h3 *ngIf="currentPayment.status !== 'Canceled'">{{ 'DASHBOARD.wouldYouLikeToDo' | translate }} ?</h3>
        <h3 *ngIf="currentPayment.status == 'Canceled'">{{ 'payment_flow.PaymentRequestDetails' | translate }}</h3>
        <h3 class="close" (click)="closePaidModal()">X</h3>
        <ul class="warning-wrapper" *ngFor="let paymentWarning of paymentWarnings">
          <li>
            <i class="ph ph-warning"></i>
            <label class="warning-label" [innerHTML]="paymentWarning"></label>
          </li>
        </ul>
        <div class="buttons-wrapper" *ngIf="currentPayment.status !== 'Canceled'">
          <button (click)="closePaidModal()" class="cancel-button nothing-now">
            {{ 'DASHBOARD.nothingForNow' | translate }}
          </button>
          <button class="submit-button" (click)="setRequestAsPaid(paidId); closePaidModal()">
            {{ 'DASHBOARD.setAsPaid' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="reopenPayment" class="modal-backdrop">
    <div class="modal insert-id-modal set-as-paid-modal">
      <div class="modal-header">
        <h3>{{ 'DASHBOARD.reopeningThePaymentRequest' | translate }}</h3>
        <h3 class="close" (click)="closeReopenModal(); closePaidModal()">X</h3>
        <div class="modal-body">
          <label class="warning-label">
            {{ 'DASHBOARD.plaseInformTheRemaining' | translate }} {{ formatAssetToUi(paymentRequestAsset) }}
            {{ 'DASHBOARD.valueToFullFillThePaymentRequest' | translate }}</label
          >
          <div [ngClass]="{ 'not-valid': invalidMissingValue }" class="input-container receiver-input">
            <input
              [(ngModel)]="missingValue"
              type="text"
              inputmode="numeric"
              [placeholder]="
                ('DASHBOARD.remaining' | translate) +
                formatAssetToUi(paymentRequestAsset) +
                ('DASHBOARD.value' | translate)
              "
              appNumberFormat
            />
          </div>
          <label *ngIf="invalidMissingValue" class="inform-label">
            {{ 'DASHBOARD.youNeedToInformTheRemainingValueToProceed' | translate }} .</label
          >
        </div>
        <div class="buttons-wrapper">
          <button (click)="closeReopenModal(true)" class="cancel-button nothing-now">{{ 'back' | translate }}</button>
          <button class="submit-button reopen-button" (click)="submitReopenPayment()">
            {{ 'confirm' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="qrModal" class="modal-backdrop">
    <div class="modal qr-modal">
      <i class="ph-bold ph-x close-modal clickable" (click)="qrModal = false"></i>
      <div class="modal-title">
        <h1>
          {{ 'DASHBOARD.scanOr' | translate }}
          {{ (navigatorShare ? 'Copy' : 'save') | translate }}
          {{ 'DASHBOARD.theQrCode' | translate }}
        </h1>
        <h3>
          {{ 'youCanCopyTheLinkOrClick' | translate }}
        </h3>
      </div>
      <hr class="spacement" />
      <div class="modal-body">
        <img [src]="qrCode" class="qr-code clickable" (click)="copyQr()" />
        <div class="input-wrapper">
          <label> {{ 'copy' | translate }} </label>
          <div class="input-container disabled clickable" (click)="onCopyRequestLink(url + selectedShortIdCode)">
            <i class="ph ph-copy icon clickable copy-icon"></i>
            <input
              type="text"
              class="clickable"
              (click)="onCopyRequestLink(url + selectedShortIdCode)"
              [value]="url + selectedShortIdCode"
              disabled
            />
          </div>
        </div>
      </div>
      <hr class="spacement" />
      <div class="buttons-wrapper">
        <button class="secondary-button" (click)="qrModal = false">{{ 'cancel' | translate }}</button>
        <button (click)="qrModal = false">{{ 'done' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal-backdrop" *ngIf="paymentReportDetails" (click)="closeModalDetailsModal($event)">
  <div class="modal transaction-description-modal">
    <div class="modal-header">
      <h1>{{ 'transactionDetails' | translate }}</h1>
      <i class="ph ph-x clickable" (click)="paymentReportDetails = false"></i>
    </div>
    <div class="modal-body">
      <div class="user-info">
        @if (detailedPaymentReport.payerUser.hasProfilePicture) {
          <img
            class="company-logo"
            [src]="environment.api + 'User/get-public-profile-picture?userId=' + detailedPaymentReport.payerUser.id"
            [matTooltipPosition]="'above'"
            matTooltip="{{ detailedPaymentReport.payerUser.publicName || detailedPaymentReport.payerUser.name }}"
            (error)="
              handleImageError(
                $event,
                detailedPaymentReport.payerUser.publicName || detailedPaymentReport.payerUser.name
              )
            "
          />
        } @else {
          <img
            class="company-logo"
            [src]="
              this.avatarService.getInitialAvatar(
                detailedPaymentReport.payerUser.publicName || detailedPaymentReport.payerUser.name
              )
            "
            alt="user-img"
          />
        }
        <div class="user-description">
          <h4>{{ detailedPaymentReport.payerUser.publicName || detailedPaymentReport.payerUser.name }}</h4>
          <p>{{ detailedPaymentReport.payerUser.email }}</p>
        </div>
      </div>
      <div class="transaction-amount description-wrapper">
        <h2>
          <mat-icon
            class="icon asset-icon"
            [svgIcon]="formatAssetToUi(detailedPaymentReport.paymentTransactions[0]?.blockchainAssetId || 'ETH.USDT')"
          ></mat-icon>

          @if (detailedPaymentReport.paymentTransactions[0].paymentTransfers[0]?.amountNative) {
            {{
              formatCurrency.convertToLocal(
                detailedPaymentReport.paymentTransactions[0].paymentTransfers[0].amountNative.toString(),
                getDecimalPlaces(detailedPaymentReport.paymentTransactions[0]?.blockchainAssetId || '')
              ) == 'NaN'
                ? '--'
                : formatCurrency.convertToLocal(
                    detailedPaymentReport.paymentTransactions[0].paymentTransfers[0].amountNative.toString(),
                    getDecimalPlaces(detailedPaymentReport.paymentTransactions[0]?.blockchainAssetId || '')
                  )
            }}
          } @else {
            --
          }
        </h2>
        <span class="status">
          <label class="payment-status">
            <a
              (click)="paymentReportClick(detailedPaymentReport)"
              [class]="getPaymentReportStatus(detailedPaymentReport) + ' clickable'"
            >
              {{ getPaymentReportStatus(detailedPaymentReport) | translate }}
            </a>
          </label>
        </span>
      </div>
      <div class="transaction-detail">
        <div>
          <label class="property-tittle">{{ 'DASHBOARD.date' | translate }}</label>
          <label> {{ translateDate(detailedPaymentReport?.createdDate | date: 'mediumDate' : '' : 'en-US') }} </label>
        </div>
        <div>
          <label class="property-tittle">{{ 'DASHBOARD.reference' | translate }}</label>
          <label>{{ detailedPaymentReport.bill.invoiceReference || '--' }}</label>
        </div>
        <div>
          <label class="property-tittle">ID</label>
          <label>{{ detailedPaymentReport.bill.shortId }}</label>
        </div>
        <div>
          <label class="property-tittle">{{ 'DASHBOARD.requestId' | translate }}</label>
          <label>
            <a
              *ngIf="!(detailedPaymentReport.bill.paymentRequest != null)"
              class="add-here clickable"
              (click)="openIdInput = true; paymentReportId = detailedPaymentReport.bill.shortId"
              >{{ 'DASHBOARD.addHere' | translate }}
            </a>
          </label>
        </div>
        <!-- <div><label>Target</label> <label for=""></label></div> -->
        <div>
          <label class="property-tittle">{{ 'DASHBOARD.transactionId' | translate }}</label>
          <label class="transactions-icon-wrapper">
            <a class="icon">
              <div
                class="transactionId"
                *ngIf="detailedPaymentReport.paymentTransactions[0]?.blockchainTransactionId"
                #transactionId
              >
                {{ detailedPaymentReport.paymentTransactions[0].blockchainTransactionId.toString() || '--' }}
              </div>
              <i
                *ngIf="detailedPaymentReport.paymentTransactions[0]?.blockchainTransactionId"
                class="ph ph-copy icon clickable copy-icon"
                [matTooltipPosition]="'above'"
                matTooltip="{{ 'copyTransactionId' | translate }}"
                (click)="onCopyTransactionId(detailedPaymentReport.paymentTransactions[0].blockchainTransactionId)"
              >
              </i>
            </a>
            <a
              [href]="getScanAddress(detailedPaymentReport)"
              target="_blank"
              *ngIf="detailedPaymentReport.paymentTransactions[0].blockchainTransactionId"
            >
              <i
                class="ph ph-arrow-line-up-right icon clickable share-icon"
                [matTooltipPosition]="'above'"
                matTooltip="{{ 'openBlockChainExplorer' | translate }}"
              ></i>
            </a>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-backdrop" *ngIf="paymentRequestDetails" (click)="closeModalDetailsModal($event)">
  <div class="modal transaction-description-modal">
    <div class="modal-header">
      <h1>{{ 'transactionDetails' | translate }}</h1>
      <i class="ph ph-x clickable" (click)="paymentRequestDetails = false"></i>
    </div>
    <div class="modal-body">
      <div class="user-info">
        @if (detailedPaymentRequest.requester.hasProfilePicture) {
          <img
            class="company-logo"
            [src]="environment.api + 'User/get-public-profile-picture?userId=' + detailedPaymentRequest.requester.id"
            [matTooltipPosition]="'above'"
            matTooltip="{{ detailedPaymentRequest.requester.publicName || detailedPaymentRequest.requester.name }}"
            (error)="
              handleImageError(
                $event,
                detailedPaymentRequest.requester.publicName || detailedPaymentRequest.requester.name
              )
            "
          />
        } @else {
          <img
            class="company-logo"
            [src]="
              this.avatarService.getInitialAvatar(
                detailedPaymentRequest.requester.publicName || detailedPaymentRequest.requester.name
              )
            "
            alt="user-img"
          />
        }
        <div class="user-description">
          <h4>{{ detailedPaymentRequest.requester.name }}</h4>
          <p>{{ detailedPaymentRequest.requester.email }}</p>
        </div>
      </div>
      <div class="transaction-amount description-wrapper">
        <h2>
          <mat-icon
            class="icon asset-icon"
            [svgIcon]="formatAssetToUi(detailedPaymentRequest.blockchainAssetId || 'ETH.USDT')"
          ></mat-icon>
          @if (detailedPaymentRequest.amountNative) {
            {{
              formatCurrency.convertToLocal(
                detailedPaymentRequest.amountNative.toString(),
                getDecimalPlaces(detailedPaymentRequest.blockchainAssetId)
              ) == 'NaN'
                ? '--'
                : formatCurrency.convertToLocal(
                    detailedPaymentRequest.amountNative.toString(),
                    getDecimalPlaces(detailedPaymentRequest.blockchainAssetId)
                  )
            }}
          } @else {
            --
          }
        </h2>
        <span class="status">
          <label class="payment-status">
            <a
              (click)="requestClick(detailedPaymentRequest)"
              [class]="formatPaymentRequestStatusFromView(detailedPaymentRequest) + ' clickable'"
            >
              {{ formatPaymentRequestStatusFromView(detailedPaymentRequest) | translate }}
            </a>
          </label>
        </span>
      </div>
      <div class="transaction-detail">
        <div>
          <label class="property-tittle">{{ 'DASHBOARD.date' | translate }}</label>
          <label> {{ translateDate(detailedPaymentRequest.createdDate | date: 'mediumDate' : '' : 'en-US') }} </label>
        </div>
        <div>
          <label class="property-tittle">{{ 'DASHBOARD.reference' | translate }}</label>
          <label>{{ detailedPaymentRequest.bill.invoiceReference || '--' }}</label>
        </div>
        <div>
          <label class="property-tittle">ID</label>
          <label class="transactions-icon-wrapper">
            <span class="id-span" *ngIf="detailedPaymentRequest.bill.shortId.length">{{
              detailedPaymentRequest.bill.shortId
            }}</span>
            <a>
              <div class="transactionId" *ngIf="detailedPaymentRequest.bill.shortId" #paymentId>
                {{ detailedPaymentRequest.bill.shortId }}
              </div>
              <i
                class="ph-bold ph-link icon clickable copy-icon"
                [matTooltipPosition]="'above'"
                matTooltip="{{ 'copyRequestLink' | translate }}"
                (click)="onCopyRequestLink(detailedPaymentRequest.bill.shortId)"
              ></i>
            </a>
            <a class="icon">
              <div class="transactionId" *ngIf="detailedPaymentRequest.bill.shortId" #paymentId>
                {{ detailedPaymentRequest.bill.shortId }}
              </div>
              <i
                class="ph-bold ph-qr-code icon clickable"
                [matTooltipPosition]="'above'"
                matTooltip="{{ 'clickToGetQRCodeForThisPaymentRequest' | translate }}"
                (click)="copyQrCode(detailedPaymentRequest.bill.shortId)"
              ></i>
            </a>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>

<app-nicky-footer *ngIf="!hideFooter"></app-nicky-footer>
