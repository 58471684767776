<div class="nicky-footer">
    <div class="opacity"></div>
    <div class="contents">
        <div class="copyright">© {{ currentYear }} Nicky L.L.C.</div>
        <div class="links">
            <label> {{ 'nickyDoestNotHoldFunds' | translate }} </label>
            <a class="clickable" [href]="termsOfUseUrl" target="_blank">{{ 'termsOfUse' | translate }} </a> 
            <a class="clickable" [href]="privacyPolicyUrl" target="_blank">{{ 'privacyPolicy' | translate }}</a>
            <a class="clickable" [href]="legalDisclosureUrl" target="_blank"> {{ 'disclousures' | translate }} </a> 
            <a class="clickable" [href]="cookiePolicyUrl" target="_blank">{{ 'cookiePolicy' | translate }}</a>
        </div>
    </div>
</div>
