import { Component, ElementRef, EventEmitter, HostListener, inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { DropdownComponent } from '../form/dropdown.component/dropdown.component';

import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { SearchService } from '../../services/search.service';
import { MatTooltipModule } from '@angular/material/tooltip';


@Component({
  selector: 'app-search-bar-private',
  standalone: true,
  imports: [RouterModule, TranslateModule, MatIconModule, DropdownComponent, MatTooltipModule],
  templateUrl: './search-bar-private.component.html',
})
export class SearchBarPrivateComponent implements OnInit, OnDestroy {

  private router = inject(Router);
  private searchService = inject(SearchService);
  
  @Output() search = new EventEmitter<string>();
  @ViewChild('searchContainer') elementRef!: ElementRef;

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (this.elementRef && !this.elementRef.nativeElement.contains(event.target)) {
      // your existing code
    }
  }
  private searchSubject = new Subject<string>();
  private destroy$ = new Subject<void>();
  searchResults: any;
  showResults: boolean = false;
  searchTerm: string = '';

  ngOnInit() {
    this.setupSearchSubscription();
    // Setup the debounced search
    this.searchSubject.pipe(
      debounceTime(700), // Wait 300ms after last input
      distinctUntilChanged(), // Only emit if value changed
      takeUntil(this.destroy$)
    ).subscribe(searchTerm => {
      this.search.emit(searchTerm);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onInputChange(event: any) {
    const term = event.target.value.trim();
    this.searchSubject.next(term);
  }

  private performSearch(term: string) {
    this.searchService.generalSearch(term).subscribe({
      next: (results: any) => {
        // In general search we should only show the results if there is a exactly match
        this.handleGeneralSearchResponse(results, term);
      },
      error: (error) => {
        console.error('Search error:', error);
        this.searchResults = [];
      }
    });
  }

  private handleGeneralSearchResponse(response: any, term: string) {
    if (!response || !term) {
      this.searchResults = [];
      return;
    }

    const formattedSearchString = this.formatSearchString(term);
    this.searchResults = response;

    if (this.searchResults?.length === 1) {
      const result = this.searchResults[0];
      result.exactlyMatch = this.isExactMatch(result, formattedSearchString);

      if (!result.exactlyMatch) {
        this.searchResults = [];
      }
    } else {
      this.searchResults = [];
    }
  }

  private formatSearchString(term: string): string {
    return term.includes('@') ? term.trim() : term.toUpperCase().trim();
  }

  private isExactMatch(result: any, searchString: string): boolean {
    return (
      searchString === result?.nick ||
      searchString === result?.shortId ||
      this.isSearchDomain(result, searchString)
    );
  }


  isSearchDomain(result: any, searchString: string): boolean {
    return (
      result.domains?.some(
        (domain: any) => domain.name.toLowerCase().trim() === searchString.toLowerCase().trim() && domain.isValid,
      ) || false
    );
  }

  private setupSearchSubscription() {
    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(term => {
      this.searchTerm = term;
      if (term.length >= 3) {
        this.performSearch(term);
      } else {
        this.searchResults = [];
      }
      this.showResults = true;
    });
  }

  goToProfile(userShortId: string): void {
    // Clear the search input and results
    this.searchTerm = '';
    this.searchResults = [];
    this.showResults = false;

    // Navigate to the user's profile
    // When we open a user profile we should use the shortId by default /s/shortId
    this.router.navigate(['s/', userShortId]);
  }
}
