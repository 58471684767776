<footer class="bg-white border-t border-gray-100">
  <!-- Remove absolute positioning -->
  <div aria-hidden="true" class="relative -z-10 bg-gradient-to-b from-gray-50/50"></div>

  <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    <div class="py-6 sm:py-8">
      <div class="flex flex-col sm:flex-row items-center justify-between gap-y-4">
        <!-- Copyright section -->
        <p class="text-sm text-gray-500">© {{ currentYear }} Nicky L.L.C.</p>

        <!-- Navigation -->
        <nav class="flex flex-col sm:flex-row items-center gap-4 text-sm">
          <p class="text-gray-500">
            {{ 'nickyDoestNotHoldFunds' | translate }}
          </p>

          <ul class="flex flex-wrap items-center gap-x-6 gap-y-2">
            @for (link of footerLinks; track link) {
              <li>
                <a
                  [href]="link.url"
                  class="text-gray-500 hover:text-gray-900 transition-colors duration-200"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ link.translationKey | translate }}
                </a>
              </li>
            }
          </ul>
        </nav>
      </div>
    </div>
  </div>
</footer>
