<app-modal
  [modalMessage]="modalMessage"
  [modalTittle]="modalTittle"
  [modalDeleteExchange]="modalDeleteExchange"
  (onDeleteConfirmation)="deleteUser($event)"
  (deleteExchange)="removeCryptoConnection(exchangeId)"
  (onCloseModal)="closeModal($event)"
  [exchangeModal]="true"
  *ngIf="showModal"
></app-modal>

<app-header
  (closeProfile)="closeModal($event)"
  class="profile-header"
  (tabName)="tourMobileTabSelection($event)"
  (userSelectedLanguage)="updateLanguage($event)"
  (finishedTour)="$event === true ? (manualTourName = '') : ''"
  [assets]="allSystemAssets"
  [fromProfile]="true"
  [userLogo]="base64image"
  [manualTourName]="manualTourName"
></app-header>

<div class="profile" (click)="toggleMenu()">
  <div class="sections-wrapper" [ngSwitch]="isMobile || currentTab">
    <h1>
      {{ 'settings' | translate }}
    </h1>
    <div class="tabs">
      <ul class="tab-selection" #tabsSelection id="introduction">
        <div *ngFor="let tab of tabs; let i = index">
          <li
            class="clickable"
            (click)="changeCurrentTab(tab, this.currentTab)"
            [id]="tab"
            [ngClass]="{ 'current-tab': currentTab == tab }"
          >
            {{ getTabName(tab) }}
          </li>
        </div>
      </ul>
      <div #generalDiv class="general tab" *ngSwitchCase="isMobile || 'General'">
        <div class="general-section button-section">
          <div class="section-info">
            <h1>
              {{ 'account' | translate }}
              <mat-icon
                svgIcon="help-book"
                class="help-book clickable"
                id="bookIcon"
                (click)="startGeneralTour()"
              ></mat-icon>
            </h1>
            <h3>{{ 'updateYourAccountInformation' | translate }}</h3>
          </div>
          <button (click)="saveGeneralUserInfo()" id="saveGeneralButton" class="save-button">
            {{ 'PROFILE.save' | translate }}
          </button>
        </div>
        <hr class="spacement" />
        <div class="general-section">
          <div class="section-info">
            <h1>{{ 'profilePhoto' | translate }}</h1>
            <h3>{{ 'profilePicture' | translate }}</h3>
          </div>

          <input #_fileInput type="file" (change)="openCropperDialog($event)" accept="image/*" hidden />
          <div *ngIf="cropped"><img [src]="cropped" /></div>

          <!-- Cropper -->
          <div class="user-picture-wrapper clickable" (click)="_fileInput.click()">
            <div class="user-info">
              <div click>
                <img
                  id="user-logo"
                  class="user-logo clickable profile-pic"
                  [src]="base64image ? base64image : '/assets/icons/missing-icon.svg'"
                />
              </div>
              <div>
                <h2>{{ user?.name }}</h2>
                <h3>{{ user?.publicName }}</h3>
              </div>
            </div>

            <i class="ph button clickable ph-pencil-simple-line" id="user-photo"></i>
          </div>
        </div>

        <hr class="spacement" />

        <div class="general-section">
          <div class="section-info">
            <h1>{{ 'personalInformation' | translate }}</h1>
            <h3>{{ 'updateYourAccountInformation' | translate }}</h3>
          </div>

          <form id="personal-data">
            <div class="input-wrapper">
              <h3>Nick</h3>

              <div class="input-container" id="nick-input" [ngClass]="{ disabled: true }">
                <input
                  class="nick-input"
                  type="text"
                  name="Nick"
                  placeholder="{{ userIsLoaded && domainIsLoaded ? user?.email : '' }}"
                  readonly
                />
              </div>
            </div>

            <div class="input-wrapper">
              <h3 class="fill-available">{{ 'Name' | translate }}</h3>

              <div class="input-container" id="name">
                <input #name type="text" name="publicName" />
              </div>
            </div>
            <div class="input-wrapper">
              <h3 class="fill-available">{{ 'publicName' | translate }}</h3>

              <div class="input-container" id="publicName">
                <input #publicName type="text" name="publicName" />
              </div>
            </div>
            <div class="input-wrapper">
              <h3>Website</h3>

              <div class="input-container" id="website">
                <input #website type="text" name="website" />
              </div>
            </div>
            <div class="input-wrapper">
              <h3>Bio</h3>
              <div class="input-container bio-container">
                <textarea maxlength="500" id="bio" #bio placeholder="{{ 'PROFILE.yourBio' | translate }}"></textarea>
                <label class="max-lenght-label">{{ 'max' | translate }} 500 {{ 'characters' | translate }}</label>
              </div>
            </div>
            <div class="input-wrapper">
              <div class="input-wrapper">
                <h3 class="fill-available">{{ 'Country' | translate }}</h3>
                <mat-select-country
                  [value]="country"
                  id="countryTour"
                  (onCountrySelected)="onCountrySelected($event)"
                  placeHolder="{{ 'selectYourCountry' | translate }}"
                >
                </mat-select-country>
              </div>
              <div class="input-wrapper" id="uniqueId">
                <h3 class="fill-available">{{ 'PROFILE.shortId' | translate }}: {{ user?.shortId }}</h3>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div #connectionsDiv class="password connections tab" *ngSwitchCase="isMobile || 'Connections'">
      <div class="general-section button-section">
        <div class="section-info">
          <h1>
            {{ 'connections' | translate }}
            <mat-icon
              svgIcon="help-book"
              class="help-book clickable"
              id="bookIcon"
              (click)="startConnTour()"
            ></mat-icon>
          </h1>
          <h3>
            {{ 'hereYouCanAddOrCheckYourExchangeConnections' | translate }}
          </h3>
        </div>
      </div>
      <hr class="spacement" />

      <div class="general-section">
        <div class="section-info">
          <h1>{{ 'connections' | translate }}</h1>
          <h3>
            {{ 'hereYouCanAddOrCheckYourExchangeConnections' | translate }}
          </h3>
        </div>

        <div class="exchange-wrapper">
          <button (click)="registerExchangeModal = true; tryToReadClipboard(true)" id="startConnTour">
            <i class="ph ph-plus"></i>
            {{ 'addConnection' | translate }}
          </button>
          <div *ngIf="showingUserConnections.length > 0" class="user-exchanges">
            <div class="display-content" *ngFor="let userExchange of showingUserConnections; let i = index">
              <div class="exchange-info">
                <div class="exchange-description">
                  <mat-icon [svgIcon]="userExchange.cryptoDataSource.toLowerCase()"></mat-icon>
                  <div>
                    {{ userExchange.name ? userExchange.name : userExchange.cryptoDataSource }}
                    <div
                      class="status"
                      [ngStyle]="{
                        color: userExchange.isValid ? 'rgba(16, 181, 33, 1)' : 'rgba(244, 67, 54, 1)',
                      }"
                    >
                      <i class="ph-fill ph-circle"></i>
                      {{ userExchange.isValid ? ('PROFILE.active' | translate) : ('PROFILE.inactive' | translate) }}
                    </div>
                  </div>
                </div>
                <div class="user-actions">
                  <!-- <i class="ph ph-pencil-simple-line button clickable"></i> -->
                  <i
                    class="ph ph-trash button clickable connection-ph-trash"
                    (click)="modalDeleteExchange = true; exchangeId = userExchange.id"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div #routingDiv id="Routing" class="wallets tab" *ngSwitchCase="isMobile || 'Routing'">
      <div class="general-section button-section">
        <div class="section-info">
          <h1>
            {{ 'walletsRoutes' | translate }}

            <mat-icon svgIcon="help-book" class="help-book clickable" id="bookIcon" (click)="startWalletTour()" />
          </h1>
          <h3>{{ 'yourWalletsRoutes' | translate }}</h3>
        </div>
      </div>

      <hr class="spacement" />
      <div class="general-section">
        <div class="section-info">
          <h1>{{ 'assets' | translate }}</h1>
          <h3>{{ 'yourWalletsRoutesForEachAssets' | translate }}</h3>
        </div>
        <div class="selection-wrapper">
          <button
            class="clickable"
            (click)="openAssetWalletConfigurationModal()"
            [matTooltipPosition]="'above'"
            matTooltip="Add"
            id="start-wallet"
          >
            <i class="ph ph-pencil"></i>
            {{ 'PROFILE.EditRoutes' | translate }}
          </button>
        </div>
      </div>

      <div class="saved-wallets">
        <div *ngFor="let wallet of savedWallets" class="common-wrapper assets-wrapper saved-wallets-wrapper">
          <div class="wallet-info">
            <mat-icon [svgIcon]="wallet.cryptoDataSource.toLowerCase()"></mat-icon>
            <div class="wallet-propertys">
              <h1 class="assets-label">
                <label class="asset-name-wrapper" #assetName>
                  {{ formatAssetName(assetById[wallet.asset].id) }} <br />
                  <span class="asset-network">
                    {{ getNetworkName(assetById[wallet.asset].assetChain) }}
                  </span>
                </label>
                <i class="ph ph-arrow-right"></i>
                <label
                  class="wallet-name"
                  (click)="openInExplorer(wallet)"
                  [ngClass]="{
                    'active-wallet-link': wallet.cryptoDataSource === 'Manual',
                  }"
                >
                  {{ wallet.cryptoDataSource === 'Manual' ? wallet.walletName : wallet.cryptoDataSource }}</label
                >
              </h1>
            </div>
          </div>

          <div class="user-actions">
            <i
              class="ph button ph-trash routing-ph-trash"
              (click)="currentDeletingWallet = wallet; modalDeleteWallet = true"
              [matTooltipPosition]="'above'"
              [matTooltip]="'PROFILE.remove' | translate"
            ></i>
          </div>
        </div>
      </div>
    </div>

    <div #domainDiv class="domain tab" *ngSwitchCase="isMobile || 'Domain'">
      <div class="general-section button-section">
        <div class="section-info">
          <h1>{{ 'Domain' | translate }}</h1>
          <h3>{{ 'domainInfo' | translate }}</h3>
        </div>
      </div>
      <hr class="spacement" />

      <div class="general-section">
        <div class="section-info">
          <h1>{{ 'domainValidation' | translate }}</h1>
          <h3>{{ 'validationInfo' | translate }}</h3>
        </div>
        <div class="common-wrapper">
          <h3>{{ 'Domain' | translate }}</h3>
          <div class="domain-status">
            <div [ngClass]="{ Valid: domain?.isValid, Pending: !domain?.isValid }" class="status clickable">
              {{ domain?.isValid ? 'Active' : 'Pending' }}
            </div>
            <i class="ph ph-arrows-clockwise clickable" (click)="checkDNS()"></i>
          </div>
          <label class="fill-view-label domain-name">
            <i class="ph ph-globe-simple"></i>
            {{ userIsLoaded && domainIsLoaded ? domain?.name : '' }}
          </label>
          <label *ngIf="!domain?.isValid" class="fill-view-label txt-record-label">
            <i class="ph-fill ph-info"></i>
            {{ 'addTheStringBelowAsATXTRecordOfYourDomainValidationCanTakeUpTo48Hours' | translate }}
          </label>
          <label *ngIf="domain?.isValid" class="fill-view-label txt-record-label validated-domain Valid">
            <i class="ph-check ph-info"></i>
            {{ 'theDomainHasBeenSuccessfullyVerified' | translate }}
          </label>
          <div class="input-container" (click)="copyDNS()">
            <i class="ph ph-copy clickable"></i>
            <label #code class="code" type="text" disabled>
              {{ userIsLoaded && domainIsLoaded ? domain?.validationKey : '' }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div #automaticConversionDiv class="automatic-conversion tab" *ngSwitchCase="isMobile || 'AutomaticConversion'">
      <div class="general-section button-section">
        <div class="section-info">
          <h1>
            {{ 'paymentConversions' | translate }}
            <mat-icon
              svgIcon="help-book"
              class="help-book clickable"
              id="bookIcon"
              (click)="paymentConversionsTour()"
            />
          </h1>
          <h3>{{ 'settlementAssets' | translate }}</h3>
        </div>
      </div>
      <hr class="spacement" />

      <div class="general-section">
        <div class="section-info">
          <h1>{{ 'paymentConversions' | translate }}</h1>
          <h3>
            {{ 'hereYouCanChooseWhichAssets' | translate }} <br />
            {{ 'justKeepInMind' | translate }}
            <a class="settlement-assets clickable" (click)="helpModal = true">{{ 'settlementAssets' | translate }}</a>
            {{ 'itWillChangeWhatThePayerCanPick' | translate }}
          </h3>
        </div>
        <div class="assets" id="payment-conversions">
          <div class="assets-wrapper toggle-assets-wrapper">
            <div
              id="assets-select"
              class="assets-info-wrapper clickable"
              *ngFor="let fiat of fiats"
              [ngClass]="{ 'active-asset': fiat.active }"
              (click)="toggleAssetStauts(fiat)"
            >
              <div class="asset-name">
                <mat-icon
                  class="assets-icons clickable"
                  [ngStyle]="{ filter: fiat.active ? 'unset' : '' }"
                  [svgIcon]="fiat.assetTicker"
                  [matTooltipPosition]="'above'"
                  matTooltip="{{ fiat.assetName }}"
                ></mat-icon>
                <label class="clickable"> {{ fiat.assetTicker }} - {{ fiat.assetName }}</label>
              </div>

              <div class="asset-toggle" id="asset-toggle">
                {{ fiat.active ? 'ON' : 'OFF' }}
                <div class="white-elipse"></div>
              </div>
            </div>
          </div>

          <div class="assets-wrapper toggle-assets-wrapper">
            <div
              class="assets-info-wrapper clickable"
              *ngFor="let asset of assets"
              [ngClass]="{ 'active-asset': asset.active }"
              (click)="toggleAssetStauts(asset)"
            >
              <div class="asset-name">
                <mat-icon
                  class="assets-icons clickable"
                  [ngStyle]="{ filter: asset.active ? 'unset' : '' }"
                  [svgIcon]="asset.assetTicker"
                  [matTooltipPosition]="'above'"
                  matTooltip="{{ asset.assetName }}"
                ></mat-icon>
                <label class="clickable"> {{ asset.assetTicker }} - {{ formatAssetName(asset.id) }}</label>
              </div>

              <div class="asset-toggle">
                {{ asset.active ? 'ON' : 'OFF' }}
                <div class="white-elipse"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div #offRampDiv class="off-ramp tab" *ngSwitchCase="isMobile || 'Off-Ramp'">
      <div class="general-section button-section">
        <div class="section-info">
          <h1>
            {{ 'bankWithdrawal' | translate }}
            <mat-icon svgIcon="help-book" class="help-book clickable" id="bookIcon" (click)="bankWithdrawalTour()" />
          </h1>
          <h3>{{ 'withdrawalSettings' | translate }}</h3>
        </div>
      </div>

      <hr class="spacement" />
      <div class="general-section">
        <div class="section-info" id="start-bankTour">
          <h1>{{ 'withdrawalSettings' | translate }}</h1>
          <h3>{{ 'setupYourSettingsForEachAsset' | translate }}</h3>
        </div>

        <div>
          <label
            *ngIf="availableConnectors.length < 1 && this.showingUserConnections.length < 1"
            class="warning-label off-ramp-warning"
          >
            <i class="ph-fill ph-warning-circle"></i>
            {{ 'PROFILE.youHaveToConfigureAnExchange' | translate }}
          </label>

          <table class="conversion-rules-table" id="conversion-rules">
            <thead>
              <tr>
                <th class="first-tittle">
                  {{ 'PAYMENT_REPORT.fiat' | translate }}
                </th>
                <th>{{ 'globalWithdrawalThreshold' | translate }}</th>
                <th class="last-tittle">{{ 'enabled' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let fiat of usdFiat">
                <td id="check-fiat">{{ fiat.assetTicker }}</td>
                <td id="global-withdrawal">
                  <div class="input-container">
                    <input
                      currencyMask
                      [options]="customCurrencyMaskConfig"
                      type="text"
                      autocomplete="off"
                      inputmode="numeric"
                      [(ngModel)]="fiat.minAmount"
                      (ngModelChange)="updateSetting(fiat)"
                      name="USD"
                      placeholder="{{ '0' + (isCommaDecimal ? ',' : '.') + '00' }}"
                    />
                  </div>
                </td>
                <td id="activate-settings">
                  <div class="asset-toggle-wrapper" [ngClass]="{ 'active-asset': fiat.activeRule }">
                    <div
                      class="asset-toggle clickable"
                      (click)="fiat.activeRule = !fiat.activeRule; updateSetting(fiat)"
                    >
                      {{ fiat.activeRule ? 'ON' : 'OFF' }}
                      <div class="white-elipse"></div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <hr class="spacement" />

      <div class="general-section">
        <div class="section-info" id="check-activate">
          <h1>{{ 'bankAccountConfiguration' | translate }}</h1>
          <h3>{{ 'activeWithdrawalSettings' | translate }}</h3>
        </div>
        <button
          class="add-configuration"
          id="add-configuration"
          [ngClass]="{ disabled: this.availableConnectors.length < 1 }"
          (click)="addingRule = !addingRule"
        >
          <i class="ph ph-plus"></i>
          {{ 'addConfiguration' | translate }}
        </button>

        <div class="off-ramp-settings-table-wrapper common-wrapper" *ngFor="let setting of filteredOffRampRules()">
          <div class="centered-div-small-spacing">
            <mat-icon [svgIcon]="setting.cryptoConnection.cryptoDataSource.toLowerCase()"></mat-icon>

            <label class="connection-label clickable" (click)="editOffRampSetting(setting)">
              <span>
                {{ setting.walletId || setting.cryptoConnection.name || '' }}
                ({{ setting.cryptoConnection.cryptoDataSource }})
              </span>
              <span class="secondary-text">
                {{ setting.blockchainAssetId ? setting.blockchainAssetId.split('.')[0] : setting.blockchainAssetId }}
              </span>
            </label>
          </div>
          <i
            class="ph ph-trash button clickable"
            (click)="disableSetting(setting)"
            matTooltip=" {{ 'PROFILE.remove' | translate }}"
            [matTooltipPosition]="'above'"
          ></i>
        </div>
      </div>

      <label class="alone-warning warning-label" *ngIf="this.availableConnectors.length < 1">
        <i class="ph-fill ph-warning-circle"></i>
        {{ 'PROFILE.youDoesntHaveABankAccountForOffRampInThisExchange' | translate }}
        @for (offRampExchange of offRampExchanges; track offRampExchanges) {
          {{ offRampExchange }}
        }
      </label>

      <div class="rule-contents common-wrapper" id="start-addConfigurationTour" *ngIf="addingRule">
        <label
          >{{ 'PROFILE.offRampRuleFor' | translate }}

          <mat-icon svgIcon="help-book" class="help-book clickable" id="bookIcon" (click)="addConfigurationTour()" />
        </label>
        <app-custom-option-select
          id="selectedFiat"
          [disabled]="editingOffRampConnection"
          [offRampExchangeSettingsForUser]="currentOptionsSelects.offRampExchangeSettingsForUser"
          [currentValue]="selectedFiat"
          (changedValue)="selectedFiat = $event || 'undefined'; startAddingRule()"
        ></app-custom-option-select>
        <!-- *ngIf="availableConnectorsForFiat && connectorSupportsCurrentFiat()"> -->
        <label *ngIf="connectorSupportsCurrentFiat()">{{ 'PROFILE.selectTheAccount' | translate }}</label>
        <app-custom-option-select
          id="current-bank"
          *ngIf="connectorSupportsCurrentFiat()"
          [offRampExchangeSettingsForUser]="currentOptionsSelects.availableConnectorsForFiat"
          [currentValue]="currentBankWallet"
          (changedValue)="currentBankWallet = $event || 'undefined'"
        ></app-custom-option-select>

        <div *ngIf="connectorSupportsCurrentFiat()" class="off-ramp-settings fees-wrapper">
          <div id="current-fee" *ngIf="(this.currentFee || 0) > 0" class="centered-div-small-spacing">
            <label>{{ 'PROFILE.fees' | translate }}</label>
            <span class="fees-label">{{ '$' + currentFee }}</span>
          </div>
        </div>
        @if (!availableConnectorsForFiat && !loadingConnections && selectedFiat != 'undefined') {
          <label class="warning-label">
            <i class="ph-fill ph-warning-circle"></i>
            {{ connectorError.errorMessage }}
            {{ selectedFiat ? selectedFiat.split(' ')[1].split('.')[0] : '' }}.
            {{ connectorError.errorCode ? ('PROFILE.pleaseCheckYourApiKeyPermission' | translate) : '' }}
          </label>
        }

        <label class="warning-label off-ramp-attention">
          <i class="ph-fill ph-warning-circle"></i>
          {{ 'PROFILE.obsRulesWillBeEvaluated' | translate }}
        </label>
        <div class="save-changes-wrapper" id="save-configuration">
          <button
            class="save-button"
            [ngClass]="{ 'disabled-save': !canSaveOffRamp() }"
            (click)="canSaveOffRamp() ? setOffRampSettings() : ''"
          >
            {{ 'PROFILE.save' | translate }}
          </button>
        </div>
      </div>

      <div class="rule-contents asset-rule" id="asset-rule" *ngIf="assetRule && !addingRule">
        <a class="cancel-adding clickable" (click)="assetRule = false">X</a>
      </div>
    </div>

    <div #securityDiv class="password tab" *ngSwitchCase="isMobile || 'Security'">
      <div class="general-section button-section">
        <div class="section-info">
          <h1>
            {{ 'accountSecurity' | translate }}
            <mat-icon svgIcon="help-book" class="help-book clickable" id="bookIcon" (click)="securityTour()" />
          </h1>
          <h3>{{ 'yourAccountSecuritySettings' | translate }}</h3>
        </div>
      </div>

      @if (!isSocialUser()) {
        <hr class="spacement" />
        <div class="general-section">
          <div class="section-info">
            <h1>{{ 'changePassword' | translate }}</h1>
            <h3>{{ 'receiveAEmailToChangeYourPassword' | translate }}</h3>
          </div>
          <button (click)="changePassword()" id="change-password">
            {{ 'PROFILE.changePassword' | translate }}
          </button>
        </div>
      }

      <hr class="spacement" />
      <div class="general-section">
        <div class="section-info">
          <h1>{{ 'deleteAccount' | translate }}</h1>
          <h3>{{ 'accountSecurity' | translate }}</h3>
        </div>
        <button id="delete-account" class="link secondary-button delete-button" (click)="deleteUser()">
          {{ 'deleteAccount' | translate }}
        </button>
      </div>
      <label class="warning-label security-warning">
        <i class="ph-fill ph-warning-circle"></i>
        {{
          'thisActionIsIrreversibleAndWillResultInThePermanentLossOfAllDataAssociatedWithYourAccountIncludingProfileInformationSettingsAndAnyContentYouHaveCreated'
            | translate
        }}
      </label>
    </div>
  </div>

  <div *ngIf="walletModal" class="modal-backdrop">
    <div class="modal wallet-modal routing-modal">
      <div class="modal-title">
        <h1>
          {{ 'editRoutes' | translate }}
        </h1>
      </div>

      <hr class="spacement" />
      <div class="exchange-wrapper">
        <div class="wallet-input-wrapper mobile-wrap">
          <div class="input-wrapper">
            <h3>
              @if (configurationExchange === 'Manual' && !routingOptionName) {
                {{ 'iWishThatMy' | translate }}
              } @else {
                {{ 'iWishThatMyWalletAtTheFollowingProvider' | translate }}
              }
            </h3>

            <app-custom-option-select
              id="wallet-select"
              [configurationExchange]="currentOptionsSelects.configurationExchange"
              (optionName)="routingOptionName = $event"
              (changedValue)="changeWalletConnectionExchange($event)"
              [currentValue]="configurationExchange"
            ></app-custom-option-select>
          </div>

          <div class="private-wallet-inputs fade-in" *ngIf="configurationExchange === 'Manual' && !routingOptionName">
            <div class="input-wrapper">
              <h3>{{ 'PROFILE.address' | translate }}</h3>
              <div
                *ngIf="clipboardPermissions?.state === 'denied' || clipboardDenied"
                class="input-container address-input-container"
              >
                <input
                  type="text"
                  #privateWalletInput
                  [(ngModel)]="privateWalletAddress"
                  (ngModelChange)="validatePrivateWallet()"
                  placeholder="{{ 'pasteTheWalletDepositAddressHere' | translate }}"
                />
              </div>

              <div
                *ngIf="clipboardPermissions?.state != 'denied' && privateWalletAddress && !clipboardDenied"
                class="private-wallet"
              >
                {{ privateWalletAddress }}

                <div>
                  <a (click)="clearWalletAddress()" *ngIf="!clipboardDenied && privateWalletAddress" target="_blank">
                    <i
                      class="ph button ph-trash"
                      [matTooltipPosition]="'above'"
                      [matTooltip]="'PROFILE.visititThisAddressInBlockchainExplorer' | translate"
                    ></i>
                  </a>

                  <a
                    (click)="checkAddressInExplorer(privateWalletAddress)"
                    *ngIf="
                      clipboardPermissions?.state === 'denied' ||
                      clipboardDenied ||
                      (privateWalletAddress && this.acceptedNetworksOnPrivateWallet.length > 0)
                    "
                    target="_blank"
                  >
                    <i
                      class="ph button ph-arrow-line-up-right"
                      [matTooltipPosition]="'above'"
                      [matTooltip]="'PROFILE.visititThisAddressInBlockchainExplorer' | translate"
                    ></i>
                  </a>
                </div>
              </div>

              <a
                *ngIf="clipboardPermissions?.state != 'denied' && !clipboardDenied && !privateWalletAddress"
                id="paste-wallet"
                class="wallet-link clickable"
                (click)="pasteWalletAddress()"
              >
                {{ 'pasteFromClipboard' | translate }}
              </a>

              <a *ngIf="!isMobile && !privateWalletAddress" class="wallet-link clickable" (click)="requestMetaMask()">
                {{ 'retrieveFromMetamask' | translate }}
              </a>
            </div>

            <div class="input-wrapper">
              <h3>{{ 'Name' | translate }}:*</h3>
              <div
                class="input-container"
                [ngClass]="{
                  'not-valid': invalidWalletName() && privateWalletName,
                }"
              >
                <input
                  type="text"
                  [(ngModel)]="privateWalletName"
                  id="name-description"
                  maxlength="20"
                  placeholder="{{ 'enterANameOrDescription' | translate }}*"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="asset-options-wrapper">
          <h3 class="to-the-following-assets" *ngIf="getSupportedNetworks().length > 0">
            {{ 'beTheRecipientOfTransfersOfTheseAssets' | translate }}
          </h3>
          <div class="display-content" *ngFor="let network of getSupportedNetworks(); trackBy: trackByNetwork">
            <div class="supported-network-assets-wrapper fade-in">
              <h3>{{ getNetworkName(network)?.toUpperCase() }}</h3>
              <div *ngFor="let asset of getSupportedAssetsOnNetwork(network); trackBy: trackByAsset">
                <div
                  class="asset-connection-info clickable"
                  [ngClass]="{
                    'already-configured':
                      hasSomeWallet(asset.blockchainAsset) && !checkIfItsDisablingAsset(asset.blockchainAsset.id),
                    active:
                      selectedAssetsForExchanges.includes(asset.blockchainAsset.id) &&
                      !checkIfItsDisablingAsset(asset.blockchainAsset.id),
                    disabled: checkIfItsDisablingAsset(asset.blockchainAsset.id),
                  }"
                  (click)="assignAssetToExchange(asset.blockchainAsset)"
                >
                  <div class="asset-name">
                    <mat-icon
                      class="assets-icons clickable"
                      [svgIcon]="asset.blockchainAsset.assetTicker"
                      [matTooltip]="asset.blockchainAsset.id"
                      [matTooltipPosition]="'above'"
                    ></mat-icon>
                    <span class="wallet-asset-name">{{ formatAssetName(asset.blockchainAsset.id) }}</span>
                    <span
                      class="connection-info"
                      *ngIf="
                        (hasSomeWallet(asset.blockchainAsset) ||
                          selectedAssetsForExchanges.includes(asset.blockchainAsset.id)) &&
                        !checkIfItsDisablingAsset(asset.blockchainAsset.id)
                      "
                    >
                      <ng-container>
                        <i class="ph ph-arrow-right"></i>
                        {{ formatWalletProviderName(asset.blockchainAsset) }}
                        <ng-container
                          *ngIf="
                            hasSomeWallet(asset.blockchainAsset) &&
                            getAssetsOnExchange(
                              asset.blockchainAsset,
                              isSelectedWalletForNewSettings(asset.blockchainAsset)
                            ).length > 1
                          "
                        >
                          <br *ngIf="isMobile" />
                          <p>
                            <ng-container
                              *ngFor="
                                let assetOnExchange of getAssetsOnExchange(
                                  asset.blockchainAsset,
                                  isSelectedWalletForNewSettings(asset.blockchainAsset)
                                );
                                let i = index;
                                trackBy: trackByAssetOnExchange
                              "
                            >
                              {{ assetOnExchange.blockchainAsset.assetChain }}
                              <ng-container
                                *ngIf="
                                  i !==
                                  getAssetsOnExchange(
                                    asset.blockchainAsset,
                                    isSelectedWalletForNewSettings(asset.blockchainAsset)
                                  ).length -
                                    1
                                "
                              >
                                -
                              </ng-container>
                            </ng-container>
                          </p>
                        </ng-container>
                      </ng-container>
                    </span>
                  </div>
                  <div class="asset-check">
                    <i
                      class="ph ph-link-simple-break"
                      *ngIf="
                        !(
                          hasSomeWallet(asset.blockchainAsset) ||
                          (selectedAssetsForExchanges.includes(asset.blockchainAsset.id) &&
                            !assetIsConnectedToTheSelectedProvider(asset.blockchainAsset))
                        ) || checkIfItsDisablingAsset(asset.blockchainAsset.id)
                      "
                    ></i>
                    <i
                      class="ph-bold ph-link"
                      *ngIf="
                        (hasSomeWallet(asset.blockchainAsset) ||
                          (selectedAssetsForExchanges.includes(asset.blockchainAsset.id) &&
                            !assetIsConnectedToTheSelectedProvider(asset.blockchainAsset))) &&
                        !checkIfItsDisablingAsset(asset.blockchainAsset.id)
                      "
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="buttons-wrapper">
          <button (click)="closeWalletModal()" class="clickable cancel-button" id="cancelButtonTour">
            {{ 'cancel' | translate }}
          </button>
          <button
            [ngClass]="{ disabled: !updatingAssets }"
            class="save-changes"
            id="saveButtonTour"
            (click)="!savingWallet && updatingAssets ? buildChangesArray() : ''"
          >
            {{ 'saveChanges' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="testModal" class="modal-delete-exchange-backdrop modal-backdrop">
    <div class="modal wallet-modal confirm-routes-change">
      <span class="modification-warning">
        <i class="ph-fill ph-info"></i>
        {{ 'warningYourModificationsWillOverrideTheFollowingExistingRoutes' | translate }}
      </span>

      <span class="routes-change-tittle">{{ 'areYouSureYouWantToContinue' | translate }}</span>

      <label class="routes-change-description">
        @for (network of getSupportedNetworks(); track getSupportedNetworks) {
          @if (changedAssetsArray[network].length > 0) {
            <div class="changed-assets-wrapper">
              <h3>{{ getNetworkName(network)?.toUpperCase() }}</h3>
              @for (oldAssetConfig of changedAssetsArray[network]; track changedAssetsArray) {
                <label>
                  <h6 #networkName>{{ oldAssetConfig.formattedName }}</h6>
                  <i class="ph ph-arrow-right"></i>
                  {{ oldAssetConfig.currentConfig }}
                </label>
              }
            </div>
          }
        }
      </label>

      <hr class="spacement" />

      <div class="buttons-wrapper">
        <button class="cancel-button" (click)="testModal = false">
          {{ 'cancel' | translate }}
        </button>
        <button (click)="saveWalletsConfiguration()">
          {{ 'continue' | translate }}
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="askUserToConfigureRoutesModal" class="modal-delete-exchange-backdrop modal-backdrop">
    <div class="modal wallet-modal">
      <div class="modal-title">
        <h1>{{ 'connectionAddedSuccessFully' | translate }}<br /></h1>
      </div>

      <!-- <hr class="spacement"> -->

      <label class="modal-description">
        {{ 'wouldYouLikeToSetUpThePaymentRoutesNow' | translate }}
      </label>

      <!-- <hr class="spacement"> -->

      <div class="buttons-wrapper">
        <button id="no-button" class="cancel-button" (click)="askUserToConfigureRoutesModal = false">
          {{ 'no' | translate }}
        </button>
        <button (click)="goToRoutesTab()">{{ 'setUpNow' | translate }}</button>
      </div>
    </div>
  </div>

  <div *ngIf="offRampModal" class="modal-backdrop">
    <div class="modal wallet-modal">
      <div class="modal-header">
        <h3>
          {{ 'PROFILE.chooseTheOffRampConnectionAndTheAmountToProceed' | translate }}
        </h3>
      </div>
      <div class="exchange-wrapper">
        <div class="wallet-input-wrapper off-ramp-setting-selection mobile-wrap">
          <app-custom-option-select
            [configurationExchange]="currentOptionsSelects.currentOffRampSettings"
            [currentValue]="this.manualOffRampSettings.cryptoConnectionId"
            (changedValue)="this.manualOffRampSettings.cryptoConnectionId = $event; getManualOffRampFee()"
          ></app-custom-option-select>

          <select class="exchange-selection" [(ngModel)]="configurationExchange" (ngModelChange)="addMetaMaskLink()">
            <option [value]="userExchange.id" *ngFor="let userExchange of userExchanges">
              {{ userExchange.name ? userExchange.name : userExchange.cryptoDataSource }}
            </option>
            <option value="private">
              {{ 'PROFILE.setTheAddressManually' | translate }}
            </option>
          </select>
        </div>
        <div>
          <div class="wallet-input-wrapper target-fiat">
            <label class="label-min-width">{{ 'PROFILE.targetFiat' | translate }}:</label>
            <app-custom-option-select
              [configurationExchange]="currentOptionsSelects.fiats"
              [currentValue]="this.manualOffRampSettings.assetId"
              (changedValue)="this.manualOffRampSettings.assetId = $event; getManualOffRampFee()"
            ></app-custom-option-select>
            <div class="wallet-input-wrapper off-ramp-info-wrapper flex-wrap" *ngIf="currentFee">
              <label class="off-ramp-info">
                {{ 'PROFILE.fees' | translate }}: <br />{{
                  '$' + formatCurrency.convertToLocal(currentFee.toString() || '0', this.getDecimalPlaces('USD.USD'))
                }}
              </label>
            </div>
          </div>
          <label class="off-ramp-description doesnt-support-label" *ngIf="notSupportManual">
            {{ 'PROFILE.thisConnectionDoesntSupport' | translate }}
            {{ this.manualOffRampSettings.assetId ? this.manualOffRampSettings.assetId.split('.')[0] : '' }}.
          </label>
          <div class="configuration-inputs" *ngIf="!notSupportManual">
            <div class="wallet-input-wrapper">
              <label *ngIf="!withdrawAll">{{ 'DASHBOARD.amount' | translate }}: </label>
              <input
                *ngIf="!withdrawAll"
                class="manual-off-ramp-input"
                currencyMask
                [options]="customCurrencyMaskConfig"
                type="text"
                inputmode="numeric"
                autocomplete="off"
                [(ngModel)]="this.manualOffRampSettings.amount"
                name="USD"
                [disabled]="withdrawAll || false"
                placeholder="{{ '0' + (isCommaDecimal ? ',' : '.') + '00' }}"
              />
              <div [ngStyle]="{ 'margin-left': withdrawAll ? '0px' : '' }" class="withdraw-all-wrapper flex-centered">
                <input (change)="setWithdrawAll()" type="checkbox" />
                {{ 'withdrawAll' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="buttons-wrapper">
          <a (click)="offRampModal = false" class="clickable">
            {{ 'cancel' | translate }}
          </a>
          <button
            class="save-changes"
            [ngClass]="{ 'disabled-save': !enabledOffRampButton() }"
            [disabled]="!enabledOffRampButton()"
            (click)="startOffRamp()"
          >
            {{ 'PROFILE.startOffRamp' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="automaticConversionSetModal" class="modal-backdrop">
    <div class="modal wallet-modal">
      <div class="modal-header">
        <!-- <h3>Attention</h3> -->
      </div>
      <div class="modal-body">
        <label class="enable-bank-withdrawal">
          {{ 'PROFILE.toEnableBankWithdrawal' | translate }}
          {{ 'PROFILE.defaultFiat' | translate }}. <br />
          {{ 'PROFILE.youHaveTheFlexbilityToModify' | translate }} <br />
          <b>
            {{ 'PROFILE.youWantToSetItTo' | translate }}
            {{
              this.selectedFiat
                .split(
                  '
                            '
                )[1]
                .split('.')[0]
            }}
            {{ 'PROFILE.nowAndProceed' | translate }}</b
          >
        </label>
      </div>
      <div class="buttons-wrapper">
        <a (click)="automaticConversionSetModal = false" class="clickable">
          {{ 'cancel' | translate }}
        </a>
        <button class="save-changes" (click)="deleteUser(true)">
          {{ 'PROFILE.confirm' | translate }}
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="metaMaskModal" class="modal-backdrop">
    <div class="modal meta-mask-modal wallet-modal">
      <div class="modal-header">
        <h3>{{ 'PROFILE.assetsInMetamask' | translate }}</h3>
      </div>
      <div class="exchange-wrapper">
        <div class="metamask-asset" *ngFor="let asset of metamaskAssets">
          <input (click)="chooseAsMetaMaskAsset(asset.id); asset.checked = true" class="checkbox" type="checkbox" />
          <label class="asset-name">
            {{
              asset.assetName == 'USDT' || asset.assetName == 'USDC' ? asset.assetName + ' (Ethereum)' : asset.assetName
            }}
          </label>
          <mat-icon
            *ngIf="checkIfConnectionAlreadyExists(asset.id)"
            class="modal-warning-icon clickable"
            svgIcon="warning"
            (click)="choosenAssetName = asset.id"
            [matTooltipPosition]="'above'"
            matTooltip="There's already a configured wallet for this asset."
          >
          </mat-icon>
          <div class="configuration-inputs">
            <div *ngIf="choosenMetaMaskAssets.includes(asset.id)" class="wallet-input-wrapper">
              <label class="label-min-width">{{ 'Name' | translate }}</label>
              <input
                [(ngModel)]="metaMaskWalletNames[choosenMetaMaskAssets.indexOf(asset.id)]"
                type="text"
                placeholder="{{ 'PROFILE.enterAnOptionalDescription' | translate }}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="choosenAssetName" class="modal-backdrop">
    <div class="modal removing-info-modal">
      <div class="modal-header">
        <h3>{{ 'PROFILE.attention' | translate }}</h3>
        <h3 class="close clickable" (click)="choosenAssetName = ''">X</h3>
      </div>
      <div class="exchange-wrapper">
        <label class="connection-info">
          {{ 'PROFILE.yourConnectionNamed' | translate }}
          <span>{{ getDeletingWalletName() }}</span
          >{{ 'PROFILE.willBeDeletedIfCheckThisOption' | translate }}</label
        >
      </div>
    </div>
  </div>

  <div *ngIf="registerExchangeModal" class="modal-backdrop">
    <div class="modal wallet-modal" *ngIf="!confirmConnectionAdditionModal">
      <div class="modal-title">
        <h1>
          {{ 'exchangeConfiguration' | translate }}
        </h1>
        <h3>
          {{ 'configureExchangeConnections' | translate }}
        </h3>
      </div>

      <hr class="spacement" />

      <div class="exchange-wrapper">
        <div class="input-wrapper" id="optionsConnTour">
          <h3>{{ 'PROFILE.exchangeInfoFor' | translate }}</h3>
          <app-custom-option-select
            [configurationExchangeModal]="currentOptionsSelects.modalOptionsSelect"
            [currentValue]="exchange"
            (changedValue)="exchange = $event"
          ></app-custom-option-select>
        </div>

        <div class="input-wrapper" id="apiKeyTour">
          <h3>
            {{ (exchange === 'Coinbase' ? 'PROFILE.apiKeyName' : 'PROFILE.apiKey') | translate }}
          </h3>
          <div class="input-container">
            <input
              id="apiKeyTour"
              [ngClass]="{ 'api-input-pt': selectedLanguage === 'pt-br' }"
              [(ngModel)]="apiKey"
              type="text"
              placeholder="{{
                (exchange === 'Coinbase' ? 'pasteYourApiKeyName' : 'PROFILE.pasteYourApiKey') | translate
              }}"
            />
          </div>
          <a
            *ngIf="clipboardPermissions?.state != 'denied' && !clipboardDenied"
            (click)="setApiKeyFromClipboard()"
            class="wallet-link clickable"
          >
            {{ 'pasteFromClipboard' | translate }}
          </a>
        </div>

        <div class="input-wrapper" id="apiSecretTour">
          <h3>
            {{ (exchange === 'Coinbase' ? 'PROFILE.privateKey' : 'PROFILE.apiSecret') | translate }}
          </h3>
          <div class="input-container">
            <input
              id="apiSecretTour"
              [ngClass]="{ 'api-input-pt': selectedLanguage === 'pt-br' }"
              [(ngModel)]="apiSecret"
              type="text"
              placeholder=" {{
                (exchange === 'Coinbase' ? 'pasteYourPrivateKey' : 'PROFILE.pasteYourApiSecret') | translate
              }}"
            />
          </div>
          <a
            *ngIf="clipboardPermissions?.state != 'denied' && !clipboardDenied"
            (click)="setApiSecretFromClipboard()"
            class="wallet-link clickable"
          >
            {{ 'pasteFromClipboard' | translate }}
          </a>
        </div>
        <div class="input-wrapper" id="nameTour">
          <h3>{{ 'Name' | translate }}</h3>
          <div class="input-container">
            <input
              id="name-connection"
              [ngClass]="{ 'api-input-pt': selectedLanguage === 'pt-br' }"
              [(ngModel)]="exchangeName"
              type="text"
              placeholder="{{ 'PROFILE.enterAnOptionalDescription' | translate }}"
            />
          </div>
        </div>

        <div class="buttons-wrapper">
          <button class="cancel-button" (click)="closeExchangeRegistrationModal()">
            {{ 'cancel' | translate }}
          </button>
          <button id="addButtonTour" class="save-changes" (click)="addCryptoConnection()">
            {{ 'PROFILE.addConnection' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="confirmConnectionAdditionModal" class="modal-backdrop">
      <div class="modal wallet-modal">
        <label class="modal-description confirm-connection-addition-description">
          {{ 'youAreAboutToConnectAnExternalCryptoExchange' | translate }}

          <hr class="spacement" />
          <label class="modal-description confirm-connection-addition-description">
            <label [innerHTML]="getAwareText()"></label>
            <div
              class="input-container"
              [ngClass]="{
                'not-valid':
                  confirmExternalCryptoExchange &&
                  confirmExternalCryptoExchange !== translate.instant('agree').toUpperCase(),
                valid:
                  confirmExternalCryptoExchange &&
                  confirmExternalCryptoExchange === translate.instant('agree').toUpperCase(),
              }"
            >
              <input [(ngModel)]="confirmExternalCryptoExchange" id="confirmation-text" type="text" />
            </div>
          </label>
        </label>
        <!-- <hr class="spacement"> -->

        <hr class="spacement" />

        <div class="buttons-wrapper">
          <button class="cancel-button" (click)="confirmConnectionAdditionModal = false">
            {{ 'cancel' | translate }}
          </button>
          <button id="confirm-button" class="clickable" (click)="addCryptoConnection(true)">
            {{ 'confirm' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="modalDeleteExchange" class="modal-delete-exchange-backdrop modal-backdrop">
    <div class="modal wallet-modal">
      <div class="modal-title">
        <h1>
          {{ 'deleteExchange' | translate }}
        </h1>
      </div>

      <hr class="spacement" />

      <label class="modal-description">
        {{ 'areYouSureYouWantToDeleteThisExchange' | translate }}
      </label>

      <hr class="spacement" />

      <div class="buttons-wrapper">
        <button class="cancel-button" (click)="modalDeleteExchange = false">
          {{ 'cancel' | translate }}
        </button>
        <button
          class="secondary-button delete-button"
          (click)="removeCryptoConnection(exchangeId); modalDeleteExchange = false"
        >
          {{ 'yesDelete' | translate }}
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="modalDeleteWallet" class="modal-backdrop">
    <div class="modal wallet-modal">
      <div class="modal-title">
        <h1>
          {{ 'deleteWallet' | translate }}
        </h1>
      </div>

      <hr class="spacement" />

      <label class="modal-description">
        {{ 'areYouSureYouWantToDeleteThisWallet' | translate }} "{{ assetById[currentDeletingWallet.asset].assetName }}
        <i class="ph ph-arrow-right"></i>
        {{
          currentDeletingWallet.cryptoDataSource === 'Manual'
            ? currentDeletingWallet.walletName
            : currentDeletingWallet.cryptoDataSource
        }}" ?
      </label>

      <hr class="spacement" />

      <div class="buttons-wrapper">
        <button class="cancel-button" (click)="modalDeleteWallet = false">
          {{ 'cancel' | translate }}
        </button>
        <button
          class="secondary-button delete-button"
          (click)="removeAssetWalletConnection(currentDeletingWallet); modalDeleteWallet = false"
        >
          {{ 'yesDelete' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalDeleteUser" class="modal-backdrop">
  <div class="modal wallet-modal">
    <div class="modal-title">
      <h1>
        {{ 'deleteAccount' | translate }}
      </h1>
    </div>

    <hr class="spacement" />

    <label class="modal-description">
      {{ 'areYouSureYouWantToDeleteYourAccount' | translate }} <br />
      {{
        'thisActionIsIrreversibleAndWillResultInThePermanentLossOfAllDataAssociatedWithYourAccountIncludingProfileInformationSettingsAndAnyContentYouHaveCreated'
          | translate
      }}
    </label>

    <hr class="spacement" />

    <div class="buttons-wrapper">
      <button class="cancel-button" (click)="modalDeleteUser = false">
        {{ 'cancel' | translate }}
      </button>
      <button class="secondary-button delete-button" (click)="deleteUser(true)">
        {{ 'yesDelete' | translate }}
      </button>
    </div>
  </div>
</div>

<div *ngIf="helpModal" class="modal-backdrop">
  <div class="modal help-modal removing-info-modal">
    <div class="modal-header">
      <h3 class="close clickable" (click)="helpModal = false">X</h3>
    </div>
    <div class="modal-body">
      <label>
        {{ 'settlementAssetsAre' | translate }}
      </label>
    </div>
  </div>
</div>

<app-nicky-footer></app-nicky-footer>
