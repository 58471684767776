import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FiatWithdrawalConfigModel } from '../components/models/fiat-withdrawal-settings.model';
import { ManualOffRampDto } from '../dtos/manual-off-ramp.dto';
import { OffRampSettings } from '../dtos/off-ramp.set.setting.dto';
import { WalletsForConnector } from '../dtos/wallets-for-connector.dto';
import { withdrawFee } from '../dtos/withdrawl-fee.dto';
import { createHttpOptions } from '../utils/http-utils';

@Injectable()
export class OffRampService {

  constructor(private http: HttpClient) { }


  getOffRampAllSettings() {
    return this.http.get(environment.api + 'OffRamp/all-settings', createHttpOptions());
  }

  getAvailableConnectors(walletsForConnector?: WalletsForConnector) {
    const queryParams = walletsForConnector ? `?assetId=${walletsForConnector.assetId}` : '';
    return this.http.get(environment.api + `OffRamp/get-available-connectors${queryParams}`, createHttpOptions());
  }

  getWalletsForConnector(walletsForConnector: WalletsForConnector) {
    const queryParams = `?cryptoConnectionId=${walletsForConnector.cryptoConnectionId}&assetId=${walletsForConnector.assetId}`;
    return this.http.get(environment.api + `OffRamp/get-wallets-for-connector${queryParams}`, createHttpOptions());
  }

  setOffRampingSettings(offRampSetting: OffRampSettings) {
    return this.http.post(environment.api + 'OffRamp/set-setting', offRampSetting, createHttpOptions('*/*'));
  }

  offRampStart(manualOffRampSettings: ManualOffRampDto) {
    const queryParams = `?cryptoConnectionId=${manualOffRampSettings.cryptoConnectionId}&assetId=${manualOffRampSettings.assetId}&amount=${manualOffRampSettings.amount}`;
    return this.http.post(environment.api + `OffRamp/start${queryParams}`, {}, createHttpOptions('*/*'));
  }

  maxOffRampStart(manualOffRampSettings: ManualOffRampDto) {
    const queryParams = `?cryptoConnectionId=${manualOffRampSettings.cryptoConnectionId}&assetId=${manualOffRampSettings.assetId}`;
    return this.http.post(environment.api + `OffRamp/start-max${queryParams}`, {}, createHttpOptions('*/*'));
  }

  getOffRampFees(withdrawFee: withdrawFee) {
    const queryParams = `?cryptoConnectionId=${withdrawFee.cryptoConnectionId}&assetId=${withdrawFee.assetId}&amount=1000`;
    return this.http.get(environment.api + `OffRamp/get-withdraw-fee${queryParams}`, createHttpOptions());
  }

  addFavorite(nick: string) {
    const queryParams = `?nick=${nick}&type=Email`;
    return this.http.post(environment.api + `FavoriteNick${queryParams}`, {}, createHttpOptions('*/*'));
  }

  setFiatWithdrawalConfig(fiatWithdrawalConfig: FiatWithdrawalConfigModel) {
    return this.http.post(environment.api + '/FiatWithdrawalConfig/set-setting', fiatWithdrawalConfig, createHttpOptions('*/*'));
  }

  getFiatWithdrawalConfig() {
    return this.http.get(environment.api + '/FiatWithdrawalConfig/all-settings', createHttpOptions());
  }
}
