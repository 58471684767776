import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

import { environment } from 'src/environments/environment';
import { createHttpOptions } from '../utils/http-utils';

@Injectable({
  providedIn: 'root',
})

export class FavoritesService {

  public route = inject(Router);
  private http = inject(HttpClient);
  public auth = inject(AuthService);

  getUserFavorites() {
    return this.http.get(`${environment.api}FavoriteNick`, createHttpOptions());
  }

  deleteFavorite(nick: string) {
    return this.http.delete(`${environment.api}FavoriteNick?nick=${nick}`, createHttpOptions());
  }

  async favoriteByNick(nick: string) {
    return this.http.get(`${environment.api}FavoriteNick/search?q=${nick}`, createHttpOptions());
  }

  addFavorite(nick: string) {
    const body = {};
    return this.http.post(`${environment.api}FavoriteNick?nick=${nick}&type=Email`, body, createHttpOptions('*/*'));
  }

  searchFavorites(nick: string) {
    return this.http.get(`${environment.api}FavoriteNick/search?q=${nick}`, createHttpOptions());
  }
}
