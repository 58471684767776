<div
  class="dashboard-wrapper mobile-searchbar only-mobile"
  [ngClass]="{
    'opened-search': searchResults && !favoritesSearch && searchResults?.length == 0,
    'general-search': generalSearch,
  }"
>
  <i class="ph ph-magnifying-glass search-icon clickable" (click)="onSearch(search.value, true)"></i>
  <input
    #search
    type="text"
    name="search"
    placeholder="{{ (favoritesSearch ? 'searchYourContacts' : 'searchNick') | translate }}"
    (input)="onInputChange(search.value)"
  />
</div>

<div
  *ngIf="searchResults && !favoritesSearch"
  class="only-mobile mobile-search-rectangle"
  [ngClass]="{ 'favorites-search': favoritesSearch }"
>
  <ul *ngIf="searchResults.length == 0">
    <li class="search-li no-results">
      <div>{{ minimumCharMessage ? minimumCharMessage : ('weDidntFindAnyResultForYourSearch' | translate) }}</div>
    </li>
  </ul>
  <ul *ngFor="let item of searchResults">
    <li class="search-li" [ngClass]="{ 'common-search-li': !item?.exactlyMatch, 'single-result': item?.exactlyMatch }">
      <img
        class="company-logo"
        [src]="environment.api + 'User/get-public-profile-picture?userId=' + item.userId"
        (error)="handleImageError($event)"
      />
      <div
        class="search-info-wrapper multi-results-wrapper"
        [ngClass]="{ 'firefox-label': !(item?.nick == myNick && !item?.exactlyMatch) }"
        *ngIf="!item?.exactlyMatch"
        (click)="isMobile ? showEmailModal(item?.domains[0].name) : ''"
      >
        <label
          [matTooltipPosition]="'above'"
          matTooltip="{{ item?.publicName }} - {{
            item?.domains[0]?.name ? item.domains[0].name : ('notValidatedDomain' | translate)
          }}"
          class="clickable company-name-link"
          [ngClass]="{ 'not-validated-company-name': item?.domains.length == 0 }"
        >
          <a
            [ngClass]="{ 'not-validated-company-name': item?.domains.length == 0 }"
            (click)="openPayment(item)"
            target="_blank"
            >{{ item?.publicName }}</a
          >
        </label>
        <mat-icon
          [matTooltipPosition]="'above'"
          matTooltip="{{ 'verifiedUser' | translate }}"
          class="checked-user"
          *ngIf="item?.domains[0]?.name"
          aria-label="verified user"
          svgIcon="checked-user"
        >
        </mat-icon>
      </div>
      <label
        *ngIf="item?.nick == myNick && !item?.exactlyMatch"
        class="clickable search-my-nick search-my-nick-resumed"
        [matTooltipPosition]="'above'"
        matTooltip="This nick belongs to you"
        >({{ 'you' | translate }})</label
      >
      <div class="search-info-wrapper single-result-wrapper" *ngIf="item?.exactlyMatch">
        <label>
          <a
            (click)="openPayment(item)"
            [ngClass]="{ 'not-validated-company-name': item?.domains.length == 0 }"
            class="clickable company-name-link"
            target="_blank"
            >{{ item?.publicName }}</a
          >
        </label>
      </div>
      <div class="search-icons-wrapper">
        <mat-icon class="clickable pay" svgIcon="pay"> </mat-icon>
      </div>
    </li>
    <hr class="spacement" *ngIf="!(searchResults.length == 1)" />
  </ul>
</div>

<div
  class="dashboard-wrapper searchbar only-desktop"
  [ngClass]="{ 'opened-search': searchResults && !favoritesSearch, 'general-search': generalSearch }"
>
  <i class="ph ph-magnifying-glass search-icon clickable" (click)="onSearch(searchDesktop.value, true)"></i>
  <input
    #searchDesktop
    type="text"
    name="search"
    placeholder="{{ (favoritesSearch ? 'searchYourContacts' : 'searchNick') | translate }}"
    (input)="onInputChange(searchDesktop.value)"
  />
</div>

<div *ngIf="!favoritesSearch && searchResults" class="search-rectangle only-desktop">
  <ul *ngIf="searchResults.length == 0">
    <li class="search-li no-results">
      <div>{{ minimumCharMessage ? minimumCharMessage : ('weDidntFindAnyResultForYourSearch' | translate) }}</div>
    </li>
  </ul>
  <ul *ngFor="let item of searchResults">
    <li
      class="search-li"
      [ngClass]="{ 'common-search-li': !item?.exactlyMatch, 'single-result': item?.exactlyMatch }"
      (click)="openPayment(item)"
    >
      <img
        class="company-logo"
        [src]="environment.api + 'User/get-public-profile-picture?userId=' + item.userId"
        (error)="handleImageError($event)"
      />
      <div
        class="search-info-wrapper multi-results-wrapper"
        [ngClass]="{ 'firefox-label': !(item?.nick == myNick && !item?.exactlyMatch) }"
        *ngIf="!item?.exactlyMatch"
        (click)="isMobile ? showEmailModal(item?.domains[0].name) : ''"
      >
        <label
          [matTooltipPosition]="'above'"
          matTooltip="{{ item?.publicName }} - {{
            item?.domains[0]?.name ? item.domains[0].name : ('notValidatedDomain' | translate)
          }}"
          class="clickable company-name-link"
          [ngClass]="{ 'not-validated-company-name': item?.domains.length == 0 }"
        >
          <a [ngClass]="{ 'not-validated-company-name': item?.domains.length == 0 }" target="_blank">{{
            item?.publicName
          }}</a>
        </label>

        <mat-icon
          [matTooltipPosition]="'above'"
          matTooltip="{{ 'verifiedUser' | translate }}"
          class="checked-user"
          *ngIf="item?.domains[0]?.name"
          aria-label="verified user"
          svgIcon="checked-user"
        >
        </mat-icon>
      </div>
      <label
        *ngIf="item?.nick == myNick && !item?.exactlyMatch"
        class="clickable search-my-nick search-my-nick-resumed"
        [matTooltipPosition]="'above'"
        matTooltip="This nick belongs to you"
        >({{ 'you' | translate }})</label
      >
      <div class="search-info-wrapper single-result-wrapper" *ngIf="item?.exactlyMatch">
        <label>
          <a
            [ngClass]="{ 'not-validated-company-name': item?.domains.length == 0 }"
            class="clickable company-name-link"
            target="_blank"
            >{{ item?.publicName }}</a
          >
        </label>
      </div>
      <div class="search-icons-wrapper">
        <mat-icon class="clickable pay" (click)="openPayment(item)" svgIcon="pay"> </mat-icon>
      </div>
    </li>
    <hr class="spacement" *ngIf="!(searchResults.length == 1)" />
  </ul>
</div>
