<!-- Main Application Content -->
<router-outlet></router-outlet>

<!-- Loading Overlay -->
@if (loading$ | async) {
  <div class="fixed inset-0 bg-black/50 flex items-center justify-center  z-in-front">
    <!-- Spinner Element -->
    <div class="w-12 h-12 border-4 border-t-blue-500 border-blue-200 rounded-full animate-spin"></div>
  </div>
}
