import { Component } from '@angular/core';
import { NICKY_URLS } from 'src/app/utils/urls.constants';

@Component({
  selector: 'app-nicky-footer',
  templateUrl: './nicky-footer.component.html',
  styleUrls: ['./nicky-footer.component.scss']
})
export class NickyFooterComponent {
  currentYear: number = new Date().getFullYear();

  termsOfUseUrl = NICKY_URLS.TERMS_OF_USE;
  privacyPolicyUrl = NICKY_URLS.PRIVACY_POLICY;
  legalDisclosureUrl = NICKY_URLS.LEGAL_DISCLOSURE;
  cookiePolicyUrl = NICKY_URLS.COOKIE_POLICY;
}
