<app-payment
  [selectedLanguage]="selectedLanguage"
  [isAuthenticated]="isAuthenticated"
  [paymentModal]="paymentFlow"
  [assets]="assets"
  (onCloseModal)="closeModal()"
  [targetUser]="target"
  *ngIf="paymentFlow"
  [user]="user"
  [targetUser]="targetUser"
  [paymentRequestInfo]="paymentRequestInfo"
></app-payment>

<div class="modal-backdrop" *ngIf="!paymentFlow">
  <app-bubbles
    (openPayment)="openPayment()"
    [profileNotFound]="profileNotFound"
    (addFavorite)="addFavorite()"
    (onCloseModal)="closeModal()"
    *ngIf="profileInfo || profileNotFound"
    [publicProfile]="true"
    [isAuthenticated]="isAuthenticated"
    [alreadyFavorite]="alreadyFavorite"
    [profileInfo]="profileInfo"
  ></app-bubbles>
</div>
