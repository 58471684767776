<app-modal
  [modalMessage]="modalMessage"
  [fullEmailModal]="fullEmailModal"
  [exchangeModal]="exchangeModal"
  (onCloseModal)="closeInfoModal()"
  [hideCopyButton]="hideCopyButton"
  *ngIf="exchangeModal"
></app-modal>

<app-header
  *ngIf="router.url.includes('/payment-report')"
  (userSelectedLanguage)="selectedLanguage = $event"
  class="direct-payment-report-header"
></app-header>

<!-- <div class="modal-backdrop">
  <div class="modal">
    <div class="modal-body">
      <button (click)="testNetworkId()"> TEST NETWORK ID</button>
    </div>
  </div>
</div> -->
<div class="payment-report-flow">
  <div class="full-width">
    <!-- <h1 class="flow-tittle">
              Payment Request
          </h1> -->
    <div class="steps-status">
      <!-- <app-bubbles ></app-bubbles> -->

      <label *ngIf="requestPayment" [ngStyle]="{ color: step === 'overview' || '#000' }">
        <div
          class="toggle-box clickable"
          [ngClass]="{ 'past-step': completedSteps.overview }"
          (click)="checkIfStepCanBeClicked('overview')"
        >
          <div class="current-step" *ngIf="step === 'overview' && !completedSteps.overview"></div>
          <i class="checked-step ph-bold ph-check" *ngIf="completedSteps.overview"></i>
        </div>
        <span *ngIf="!isMobile || step === 'overview'">{{ 'payment_flow.payment' | translate }}</span>
      </label>

      <hr *ngIf="requestPayment" />

      <!-- <label [ngStyle]="{'color': (step === 'payment-info' || step == 'guest') || '#000'}" *ngIf="!isAuthenticated">
        <div class="toggle-box clickable" [ngClass]="{'past-step': completedSteps.identification }" 
        (click)="checkIfStepCanBeClicked( isAuthenticated ? 'payment-info' : 'guest') ">
          <div class="current-step" *ngIf="(step === 'payment-info' || step == 'guest') && !completedSteps.identification">

          </div>
          <i class="checked-step ph-bold ph-check" *ngIf="completedSteps.identification"></i>
        </div>
        <span *ngIf="!isMobile || (step === 'payment-info' || step == 'guest')">{{ 'payment_flow.identification' | translate }}</span>
      </label>
      <hr *ngIf="!isAuthenticated">  -->

      <label [ngStyle]="{ color: step === 'your-information' || '#000' }" *ngIf="!isAuthenticated">
        <div
          class="toggle-box clickable"
          [ngClass]="{ 'past-step': completedSteps.contact }"
          (click)="checkIfStepCanBeClicked('your-information')"
        >
          <div class="current-step" *ngIf="step === 'your-information' && !completedSteps.contact"></div>
          <i class="checked-step ph-bold ph-check" *ngIf="completedSteps.contact"></i>
        </div>
        <span *ngIf="!isMobile || step === 'your-information'">{{ 'payment_flow.contact' | translate }}</span>
      </label>
      <hr *ngIf="!isAuthenticated" />

      <label [ngStyle]="{ color: step === 'payment' || '#000' }">
        <div
          class="toggle-box clickable"
          [ngClass]="{ 'past-step': completedSteps.payment }"
          (click)="checkIfStepCanBeClicked('payment')"
        >
          <div class="current-step" *ngIf="step === 'payment' && !completedSteps.payment"></div>
          <i class="checked-step ph-bold ph-check" *ngIf="completedSteps.payment"></i>
        </div>
        <span *ngIf="!isMobile || step === 'payment'">{{ 'Info' | translate }}</span>
      </label>
      <hr />

      <label [ngStyle]="{ color: step === 'confirmation' || '#000' }">
        <div
          class="toggle-box clickable"
          [ngClass]="{ 'past-step': completedSteps.confirmation }"
          (click)="checkIfStepCanBeClicked('confirmation')"
        >
          <div class="current-step" *ngIf="step === 'confirmation' && !completedSteps.confirmation"></div>
          <i class="checked-step ph-bold ph-check" *ngIf="completedSteps.confirmation"></i>
        </div>
        <span *ngIf="!isMobile || step === 'confirmation'">{{ 'payment_flow.payment' | translate }}</span>
      </label>
    </div>
  </div>

  <div class="loading-modal" *ngIf="metamaskLoading.length > 0">
    <div class="metamask-loading" *ngIf="metamaskLoading.length > 0">
      <h2>
        {{ 'theTransactionIsNowUnderTheControlOfMetamask' | translate: { walletExtension: selectedWallet.info.name } }}
      </h2>
      <mat-icon aria-label="MetaMask" [svgIcon]="selectedWallet.info.name"> </mat-icon>
      <div class="spinner" [ngClass]="{ 'common-spinner': selectedWallet.info.name != 'MetaMask' }"></div>
    </div>
  </div>

  <div class="modal-backdrop public-profile-backdrop" *ngIf="publicProfile">
    <div class="modal public-profile-modal">
      <app-bubbles
        [isAuthenticated]="isAuthenticated"
        [profileInfo]="targetUser"
        [paymentProfile]="publicProfile"
        (closePaymentProfile)="publicProfile = false"
      ></app-bubbles>
    </div>
  </div>

  <!-- {{ 'payment_flow.overview' | translate }} | overview -->
  <div class="modal-body overview-body" *ngIf="step == 'overview'">
    <div class="step-container step-overview">
      <div class="step-tittle flex-centered">
        <h2>
          {{ targetUser.publicName }}
          {{ 'payment_flow.isRequestingAPayment' | translate }}
        </h2>
      </div>

      <div class="request-info user-info">
        <img
          class="profile-pic"
          [src]="environment.api + 'User/get-public-profile-picture?userId=' + targetUser.id"
          matTooltipPosition="above"
          matTooltip="{{ targetUser.publicName?.charAt(0) }}"
          (error)="handleImageError($event, targetUser.publicName)"
        />
        <div>
          <h2>{{ targetUser.publicName }}</h2>
          <!-- <h3> {{ targetNick }} </h3> -->
        </div>
        <!-- <span class="short-id" *ngIf="target.shortId"> {{ target.shortId }}</span> -->
        <div class="open-public-profile" (click)="publicProfile = true">
          <i class="ph ph-arrow-line-up-right button"></i>
        </div>
      </div>

      <div class="payer-propertys">
        <div class="amount-info">
          <div class="asset-info flex-centered">
            @if (!checkIfRequestedAssetIsFiat()) {
              <mat-icon class="icon asset-icon" [svgIcon]="payingAsset" [attr.aria-label]="payingAsset"></mat-icon>
            }
            <label>
              {{ payingAsset }}
            </label>
          </div>
          <label class="amount">{{ formatPrice(paymentValue, true, true) }}</label>
        </div>
        <div class="request-info">
          <label class="info-name">{{ 'payment_flow.from' | translate }}</label>
          <label class="info-value">{{ behalfName || behalfCompany }}</label>
        </div>

        <div class="request-info">
          <label class="info-name">{{ 'payment_flow.email' | translate }}</label>
          <label class="info-value"> {{ behalfEmail }} </label>
        </div>
      </div>

      <div class="payment-details">
        @if (this.payment.controls['description'].value) {
          <div class="request-info bordered-request-info">
            <label class="info-name">{{ 'payment_flow.Description' | translate }}</label>
            <label #description class="info-value">
              <span>{{
                this.payment.controls['description'].value || '< ' + ('PAYMENT_REQUEST.empty' | translate) + ' >'
              }}</span>
              @if (checkIfOverflows(this.payment.controls['description'].value, description)) {
                ...
                <a
                  class="see-more clickable"
                  *ngIf="checkIfOverflows(this.payment.controls['description'].value, description)"
                  (click)="openMoreInfoModal(this.payment.controls['description'].value)"
                  >See more</a
                >
              }
            </label>
          </div>
        }
        @if (this.payment.controls['reference'].value) {
          <div class="request-info bordered-request-info">
            <label class="info-name">{{ 'payment_flow.Reference' | translate }}</label>
            <label #reference class="info-value">
              <span>{{
                this.payment.controls['reference'].value || '< ' + ('PAYMENT_REQUEST.empty' | translate) + ' >'
              }}</span>
              @if (checkIfOverflows(this.payment.controls['reference'].value, reference)) {
                ...
                <a class="see-more clickable" (click)="openMoreInfoModal(this.payment.controls['reference'].value)"
                  >See more</a
                >
              }
            </label>
          </div>
        }

        <div class="request-info bordered-request-info">
          <label class="info-name">{{ 'payment_flow.PaymentID' | translate }}</label>
          <label class="info-value"> {{ requestParams?.paymentId }} </label>
        </div>

        <!-- <h3 class="have-account" (click)='redirectToLogin()'  [innerHTML]="'orLoginIfYouHaveAnAccount' | translate">
          
        </h3> -->
      </div>
    </div>
    <div class="modal-footer side-buttons-footer step-overview-buttons">
      <a class="cancel-button" (click)="alreadyFinished = true; closeModal()">
        {{ 'payment_flow.Cancel' | translate }}
      </a>
      <button (click)="isAuthenticated ? nextStep('payment') : nextStep('your-information')" class="only-portrait">
        {{ 'PAYMENT_REPORT.yesIWantToGoAhead' | translate }}
      </button>
    </div>
  </div>

  <!-- Identification step | guest -->
  <div class="modal-body step-overview-body" *ngIf="step == 'guest'">
    <div class="step-container step-overview">
      <div class="step-tittle flex-centered">
        <h2>{{ 'PAYMENT_REPORT.areYouANickyUser' | translate }} ?</h2>
        <!-- <h3>
          {{ 'PAYMENT_REPORT.IfYouAlreadyHaveAnAccountYouCanSignIn' | translate }}
        </h3> -->
      </div>
      <div class="input-wrapper">
        <div class="payer-info">
          <div
            class="payer-type clickable"
            (click)="areAUser = 'login'"
            [ngClass]="{ 'selected-type': areAUser === 'login' }"
          >
            <div class="payer-icons">
              <i class="ph ph-sign-out"></i>
              <i class="ph ph-check" *ngIf="areAUser === 'login'"></i>
            </div>
            <h2>Login</h2>
            <h3>{{ 'payment_flow.YesIWantToLogin' | translate }}</h3>
          </div>

          <!-- <div class="payer-type clickable" (click)="areAUser = 'register'"
            [ngClass]="{'selected-type': areAUser === 'register'}">
            <div class="payer-icons">
              <i class="ph ph-user-circle-plus"></i>
              <i class="ph ph-check" *ngIf="areAUser === 'register'"></i>
            </div>
            <h2>Sign up</h2>
            <h3>{{ 'payment_flow.NotAUserButIWantToSignUp' | translate }}</h3>
          </div> -->

          <div
            class="payer-type clickable"
            (click)="areAUser = 'guest'"
            [ngClass]="{ 'selected-type': areAUser === 'guest' }"
          >
            <div class="payer-icons">
              <i class="ph ph-user-circle"></i>
              <i class="ph ph-check" *ngIf="areAUser === 'guest'"></i>
            </div>
            <h2>{{ 'payment_flow.ContinueAsGuest' | translate }}</h2>
            <h3>
              {{ 'payment_flow.JustAQuickCoupleOfQuestions' | translate }}
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer side-buttons-footer centered-footer">
      <button (click)="showConfirmationModal = false; guestNextStep('your-information')">
        {{ 'payment_flow.Continue' | translate }}
      </button>
    </div>
  </div>

  <!-- Contact | your-information -->
  <div class="modal-body contact-step" *ngIf="step == 'your-information'">
    <div class="step-container">
      <div class="step-tittle flex-centered">
        <h2>
          {{ 'payment_flow.contact' | translate }}
        </h2>
        <!-- <h3>
                {{ 'payment_flow.PayersInformation' | translate }}
            </h3> -->
      </div>
      <div class="input-wrapper" *ngIf="selectedUserType === 'individual'">
        <h3>{{ 'Name' | translate }}</h3>
        <div
          class="input-container"
          [ngClass]="{
            invalid: invalidGuestInfo && !this.payment.controls['guestName']?.valid,
          }"
        >
          <i class="ph ph-user-circle"></i>
          <input
            autocomplete="name"
            name="name"
            id="name"
            class="common-input bigger-input"
            type="text"
            [(ngModel)]="guestName"
            (ngModelChange)="payment.controls['guestName'].setValue(guestName)"
            *ngIf="selectedUserType == 'individual'"
          />
        </div>
      </div>

      <div class="input-wrapper" *ngIf="selectedUserType === 'organization'">
        <h3>{{ 'payment_flow.PayersOrganizationName' | translate }}</h3>
        <div
          class="input-container"
          [ngClass]="{
            invalid: invalidGuestInfo && !this.payment.controls['company'].valid,
          }"
        >
          <i class="ph ph-user-circle"></i>
          <input
            autocomplete="organization"
            name="organization"
            class="common-input bigger-input"
            type="text"
            [(ngModel)]="organization"
            (ngModelChange)="payment.controls['company'].setValue(organization)"
            *ngIf="selectedUserType == 'organization'"
          />
        </div>
      </div>

      <label
        *ngIf="selectedUserType == 'individual' && invalidGuestInfo && !this.payment.controls['guestName']?.valid"
        class="inform-label warning-inform"
      >
        {{ 'youNeedToInformYourName' | translate }}
      </label>
      <label
        *ngIf="selectedUserType == 'organization' && invalidGuestInfo && !this.payment.controls['company']?.valid"
        class="inform-label warning-inform"
      >
        {{ 'youNeedToInformYourCompanyName' | translate }}
      </label>

      <div class="input-wrapper">
        <h3>{{ 'email' | translate }}</h3>
        <div
          class="input-container"
          [ngClass]="{
            invalid: (invalidGuestInfo && !this.payment.controls['email']?.valid) || invalidEmail,
          }"
        >
          <i class="ph ph-at"></i>
          <input
            autocomplete="email"
            name="email"
            class="common-input bigger-input"
            type="text"
            [(ngModel)]="email"
            (keyup)="emailInput()"
          />
        </div>
      </div>

      <label
        *ngIf="(invalidGuestInfo && !this.payment.controls['email']?.valid) || invalidEmail"
        class="inform-label warning-inform"
      >
        {{ 'youNeedToInformAValidEmail' | translate }}
      </label>

      <div class="input-wrapper">
        <h3>{{ 'iAmAn' | translate }}</h3>
        <div class="payer-info">
          <div
            class="payer-type clickable"
            (click)="selectedUserType = 'individual'"
            [ngClass]="{ 'selected-type': selectedUserType === 'individual' }"
          >
            <div class="payer-icons">
              <i class="ph ph-user"></i>
              <i class="ph ph-check" *ngIf="selectedUserType === 'individual'"></i>
            </div>
            <h2>{{ 'payment_flow.Individual' | translate }}</h2>
            <!-- <h3>{{ 'payment_flow.PayerIsAnIndividual' | translate }} </h3> -->
          </div>
          <div
            class="payer-type clickable"
            (click)="selectedUserType = 'organization'"
            [ngClass]="{ 'selected-type': selectedUserType === 'organization' }"
          >
            <div class="payer-icons">
              <i class="ph ph-buildings"></i>
              <i class="ph ph-check" *ngIf="selectedUserType === 'organization'"></i>
            </div>
            <h2>{{ 'payment_flow.Organization' | translate }}</h2>
            <!-- <h3>{{ 'payment_flow.PayerIsAnOrganization' | translate }}</h3> -->
          </div>
        </div>
      </div>
      <h3 class="have-account" (click)="redirectToLogin()" [innerHTML]="'orLoginIfYouHaveAnAccount' | translate"></h3>
    </div>
    <div class="modal-footer side-buttons-footer only-desktop">
      <button class="cancel-button" (click)="closeModal()">
        {{ 'payment_flow.Cancel' | translate }}
      </button>
      <button id="payment-continueButton" (click)="nextStep('payment')">
        {{ 'payment_flow.Continue' | translate }}
      </button>
    </div>
  </div>

  <!-- Payment | payment -->
  <div class="modal-body paying-body" *ngIf="step == 'payment'">
    <div class="step-container step-payment">
      <mat-icon
        *ngIf="!isMobile"
        svgIcon="help-book"
        class="help-book clickable"
        id="bookIcon"
        (click)="startPaymentTour()"
        style="float: right"
      ></mat-icon>

      <div class="step-tittle flex-centered">
        <h2>
          {{ 'Info' | translate }}
        </h2>
      </div>

      <div class="amount-inputs-wrapper">
        <div class="amount-info-wrapper" id="received">
          <label class="info-wrapper-title">
            <span class="italic">{{ 'iOwe' | translate: { receiver: '' } }}</span>
            {{ targetUser.publicName }}
            <i
              class="ph ph-info no-arrow clickable"
              #tooltip="matTooltip"
              (click)="!isMobile || tooltip.toggle()"
              matTooltip="{{ 'iOweExplanation' | translate }}"
              matTooltipPosition="below"
            ></i>
          </label>
          <div class="amount-inputs">
            @if ((requestParams != undefined && requestPayment) || onlyOneAcceptedAsset) {
              <mat-icon class="icon asset-icon" [svgIcon]="payingAsset" [attr.aria-label]="payingAsset"></mat-icon>
              <span class="asset-label" [ngStyle]="{ width: assetOptions.length != 1 ? '70px' : '' }">
                {{ payingAsset }}
              </span>
            } @else {
              <div class="asset-select-wrapper flex-centered">
                <mat-icon class="icon asset-icon" [svgIcon]="payingAsset" [attr.aria-label]="payingAsset"></mat-icon>
                <select
                  id="settlement-asset-option-select"
                  #settlementSelect
                  [ngClass]="{ 'single-asset-select': onlyOneAcceptedAsset }"
                  [(ngModel)]="payingAsset"
                  class="selection selected-asset hidden-option-select"
                  (ngModelChange)="changeTargetCurrency(); updateMaskConfig(); changingSelectedAssetsOnUi()"
                >
                  <option *ngIf="cryptoAssets.length > 0" style="font-weight: 700; text-transform: uppercase" disabled>
                    {{ 'PAYMENT_REPORT.cryptoAssets' | translate }}
                  </option>
                  <option [value]="asset.assetTicker" *ngFor="let asset of cryptoAssets">
                    {{ buildSelectionText(asset.assetName, asset.assetTicker) }}
                  </option>
                  <option *ngIf="fiatAssets.length > 0" style="font-weight: 700; text-transform: uppercase" disabled>
                    {{ 'PAYMENT_REPORT.fiat' | translate }}
                  </option>
                  <option [value]="asset.assetTicker" *ngFor="let asset of fiatAssets">
                    {{ buildSelectionText(asset.assetName, asset.assetTicker) }}
                  </option>
                  <option *ngIf="requestParams != undefined && requestPayment" [value]="payingAsset">
                    {{ payingAsset }}
                  </option>
                </select>

                <div class="selection-text" (click)="settlementSelect.focus()">
                  {{ payingAsset }}
                </div>

                <!-- <app-custom-option-select class="inline-flex-option-select"
              [assets]="currentOptionsSelects.payingAsset" [currentValue]="payingAsset"
              (changedValue)="payingAsset = $event;
              updateMaskConfig();"></app-custom-option-select>   -->
              </div>
            }
            @if (!itsFixedSettlementAsset()) {
              <div class="input-container">
                <input
                  #input
                  currencyMask
                  [options]="customCurrencyMaskConfig"
                  type="text"
                  id="amount"
                  name="amount"
                  inputmode="numeric"
                  autocomplete="off"
                  [(ngModel)]="paymentValue"
                  (ngModelChange)="updateAsset()"
                  [disabled]="itsFixedSettlementAsset()"
                  name="USD"
                  (keydown.backspace)="updateAsset(true)"
                  placeholder="{{ '0' + (isCommaDecimal ? ',' : '.') + generatePlaceholderZerosForAsset(payingAsset) }}"
                />
              </div>
            } @else {
              {{ formatPrice(paymentValue, true, true) }}
            }
          </div>

          <div class="amount-info-wrapper" id="reference">
            <label class="info-wrapper-title italic less-priority-label italic">
              {{ 'relativeTo' | translate }}
              <!-- <i class="ph ph-info no-arrow clickable" #tooltip="matTooltip" (click)="!isMobile || tooltip.toggle()" *ngIf="loading.length < 1"  matTooltip="{{ 'iOweExplanation' | translate }}" matTooltipPosition="below"></i> -->
            </label>
            <div class="amount-inputs">
              @if (
                (paymentRequestInfo && paymentRequestInfo?.bill?.invoiceReference) || this.queryParams?.['reference']
              ) {
                <span class="bold reference-label">{{ payment.controls['reference'].value }}</span>
              } @else {
                <div class="input-container">
                  <input
                    type="text"
                    id="relative-to"
                    class="fill-size-input bigger-input"
                    [maxlength]="17"
                    [(ngModel)]="reference"
                    (ngModelChange)="payment.controls['reference'].setValue(reference)"
                    placeholder="{{ 'payment_flow.reference' | translate }}"
                  />
                </div>
              }
            </div>
          </div>
        </div>
        <hr *ngIf="!isMobile" class="spacement" />

        <div class="amount-info-wrapper" id="paid">
          <label class="info-wrapper-title italic">
            {{ 'soIAmSending' | translate }}
            <i
              class="ph ph-info no-arrow clickable"
              #tooltip="matTooltip"
              (click)="!isMobile || tooltip.toggle()"
              matTooltip="{{ 'iAmSendingExplanations' | translate }}"
              matTooltipPosition="above"
            ></i>
          </label>
          <div class="amount-inputs">
            @if (assetOptions.length === 1) {
              <mat-icon class="icon asset-icon" [svgIcon]="'filter-' + selectedAsset"></mat-icon>
              <label class="asset-label">{{ assetOptions[0].assetTicker }} </label>
              {{ formatPrice(assetValue || 0, true, false) }}
            } @else {
              <div [ngClass]="{ 'single-asset': assetOptions.length === 1 }" class="asset-select-wrapper flex-centered">
                <mat-icon
                  class="icon asset-icon"
                  [svgIcon]="'filter-' + selectedAsset"
                  [attr.aria-label]="selectedAsset"
                ></mat-icon>
                <select
                  *ngIf="assetOptions.length > 1"
                  [(ngModel)]="selectedAsset"
                  name="settlementAsset"
                  class="selection asset-selection selected-asset hidden-option-select"
                  (ngModelChange)="chooseUserWallet(true); changingSelectedAssetsOnUi()"
                >
                  <option [value]="connection.assetTicker" *ngFor="let connection of assetOptions">
                    {{ buildSelectionText(connection.assetName, connection.assetTicker) }}
                  </option>
                </select>
                <div class="selection-text">
                  {{ selectedAsset }}
                </div>
                <!-- <app-custom-option-select class="inline-flex-option-select"
                [assets]="currentOptionsSelects.payingAsset" [currentValue]="payingAsset"
                (changedValue)="payingAsset = $event;
                updateMaskConfig();"></app-custom-option-select>   -->
              </div>
              {{ formatPrice(assetValue || 0, true, false) }}
              <!-- <div class="input-container">
                <input type="text" inputmode="numeric" [disabled]="true" [(ngModel)]="assetValue" appNumberFormat
                  name="paying-amount" (keyup.backspace)="updateSettlementAsset(true)" (keyup.space)="updateSettlementAsset(true)"
                  (ngModelChange)="updateSettlementAsset();"
                  placeholder="{{'0' + (isCommaDecimal ? ',' : '.') + '0000000'}}" class="asset-placeholder">
              </div> -->
            }
          </div>
          <div
            class="network-select"
            *ngIf="selectedAsset != 'BTC' && selectedAsset != 'ETH' && selectedAsset != 'POL'"
          >
            <label class="italic"> {{ 'via' | translate }} </label>

            @for (enabledNetwork of enabledNetworks; track selectedAsset) {
              <div
                class="toggle-selection clickable"
                name="network"
                id="network"
                (click)="network = enabledNetwork; getUserWallets(true)"
                [ngClass]="{ 'selected-toggle': network === enabledNetwork }"
              >
                <div class="toggle-icons">
                  <i class="ph ph-check"></i>
                </div>
                <h2>{{ getNetworkName(enabledNetwork) }}</h2>
              </div>
            }
          </div>
        </div>
      </div>
      @if (!sameAssets()) {
        <div class="rates-container" id="rate">
          <h2 class="rate-content-title">
            {{ 'PAYMENT_REPORT.currentRate' | translate }}
            <label class="rate-cooldown">
              {{ !sameAssets() ? formatTimer(refreshRatesTimerInSeconds.toString()) : '' }}</label
            >
            <i class="ph ph-timer"></i>
            <i
              class="ph ph-info clickable"
              #tooltip="matTooltip"
              (click)="!isMobile || tooltip.toggle()"
              matTooltip="{{ getCoinMarketTooltip() }}"
              matTooltipPosition="above"
            ></i>
          </h2>

          @if (!flippedRates) {
            <label class="rates-asset-label"> {{ payingAsset }} 1 </label> =
            <label>{{ selectedAsset }} {{ formatPrice(1 / payingAssetPrice) }}</label>
          } @else {
            <label class="rates-asset-label"> {{ selectedAsset }} 1 </label> =
            <label>
              {{ payingAsset }}
              {{ payingAssetPrice ? formatPrice(payingAssetPrice, true, true) : '' }}
            </label>
          }
          <a (click)="flippedRates = !flippedRates" class="flip-button clickable">{{ 'flip' | translate }}</a>
        </div>

        <!-- <div class="input-container time-lapse-container" id="payment-deadline">
            <label class="time-lapse-title">
             {{ 'payment_flow.refreshingIn' | translate }}
            </label>
            <div class="time-lapse-bar" [ngStyle]="{'background': fillTimeLapseBar(refreshRatesTimerInSeconds, 15)}">
  
            </div>
            <label class="rate-cooldown"> {{ !sameAssets() ? formatTimer(refreshRatesTimerInSeconds.toString()) : '' }} </label>
          </div> -->
      }
    </div>
    <div class="modal-footer side-buttons-footer">
      <a class="cancel-button" (click)="closeModal()">
        {{ 'payment_flow.Cancel' | translate }}
      </a>

      <div class="buttons-wrapper">
        <button
          class="secondary-button"
          *ngIf="requestParams || !isAuthenticated"
          (click)="backPreviousStep('your-information')"
        >
          {{ 'back' | translate }}
        </button>
        <button
          class="proceed-button clickable"
          id="next-button"
          [ngClass]="{ disabled: testIfZero() }"
          (click)="!nullPrice && !testIfZero() ? nextStep('confirmation') : ''"
        >
          {{ 'next' | translate }}
        </button>
      </div>

      <label
        class="secondary-text no-wallet-warning"
        *ngIf="walletError"
        [innerHTML]="
          'userTemporarilyUnableToReceivePayments' | translate: { assetName: this.getFormatedAssetForWarnings() }
        "
      >
      </label>
      <label class="secondary-text" *ngIf="!walletError">
        {{ 'inTheNextStepYouWillHave' | translate: { minutes: 15 } }}
      </label>
    </div>
  </div>

  <!-- Confirmation | confirmation -->
  <div class="modal-body confirmation-body" *ngIf="step == 'confirmation'">
    <div class="step-container">
      <mat-icon
        *ngIf="!isMobile"
        svgIcon="help-book"
        class="help-book clickable"
        id="bookIcon"
        (click)="confirmationPaymentTour()"
      ></mat-icon>

      <div class="step-tittle flex-centered">
        <h2>
          {{ 'payment_flow.payment' | translate }}
        </h2>

        <!-- <label class="timer-inform">
          <i class="ph ph-info"></i>
          <h3>
            {{ 'PAYMENT_REPORT.paymentMustBeCompletedWithin' | translate }} {{ minutes }} min
          </h3>
        </label> -->
      </div>

      <div class="request-info user-info mobile-i-owe" id="amount-received">
        <label class="quote-tittle">
          <span class="italic">{{ 'iOwe' | translate: { receiver: '' } }}</span>
          {{ targetUser.publicName }}
          <i
            class="ph ph-info i-owe-tooltip no-arrow clickable"
            #tooltip="matTooltip"
            (click)="!isMobile || tooltip.toggle()"
            matTooltip="{{ 'iOweExplanation' | translate }}"
            matTooltipPosition="below"
          ></i>
          <br />
          <label class="i-owe-amount">
            <mat-icon class="icon asset-icon" [svgIcon]="payingAsset" [attr.aria-label]="payingAsset"></mat-icon>
            <label>
              {{ payingAsset }}
              {{ formatPrice(savedpaymentValue, payingAsset == 'BTC' || payingAsset == 'ETH', true).toString() }}
            </label>
            <label class="asset-name-label">
              {{ currentSettlingAssetName }}
            </label>
          </label>
          <label *ngIf="payment.controls['reference'].value" class="relative-to less-priority-label">
            <span class="italic">{{ 'relativeTo' | translate }}</span>
            {{ this.payment.controls['reference'].value }}
          </label>
        </label>
      </div>

      <div class="quote-info">
        <!-- <hr *ngIf="!isMobile" class="spacement"> -->
        <ng-conatiner>
          <div class="quote-wrapper via-wrapper" id="amount-paid">
            <label class="quote-tittle italic">
              {{ 'soIAmSending' | translate }}
              <i
                class="ph ph-info no-arrow clickable"
                #tooltip="matTooltip"
                (click)="!isMobile || tooltip.toggle()"
                matTooltip="{{ 'iAmSendingExplanations' | translate }}"
                matTooltipPosition="above"
              ></i>
            </label>
            <div class="amount-info">
              <div class="asset-info flex-centered">
                <mat-icon
                  class="icon asset-icon"
                  [svgIcon]="selectedAsset"
                  [attr.aria-label]="selectedAsset"
                ></mat-icon>
                <label>
                  {{ selectedAsset }}
                </label>
              </div>
              <label class="amount">
                {{
                  formatPrice(currentQuote?.price, selectedAsset === 'BTC' || selectedAsset === 'ETH' ? true : false)
                }}
              </label>
              <i class="ph ph-copy copy-button" (click)="copySendingAmount()"></i>
              <label class="asset-name-label">
                {{ currentPayingAssetName }}
              </label>
            </div>
            <label class="bold">
              <span class="italic">{{ 'via' | translate }}</span>
              <span class="underlined via-network-name"
                >{{ formatNetworkName(getSelectedAssetNetwork()).toLowerCase() + 'Network' | translate }}
              </span>
            </label>
          </div>
        </ng-conatiner>
      </div>

      <div class="input-container time-lapse-container" id="payment-deadline">
        <label class="time-lapse-title">
          {{ 'openYourWalletAndSendPayment' | translate }}
          <i
            class="ph ph-info clickable"
            #tooltip="matTooltip"
            (click)="!isMobile || tooltip.toggle()"
            matTooltip="{{ 'userYourPreferredCryptoWallet' | translate }}"
            matTooltipPosition="above"
          ></i>
        </label>
        <div
          class="time-lapse-bar"
          [ngStyle]="{
            background: fillTimeLapseBar(refreshQuotesTimerInSeconds, totalTimeInMinutes * 60),
          }"
        ></div>
        <label class="rate-cooldown">
          {{ !sameAssets() ? formatTimer(refreshQuotesTimerInSeconds.toString()) : '' }}
        </label>
      </div>

      <div class="bottom-content">
        <div class="qr-code-wrapper" id="payment-address">
          <div class="nick-wallet-code">
            <div class="copy-code clickable" (click)="qrModal = true">
              <qrcode
                class="qr-code"
                [qrdata]="walletAddress"
                [allowEmptyString]="true"
                [imageSrc]="'./assets/nicky-n.png'"
                [imageWidth]="isMobile ? 22 : 22"
                [imageHeight]="isMobile ? 22 : 29"
                [cssClass]="'center'"
                [colorDark]="'#000000ff'"
                [colorLight]="'#ffffffff'"
                [elementType]="'canvas'"
                [errorCorrectionLevel]="'M'"
                [margin]="0"
                [scale]="1"
                [width]="isMobile ? 90 : 100"
                [title]="'address' | translate"
              ></qrcode>
              <label class="clickable expand-button">{{ 'expand' | translate }}</label>
            </div>
          </div>
        </div>

        <div class="payment-info-container">
          <div class="rates-container">
            <h2 class="rate-content-title">
              <span [innerHTML]="getAddressViaNetwork()"></span>
              <i
                class="ph ph-info i-owe-tooltip no-arrow clickable"
                #tooltip="matTooltip"
                (click)="!isMobile || tooltip.toggle()"
                matTooltip="{{ getWrongNetworkInforming() }}"
                matTooltipPosition="below"
              ></i>
            </h2>
            <label [title]="walletAddress" id="address" class="rates-asset-label wallet-address-label">
              {{ walletAddress }}
              <i class="ph ph-copy copy-button" (click)="copyWalletAddress()"></i>
            </label>
          </div>

          @if (!sameAssets()) {
            <div class="rates-container">
              <h2 class="rate-content-title">
                {{ 'payment_flow.LockedRate' | translate }}
                <i
                  class="ph ph-info clickable"
                  #tooltip="matTooltip"
                  (click)="!isMobile || tooltip.toggle()"
                  matTooltip="{{ getCoinMarketTooltip() }}"
                  matTooltipPosition="above"
                ></i>
              </h2>
              <label class="flipped-rates">
                @if (!flippedRates) {
                  {{ payingAsset }} 1 = {{ selectedAsset }}
                  {{ formatPrice(1 / payingAssetPrice) }}
                } @else {
                  {{ selectedAsset }} 1 = {{ payingAsset }}
                  {{ payingAssetPrice ? formatPrice(payingAssetPrice, true, true) : '' }}
                }
                <a (click)="flippedRates = !flippedRates" class="flip-button clickable">{{ 'flip' | translate }}</a>
              </label>
            </div>
          }
        </div>
      </div>

      <h1 *ngIf="isMobile" class="terms-inform" [innerHTML]="getWalletExchange()"></h1>
    </div>

    <h3 *ngIf="!isMobile && !(selectedAsset === 'BTC') && availableWallets.length > 1" class="metamask-pay">
      {{ 'youHaveWalletExtensionsInstalledClickToPayWithOneOfThem' | translate }}
      @for (availableWallet of availableWallets; track availableWallets) {
        <mat-icon
          (click)="startPaymentWallet(availableWallet)"
          class="clickable"
          aria-label="MetaMask"
          [svgIcon]="availableWallet.info.name"
        >
        </mat-icon>
      }
    </h3>

    <h3 *ngIf="!isMobile && !(selectedAsset === 'BTC') && availableWallets.length === 1" class="metamask-pay">
      <!-- {{ 'weNoticedThatYouHaveTheMetamaskExtensionInstaled' | translate: {walletExtension: selectedWallet.info.name} }} -->

      <mat-icon
        [aria-label]="selectedWallet.info.name + ' wallet'"
        [svgIcon]="selectedWallet.info.name.toLocaleLowerCase()"
      >
      </mat-icon>

      <a class="clickable" (click)="startPaymentWallet(selectedWallet)">{{
        'clickHereToPayWith' | translate: { walletExtension: selectedWallet.info.name }
      }}</a>
    </h3>

    <h1 *ngIf="!isMobile" class="terms-inform" [innerHTML]="getWalletExchange()"></h1>

    <!-- Ask to syeda where to put this -->
    <!--  -->
    <div class="modal-footer side-buttons-footer">
      <label class="quote-warning" *ngIf="invalidQuote">{{
        'payment_flow.attentionQuoteIsOutdated' | translate
      }}</label>
      <a (click)="alreadyFinished = true; closeModal()">{{ 'cancel' | translate }}</a>
      <div class="buttons-wrapper" id="confirmation-step">
        <button class="secondary-button" (click)="backPreviousStep('payment')">
          {{ 'back' | translate }}
        </button>
        <button
          id="ihavesent-button"
          class="have-trasnfered italic"
          (click)="transferred = true; completedSteps.confirmation = true"
        >
          {{ 'iHaveSent' | translate }}
        </button>
      </div>

      <!-- <label *ngIf="!isMobile && !(selectedAsset === 'BTC')">{{ 'payment_flow.or' | translate }}</label> -->
      <!-- <label class="secondary-text">
        {{ 'inTheNextStepYouWillBeAskedToProvideTheTransactionId' | translate }}
      </label> -->
      <!-- <button *ngIf="transferred" class="have-trasnfered" (click)=" loading.length == 0 ? nextStep('finish') : ''" >{{ 'PAYMENT_REPORT.finishTrnasaction' | translate }}</button> -->
    </div>
  </div>

  <!-- finish | finish -->
  <div class="modal-body step-success-body" *ngIf="step == 'finish' && newPaymentId">
    <div class="step-container step-overview">
      <div class="step-tittle success-tittle flex-centered">
        <mat-icon class="success-payment-img" svgIcon="success-payment"> </mat-icon>
        <h2>{{ 'payment_flow.PaymentSuccessful' | translate }}!</h2>
      </div>
      <label class="payment-success-inform" [innerHTML]="getSuccessPhrase()"> </label>
    </div>
    <div class="modal-footer side-buttons-footer centered-footer">
      <button *ngIf="!paymentRequestInfo?.successUrl" (click)="showConfirmationModal = false; closeModal()">
        {{ isAuthenticated ? ('backToDashboard' | translate) : ('createAnAccount' | translate) }}
      </button>
      <button *ngIf="paymentRequestInfo?.successUrl" (click)="showConfirmationModal = false; closeModal()">
        {{ 'proceed' | translate }}
      </button>
    </div>
  </div>

  <div class="modal-body step-success-body step-timeout" *ngIf="step === 'invalid-quote'">
    <div class="step-container step-overview">
      <mat-icon class="success-payment-img" svgIcon="error"> </mat-icon>
      <label class="quote-inform">
        <h1>{{ 'timeOut' | translate }}</h1>
        <br />
        <h2>{{ 'didYouCompleteTheTransaction' | translate }}</h2>
      </label>
    </div>
    <div class="modal-footer side-buttons-footer centered-footer">
      <button (click)="nextStep('confirmation')" class="secondary-button">
        {{ 'noIDidNot' | translate }}
      </button>
      <button (click)="nextStep('confirmation'); transferred = true; completedSteps.confirmation = true">
        {{ 'yesICompletedTheTransaction' | translate }}
      </button>
    </div>
  </div>

  <div class="confirmation-modal-backdrop modal-backdrop" *ngIf="transferred">
    <div class="modal-body transaction-id-modal">
      <div class="modal-header">
        <h3 class="payment-tittle">
          {{ 'PAYMENT_REPORT.transactionId' | translate }}
        </h3>
        <!-- <h3 class="close clickable" (click)="transferred = false;"> X </h3> -->
      </div>
      <div class="input-container time-lapse-container">
        <label class="time-lapse-title">
          {{ 'informTheTransactionId' | translate }}
        </label>
        <div
          class="time-lapse-bar"
          [ngStyle]="{
            background: fillTimeLapseBar(refreshQuotesTimerInSeconds, totalTimeInMinutes * 60),
          }"
        ></div>
        <label class="rate-cooldown">
          {{ !sameAssets() ? formatTimer(refreshQuotesTimerInSeconds.toString()) : '' }}
        </label>
      </div>
      <!-- Removed warning -->
      <!-- <label class="timer-inform">
        <i class="ph ph-info"></i>
        <h3>
          {{ 'PAYMENT_REPORT.paymentMustBeCompletedWithin' | translate }} {{ totalTimeInMinutes }} min
        </h3>
      </label> -->
      <div></div>
      <div class="input-wrapper">
        <label class="transaction-id-info" [innerHTML]="getInformingPhrase()">
          <!-- {{ 'payment_flow.EnterOrPasteYourTransactionID' | translate }} -->
        </label>
        <div class="input-container">
          <input
            *ngIf="!isMobile"
            type="text"
            [ngClass]="{
              invalid: !payment.controls['transactionID']?.valid && payment.controls['transactionID'].touched,
            }"
            [(ngModel)]="transactionID"
            (ngModelChange)="payment.controls['transactionID'].setValue(transactionID)"
            class="fill-size-input transaction-id-input"
            id="txId"
            name="transaction-id"
            placeholder="{{ 'PAYMENT_REPORT.transactionId' | translate }}"
          />

          <textarea
            *ngIf="isMobile"
            type="text"
            [ngClass]="{
              invalid: !payment.controls['transactionID']?.valid && payment.controls['transactionID'].touched,
            }"
            [(ngModel)]="transactionID"
            (ngModelChange)="payment.controls['transactionID'].setValue(transactionID)"
            class="fill-size-input transaction-id-input"
            id="txId"
            name="transaction-id"
            placeholder="{{ 'PAYMENT_REPORT.transactionId' | translate }}"
          ></textarea>
        </div>
        <a
          *ngIf="clipboardPermissions?.state != 'denied' && !clipboardDenied"
          class="wallet-link clickable"
          (click)="pasteTransactionId()"
        >
          {{ 'pasteFromClipboard' | translate }}
        </a>
      </div>
      <div class="modal-footer side-buttons-footer">
        <button class="cancel-button" (click)="transferred = false">
          {{ 'payment_flow.Cancel' | translate }}
        </button>
        <button
          id="submit-txid"
          class="have-trasnfered"
          [ngClass]="{ disabled: !this.transactionID }"
          (click)="nextStep('finish')"
        >
          {{ 'submit' | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="confirmation-modal-backdrop modal-backdrop" *ngIf="qrModal">
    <div class="modal-body transaction-id-modal qr-code-modal">
      <div class="modal-header">
        <h3 class="payment-tittle">{{ 'scanQrCode' | translate }}</h3>
        <!-- <h3 class="close clickable" (click)="transferred = false;"> X </h3> -->
      </div>
      <qrcode
        class="qr-code"
        [qrdata]="walletAddress"
        [allowEmptyString]="true"
        [imageSrc]="'./assets/nicky-n.png'"
        [imageHeight]="56"
        [imageWidth]="47"
        [cssClass]="'center'"
        [colorDark]="'#000000ff'"
        [colorLight]="'#ffffffff'"
        [elementType]="'canvas'"
        [errorCorrectionLevel]="'M'"
        [margin]="1"
        [scale]="1"
        [width]="254"
        [title]="'Qr-code'"
      ></qrcode>

      <label class="address-label">
        <h2>
          <span [innerHTML]="getAddressViaNetwork()"> </span>
          <i
            class="ph ph-info i-owe-tooltip no-arrow clickable"
            #tooltip="matTooltip"
            (click)="!isMobile || tooltip.toggle()"
            matTooltip="{{ getWrongNetworkInforming() }}"
            matTooltipPosition="below"
          ></i>
        </h2>
        {{ walletAddress }}
      </label>

      <label class="wallet-info">
        {{ getWalletExchange(true) }}
      </label>

      <div class="modal-footer side-buttons-footer">
        <button (click)="qrModal = false">{{ 'done' | translate }}</button>
      </div>
    </div>
  </div>

  <div class="confirmation-modal-backdrop modal-backdrop" *ngIf="moreDetailsModal">
    <div class="modal-body more-info-modal">
      <div class="modal-header">
        <i class="ph-bold ph-x close clickable" (click)="moreDetailsModal = false"></i>
      </div>
      <label [innerHTML]="moreDetailsModalContent"> </label>
    </div>
  </div>

  <div class="confirmation-modal-backdrop modal-backdrop" *ngIf="showConfirmationModal">
    <div class="modal confirmation-modal" *ngIf="showConfirmationModal">
      <div class="modal-header">
        <h3 class="payment-tittle">{{ 'holdOn' | translate }}</h3>
        <h3 class="close" (click)="showConfirmationModal = false">X</h3>
        <br />
      </div>
      <div class="modal-body">
        <label>
          {{ 'areYouSureYouDontWantToReportThisPayment' | translate }}
        </label>
      </div>
      <div class="modal-footer side-buttons-footer">
        <button class="no-report" (click)="showConfirmationModal = false; alreadyFinished = true; closeModal()">
          {{ 'PAYMENT_REPORT.iDontT' | translate }}
        </button>
        <button (click)="showConfirmationModal = false" [ngClass]="{ 'pt-continue': selectedLanguage == 'pt-br' }">
          {{ 'continue' | translate }}
        </button>
      </div>
    </div>
  </div>

  <app-nicky-footer *ngIf="isMobile"></app-nicky-footer>
</div>
