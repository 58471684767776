import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { systemLanguages } from "src/environments/environment";

@Injectable()
export default class FormatCurrency {
  public selectedLanguage:string = this.checkDefaultLanguage();

  constructor(public toastr:ToastrService){}
  
  checkDefaultLanguage(){
    const browserLanguage = navigator.language.toLowerCase()
    const defaultLanguage = localStorage.getItem('defaultLang')
    const hasTransaltions = systemLanguages.filter(idiom => idiom.language === browserLanguage)
    if(defaultLanguage){
      return defaultLanguage;
    } else if(hasTransaltions.length > 0){
      return browserLanguage;
    } else {
      return 'en'
    }
  }

  public commaDecimalCountries = [
    'af-ZA', 
    'sq-AL', 
    'de-DE', 
    'ca-AD', 
    'es-AR', 
    'hy-AM', 
    'de-AT', 
    'az-AZ', 
    'nl-BE',
    'be-BY',
    'es-BO', 
    'bs-BA',
    'pt-BR', 
    'bg-BG', 
    'fr-CM', 
    'fr-CA', 
    'kk-KZ', 
    'es-CL', 
    'el-CY', 
    'es-CO', 
    'es-CR', 
    'hr-HR', 
    'es-CU', 
    'da-DK', 
    'es-EC', 
    'sk-SK', 
    'sl-SI', 
    'es-ES', 
    'et-EE', 
    'fo-FO', 
    'fi-FI', 
    "fr-FR", 
    "el-GR", 
    "nl-NL", 
    "es-HN", 
    "hu-HU", 
    "en-IE", 
    "id-ID", 
    "is-IS", 
    "it-IT", 
    "lv-LV", 
    "lt-LT", 
    "lb-LU",
    "pt-MO", 
    "mk-MK",
    "ro-MD", 
    "es-NI", 
    "nb-NO", 
    "es-PA", 
    "es-PY",
    "pl-PL", 
    "pt-PT", 
    "cs-CZ", 
    "es-DO", 
    "ro-RO", 
    "ru-RU",
    "sr-RS",
    "sv-SE",
    "de-CH",
    "fr-CH",
    "tn-TN",
    "tr-TR",
    "uk-UA",
    "es-UY",
    "es-VE",
    "vi-VN"
  ]

  public convertToLocal(value: string, decimalPlacesForNacionalCurrency?:number): string {
      if(value == "NaN"){
        return "Can't get quote";
      }
      let assetmaximumFractionDigits;
      let assetMinimumFractionDigits = 2;
      if (!value && Number.isNaN(value) ) {
        return "N/A";
      }
      if(!decimalPlacesForNacionalCurrency){
          assetmaximumFractionDigits = 6;
      }
      const options = {
        minimumFractionDigits:  assetMinimumFractionDigits,
        maximumFractionDigits: decimalPlacesForNacionalCurrency ? decimalPlacesForNacionalCurrency : assetmaximumFractionDigits ,
      };
      const numberValue = parseFloat(value);
      const formattedValue = numberValue.toLocaleString(
        this.selectedLanguage,
        options
      );
      return formattedValue;
    }

    public isCommaDecimal(language:any){
      if(this.commaDecimalCountries.includes(language)){
        return true;
      }
      return false;
    }


  public testDecimalOperator(value:string){

    let separator = this.commaDecimalCountries.includes(navigator.language) ? '","' : "."  
    let moreThanOneSeparator = /([.,]).*?\1/
    if(this.commaDecimalCountries.includes(navigator.language)){
      value = value.replace(/\./g, '')
    } else {
      value = value.replace(/\,/g, '')
    }
    if (value.indexOf(',') !== -1 && value.indexOf('.') !== -1) {
      this.toastr.error('Decimal separator for ' + navigator.language.toUpperCase() + ' is ' + separator )
      return undefined; 
    }
    if (moreThanOneSeparator.test(value)) {
      this.toastr.error(`There are more than one separator in the value.`);
      return undefined;
    }
    if(this.commaDecimalCountries.includes(navigator.language)){
      if(value.indexOf('.') !== -1){
        this.toastr.error(`Your language decimal separator is ","`,'',{
          timeOut:5000
        })
        return false;
      }
      return true;
    } else {
      if(value.indexOf(',') !== -1){
          this.toastr.error(`Your language decimal separator is "."`,'',{
            timeOut:5000
          })
        return false;
      }
    }
    return true;
  }
}