import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root'
})
export class OverviewResolver implements Resolve<any> {
  JWTToken: any;


  constructor(
    public auth: AuthService,
    private http: HttpClient) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.auth.isAuthenticated$.pipe(
      switchMap((isAuthenticated: boolean) => {
        if (isAuthenticated) {
          return this.auth.getAccessTokenSilently({
            authorizationParams: {
              audience: environment.auth0Audience,
            },
          });
        } else {
          return of(null);
        }
      }),
      switchMap((token: string | null) => {
        if (token) {
          this.JWTToken = token;
        }
        
        const shortid = localStorage.getItem('reservedShortid');

        if (shortid) {
          const headers = new HttpHeaders({
            'Authorization': `Bearer ${this.JWTToken}`,
            'Content-Type':'application/json'
          });

          
          return this.http.post(`${environment.api}ReservedShortId/claim`, '"'+shortid+'"', { headers })
            .pipe(
              tap(() => {
                console.log('shortid claimed');
                localStorage.removeItem('reservedShortid');
              }),
              catchError((error: any) => {
                console.log('Error claiming shortid:', error);
                return of(null);
              })
            );
        } else {
          return of(null);
        }
      }),
      catchError((e: any) => {
        console.log(e);
        return of(null);
      })
    );
  }
} 