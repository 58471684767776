import { Component, inject, Input, ViewChild, AfterViewInit, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ConnectedPosition,
  Overlay
} from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';
import { debounceTime, fromEvent, tap, filter} from 'rxjs';



@Component({
  selector: 'app-dropdown',
  standalone: true, // Standalone component
  imports: [CommonModule, CdkConnectedOverlay, CdkOverlayOrigin, MatIconModule], // Import necessary modules
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent implements AfterViewInit {
  private overlay = inject(Overlay);
  private destroyRef = inject(DestroyRef);
  private readonly resizer = fromEvent(window, 'resize').pipe(
    debounceTime(400),
    takeUntilDestroyed(this.destroyRef),
    filter(() => this.dropdownOpen),
    tap(() => this.close())
  );

  @Input() label: string = '';
  @Input() responsiveLabel: boolean = false;
  @Input() disabled: boolean = false;
  @Input() dropdownEnd: boolean = true;
  @Input() dropdownPosition: 'top' | 'bottom' = 'top';

  @ViewChild('trigger') trigger!: CdkOverlayOrigin;

  dropdownOpen = false;

  positionStrategy!: any;

  positions: ConnectedPosition[] = this.dropdownPosition === 'top' ? [
    // Top positions (showing above trigger)
    {
      originX: this.dropdownEnd ? 'end' : 'start',
      originY: 'top',
      overlayX: this.dropdownEnd ? 'end' : 'start',
      overlayY: 'bottom',
    },
    // Fallback to bottom if no space above
    {
      originX: this.dropdownEnd ? 'end' : 'start',
      originY: 'bottom',
      overlayX: this.dropdownEnd ? 'end' : 'start',
      overlayY: 'top',
    }
  ] : [
    // Bottom positions (showing below trigger)
    {
      originX: this.dropdownEnd ? 'end' : 'start',
      originY: 'bottom',
      overlayX: this.dropdownEnd ? 'end' : 'start',
      overlayY: 'top',
    },
    // Fallback to top if no space below
    {
      originX: this.dropdownEnd ? 'end' : 'start',
      originY: 'top',
      overlayX: this.dropdownEnd ? 'end' : 'start',
      overlayY: 'bottom',
    }
  ];

  ngAfterViewInit() {
    this.resizer.subscribe(_ => console.log('resized'));
    
    this.positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.trigger.elementRef.nativeElement)
      .withPositions(this.positions);
  }

  close() {
    this.dropdownOpen = false;
  }

  toggle() {
    if (!this.disabled) {
      this.dropdownOpen = !this.dropdownOpen;
    }
  }
}
