import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DescriptionInputComponent } from 'src/app/components/form/description-input/description-input.component';
import { UserProfilePictureComponent } from 'src/app/components/user-profile-picture/user-profile-picture.component';


@Component({
  selector: 'app-overview-step',
  standalone: true,
  imports: [MatIconModule, UserProfilePictureComponent, TranslateModule, DescriptionInputComponent],
  templateUrl: './overview-step.component.html',
})
export class OverviewStepComponent {

}
