<div class="max-w-2xl mx-auto p-6 space-y-6">
  <!-- I owe section -->
  <div class="bg-gray-50 rounded-xl p-4">
    <div class="flex items-center gap-2 mb-4">
      <span class="text-lg font-medium">I owe</span>
      <button class="text-gray-400 hover:text-gray-600">
        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </button>
    </div>
    <div class="flex items-center gap-3">
      <div class="flex items-center gap-2">
        <mat-icon [svgIcon]="'BTC'"></mat-icon>
        <span class="text-gray-500">BTC</span>
      </div>
      <span class="text-xl">1,111.111111</span>
    </div>
  </div>

  <!-- So I am sending section -->
  <div class="bg-gray-50 rounded-xl p-4">
    <div class="flex items-center gap-2 mb-4">
      <span class="text-lg font-medium italic">so I am sending</span>
      <button class="text-gray-400 hover:text-gray-600">
        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </button>
    </div>
    <div class="flex items-center gap-3">
      <app-crypto-select></app-crypto-select>
      <span class="text-xl">168,768,110.15</span>
    </div>
  </div>

  <!-- Current rate section -->
  <div class="space-y-2">
    <div class="flex items-center gap-2">
      <span class="text-lg font-medium">Current rate</span>
      <button class="text-gray-400 hover:text-gray-600">
        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </button>
    </div>
    <div class="text-gray-500">
      BTC 1 = POL 151,891.30
      <button class="ml-2 text-blue-600 hover:text-blue-700 font-medium">FLIP</button>
    </div>
  </div>

  <!-- Refresh timer -->
  <div class="bg-gray-50 rounded-lg p-4">
    <div class="flex justify-between items-center">
      <span class="font-medium">Refreshing in</span>
      <span class="font-medium">00:07</span>
    </div>
    <div class="mt-2 bg-gray-200 rounded-full h-2">
      <div class="bg-green-500 h-2 rounded-full w-3/4"></div>
    </div>
  </div>
</div>
