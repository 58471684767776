<!-- Background wrapper -->
<div class="h-full bg-gray-200 relative overflow-hidden">
  <!-- Gradient spots -->
  <div class="absolute w-[27.428rem] h-[21.272rem] left-[4rem] top-[23rem] rotate-[140.09deg]">
    <div class="w-full h-full bg-[#DEFF96]/[0.14] blur-[8.675rem] rounded-full"></div>
  </div>
  <div class="absolute w-[19.313rem] h-[26.188rem] left-[50rem] top-[4rem] rotate-[37.24deg]">
    <div class="w-full h-full bg-[#EBDAFF]/[0.29] blur-[8.675rem] rounded-full"></div>
  </div>

  <!-- Top Banner -->
   @if (!authStore.authenticated()) {
    <div class="relative bg-gray-500 text-white px-4 py-2 flex justify-center items-center z-50 rounded-xl mx-2 my-1">
      <span class="text-sm"
        >This is a banner message
        <a routerLink="/register" class="text-sm cursor-pointer text-white hover:underline font-medium">SIGN-UP</a></span
      >
    </div>
   }

  <!-- Content wrapper -->
  <div class="relative h-full flex flex-col items-center justify-center px-4 z-50">
    <!-- Profile Card -->
    <div class="max-w-xl w-full p-6 bg-white rounded-3xl shadow-sm">
      <!-- Profile Header -->
      <div class="flex items-center bg-purple-100 border-solid border-2 border-purple-300 p-4 gap-4 mb-6 rounded-xl relative">
        <div class="min-15 min-h14 w-15 h-14">
          <app-user-profile-picture
            [imageUrl]="profileInfo?.avatar"
            [name]="profileInfo?.name || profileInfo?.publicName"
            [size]="'lg'"
          />
        </div>
        <div>
          <h1 class="text-2xl text-bg-main font-bold">{{ profileInfo?.name }}</h1>
          <div class="flex gap-2 items-center">
            <i class="before:text-2xl text-text-secondary ph-fill ph-envelope"></i>
            <i class="before:text-2xl text-text-secondary ph ph-globe-simple"></i>
          </div>
        </div>
        <div class="ml-auto flex items-center gap-2">
          <span class="text-xs text-gray-600 absolute top-2 right-2">{{ profileInfo?.shortId }}</span>
          <button (click)="openShareModal()" class="flex items-center py-2 px-3 hover:bg-gray-100 rounded-lg bg-white border-2 border-bg-gray-400 text-sm">
            <i class="ph ph-share-network text-gray-600 text-lg"></i>
          </button>
          @if (authStore.authenticated()) {
            @if(!alreadyFavorite) {
              <button class="flex items-center secondary-button gap-2 py-2 px-2 hover:bg-gray-100 rounded-lg bg-white border-2 border-bg-gray-400 text-sm" (click)="addFavorite()">
                <i class="ph ph-plus text-sm"></i>
                {{ 'public_profile.connect' | translate }}
              </button>
            }

            @if(alreadyFavorite) {
              <div class="flex items-center text-bg-green font-bold items-center secondary-button gap-2 py-2 px-2 hover:bg-gray-100 rounded-lg bg-white border-2 border-bg-gray-400 text-sm cursor-pointer"
              (click)="deleteFavorite(profileInfo.email)" >
              <div class="toggle-icons flex items-center">
                <i class="ph-fill ph-check-circle text-lg"></i>
              </div>
              <h2> {{ 'public_profile.connected'  | translate }} </h2>
            </div>
            }
          }
        </div>
      </div>

      <!-- Pay Button -->
      <button (click)="openPaymentFlow()" class="!w-full flex-grow bg-black text-white py-3 rounded-xl mb-6 font-medium hover:bg-gray-800">
        {{ 'public-profile.pay' | translate }}
      </button>

      <!-- Payment Methods -->
      <div class="mb-6 bg-bg-gray p-3 pb-2 rounded-xl relative">
        <h2 class="text-gray-500 text-xs font-semibold mb-3 uppercase absolute -top-2 left-4">{{ 'payWith' | translate }}</h2>
        <div class="flex gap-2">
          @for (asset of userAcceptedAssets; track asset.id) {
            <div class="w-8 h-8 rounded-ful flex items-center justify-center">
              <mat-icon [svgIcon]="asset.assetTicker" class="w-8 h-8"></mat-icon>
            </div>
          }
        </div>
      </div>

      <!-- Description -->
      <div class="text-gray-600 text-sm whitespace-pre">
        {{ profileInfo?.bio }}
      </div>
    </div>

    <!-- Footer -->
    <div class="max-w-xl w-full mt-4 flex justify-end gap-4">
      <a
        [href]="privacyPolicyUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="text-sm text-gray-500 hover:underline"
        >Privacy</a
      >
      <a [href]="termsOfUseUrl" target="_blank" rel="noopener noreferrer" class="text-sm text-gray-500 hover:underline"
        >Terms</a
      >
    </div>
  </div>
</div>
