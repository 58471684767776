<form
  [formGroup]="form"
  class="text-left max-w-md mx-auto p-6 space-y-6 min-h-[calc(100vh-150px)] flex flex-col justify-center"
>
  <h1 class="text-style-sheet-title">
    {{ 'LOGIN.forgotYourPassword' | translate }}
  </h1>

  <h3 class="text-style-sheet-subtitle">
    {{ 'LOGIN.enterYourEmailNickAndWeWill' | translate }}
  </h3>

  <div class="space-y-4">
    <input
      formControlName="nick"
      type="text"
      class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-primary"
      placeholder="Email or username"
    />

    <div class="flex justify-center">
      <button class="text-style-button-primary full-width" (click)="forgotPassword(form.controls['nick'].value)">
        <!-- {{ 'continue' | translate }} -->
        continue
      </button>
    </div>
  </div>
</form>
