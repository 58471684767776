<div class="space-y-2 mt-5 text-left">
  <label class="block text-sm font-medium text-gray-700">{{ label }}</label>
  <div class="flex space-x-4">
    @for (option of options; track option.value) {
      <button
        type="button"
        [class]="
          control?.value === option.value
            ? 'flex-1 p-4 bg-green-50 border-2 border-green-500 rounded-lg flex items-center gap-3 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent relative'
            : 'flex-1 p-4 bg-white border border-gray-200 rounded-lg flex items-center gap-3 hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent relative'
        "
        (click)="control?.setValue(option.value)"
        [attr.aria-pressed]="control?.value === option.value"
        [attr.aria-label]="option.label"
      >
        <div class="flex flex-col items-left gap-2">
          <div
            class="w-10 h-10 bg-gray-100 border-solid border-2 border-gray-200 rounded-xl flex items-center justify-center"
          >
            <i class="ph ph-{{ option.icon }} text-xl"></i>
          </div>
          <span class="text-gray-900 mt-5 font-medium">{{ option.label }}</span>
        </div>
        @if (control?.value === option.value) {
          <div class="absolute right-4 top-4">
            <div class="w-6 h-6 bg-green-600 rounded-full flex items-center justify-center">
              <i class="ph ph-check text-white text-sm"></i>
            </div>
          </div>
        }
      </button>
    }
  </div>
  @if (control?.touched && control?.invalid) {
    <div class="text-red-500 text-sm">{{ errorMessage }}</div>
  }
</div>
