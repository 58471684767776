<div #searchContainer class="search-container">
  <!-- your existing search bar content -->
  <!-- Search Bar -->
  <div class="relative w-full max-w-md">
    <!-- Search Icon -->
    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
      <i class="ph ph-magnifying-glass text-gray-400"></i>
    </div>

    <!-- Search Input -->
    <input
      type="text"
      class="w-full pl-10 pr-4 py-2 bg-gray-50 border border-gray-300 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent placeholder-gray-400"
      placeholder="{{ 'searchNick' | translate }}"
      (input)="onInputChange($event)"
      #searchInput
    />

    <!-- Search Results Dropdown -->
    @if (showResults && (searchResults?.length > 0 || searchTerm)) {
      <div
        class="absolute mt-1 w-full bg-white rounded-lg shadow-lg border border-gray-200 max-h-60 overflow-y-auto z-50"
      >
        @if (searchResults?.length === 0) {
          <div class="px-4 py-3 text-sm text-gray-500">
            {{ 'weDidntFindAnyResultForYourSearch' | translate }}
          </div>
        } @else {
          @for (result of searchResults; track result.shortId) {
            <div
              class="hover:bg-gray-50 px-4 py-2 cursor-pointer flex items-center gap-3"
              (click)="goToProfile(result.shortId)"
            >
              <!-- <img [src]="result.profilePicture" class="w-8 h-8 rounded-full object-cover" alt="Profile picture" /> -->

              <div class="flex-1">
                <div class="text-sm font-medium">{{ result.publicName }}</div>
                <div class="text-xs text-gray-500">{{ result.nick }}</div>
              </div>
              @if (result.domains?.length > 0) {
                <mat-icon class="text-green-500 w-5 h-5" svgIcon="checked-user"> </mat-icon>
              }
            </div>
          }
        }
      </div>
    }
  </div>
</div>
