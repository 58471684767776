<div class="confirm-registration">
  <div class="register">
    <div class="header-content">
      <picture>
        <source srcset="assets/nicky-logo.svg" type="image/svg+xml" />
        <img class="logo clickable" src="/assets/nicky-logo.png" alt="logo" (click)="goHome()" />
      </picture>
      <a> Login ></a>
    </div>

    <div class="confirm-content">
      <label>
        We have sent a link to confirm your registration to your email. You can click directly on the link or enter the
        code in the field below.
      </label>
      <div class="input-wrapper">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <input
            #firstInputNumber
            formControlName="firstInputNumber"
            type="text"
            name="firstNumber"
            (keyup)="moveToNext(form.controls['firstInputNumber'], secondInputNumber)"
          />
          <input
            #secondInputNumber
            formControlName="secondInputNumber"
            type="text"
            name="secondNumber"
            (keyup)="moveToNext(form.controls['secondInputNumber'], thirdInputNumber)"
          />
          <input
            #thirdInputNumber
            formControlName="thirdInputNumber"
            type="text"
            name="thirdNumber"
            (keyup)="moveToNext(form.controls['thirdInputNumber'], fourthInputNumber)"
          />
          <input
            #fourthInputNumber
            formControlName="fourthInputNumber"
            type="text"
            name="fourthNumber"
            (keyup)="moveToNext(form.controls['fourthInputNumber'], fifthInputNumber)"
          />
          <input
            #fifthInputNumber
            formControlName="fifthInputNumber"
            type="text"
            name="fifthNumber"
            (keyup)="moveToNext(form.controls['fifthInputNumber'], sixthInputNumber)"
          />
          <input
            #sixthInputNumber
            formControlName="sixthInputNumber"
            type="text"
            name="sixthNumber"
            (keyup)="moveToNext(form.controls['sixthInputNumber'])"
          />
        </form>
      </div>
      <button routerLink="/register" class="cancel-button">Cancel</button>
    </div>
  </div>
</div>
