<div
  class="min-h-screen flex flex-col"
  [class.h-screen]="fullWidthMode$ | async"
  [class.bg-white]="fullWidthMode$ | async"
>
  @if( isAuthenticated$ | async) {
    <app-header-public [fullWidthMode]="(fullWidthMode$ | async) ?? false"></app-header-public>
  } @else {
    <app-header-private [fullWidthMode]="(fullWidthMode$ | async) ?? false"></app-header-private>
  }

  <main [class.flex-grow]="!(fullWidthMode$ | async)" [class.h-full]="fullWidthMode$ | async">
    <router-outlet></router-outlet>
  </main>

  @if (!(fullWidthMode$ | async)) {
    <app-nicky-footer></app-nicky-footer>
  }
</div>
