<app-modal
  [modalTittle]="modalTittle"
  [modalMessage]="modalMessage"
  [dashBoardOptions]="dashBoardOptions"
  [fullEmailModal]="fullEmailModal"
  [modalPublicName]="modalPublicName"
  [modalNick]="modalNick"
  (onCloseModal)="closeModal($event)"
  (onClickFavorite)="addFavorite($event)"
  *ngIf="showModal"
></app-modal>

<app-payment-requests
  *ngIf="newPaymentRequest"
  [targetUser]="targetPaymentRequestUser"
  [isAuthenticated]="authenticated"
  [paymentModal]="paymentFlow"
  [assets]="assets"
  (onCloseModal)="closeModal($event)"
  [userId]="userId"
  [user]="user"
  [targetNick]="targetNick"
></app-payment-requests>

<app-payment
  [isAuthenticated]="authenticated"
  [paymentModal]="paymentFlow"
  (onCloseModal)="closeModal($event)"
  *ngIf="paymentFlow"
  [user]="user"
  [targetUser]="target"
  [assets]="assets"
></app-payment>

<!-- <app-notification *ngIf="loaded" [JWTToken]="JWTToken"></app-notification> -->

<app-public-profile
  [target]="target"
  [targetDomains]="targetDomains"
  [selectedLanguage]="selectedLanguage"
  [isAuthenticated]="authenticated"
  [userId]="userId"
  [targetNick]="targetNick"
  [targetCompany]="targetCompany"
  [companyWebsite]="companyWebsite"
  [user]="user"
  *ngIf="userProfile"
  [assets]="assets"
  (onCloseModal)="closeModal($event)"
></app-public-profile>

<app-header
  (closeProfile)="closeModal($event)"
  (userSelectedLanguage)="selectedLanguage = $event"
  [assets]="assets"
></app-header>

<div class="dashboard" (click)="toggleMenu()">
  <!-- <app-searchbar class="only-mobile" [myNick]="myNick" [JWTToken]="JWTToken"  [authenticated]="authenticated" (openNewPayment)="openPayment($event)"></app-searchbar> -->

  <div class="dashboard-wrapper table-wrapper common-border">
    <h3>
      {{ 'contacts' | translate }}
    </h3>
    <div class="secondary-button export-button absolute-button" (click)="exportTable()">
      {{ 'export' | translate }}
    </div>
    <div class="searchbar">
      <app-searchbar
        (favoriteSearchList)="setFavoriteSearchList($event)"
        [myNick]="myNick"
        [authenticated]="authenticated"
        (openNewPayment)="openPayment($event)"
        [favoritesSearch]="true"
      ></app-searchbar>
    </div>

    <div class="table-limit" [ngStyle]="{ 'min-height': undefined == 0 ? 'unset' : '' }">
      <!-- contacts table -->

      <table id="contacts-table">
        <tr class="only-desktop">
          <!-- <th class="asset-header">
            <input type="checkbox" name="transaction checkbox">
          </th> -->
          <th>{{ 'Nick' | translate }}</th>
          <th>{{ 'Name' | translate }}</th>
          <th>{{ 'publicName' | translate }}</th>
          <th>{{ 'accepts' | translate }}</th>
          <th class="actions-th">{{ 'Actions' | translate }}</th>
        </tr>

        <tr
          class="hide-portrait"
          *ngFor="
            let favorite of favoritesList
              | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: favoritesList?.length }
          "
        >
          <!-- <td class="date-icon">
            <input type="checkbox" name="transaction checkbox">
          </td> -->
          <td>
            <label class="only-mobile">{{ 'Nick' | translate }}</label>
            <div class="payer-info">
              <div class="bottom-content">
                <div class="sender-info" (click)="openProfile(favorite)">
                  @if (favorite.hasProfilePicture) {
                    <img
                      class="company-logo"
                      [src]="environment.api + 'User/get-public-profile-picture?userId=' + favorite.userId"
                      [matTooltipPosition]="'above'"
                      matTooltip="{{ favorite.publicName || favorite.name }}"
                      (error)="handleImageError($event, favorite.publicName || favorite.name)"
                    />
                  } @else {
                    <img
                      class="company-logo"
                      [src]="this.avatarService.getInitialAvatar(favorite.publicName || favorite.name)"
                      alt="favorite-img"
                    />
                  }

                  <div class="sender-info-labels">
                    <label class="receiver-dashboard overflow-label margin-bottom-label">
                      {{ favorite.publicName || favorite.name }}
                    </label>
                    {{ isMobile ? '\n' : '' }}
                    <label
                      class="receiver-dashboard secondary-text overflow-label"
                      (click)="isMobile ? showEmailModal(favorite.nick) : ''"
                      [matTooltipPosition]="'above'"
                      matTooltip="{{ favorite.nick }}"
                    >
                      {{ favorite.nick }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td>
            {{ favorite.name }}
          </td>
          <td>
            <a class="public-name-link clickable" (click)="openProfile(favorite)">{{ favorite.publicName }}</a>
          </td>
          <td class="doesnt-accept-column">
            @if (isContactAssetsChecked(favorite.userId)) {
              @if (favorite?.acceptedAssets && favorite?.acceptedAssets?.length > 0) {
                <div class="accepted-assets-wrapper">
                  @for (asset of favorite.acceptedAssets; track $index) {
                    <mat-icon
                      class="accepted-asset"
                      [matTooltip]="asset"
                      [matTooltipPosition]="'above'"
                      [svgIcon]="asset"
                    ></mat-icon>

                    <span style="font-size: 0px">{{ ' ' + asset }}&nbsp </span>
                  }
                </div>
              } @else {
                <label class="secondary-text">
                  {{ 'thisUserIsNotCurrentlyAcceptingPayments' | translate }}
                </label>
              }
            }
          </td>
          <td>
            <div class="flex-centered flex-end">
              <button
                class="secondary-button"
                [ngClass]="{ disabled: favorite?.acceptedAssets?.length <= 0 }"
                (click)="openPayment(favorite)"
              >
                <i class="ph ph-share-fat"></i>
                {{ 'transact' | translate }}
              </button>
              <i
                class="button ph ph-invoice clickable"
                #tooltip="matTooltip"
                [matTooltipPosition]="'above'"
                [matTooltip]="'createAPaymentRequestForThisUser' | translate"
                (click)="createNamedPaymentRequest(favorite); !isMobile || tooltip.toggle()"
              ></i>
              <i class="button clickable ph ph-trash" (click)="deleteNick(favorite.nick)"></i>
            </div>
          </td>
        </tr>

        <!-- here i'm doing the new mobile version -->
        <tr
          class="only-mobile"
          *ngFor="
            let favorite of favoritesList
              | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: favoritesList?.length }
          "
        >
          <div class="info-container">
            <div class="payer-info">
              <div class="bottom-content">
                <i
                  class="button ph ph-invoice mobile-invoice clickable"
                  #tooltip="matTooltip"
                  [matTooltipPosition]="'above'"
                  [matTooltip]="'createAPaymentRequestForThisUser' | translate"
                  (click)="createNamedPaymentRequest(favorite); !isMobile || tooltip.toggle()"
                ></i>
                <i class="button clickable mobile-trash ph ph-trash" (click)="deleteNick(favorite.nick)"></i>
                <div class="sender-info">
                  @if (favorite.hasProfilePicture) {
                    <img
                      class="company-logo"
                      [src]="environment.api + 'User/get-public-profile-picture?userId=' + favorite.userId"
                      [matTooltipPosition]="'above'"
                      matTooltip="{{ favorite.publicName || favorite.name }}"
                      (error)="handleImageError($event, favorite.publicName || favorite.name)"
                    />
                  } @else {
                    <img
                      class="company-logo"
                      [src]="this.avatarService.getInitialAvatar(favorite.publicName || favorite.name)"
                      alt="favorite-img"
                    />
                  }
                  <div class="sender-info-labels">
                    <label class="receiver-dashboard overflow-label margin-bottom-label">
                      {{ favorite.publicName || favorite.name }}
                    </label>
                    <label
                      class="receiver-dashboard secondary-text overflow-label"
                      (click)="isMobile ? showEmailModal(favorite.email) : ''"
                      [matTooltipPosition]="'above'"
                      matTooltip="{{ favorite.email }}"
                    >
                      {{ favorite.email }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="additional-info">
              <div class="property-wrapper">
                <label class="property-name secondary-text">
                  {{ 'Name' | translate }}
                </label>
                <label class="property-value">
                  {{ favorite.name }}
                </label>
              </div>
            </div>
            <div class="additional-info">
              <div class="property-wrapper">
                <label class="property-name secondary-text">
                  {{ 'publicName' | translate }}
                </label>
                <label class="property-value">
                  <a class="public-name-link clickable" (click)="openPayment(favorite)">{{ favorite.publicName }}</a>
                </label>
              </div>
            </div>
            <div class="additional-info">
              @if (favorite?.acceptedAssets?.length > 0) {
                <div class="property-wrapper">
                  <label class="property-name secondary-text">
                    {{ 'accepts' | translate }}
                  </label>
                  <label class="property-value">
                    <!-- {{ getAcceptedAssets(favorite) }} -->

                    <div *ngIf="favorite?.acceptedAssets?.length > 0" class="accepted-assets-wrapper">
                      @for (asset of favorite.acceptedAssets; track $index) {
                        <mat-icon
                          class="accepted-asset"
                          [matTooltip]="asset"
                          [matTooltipPosition]="'above'"
                          [svgIcon]="asset"
                        ></mat-icon>

                        <span style="display: none">{{ asset }} </span>
                      }
                    </div>
                  </label>
                </div>
              } @else {
                <label class="secondary-text">
                  {{ 'thisUserIsNotCurrentlyAcceptingPayments' | translate }}
                </label>
              }
            </div>
            @if (favorite?.acceptedAssets?.length > 0) {
              <button class="secondary-button mobile-secondary-button" (click)="openPayment(favorite)">
                <i class="ph ph-share-fat"></i>
                {{ 'transact' | translate }}
              </button>
            }
          </div>
        </tr>
      </table>
      <!-- end of payment reports table -->
    </div>
    <div class="no-transactions no-contacts" *ngIf="favoritesList?.length == 0">
      <mat-icon svgIcon="no-contacts" />
      <div class="description-wrapper">
        {{ 'noFavoritesToBeShown' | translate }}
        <label *ngIf="!noSearchResult">{{ 'DASHBOARD.startBySearchingAnEmail' | translate }}</label>
        <label *ngIf="noSearchResult">{{ 'weDidntFindAnyResultForYourSearch' | translate }}</label>
      </div>
    </div>

    <div class="pagination-wrapper" *ngIf="!(favoritesList?.length == 0)">
      <div class="items-per-page-wrapper">
        <label class="pagination-legend"> {{ showingNicks() }} of {{ favoritesList?.length }} </label>
      </div>

      <div class="flex-centered">
        <label class="pagination-legend">{{ 'rowsPerPage' | translate }}:</label>
        <select
          class="items-per-page"
          [(ngModel)]="itemsPerPage"
          (ngModelChange)="itemsPerPage = $event"
          (ngModelChange)="itemsPerPage = $event"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
        </select>
        <div class="pagination-controls flex-centered">
          <a class="previous-page clickable" (click)="previousPage()">
            <i class="ph-bold ph-caret-left"></i>
          </a>

          <label class="pagination-legend">
            <span class="current-page">{{ currentPage }} </span> / {{ getPagesNumber(favoritesList?.length) }}
          </label>

          <a class="next-page clickable" (click)="nextPage(favoritesList?.length)">
            <i class="ph-bold ph-caret-right"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-nicky-footer></app-nicky-footer>
