import { CommonModule } from '@angular/common';
import { Component, Input, ContentChild, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error-state',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './error-state.component.html',
})
export class ErrorStateComponent implements OnInit {
  private route = inject(ActivatedRoute);

  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() hasCustomButtons: boolean = false;

  @ContentChild('errorButtons') errorButtons: any;

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['reason'] === 'profile-not-found') {
        this.title = 'Profile Not Found';
        this.subtitle = 'The profile you are looking for does not exist or has been removed.';
      }
    });
  }

  goBack(): void {
    window.history.back();
  }
}
