import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from "@ngrx/signals";
import { setAllEntities, withEntities } from '@ngrx/signals/entities';

import { Asset } from "../components/models/asset.model";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { computed, inject } from "@angular/core";
import { PaymentService } from "../services/payment.service";
import { tapResponse } from "@ngrx/operators";
import { pipe, switchMap } from "rxjs";
import { UserService } from "../services/user.service";

type AssetStoreState = {
    loaded: { assets:boolean, userAcceptedAssets: boolean };
    selectedAsset: string | null;
    assets: Asset[];
    userAcceptedAssets: Asset[];
};

const initialState: AssetStoreState = {
  loaded: { assets: false, userAcceptedAssets: false },
  selectedAsset: null,
  assets: [],
  userAcceptedAssets: []
};

export const AssetStore = signalStore(
  {
    providedIn: 'root',
  },
  withEntities<Asset>(),
  withState(initialState),
  withComputed(({ entities, selectedAsset }) => {
    const decimalPlacesFor = computed(() => {
      return entities().reduce((acc, asset) => {
        acc[asset.assetTicker as string] = asset.decimalPrecisionUI;
        return acc;
      }, {} as Record<string, number>);
    });

    const getSelectedAsset = computed(() => {

      const asset = selectedAsset()
      if (!!asset) {
        return entities()[asset as any];
      } else {
        return null;
      }
    });

    return {
      decimalPlacesFor,
      getSelectedAsset
    }
  }),
  withMethods((store, paymentService = inject(PaymentService), userService = inject(UserService)) => {
    const loadAssets = rxMethod<void>(
    pipe(
          switchMap(() => {
            return paymentService.getAllAssets().pipe(
              tapResponse({
                next: assets => {
                   // Sort assets with fiat first, then by ticker
                  const sortedAssets = assets.sort((a, b) => {
                    if (a.isFiat && !b.isFiat) return -1;
                    if (!a.isFiat && b.isFiat) return 1;
                      return a.assetTicker.localeCompare(b.assetTicker);
                    });
                  patchState(store, setAllEntities(sortedAssets));
                  patchState(store, {
                    loaded: { ...store.loaded(), assets: true },
                  });
                },
                error: (error: { message: string }) => {
                  // todo: handle error
                  console.log(error);
                },
              })
            );
          })
        )
    );
    const loadUserAcceptedAssets = rxMethod<void>(
      pipe(
            switchMap(() => {
              return userService.getAcceptedAssets().pipe(
                tapResponse({
                  next: userAcceptedAssets => {
                    patchState(store, { userAcceptedAssets: userAcceptedAssets });
                    patchState(store, {
                      loaded: { ...store.loaded(), userAcceptedAssets: true },
                    });
                  },
                  error: (error: { message: string }) => {
                    // todo: handle error
                    console.log(error);
                  },
                })
              );
            })
          )
      );

    const selectAsset = (assetTicker: string) => {
      patchState(store, { selectedAsset: assetTicker });
    }

    return {
      loadAssets,
      loadUserAcceptedAssets,
      selectAsset
    }
  }),
  withHooks({
    onInit(store) {
      store.loadAssets();
    },
  }),
);
