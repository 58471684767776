import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from '../../../shared/components/wizard/wizard.component';
import { WizardStep } from '../../../shared/store/wizard.store';
import { OverviewStepComponent } from '../components/overview-step/overview-step.component';
import { IdentificationStepComponent } from '../components/identification-step/identification-step.component';
import { PaymentStepComponent } from '../components/payment-step/payment-step.component';
import { ConfirmationStepComponent } from '../components/confirmation-step/confirmation-step.component';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaymentQueryParams } from '../../../shared/models/payment-params.model';
import { PaymentRouteParams } from '../../../shared/models/payment-params.model';

@Component({
  selector: 'app-payment-report-page',
  standalone: true,
  imports: [WizardComponent],
  template: `
    <app-wizard 
      [stepsConfig]="stepsConfig" 
      [isPaymentReport]="true"
      [showCancelButton]="false">
    </app-wizard>
  `
})
export class PaymentReportPageComponent implements OnInit {

  private route = inject(ActivatedRoute);
  private router = inject(Router);

  stepsConfig: WizardStep[] = [
    {
      id: 'overview',
      label: 'Overview',
      isActive: true,
      isCompleted: false,
      order: 1,
      componentType: OverviewStepComponent
    },
    {
      id: 'identification',
      label: 'Identification',
      isActive: false,
      isCompleted: false,
      order: 2,
      componentType: IdentificationStepComponent
    },
    {
      id: 'payment',
      label: 'Payment',
      isActive: false,
      isCompleted: false,
      order: 3,
      componentType: PaymentStepComponent
    },
    {
      id: 'confirmation',
      label: 'Confirmation',
      isActive: false,
      isCompleted: false,
      order: 4,
      componentType: ConfirmationStepComponent
    }
  ];


  ngOnInit() {  
    // Handle both legacy and new URL formats
    combineLatest([
      this.route.params.pipe(
        map(params => params as PaymentRouteParams)
      ),
      this.route.queryParams.pipe(
        map(params => params as PaymentQueryParams)
      )
    ]).subscribe(([params, queryParams]) => {
      const receiverShortId = params['receiverShortId'];
      const paymentId = queryParams['paymentId'];
      
      if (paymentId) {
        // Handle legacy format
        this.router.navigate(['/payment-report', paymentId], {
          queryParamsHandling: 'merge',
          queryParams
        });
        return;
      }

      if (receiverShortId) {
        this.initializeWithShortId(receiverShortId);
      }

      // Initialize wizard with the correct step
      const { step } = queryParams;
      if (step && this.stepsConfig.some(s => s.id === step)) {
        this.stepsConfig = this.stepsConfig.map(s => ({
          ...s,
          isCompleted: this.stepsConfig.findIndex(config => config.id === step) > 
            this.stepsConfig.findIndex(config => config.id === s.id)
        }));
      }
    });
  }

  private initializeWithShortId(shortId: string) {
    // Initialize the wizard with the shortId
    // You can make API calls here to fetch payment details
    console.log('API CALLS HERE');
    console.log('Initializing payment report for:', shortId);
  }
}