import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CryptoSelectComponent } from '../crypto-select/crypto-select.component';

@Component({
  selector: 'app-payment-step',
  standalone: true,
  imports: [MatIconModule, CryptoSelectComponent],
  templateUrl: './payment-step.component.html',
  styleUrl: './payment-step.component.scss'
})
export class PaymentStepComponent {

}
