import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export default class FiatByCountry {

  public BRLCountrys = ['BR'];

  public EURCountrys = [
    'AT',
    'BE',
    'CY',
    'DE',
    'EE',
    'ES',
    'FI',
    'FR',
    'GR',
    'IE',
    'IT',
    'LT',
    'LU',
    'LV',
    'MT',
    'NL',
    'PT',
    'SI',
    'SK',
    'MC',
    'SM',
    'VA',
  ];

  getUserFiatByCountry(countryCode: string) {
    if (this.BRLCountrys.includes(countryCode)) {
      return ['BRL.BRL'];
    }

    if (this.EURCountrys.includes(countryCode)) {
      return ['EUR.EUR'];
    }

    return ['USD.USD'];
  }
}
