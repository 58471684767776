<nav class="bg-white relative border-b border-gray-200 border-solid">
  <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
    <div class="relative flex h-16 items-center justify-between">
      <!-- Logo and Navigation Links -->
      <div class="flex items-center justify-center sm:items-stretch sm:justify-start">
        <!-- Logo -->
        <div class="flex shrink-0 items-center">
          <picture (click)="goHome()" class="w-[102px] h-[42px]">
            <source srcset="assets/nicky-logo.svg" type="image/svg+xml" />
            <img class="h-9 mt-0.5 clickable" src="/assets/nicky-logo.svg" alt="logo" />
          </picture>
        </div>

        <div class="flex items-center divide-x">
          <!-- Route Title -->
          @if (routeTitle) {
            <div class="block my-auto">
              <div class="flex space-x-4">
                <div class="flex-grow border-r border-solid border-gray-300"></div>
                <div class="px-2">
                  <h1 class="font-inter text-lg font-semibold leading-6 tracking-tight text-left text-gray-700">
                    {{ routeTitle }}
                  </h1>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</nav>
