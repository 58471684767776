import { Injectable } from '@angular/core';
import { decimalPlacesFor } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AssetIdFormatterUtil {
  formatAssetId(asset: any) {
    if (asset.blockchainAsset.id === "POL") {
      asset.blockchainAsset.id = "MATIC.POL";
    } else if (asset.blockchainAsset.id === "USDC" || asset.blockchainAsset.id === "USDT") {
      asset.blockchainAsset.id = "ETH." + asset.blockchainAsset.id;
    } else if (!asset.blockchainAsset.id.includes(".")) {
      asset.blockchainAsset.id = asset.blockchainAsset.id + "." + asset.blockchainAsset.id;
    }
    return asset;
  }

  compareAssetIds(assetA: any, assetB: any) {
    const result = assetA.blockchainAsset.id.split('.')[1]?.localeCompare(assetB.blockchainAsset.id.split('.')[1]);
    if (result === 0) {
      return assetA.blockchainAsset.id.split('.')[0]?.localeCompare(assetB.blockchainAsset.id.split('.')[0]);
    }
    return result;
  }

  formatBlockchainAssetId(input: any): string {
    let assetId = typeof input === 'string' ? input : input.blockchainAsset.id;
  
    switch (assetId) {
      case "BTC":
        return "BTC.BTC";
      case "ETH":
        return "ETH.ETH";
      case "USDC":
        return "ETH.USDC";
      case "USDT":
        return "ETH.USDT";
      case "POL":
        return "MATIC.POL";
      default:
        return `${assetId}.${assetId}`;
    }
  }

  getDecimalPlaces(assetId:string){
    return decimalPlacesFor[assetId?.split('.')[1] || 'BTC'];
  }
  
  generatePlaceholderZerosForAsset(assetId:string){
    
    let currentAssetValue = assetId;
    let payingAsset =   this.formatBlockchainAssetId(currentAssetValue || "");
    let amountZero = null;
    if(payingAsset!=""){
      let decimalPlaces = this.getDecimalPlaces(payingAsset);
      amountZero = "0".repeat(decimalPlaces);
    }else{
      amountZero = "0000000";
    }
    return amountZero;
  }

}