<div class="space-y-2 mt-5 text-left">
  @if (label) {
    <label [ngClass]="['block text-sm font-medium text-gray-700', disabled ? 'text-gray-200 cursor-not-allowed' : '']"
      >{{ label }}
      @if (isRequired) {
        <span class="text-red-500">*</span>
      }
    </label>
  }
  <div class="relative">
    <div [class]="inputContainerClass">
      @if (icon && iconPosition === 'left') {
        <i [class]="'ph ph-icon ' + icon + ' absolute left-3 text-gray-400'"></i>
      }
      <input
        [type]="type"
        [disabled]="disabled"
        [placeholder]="placeholder"
        [ngClass]="[
          modifiedInputClass,
          control?.touched && control?.invalid ? 'border-red-500' : 'border-gray-300',
          disabled ? 'bg-gray-100 text-gray-500 cursor-not-allowed' : '',
        ]"
        [formControl]="control"
        (blur)="onTouched()"
        (input)="onInput($event)"
      />
      @if (icon && iconPosition === 'right') {
        <i [class]="'ph ph-icon ' + icon + ' absolute right-3 text-gray-400'"></i>
      }
    </div>
    @if (control?.touched && control?.invalid) {
      <div class="text-red-500 text-sm mt-1">
        {{ errorMessage }}
      </div>
    }
  </div>
</div>
