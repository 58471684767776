import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { decimalPlacesFor, environment } from 'src/environments/environment';
import { createHttpOptions } from '../utils/http-utils';

@Injectable({
  providedIn: 'root'
})
export class DecimalPlacesService {
  constructor(private http: HttpClient) {}

  fetchData(): Promise<any> {
    return lastValueFrom(this.http.post(environment.api + 'BlockchainAsset/all', createHttpOptions));
  }
}

export function fetchDecimalPlaces(decimalConfiguration: DecimalPlacesService): () => Promise<any> {
  return () => decimalConfiguration.fetchData().then(
    (data: any) => {
      if (data && data.length > 0) {
        data.forEach((asset: any) => {
          decimalPlacesFor[asset.assetTicker] = asset.decimalPrecisionUI;
        });
      }
    },
    (error) => {
      console.error('Error fetching decimal places:', error);
    }
  );
}
