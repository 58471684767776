export const TOUR_STEPS = {

    // Overview page main tour
    OVERVIEW: {
      MAIN: {
        OVERVIEW: {
          id: 'overview',
          title: 'Here is the overview!',
          text: 'Here you will find a general view of your information.',
          position: 'bottom'
        },
        BALANCES: {
          id: 'balances',
          title: 'My Balances',
          text: 'Here you can see your balance in each of the assets supported by Nicky.\nZero Balance Assets are not displayed.',
          position: 'bottom'
        },
        ADD_WALLET: {
          id: 'add-wallet',
          title: 'Add Wallet',
          text: 'Click here to add wallets!',
          position: 'bottom'
        }
      },
      // Overview page section-specific tours
      SECTIONS: {
        BALANCE_SECTION: {
          CRYPTO: {
            id: 'crypto-balance',
            title: 'Crypto Balances',
            text: 'View your cryptocurrency balances here.',
            position: 'bottom'
          },
          FIAT: {
            id: 'fiat-balance',
            title: 'Fiat Balances',
            text: 'View your fiat currency balances here.',
            position: 'bottom'
          }
        },
        ACTIVITY_SECTION: {
          RECENT: {
            id: 'recent-activity',
            title: 'Recent Activity',
            text: 'Your latest transactions and activities.',
            position: 'bottom'
          },
          STATS: {
            id: 'activity-stats',
            title: 'Activity Statistics',
            text: 'Overview of your transaction patterns.',
            position: 'bottom'
          }
        }
      }
    },

    // Public Profile page tours
    PUBLIC_PROFILE: {
      MAIN: {
        PROFILE_INFO: {
          id: 'profile-info',
          title: 'Profile Information',
          text: 'View basic profile information here.',
          position: 'bottom'
        },
        CONTACT_OPTIONS: {
          id: 'contact-options',
          title: 'Contact Options',
          text: 'Different ways to connect with this profile.',
          position: 'bottom'
        }
      },
      SECTIONS: {
        PAYMENT_SECTION: {
          PAYMENT_METHODS: {
            id: 'payment-methods',
            title: 'Payment Methods',
            text: 'Available payment methods for this profile.',
            position: 'bottom'
          },
          QUICK_PAY: {
            id: 'quick-pay',
            title: 'Quick Pay',
            text: 'Quickly send payments here.',
            position: 'bottom'
          }
        },
        PROFILE_DETAILS: {
          BIO: {
            id: 'profile-bio',
            title: 'Profile Bio',
            text: 'Learn more about this profile.',
            position: 'bottom'
          },
          SOCIAL: {
            id: 'social-links',
            title: 'Social Links',
            text: 'Connect on other platforms.',
            position: 'bottom'
          }
        }
      }
    },

    // Payment Report page tours
    PAYMENT_REPORT: {
      MAIN: {
        OVERVIEW: {
          id: 'report-overview',
          title: 'Report Overview',
          text: 'Summary of your payment report.',
          position: 'bottom'
        },
        TOTALS: {
          id: 'report-totals',
          title: 'Payment Totals',
          text: 'View total amounts and statistics.',
          position: 'bottom'
        }
      },
      SECTIONS: {
        SUMMARY: {
          OVERVIEW: {
            id: 'summary-overview',
            title: 'Summary Overview',
            text: 'Quick overview of your payment activity.',
            position: 'bottom'
          },
          CHARTS: {
            id: 'summary-charts',
            title: 'Activity Charts',
            text: 'Visual representation of your payment data.',
            position: 'bottom'
          }
        },
        DETAILS: {
          TRANSACTIONS: {
            id: 'report-transactions',
            title: 'Transactions',
            text: 'Detailed list of transactions.',
            position: 'bottom'
          },
          FILTERS: {
            id: 'report-filters',
            title: 'Filters',
            text: 'Filter and search your transactions.',
            position: 'bottom'
          }
        }
      }
    }
  
} as const; 