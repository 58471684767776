import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShortIdResolver implements Resolve<any> {
  constructor(private http: HttpClient, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const reservedShortid = route.paramMap.get('shortId');
    const checkUrl = `${environment.api}/ReservedShortId/check-is-reserved?shortId=${reservedShortid}`;

    return this.http.get(checkUrl).pipe(
      map((response: any) => {
        // Assuming a successful response means the shortId is reserved
        if(response === true) {
          this.router.navigate(['/register'], { queryParams: { reservedShortid } });
          return null; // or any default value if needed
        }
        return null;
      }),
      catchError((error) => {
        return of(null);
      })
    );
  }
} 