<div class="max-w-2xl mx-auto p-6 space-y-6">
  <!-- I owe section -->
  <div class="bg-gray-50 rounded-xl p-4">
    <div class="flex items-center gap-2 mb-3">
      <span class="text-lg">I owe <span class="font-medium">Milos Rujevic</span></span>
      <button class="text-gray-400 hover:text-gray-600">
        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </button>
    </div>
    <div class="flex items-center gap-3">
      <img src="assets/btc-logo.png" alt="BTC" class="w-8 h-8" />
      <div class="text-xl">
        <span class="font-medium">BTC</span>
        <span>1,111.111111</span>
      </div>
    </div>
  </div>

  <!-- So I am sending section -->
  <div class="bg-green-50 rounded-xl p-4">
    <div class="flex justify-between items-center mb-3">
      <div class="flex items-center gap-2">
        <span class="text-lg italic">so I am sending</span>
        <button class="text-gray-400 hover:text-gray-600">
          <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
      </div>
      <div class="text-right">
        <span class="text-gray-600">via</span>
        <a href="#" class="text-blue-600 hover:text-blue-700 ml-1">Polygon Network</a>
      </div>
    </div>
    <div class="flex items-center gap-3">
      <img src="assets/pol-logo.png" alt="POL" class="w-8 h-8" />
      <div class="text-xl">
        <span class="font-medium">POL</span>
        <span>168,768,110.15</span>
      </div>
    </div>
  </div>

  <!-- Progress section -->
  <div class="space-y-2">
    <div class="flex justify-between items-center">
      <div class="flex items-center gap-2">
        <h2 class="text-xl font-medium">Open your wallet and pay now</h2>
        <button class="text-gray-400 hover:text-gray-600">
          <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
      </div>
      <span class="text-xl">13:33</span>
    </div>
    <div class="bg-green-500 h-2 rounded-full"></div>
  </div>

  <!-- QR and Address section -->
  <div class="flex gap-6">
    <div class="w-48 h-48 bg-white p-2 rounded-lg">
      <img src="assets/qr-code.png" alt="QR Code" class="w-full h-full" />
    </div>
    <div class="flex-1 space-y-6">
      <div>
        <div class="flex items-center justify-between mb-2">
          <h3 class="text-lg font-medium">POL address</h3>
          <button class="text-gray-500 hover:text-gray-700">
            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
              />
            </svg>
          </button>
        </div>
        <div class="bg-gray-100 p-3 rounded-lg text-gray-600 break-all">0x3fc8d7599d0ae72c4dad05a150a449d7ac8e0783</div>
      </div>

      <div>
        <div class="flex items-center gap-2 mb-2">
          <h3 class="text-lg font-medium">Locked rate</h3>
          <button class="text-gray-400 hover:text-gray-600">
            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>
        <div class="text-gray-600">
          BTC 1 = POL 151,891.30
          <button class="ml-2 text-blue-600 hover:text-blue-700 font-medium">FLIP</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Footer text -->
  <p class="text-gray-500 text-sm">
    Polygon Ecosystem Token private wallet provided and owned by the recipient. Nicky is a peer-to-peer service. By
    making the payment, you agree with
    <a href="#" class="text-blue-600 hover:text-blue-700">Nicky's terms of use.</a>
  </p>
</div>
