import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, firstValueFrom, Observable, throwError, tap } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService {
    private jwtTokenSubject = new BehaviorSubject<string | null>(null);
    jwtToken$ = this.jwtTokenSubject.asObservable();

    constructor(
        private http: HttpClient,
        private router: Router,
        private auth: AuthService,
        private params: ActivatedRoute,
        private userService: UserService
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const currentUrl = state.url;
        const isAuthenticated = await this.isAuthenticated();
        const user = await this.getAuth0User(isAuthenticated);

        if (this.isAuthRoute(currentUrl)) {
            return this.handleAuthRoutes(isAuthenticated, user);
        }

        return this.handleProtectedRoutes(isAuthenticated, user, currentUrl);
    }

    private async isAuthenticated(): Promise<boolean> {
        return await firstValueFrom(this.auth.isAuthenticated$);
    }

    public async getAuth0User(isAuthenticated: boolean): Promise<any> {
        return isAuthenticated ? await firstValueFrom(this.auth.user$) : undefined;
    }

    private isAuthRoute(url: string): boolean {
        return ['/login', '/register', '/login/forgotpassword', '/activate-account'].some(route => url.includes(route));
    }

    private handleAuthRoutes(isAuthenticated: boolean, user: any): boolean {
        if (isAuthenticated) {
            if (user?.blockedUser) {
                this.redirectToHomeWithWarning();
                return false;
            }
            this.router.navigateByUrl('/overview');
            return false;
        }
        return true;
    }

    private handleProtectedRoutes(isAuthenticated: boolean, user: any, currentUrl: string): boolean {
        if (isAuthenticated) {
            if (user?.blockedUser) {
                this.redirectToHomeWithWarning();
                return false;
            }
            return true;
        }
        this.redirectToLogin(currentUrl);
        return false;
    }

    private redirectToHomeWithWarning(): void {
        this.router.navigateByUrl('/');
        localStorage.setItem('manyUsers', 'true');
    }

    private redirectToLogin(targetUrl: string): void {
        this.auth.loginWithRedirect({
            appState: { target: targetUrl },
            authorizationParams: {
                ui_locales: navigator.language || 'en',
            }
        });
    }

    getTokenWithCorrectAudience(): Observable<any> {
        return this.auth.isAuthenticated$.pipe(
            switchMap(isAuthenticated => {
                if (isAuthenticated) {
                    return this.auth.getAccessTokenSilently({
                        authorizationParams: {
                            audience: environment.auth0Audience,
                        },
                    }).pipe(
                        tap(token => this.jwtTokenSubject.next(token))
                    );
                } else {
                    return throwError('User is not authenticated');
                }
            }),
            catchError(error => {
                console.error('Error getting token:', error);
                return throwError(error);
            })
        );
    }

    getJWTToken(): string | null {
        return this.jwtTokenSubject.getValue();
    }

    setJWTToken(token: string): void {
        this.jwtTokenSubject.next(token);
    }

    getUserInfo(): Observable<any> {
        return this.userService.getUser().pipe(
            catchError(error => {
                console.error('Error fetching user info:', error);
                return throwError(error);
            })
        );
    }
}