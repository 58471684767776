<div class="fixed inset-0 bg-black/50 z-50 overflow-hidden">
  <div
    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-lg max-h-[90vh] bg-white rounded-xl shadow-lg flex flex-col"
  >
    <!-- Header -->
    <div class="p-6 flex justify-between items-center">
      <h3 class="text-xl font-semibold">{{ 'share' | translate }}</h3>
      <button (click)="dialogRef.close(false)" class="text-gray-500 hover:text-gray-700">
        <i class="ph-bold ph-x"></i>
      </button>
    </div>

    <hr class="border-gray-200" />

    <!-- Body -->
    <div class="p-6 space-y-6 overflow-y-auto flex-1">
      <!-- QR Code Section -->
      <div class="flex justify-center">
        <qrcode
          id="qr-code"
          class="center"
          [qrdata]="getProfileUrl()"
          [allowEmptyString]="true"
          [cssClass]="'center'"
          [colorDark]="'#000000ff'"
          [colorLight]="'#ffffffff'"
          [elementType]="'canvas'"
          [errorCorrectionLevel]="'M'"
          [margin]="4"
          [scale]="1"
          [title]="'A custom title attribute'"
          [width]="140"
        ></qrcode>
      </div>

      <!-- Input Section -->
      <div class="space-y-4">
        <label class="block text-sm font-medium text-gray-700">
          {{ 'public_profile.copyProfileLink' | translate }}
        </label>
        <div
          class="flex items-center border border-gray-300 rounded-lg p-2 hover:border-blue-500 cursor-pointer"
          (click)="copyProfileUrl()"
          id="profile-link"
        >
          <i id="copy-link" class="ph ph-copy text-gray-500 mr-2"></i>
          <input
            [disabled]="true"
            type="text"
            [value]="getProfileUrl()"
            class="flex-1 bg-transparent outline-none text-gray-700"
          />
        </div>
      </div>
    </div>

    <hr class="border-gray-200" />

    <!-- Footer -->
    <div class="flex justify-end gap-3 p-6 bg-gray-50 rounded-b-xl">
      <button
        (click)="dialogRef.close(false)"
        class="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
      >
        {{ 'close' | translate }}
      </button>
    </div>
  </div>
</div>
