import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { QuoteModel } from '../components/models/quote.model';
import { Wallet } from '../components/models/wallet.model';
import { formatEthOrMaticAddress } from '../utils/format-eth-matic-address.utils';
import { createHttpOptions } from '../utils/http-utils';

@Injectable()
export class PaymentService {

  constructor(
    public route: Router,
    private http: HttpClient,
    public auth: AuthService
  ) { }


  getUserWallets(targetNick: string, selectedAsset: string) {
    return this.http.get<Wallet>(
      `${environment.api}Address/deposit-address?assetId=${selectedAsset}&nick=${targetNick}`,
      createHttpOptions('text/plain')
    );
  }

  getPrice(currency: string, targetCurrency: string) {
    const body = {
      amount: 1,
      fromBlockchainId: currency,
      toBlockchainId: targetCurrency
    };
    return this.http.post(
      `${environment.api}ConversionRate/get-price`,
      body,
      createHttpOptions()
    );
  }

  getQuotes(amount: number, currency: string, targetCurrency: string) {
    const body = {
      amount: amount,
      fromBlockchainId: currency,
      toBlockchainId: targetCurrency
    };
    return this.http.post(
      `${environment.api}ConversionRate/get-quote`,
      body,
      createHttpOptions()
    );
  }

  getTransactions(assetId: string, address: string, dateTime: string) {
    return this.http.get(
      `${environment.api}Blockchain/transactions/${assetId}/${address}?startTime=${dateTime}`,
      createHttpOptions('text/plain')
    );
  }

  doTransaction(
    transaction: FormGroup,
    walletAddress: string,
    user: any,
    targetId: any,
    targetAddress: any,
    savedAssetValue: any,
    savedUsdValue: any,
    authenticated: boolean,
    targetCompany: string,
    targetNick: string,
    existingBillShortId?: string,
    currentQuote?: QuoteModel
  ) {
    const assetId = this.getAssetId(transaction.controls['assetId'].value);
    const transactionId = transaction.controls['transactionID'].value?.trim();
    const reference = transaction.controls['reference'].value?.trim().substring(0, 17);
    const description = transaction.controls['description'].value?.trim();
    const { anonUserName, anonUserEmail, anonUserCompany } = this.getAnonymousUserInfo(transaction, authenticated, user);

    const body = {
      payerUserAnon: {
        email: authenticated ? user.email : anonUserEmail,
        name: authenticated ? user.name : anonUserName,
        company: authenticated ? user.company : anonUserCompany
      },
      existingBillShortId: existingBillShortId,
      billDetails: {
        receiverUserId: targetId,
        invoiceReference: reference,
        description: description
      },
      paymentTransactions: [
        {
          blockchainTransactionId: this.formatEthOrMaticTransactionId(transactionId, assetId),
          blockchainAssetId: assetId,
          receiverAddress: walletAddress,
          conversionQuoteId: currentQuote?.id
        }
      ]
    };

    return this.http.post(
      `${environment.api}PaymentReport/create`,
      body,
      createHttpOptions()
    );
  }

  private getAssetId(assetId: string): string {
    switch (assetId) {
      case "USD":
        return "USD.USD";
      case "EUR":
        return "EUR.EUR";
      default:
        return assetId;
    }
  }

  private getAnonymousUserInfo(transaction: FormGroup, authenticated: boolean, user: any) {
    if (authenticated) {
      return { anonUserName: user.name, anonUserEmail: user.email, anonUserCompany: user.company };
    }
    return {
      anonUserName: transaction.controls['guestName'].value?.trim(),
      anonUserEmail: transaction.controls['email'].value?.trim(),
      anonUserCompany: transaction.controls['company'].value?.trim() || null
    };
  }

  private formatEthOrMaticTransactionId(transactionId: string, blockchainAssetId: string) {
    const isEthereumOrMatic = blockchainAssetId.split('.')[0] === 'ETH' || blockchainAssetId.split('.')[0] === 'MATIC';
    return isEthereumOrMatic ? formatEthOrMaticAddress(transactionId) : transactionId;
  }

  getAllAssets() {
    return this.http.post(
      `${environment.api}BlockchainAsset/all`,
      {},
      createHttpOptions('text/plain')
    );
  }
}










