import { Injectable, signal, computed, Type, ComponentRef } from '@angular/core';

export interface WizardStep {
  componentType: Type<any>; // Store the component class
  id: string;
  label?: string;
  isActive: boolean;
  isCompleted: boolean;
  order: number;
  onNext?: () => boolean; // Return boolean to indicate success or failure
  nextLabel?: string;     // Custom label for the Next button
  backLabel?: string;     // Custom label for the Back button
  resetLabel?: string;    // Custom label for the Reset button
  errorMessage?: string;  // Custom error message for the step
  fullWidthNext?: boolean; // Flag to show only the Next button in full width
}
@Injectable({
  providedIn: 'root'
})
export class WizardStore {
  private state = signal<{
    currentStep: string;
    steps: WizardStep[];
  }>({
    currentStep: '',
    steps: []
  });

  // Initialize the store with a specific set of steps
  initialize(steps: WizardStep[]) {
    this.state.set({
      currentStep: steps[0].id,
      steps: steps.map((step, index) => ({
        ...step,
        isActive: index === 0,
        isCompleted: false
      }))
    });
  }

  // Selectors
  readonly currentStep = computed(() => this.state().currentStep);
  readonly steps = computed(() => this.state().steps);

  // Actions
  setStep(stepId: string) {
    this.state.update(state => {
      const updatedSteps = state.steps.map(step => ({
        ...step,
        isActive: step.id === stepId,
        isCompleted: step.isCompleted || step.id === state.currentStep
      }));
      return { ...state, currentStep: stepId, steps: updatedSteps };
    });
  }

  onCancel(event: Event) {
    event.preventDefault();
    this.reset();
  }

  reset() {
    const initialSteps = this.state().steps.map((step, index) => ({
      ...step,
      isActive: index === 0,
      isCompleted: false
    }));
    this.state.set({
      currentStep: initialSteps[0].id,
      steps: initialSteps
    });
  }
}