
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NICKY_URLS } from 'src/app/utils/urls.constants';

interface FooterLink {
  url: string;
  translationKey: string;
}

@Component({
  selector: 'app-nicky-footer',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './nicky-footer.component.html',
})
export class NickyFooterComponent {
  currentYear = new Date().getFullYear();

  termsOfUseUrl = NICKY_URLS.TERMS_OF_USE;
  privacyPolicyUrl = NICKY_URLS.PRIVACY_POLICY;
  legalDisclosureUrl = NICKY_URLS.LEGAL_DISCLOSURE;
  cookiePolicyUrl = NICKY_URLS.COOKIE_POLICY;

  footerLinks: FooterLink[] = [
    { url: this.termsOfUseUrl, translationKey: 'termsOfUse' },
    { url: this.privacyPolicyUrl, translationKey: 'privacyPolicy' },
    { url: this.legalDisclosureUrl, translationKey: 'disclousures' },
    { url: this.cookiePolicyUrl, translationKey: 'cookiePolicy' },
  ];
}
