import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable()
export default class FiatByCountry {

  constructor(public toastr:ToastrService){

  }
  
  public BRLCountrys = [
    "BR"
  ]

  public EURCountrys = [
    "AT",  
    "BE",  
    "CY",  
    "DE",  
    "EE",  
    "ES",  
    "FI", 
    "FR",
    "GR",  
    "IE",  
    "IT", 
    "LT",  
    "LU",  
    "LV", 
    "MT",  
    "NL", 
    "PT",  
    "SI", 
    "SK",  
    "MC",  
    "SM",  
    "VA"  
  ]


  getUserFiatByCountry(countryCode:string){
    if(this.BRLCountrys.includes(countryCode)){    
        return [ 'BRL.BRL' ]
    }

    if(this.EURCountrys.includes(countryCode)){
        return [ 'EUR.EUR' ]
    }

    return [ 'USD.USD' ]
  }

}