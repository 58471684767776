<div class="max-w-2xl mx-auto p-6 space-y-6">
  <!-- Profile Card -->
  <div class="bg-[#F3F4FF] rounded-2xl p-4 flex items-center justify-between">
    <div class="flex items-center gap-4">
      <!-- TODO GET USER NAME FROM SERVICE -->
      <app-user-profile-picture [name]="'Milos Rujevic'" [size]="'lg'" />
      <h2 class="text-2xl font-semibold">Milos Rujevic</h2>
    </div>
  </div>

  <!-- Payment Card -->
  <div class="bg-[#F4FFE8] rounded-2xl p-6 relative">
    <div class="flex items-center gap-3 mb-6">
      <mat-icon [svgIcon]="'BTC'"></mat-icon>
      <span class="text-2xl font-bold">BTC</span>
      <span class="text-3xl font-bold ml-auto">1,111.111111</span>
    </div>

    <div class="space-y-4">
      <div class="flex justify-between">
        <span class="text-gray-500">From</span>
        <span class="font-medium">Mike</span>
      </div>
      <div class="flex justify-between">
        <span class="text-gray-500">Email</span>
        <span class="font-medium">mikeruje gmail.com</span>
      </div>
    </div>
  </div>

  <!-- Details -->
  <div class="space-y-4">
    <div class="flex justify-between p-4 rounded-xl border">
      <span class="text-gray-500">Description</span>
      <span class="font-medium">Description text</span>
    </div>

    <div class="flex justify-between p-4 rounded-xl border">
      <span class="text-gray-500">Reference</span>
      <span class="font-medium">Reference text</span>
    </div>

    <div class="flex justify-between p-4 rounded-xl border">
      <span class="text-gray-500">Payment ID</span>
      <span class="font-medium">MDPSDQ</span>
    </div>
  </div>
</div>
