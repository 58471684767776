import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../services/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isTranslationRequest = req.url.includes('./assets/i18n/');
    const isPublicRequest = req.url.includes('/public');
    const getPrice = req.url.includes('get-price');
    const getDecimalPlaces = req.url.includes('BlockchainAsset/all');
    const isValidatingAddress = req.url.includes('/wallet/validateaddress');
    const isValidatingUser = req.url.includes('User/isValidated?email=');
  
    if (isTranslationRequest || isPublicRequest || getPrice || getDecimalPlaces || isValidatingAddress || isValidatingUser) {
      return next.handle(req);
    }

    this.loadingService.setLoading(true);

    return next.handle(req).pipe(finalize(() => this.loadingService.setLoading(false)));
  }
}
