<nav class="bg-white relative z-50 border-b border-gray-200 border-solid">
  <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
    <div class="relative flex h-16 items-center justify-between">
      <!-- Mobile Menu Button -->
      <div class="absolute inset-y-0 right-0 flex items-center lg:hidden">
        <button
          type="button"
          id="mobile-menu-button"
          (click)="toggleMobileMenu()"
          class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          aria-controls="mobile-menu"
          [attr.aria-expanded]="isMobileMenuOpen"
        >
          <span class="absolute -inset-0.5"></span>
          <span class="sr-only">Open main menu</span>
          <svg
            [class]="isMobileMenuOpen ? 'hidden' : 'block' + ' size-6'"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
            data-slot="icon"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
          <svg
            [class]="isMobileMenuOpen ? 'block size-6' : 'hidden' + ' size-6'"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
            data-slot="icon"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <!-- Logo and Navigation Links -->
      <div class="flex items-center justify-center sm:items-stretch sm:justify-start">
        <!-- Logo -->
        <div class="flex shrink-0 items-center">
          <picture (click)="goHome()" class="w-[102px] h-[42px]">
            <source srcset="assets/nicky-logo.svg" type="image/svg+xml" />
            <img class="h-9 mt-0.5 clickable" src="/assets/nicky-logo.svg" alt="logo" />
          </picture>
        </div>

        <!-- Desktop Navigation Links or Route Title -->
        <div class="hidden lg:ml-6 lg:block my-auto">
          <div class="flex space-x-4">
            @if (!fullWidthMode) {
              <!-- Navigation Links -->
              @for (link of navigationLinks; track link.id) {
                <a
                  [id]="link.id"
                  [routerLink]="link.path"
                  routerLinkActive="bg-black text-white"
                  class="p-2 gap-1 rounded-xl inline-flex items-center text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                >
                  <i [class]="link.icon" class="w-5 h-5 p-1 leading-none" aria-hidden="true"></i>
                  <span
                    class="font-inter text-sm font-normal leading-5 text-left underline-offset-auto decoration-skip-ink-none"
                  >
                    {{ link.labelKey | translate }}
                  </span>
                </a>
              }
            }
          </div>
        </div>

        <!-- Route Title -->
        @if (fullWidthMode) {
          <div class="hidden lg:ml-6 lg:block my-auto">
            <div class="flex space-x-4">
              <div class="flex-grow border-r border-solid border-gray-300"></div>
              <div class="px-2">
                <h1 class="font-inter text-lg font-semibold leading-6 tracking-tight text-left text-gray-700">
                  {{ routeTitle }}
                </h1>
              </div>
            </div>
          </div>
        }
      </div>

      <!-- User Actions (Search, Help, Language, Profile) -->
      <div class="flex items-center divide-x">
        <!-- Search Bar -->
        <div class="pr-14 lg:pr-0">
          <app-search-bar-private></app-search-bar-private>
        </div>

        <!-- Desktop User Actions -->
        <div class="hidden lg:flex items-center">
          <!-- Help Button -->
          <button type="button" class="bg-white ml-4 rounded-md text-sm font-medium flex items-center leading-none">
            <mat-icon svgIcon="help-book" id="bookIcon" (click)="startPageTour()"></mat-icon>
          </button>

          <!-- Language Dropdown -->
          <button class="bg-white ml-4 rounded-md text-sm font-medium flex items-center leading-none">
            <app-dropdown [responsiveLabel]="true" [dropdownEnd]="false">
              <div type="button" id="user-menu-button" dropdown-label aria-haspopup="menu">
                <span class="sr-only">Open language menu</span>
                <mat-icon svgIcon="{{ authStore.profile()?.language }}"></mat-icon>
              </div>
              <div
                class="z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none"
                role="language-menu"
              >
                @for (lang of systemLanguages; track lang.language) {
                  <a
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center gap-2"
                    role="language-item"
                    (click)="changeLanguage(lang.language)"
                  >
                    <mat-icon [svgIcon]="lang.language" class="size-5"></mat-icon>
                    <span>{{ lang.name }}</span>
                  </a>
                }
              </div>
            </app-dropdown>
          </button>

          <!-- Profile Dropdown -->
          <button class="bg-white ml-4 rounded-md text-sm font-medium flex items-center">
            <app-dropdown [responsiveLabel]="true">
              <div type="button" id="user-menu-button" dropdown-label aria-haspopup="menu">
                <span class="sr-only">Open profile menu</span>
                @if (authStore.profilePicture(); as profilePicture) {
                  <img class="size-8 rounded-full" [src]="profilePicture" />
                } @else {
                  <!-- TODO: add initials -->
                  <!-- <span class="size-8 rounded-full">{{ authStore.userInitials() }}</span> -->
                }
              </div>
              <div
                class="z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none"
                role="menu"
              >
                @for (link of profileMenuLinks; track link.id) {
                  <a
                    [routerLink]="link.path"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                    id="user-menu-item-{{ link.id }}"
                    (click)="link.action ? link.action() : null"
                  >
                    <i [class]="link.icon" class="text-lg leading-none"></i>
                    <span>{{ link.labelKey | translate }}</span>
                  </a>
                }
              </div>
            </app-dropdown>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile Menu -->
  <div
    class="lg:hidden fixed inset-x-0 top-16 bottom-0 bg-white z-40"
    id="mobile-menu"
    [class.hidden]="!isMobileMenuOpen"
  >
    <div class="h-full flex flex-col">
      <!-- Mobile Navigation Links -->
      <div class="flex-none px-4 py-3 space-y-6">
        @for (link of navigationLinks; track link.id) {
          <a
            [id]="link.id"
            [routerLink]="link.path"
            routerLinkActive="bg-black text-white"
            class="w-full rounded-xl px-4 py-3 text-sm font-medium text-gray-900 hover:bg-gray-100 inline-flex items-center gap-3"
            (click)="toggleMobileMenu()"
          >
            <i [class]="link.icon" class="text-lg" aria-hidden="true"></i>
            <span class="font-inter text-base font-normal">
              {{ link.labelKey | translate }}
            </span>
          </a>
        }
      </div>

      <!-- Mobile Language and Profile -->
      <div class="flex-grow"></div>

      <!-- Mobile Language Selector -->
      <div class="px-4 py-3 border border-solid border-[#EFF0F4] rounded-lg m-4">
        <app-dropdown class="w-full" [responsiveLabel]="true" [dropdownEnd]="false" dropdownPosition="bottom">
          <div type="button" id="mobile-language-button" dropdown-label aria-haspopup="menu">
            <div class="flex items-center gap-3 text-gray-900">
              <mat-icon svgIcon="{{ authStore.profile()?.language }}" class="size-5"></mat-icon>
              <span class="text-base font-normal">Language</span>
            </div>
          </div>
          <div
            class="z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none"
            role="language-menu"
          >
            @for (lang of systemLanguages; track lang.language) {
              <a
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center gap-2"
                role="language-item"
                (click)="changeLanguage(lang.language)"
              >
                <mat-icon [svgIcon]="lang.language" class="size-5"></mat-icon>
                <span>{{ lang.name }}</span>
              </a>
            }
          </div>
        </app-dropdown>
      </div>

      <!-- Mobile Profile Menu -->
      <div class="px-4 py-3 border border-solid border-[#EFF0F4] rounded-lg m-4">
        <app-dropdown class="w-full" [responsiveLabel]="true" dropdownPosition="bottom">
          <div type="button" id="mobile-profile-button" dropdown-label aria-haspopup="menu">
            <div class="flex items-center gap-3 text-gray-900">
              @if (authStore.profilePicture(); as profilePicture) {
                <img class="size-8 rounded-full" [src]="profilePicture" />
              }
              <span class="text-base font-normal">Profile</span>
            </div>
          </div>
          <div
            class="z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none"
            role="menu"
          >
            @for (link of profileMenuLinks; track link.id) {
              <a
                [routerLink]="link.path"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
                id="user-menu-item-{{ link.id }}"
                (click)="link.action ? link.action() : null"
              >
                <i [class]="link.icon" class="text-lg leading-none"></i>
                <span>{{ link.labelKey | translate }}</span>
              </a>
            }
          </div>
        </app-dropdown>
      </div>
    </div>
  </div>
</nav>
