<div class="flex items-center gap-2 bg-white px-4 py-2 rounded-lg border border-gray-200 hover:border-gray-300">
  @if (selectedCrypto) {
    <mat-icon [svgIcon]="selectedCrypto"></mat-icon>
  }
  <select class="w-full bg-transparent outline-none cursor-pointer" [(ngModel)]="selectedCrypto">
    @for (crypto of availableCryptos; track crypto.symbol) {
      <option [value]="crypto.symbol">
        {{ crypto.name }}
      </option>
    }
  </select>
</div>
