import { patchState, signalStore, withMethods, withState } from "@ngrx/signals";
import { setAllEntities, withEntities } from '@ngrx/signals/entities';
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { inject } from "@angular/core";
import { tapResponse } from "@ngrx/operators";
import { pipe, switchMap, tap } from "rxjs";
import { PaymentReportModel } from "../components/models/payment.report.model";
import { FilterService } from "../services/filter.service";

type ReportsStoreState = {
    loaded: boolean;
    filters: {
        pageSize: number,
        pageIndex: number,
        emails: string[],
        filterForm?: any,
        mustOrderByDescending: boolean,
    },
    total: number,
};

const initialState: ReportsStoreState = {
 loaded: false,
 filters: {
    pageSize: 10,
    pageIndex: 0,
    emails: [],
    mustOrderByDescending: true,
 },
 total: 0,
};


export const ReportsStore = signalStore(
  {
    providedIn: 'root',
  },
  withEntities<PaymentReportModel>(),
  withState(initialState),
  withMethods((store, filterService = inject(FilterService)) => {
    const loadReports = rxMethod<void>(
    pipe(
          switchMap(() => {
            return filterService.getAllPaymentReportsWithoutFilters(
              store.filters.pageSize(),
              store.filters.pageIndex(),
              store.filters.emails(),
              {} as any,
              store.filters.mustOrderByDescending()
            ).pipe(
              tapResponse({
                next: ({ total, data }) => {
                  patchState(store, setAllEntities(data));
                  patchState(store, {total});
                  patchState(store, {loaded: true});
                },
                error: (error: { message: string }) => {
                  // TODO: Handle error appropriately
                  console.error('Error loading reports:', error);
                },
              })
            );
          })
        )
    );

    const updatePagination = (pageIndex: number, pageSize: number) => {
      patchState(store, {
        filters: {
          ...store.filters(),
          pageIndex,
          pageSize
        }
      });
      loadReports();
    };

    return {
      loadReports,
      updatePagination,
      // Optionally, add methods to update other filters
    }
  }),
);
