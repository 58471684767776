import { Component, inject } from '@angular/core';
import { Router, NavigationEnd, RouterModule, ActivatedRoute } from '@angular/router';
import { filter, map, startWith, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { NickyFooterComponent } from 'src/app/layouts/shared/nicky-footer/nicky-footer.component';
import { CommonModule } from '@angular/common';
import { HeaderPublicComponent } from 'src/app/layouts/layout-public/header-public/header-public.component';
import { AuthStore } from 'src/app/stores/auth.store';
import { ProfileDto } from 'src/app/dtos/user-profile.dto';
import { HeaderPrivateComponent } from '../layout-private/header-private/header-private.component';

@Component({
  selector: 'app-layout-public',
  standalone: true,
  imports: [CommonModule, HeaderPublicComponent, HeaderPrivateComponent, NickyFooterComponent, RouterModule],
  templateUrl: './layout-public.component.html'
})
export class LayoutPublicComponent {
  fullWidthMode$!: Observable<boolean>;
  couldBeAuthenticated$!: Observable<boolean>;
  isAuthenticated$!: Observable<ProfileDto | boolean>;
  private routerSubscription?: Subscription;
  protected authStore = inject(AuthStore);
  protected router = inject(Router);

  ngOnInit() {
    this.fullWidthMode$ = this.router.events.pipe(
      filter(event => { console.log(event); return event instanceof NavigationEnd }),
      map(() => this.getFullWidthMode()),
      startWith(this.getFullWidthMode()),
      distinctUntilChanged()
    );


    this.couldBeAuthenticated$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.getCouldBeAuthenticated()),
      startWith(this.getCouldBeAuthenticated()),
      distinctUntilChanged()
    );

    this.isAuthenticated$ = this.couldBeAuthenticated$.pipe(
      switchMap(couldBeAuthenticated => {
        if (couldBeAuthenticated) {
          const profile = this.authStore.profile();
          const avatar = this.authStore.getProfilePicture();
          return of(profile);
        }
        return of(false);
      }),
      map(profileOrFalse => !!profileOrFalse)
    );

    this.routerSubscription = this.fullWidthMode$.subscribe();

    this.routerSubscription = this.fullWidthMode$.subscribe();
  }

  private getFullWidthMode(): boolean {
    return this.checkFullWidthMode(this.router.routerState.root);
  }

  private getCouldBeAuthenticated(): boolean {
    return this.checkRouteData(this.router.routerState.root, 'couldBeAuthenticated');
  }

  private checkRouteData(route: ActivatedRoute, key: string): boolean {
    if (route.snapshot.data[key]) {
      return true;
    }
    return route.firstChild ? this.checkRouteData(route.firstChild, key) : false;
  }

  private checkFullWidthMode(route: ActivatedRoute): boolean {
    if (route.snapshot.data['fullWidthMode']) {
      return true;
    }
    return route.firstChild ? this.checkFullWidthMode(route.firstChild) : false;
  }

  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
  }
}
