import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { PaymentService } from "../services/payment.service";
import { ToastrFactoryService } from "../services/toastr-factory.service";
import { firstValueFrom } from "rxjs";
import { QuoteModel } from "../components/models/quote.model";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export default class ConvertAsset {
    public convertedAsset: { [key: string]: { value: any, asset: string } } = {};
    
    constructor(public toastrService:ToastrService,
        public paymentService:PaymentService,
        private translate: TranslateService,
        private toastrFactory: ToastrFactoryService){
  }

  async getConvertedAssets(assets:any){
    for (let asset of assets) {
      let assetInfo = asset
      await this.getAssetConversionValue(assetInfo);
    }

    return this.convertedAsset;
  }

  async getAssetConversionValue(assetInfo:any){
    let assetId = this.formatAsset(assetInfo.blockchainAsset.id);
    if(assetInfo.amountNative === 0){
        this.convertedAsset[assetId] = { value: 0, asset: assetId  };
        return;
    }
    if(assetId)
    try {
      const priceAmount = await firstValueFrom(this.paymentService.getPrice(assetId, "USD.USD")) as QuoteModel;
    
     if(priceAmount.price == null){
        this.convertedAsset[assetId] = { value: 'Error getting prices', asset: assetId  };
        return;
      }
      this.convertedAsset[assetId] = { value: priceAmount.price, asset: assetId  };
    } catch(e) {
      console.log(e);
      this.toastrFactory.unknownError(this.translate.instant('unknownError'));
    }
  }

  public formatAsset(asset: string) {
    if (asset === "USDC" || asset === "USDT")
      return "ETH." + asset
    if (asset === "MATIC")
      return "MATIC." + asset
    if (asset === "POL")
      return "MATIC." + asset
    if(!asset.includes(".")){
      return asset + "." + asset
    }
    return asset;
  }
}

