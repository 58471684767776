/******************************************************************************
 * Angular and Third-Party Imports
 *****************************************************************************/
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

/******************************************************************************
 * Application Guards
 *****************************************************************************/
import { loginGuard } from './guards/login.guard';
import { publicProfileGuard } from './guards/public-profile.guard';

/******************************************************************************
 * Component Imports
 *****************************************************************************/
// Public Components not lazy loaded
import { PublicProfileComponent } from './pages/public/public-profile/public-profile.component';
import { ErrorStateComponent } from './pages/public/error-state/error-state.component';
import { ForgotPasswordComponent } from './pages/public/forgot-password/forgot-password.component';
import { LayoutPublicComponent } from './layouts/layout-public/layout-public.component';
import { PaymentReportPageComponent } from './features/payment/public/report/page/payment-report-page.component';

/******************************************************************************
 * Route Configuration
 *****************************************************************************/
const routes: Routes = [
  // Error handling route
  { path: 'error', component: ErrorStateComponent },

  // Public routes wrapped in LayoutPublicComponent
  {
    path: '', 
    component: LayoutPublicComponent,
    children: [
      // Authentication routes
      { path: 'register', 
        loadComponent: () => import('./pages/public/register-page/register-page.component').then((m) => m.RegisterPageComponent) 
      },
      { path: 'login', canActivate: [loginGuard] },
      { path: 'login/forgotpassword', component: ForgotPasswordComponent,
        data: { fullWidthMode: true }
       },

      // Public profile access routes
      { 
        path: 'e/:email', 
        component: PublicProfileComponent, 
        canActivate: [publicProfileGuard],
        data: { fullWidthMode: true }
      },
      { 
        path: 'd/:domain', 
        component: PublicProfileComponent, 
        canActivate: [publicProfileGuard],
        data: { fullWidthMode: true }
      },
      { 
        path: 's/:shortId',
        component: PublicProfileComponent,
        canActivate: [publicProfileGuard],
        data: { fullWidthMode: true }
      },
      // Payment Report related routes
      // DO NOT DELETE this route, it is used for the wizard to redirect to the home page
      {
        path: 'home',
        component: PaymentReportPageComponent,
        title: 'Payment Report',
        data: { fullWidthMode: true }
      },
      {
        path: 'payment-report/:receiverShortId',
        component: PaymentReportPageComponent,
        title: 'Payment Report',
        data: { fullWidthMode: true }
      },
      {
        path: 'home/:receiverShortId/:step',
        redirectTo: 'payment-report/:receiverShortId',
        pathMatch: 'full'
      },
      // Payment Report related routes
      // DO NOT DELETE this route, it is used for the wizard to redirect to the home page
    ],
  },

  // Private routes (require authentication) wrapped in LayoutPrivateComponent
  {
    path: '',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./layouts/layout-private/layout-private.component').then((m) => m.LayoutPrivateComponent),
    children: [
      {
        path: 'contacts',
        loadComponent: () =>
          import('./pages/private/contact-card/contact-card.component').then((m) => m.ContactCardComponent),
      },
      {
        path: 'overview',
        loadComponent: () =>
          import('./pages/private/dashboard-private/dashboard-private.component').then(
            (m) => m.DashboardPrivateComponent,
          ),
      },
      {
        path: 'transactions',
        loadComponent: () =>
          import('./pages/private/transaction-tabs/transaction-tabs.component').then((m) => m.TransactionTabsComponent),
      },
      {
        path: 'settings',
        loadComponent: () =>
          import('./pages/private/profile-settings/profile-settings.component').then((m) => m.ProfileSettingsComponent),
        children: [
          {
            path: '',
            redirectTo: 'general',
            pathMatch: 'full',
          },
          {
            path: 'general',
            loadComponent: () =>
              import('./pages/private/settings/general/general.component').then((m) => m.GeneralComponent),
            title: 'Settings - General',
          },
          {
            path: 'connections',
            loadComponent: () =>
              import('./pages/private/settings/connections/connections.component').then((m) => m.ConnectionsComponent),
            title: 'Settings - Connections',
          },
          {
            path: 'routing',
            loadComponent: () =>
              import('./pages/private/settings/routing/routing.component').then((m) => m.RoutingComponent),
            title: 'Settings - Routing',
          },
          {
            path: 'payment-conversions',
            loadComponent: () =>
              import('./pages/private/settings/payment-conversions/payment-conversions.component').then(
                (m) => m.PaymentConversionsComponent,
              ),
            title: 'Settings - Payment Conversions',
          },
          {
            path: 'bank-withdrawal',
            loadComponent: () =>
              import('./pages/private/settings/bank-withdrawal/bank-withdrawal.component').then(
                (m) => m.BankWithdrawalComponent,
              ),
            title: 'Settings - Bank Withdrawal',
          },
          {
            path: 'security',
            loadComponent: () =>
              import('./pages/private/settings/security/security.component').then((m) => m.SecurityComponent),
            title: 'Settings - Security',
          },
        ],
      },
      {
        path: 'payment-request',
        loadComponent: () =>
          import('./features/payment/private/request/pages/payment-request.page').then((m) => m.PaymentRequestPage),
        title: 'Payment Request',
        data: { fullWidthMode: true }
      },
    ],
  },

  { path: '**', redirectTo: 'error' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
