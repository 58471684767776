import { AuthStore } from 'src/app/stores/auth.store';
import { inject } from '@angular/core';
import { CanActivateFn, Router, ActivatedRouteSnapshot } from '@angular/router';
import { SearchService } from '../services/search.service';
import { Observable, map, catchError, of } from 'rxjs';

export const publicProfileGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const searchService = inject(SearchService);
  const router = inject(Router);
  const authStore = inject(AuthStore)

  const handleProfile = (profile: any) => {
    if (!profile) {
      router.navigate(['/error'], { queryParams: { reason: 'profile-not-found' } });
      return false;
    }
    return true;
  };

  const handleError = () => {
    router.navigate(['/error'], { queryParams: { reason: 'profile-not-found' } });
    return of(false);
  };

  const getProfileObservable = (params: any): Observable<any> => {
    const { shortId, domain, email } = params;

    if (shortId) return searchService.getPublicProfileByShortId(shortId);
    if (domain) return searchService.getPublicProfileByDomain(domain);
    if (email) return searchService.generalSearch(email);
    return of(null);
  };

  const params = {
    shortId: route.paramMap.get('shortId'),
    domain: route.paramMap.get('domain'),
    email: route.paramMap.get('email')
  };

  return getProfileObservable(params).pipe(
    map(handleProfile),
    catchError(handleError)
  );
};
