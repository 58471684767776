<div
  class="flex items-center justify-between gap-2"
  cdkOverlayOrigin
  #dropdownContainer
  (click)="$event.stopPropagation(); toggle()"
  #trigger="cdkOverlayOrigin"
>
  @if (label) {
    <div class="flex flex-col items-start">
      <span
        [ngClass]="{
          'hidden md:flex': responsiveLabel,
        }"
        >{{ label }}</span
      >
      <ng-content select="[dropdown-upsert]"></ng-content>
    </div>
    @if (dropdownEnd) {
      <div class="flex items-center gap-2">
        <span class="text-black notranslate">
          @if (dropdownOpen) {
            <i class="ph-thin ph-caret-up clickable"></i>
          } @else {
            <i class="ph-thin ph-caret-down clickable"></i>
          }
        </span>
      </div>
    }
  } @else {
    <ng-content select="[dropdown-label]"></ng-content>
    @if (dropdownEnd) {
      <div class="flex items-center gap-2">
        <span class="text-black notranslate">
          @if (dropdownOpen) {
            <i class="ph-thin ph-caret-up clickable"></i>
          } @else {
            <i class="ph-thin ph-caret-down clickable"></i>
          }
        </span>
      </div>
    }
  }
</div>
<ng-template
  (overlayOutsideClick)="dropdownOpen = false"
  cdkConnectedOverlay
  [cdkConnectedOverlayPositions]="positions"
  [cdkConnectedOverlayPositionStrategy]="positionStrategy"
  [cdkConnectedOverlayFlexibleDimensions]="true"
  [cdkConnectedOverlayDisposeOnNavigation]="true"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="dropdownOpen"
  (detach)="dropdownOpen = false"
  (backdropClick)="dropdownOpen = false"
>
  <ng-content></ng-content>
</ng-template>
