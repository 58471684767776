import { Injectable } from "@angular/core";

@Injectable()
export default class RoundUpUtil {
    constructor() { }
    roundUpAmount(num :number, decimalPlaces : number) {
        const factor = Math.pow(10, decimalPlaces);
        let result = (Math.ceil(num * factor) / factor).toFixed(decimalPlaces);
        return result;
      }
}

