import { COUNTRIES_DB, MatSelectCountryComponent, MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { ProfileDto } from './dtos/user-profile.dto';
import { UTMDto } from './dtos/utm.dto';
import { AuthGuardService } from './services/auth.service';
import { LoadingService } from './services/loading.service';
import { UserService } from './services/user.service';
import FiatByCountry from './utils/fiat-by-country';
import { convertImageUrlToFile } from './utils/image-utils';
import { catchError, EMPTY, finalize, firstValueFrom, tap } from 'rxjs';
import { IconService } from './services/icon.service';


export const toggleMenu = () => {
  let headerContent = document.querySelector(".header-content")
  let profileMenu = document.querySelector(".profile-menu")

  if (headerContent?.classList.contains('active')) {
    headerContent.classList.toggle('active');
  }

  if (profileMenu?.classList.contains('active')) {
    profileMenu.classList.toggle('active');
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  loading$ = this.loadingService.loading$;
  title = 'nicky';
  public isAuthenticated!: boolean;
  public user!: ProfileDto;
  private auth0User: any;
  public checkedUserState:boolean = false;

  constructor(
    public authGuardService: AuthGuardService,
    public router: Router,
    private userService: UserService,
    public translate: TranslateService,
    public angularCountry: MatSelectCountryComponent,
    public country: MatSelectCountryModule,
    private params: ActivatedRoute,
    private fiatByCountry: FiatByCountry,
    private loadingService: LoadingService,
    public iconService: IconService
  ) {
    
  }

  ngOnInit(): void {
    this.initializeAuth();
    this.getUTMParams();
  }

  private initializeAuth(): void {
    this.authGuardService.getTokenWithCorrectAudience().pipe(
      tap((token: any) => {
        console.log('aqui')
        this.fetchUserInfo();
      }),
      catchError((error:any) => {
        this.checkedUserState = true;
        console.error('Error during authentication:', error);
        return EMPTY;
      })
    ).subscribe();
  }
  

  private fetchUserInfo(): void {
    this.authGuardService.getUserInfo().pipe(
      tap(async (response:any) => {
        this.auth0User = await this.authGuardService.getAuth0User(true);
        this.user = response;
        this.populateSocialSignupUserInfo();
        this.checkedUserState = true;
      }),
      catchError((error:any) => {
        console.error('Error fetching user info:', error);
        this.checkedUserState = true;
        return EMPTY;
      })
    ).subscribe();
  }

  getUTMParams() {
    this.params.queryParams.pipe(
      tap((params: Params) => {
        if (this.router.url === "/") {
          if (window.location.href.includes('error_description=many-users')) {
            localStorage.setItem('manyUsers', 'true');
          }
        }
        const utmSettings = {
          utmCampaign: params['utm_campaign'],
          utmMedium: params['utm_medium'],
          utmSource: params['utm_source']
        };
        localStorage.setItem("utmSettings", JSON.stringify(utmSettings));
      }),
      catchError((error:any) => {
        console.error('Error fetching params:', error);
        this.checkedUserState = true;
        return EMPTY;
      })
    ).subscribe();  
  }

 
populateSocialSignupUserInfo() {
  if (this.shouldPopulateUserInfo()) {
    const newUser: User = this.auth0User;
    const utmInfo = this.getUTMInfo();
    const referrer = this.getReferrer();
    const userCountry = this.getUserCountry(newUser);

    const userInfo: ProfileDto = {
      agreedPrivacyPolicy: false,
      name: newUser.name,
      publicName: newUser.name,
      websiteUrl: undefined,
      country: userCountry?.toLowerCase(),
      umtCampaign: utmInfo.utmCampaign,
      umtMedium: utmInfo.utmMedium,
      umtSource: utmInfo.utmSource,
      referrer: referrer
    };

    this.setGeneralInfoForSocialSignup(userInfo, newUser);
  }
}

private shouldPopulateUserInfo(): boolean {
  return !!(!this.user.name && !this.user.publicName && !this.user.agreedPrivacyPolicy && this.auth0User?.googleAuth);
}

private getUTMInfo(): UTMDto {
  const utmSettingsItem = localStorage.getItem('utmSettings');
  if (utmSettingsItem) {
    try {
      return JSON.parse(utmSettingsItem);
    } catch (error) {
      console.error('Error parsing JSON from localStorage:', error);
    }
  }
  return {};
}

private getReferrer(): string | undefined {
  return localStorage.getItem('referrer') || undefined;
}

private getUserCountry(newUser: User): string {
  return this.checkSupportForUserCountry(newUser["https://example.com/country"] || '')?.toUpperCase() || "US";
}

  checkSupportForUserCountry(userLocale: string) {
    const country = COUNTRIES_DB.filter(country => country.name === userLocale);
    if (country.length === 1) {
      return country[0].alpha2Code;
    }
    return undefined;
  }

  setGeneralInfoForSocialSignup(userInfo: ProfileDto, newUser: User) {

    this.userService.editGeneralUserInfo(userInfo).pipe(
      tap(async () => {
        const userImg: any = await convertImageUrlToFile(newUser.picture); 
        this.checkedUserState = true;
        if (userImg) {
          this.setSocialUserImage(userImg, newUser);
        } else {
          this.setFirstSettlementAsset(newUser);
        }
      }),
      catchError(() => {
        this.checkedUserState = true;
        return EMPTY;
      })
    ).subscribe();

  }

  async setSocialUserImage(userImg: any, newUser: User) {
      this.userService.updateProfilePicture(userImg).pipe(
        finalize(() => {
          this.setFirstSettlementAsset(newUser);
        })
      ).subscribe();
  }

setFirstSettlementAsset(newUser: User) {
  const userCountry = this.getUserCountry(newUser);
  const asset = this.getUserFiatAsset(userCountry);

  this.updateAcceptedAssets(asset);
}

private getUserFiatAsset(userCountry: string): any {
  return this.fiatByCountry.getUserFiatByCountry(userCountry);
}

private updateAcceptedAssets(asset: any): void {
  this.userService.setAcceptedAssets(asset).pipe(
    catchError((error: any) => {  this.handleError(error); return EMPTY }) 
  ).subscribe();
}

private handleError(error: any): void {
  console.error('Error setting accepted assets:', error);
}

}
