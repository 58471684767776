<!-- Main container for the wizard -->
<div
  class="wizard-container mx-auto min-h-[720px] flex flex-col"
  [ngClass]="{
    'max-w-[743px]': isPaymentRequest,
    'max-w-[1070px]': isPaymentReport,
  }"
>
  <!-- Navigation Bar for Steps -->
  <nav class="steps-navigation flex items-center justify-between my-6 mx-4 sm:mx-1">
    <div class="flex-1">
      <ol class="flex items-center">
        <!-- Loop through each step -->
        @for (step of store.steps(); track trackByStepId(i, step); let i = $index) {
          <li
            class="flex items-center"
            [class.flex-1]="i !== store.steps().length - 1"
            [class.cursor-pointer]="canClickStep(step)"
            (click)="onStepClick(step)"
          >
            <!-- Step Indicator -->
            @if (step.isActive) {
              <div
                class="ml-2 flex items-center justify-center w-6 h-6 rounded-full border-2 transition-colors border-solid border-black"
              >
                <div class="w-4 h-4 rounded-full flex items-center justify-center bg-black">
                  <i class="ph-bold ph-circle-fill text-black ng-star-inserted"></i>
                </div>
              </div>
            }
            @if (!step.isActive && step.isCompleted) {
              <div class="ml-2 flex items-center justify-center w-6 h-6 rounded-full transition-colors">
                <div class="w-6 h-6 rounded-full flex items-center justify-center bg-black">
                  <i class="ph-bold ph-check text-white ng-star-inserted"></i>
                </div>
              </div>
            }
            @if (!step.isActive && !step.isCompleted) {
              <div
                class="ml-2 flex items-center justify-center w-6 h-6 rounded-full border-2 transition-colors border-solid border-gray-300"
              >
                <div class="w-4 h-4 rounded-full flex items-center justify-center bg-white"></div>
              </div>
            }
            <!-- Step Label -->
            <span
              class="ml-3 text-sm font-medium transition-colors"
              [ngClass]="{
                'text-black': step.isActive || step.isCompleted,
                'text-gray-300': !step.isActive && !step.isCompleted,
                'hidden sm:inline': !step.isActive,
              }"
            >
              {{ step.label }}
            </span>
            <!-- Connector between steps -->
            @if (i !== store.steps().length - 1) {
              <div class="flex-1 ml-3">
                <div class="h-0.5 transition-colors bg-gray-300"></div>
              </div>
            }
          </li>
        }
      </ol>
    </div>
  </nav>

  <!-- Step Content Area -->
  <!-- TODO MOVE TO TAILWIND CONFIG -->
  <div
    class="step-content flex-grow bg-white rounded-lg p-6 text-center mx-2 md:mx-24 relative rounded-lg [box-shadow:14px_27px_45px_4px_#7090B033,0px_-12px_40px_0px_#A7DA391F]"
  >
    <ng-container #componentOutlet></ng-container>

    <!-- <ng-container *ngComponentOutlet="getComponentForStep(store.currentStep())"> -->
    <!-- <h2 class="text-2xl hidden lg:block font-semibold mb-4">{{ getComponentForStep(store.currentStep()).label }}</h2> -->
    <!-- </ng-container> -->

    <!-- <ng-container [ngSwitch]="store.currentStep()"> -->
    <!-- <ng-container *ngFor="let step of stepsConfig; trackBy: trackByStepId"> -->
    <!-- Step-specific content -->
    <!-- <ng-container *ngSwitchCase="step.id" style="box-shadow: 14px 27px 45px 4px #7090b033"> -->
    <!-- Display the step title if applicable -->
    <!-- @if (step.label) { -->
    <!-- <h2 class="text-2xl hidden lg:block font-semibold mb-4">{{ step.label }}</h2> -->
    <!-- } -->
    <!-- <ng-template *ngComponentOutlet="step.componentType"></ng-template> -->
    <!-- </ng-container> -->
    <!-- </ng-container> -->
    <!-- </ng-container> -->

    <!-- Navigation Buttons -->
    <div class="navigation-buttons border-t border-gray-200 absolute m-6 bottom-0 left-0 right-0 bg-white">
      @if (!isLastStep()) {
        @if (shouldShowFullWidthNext()) {
          <!-- Full-width Next Button -->
          <button mat-button class="w-full" (click)="onNext()">
            {{ getNextButtonLabel() }}
          </button>
        } @else {
          <div class="flex justify-between items-center pt-3">
            <!-- Left Section -->
            <div class="left-section">
              <!-- Cancel Button -->
              @if (showCancelButton) {
                <button class="text-style-button-text" (click)="onCancel($event)">Cancel</button>
              }
            </div>
            <!-- Right Section -->
            <div class="right-section flex items-center gap-2">
              <!-- Back Button -->
              <button
                class="text-style-button-secondary"
                (click)="onBack()"
                [disabled]="store.currentStep() === stepsConfig[0]?.id"
              >
                {{ getBackButtonLabel() }}
              </button>
              <!-- Next Button -->
              <button
                (click)="onNext()"
                [disabled]="isNextButtonDisabled()"
                [ngClass]="{
                  'text-style-button-primary': !isNextButtonDisabled(),
                  'text-style-button-primary-disabled': isNextButtonDisabled(),
                }"
                class="px-4 py-2 rounded-lg transition-colors"
              >
                {{ getNextButtonLabel() }}
              </button>
              <!-- Reset Button -->
              <!-- <button (click)="onReset()">
          {{ getResetButtonLabel() }}
        </button> -->
            </div>
          </div>
        }
      } @else {
        <!-- Custom buttons for the last step -->
        <ng-content select="[last-step-buttons]"></ng-content>
      }
    </div>
  </div>
</div>
