import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

interface OptionItem {
  value: string;
  label: string;
  icon: string;
}

@Component({
  selector: 'app-option-button-group',
  templateUrl: './option-button-group.component.html',
  standalone: true
})
export class OptionButtonGroupComponent {
  @Input() options: OptionItem[] = [
    { value: 'individual', label: 'Individual', icon: 'user' },
    { value: 'organization', label: 'Organization', icon: 'building' }
  ];
  @Input() control: any;
  @Input() label: string = '';
  @Input() errorMessage: string = 'Please select an option';
} 