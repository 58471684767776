import { Component, inject } from '@angular/core';
import { LoadingService } from './services/loading.service';
import { IconService } from './services/icon.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent {  
  protected loadingService = inject(LoadingService);

  // TODO: Move to custom icons via tailwind css 
  // For now it is injected here so all components can use it
  public iconService = inject(IconService);
  
  loading$ = this.loadingService.loading$;
  title = 'nicky';
}
  

// TODO: Move to store if needed 

  // ngOnInit(): void {
    // this.getUTMParams();
  // }

  // getUTMParams() {
  //   // TODO: Create jira ticket to move to GUARD 
  //   // TODO: Create jira ticket to move to GUARD 
  //   if (this.router.url === '/') {
  //         const manyUsers = this.params.snapshot.queryParamMap.get('error_description') === 'many-users';
  //     if (manyUsers) {
  //       localStorage.setItem('manyUsers', 'true');
  //     }
  //   }
  //   const utmSettings = {
  //     utmCampaign: this.params.snapshot.queryParamMap.get('utm_campaign'),
  //     utmMedium: this.params.snapshot.queryParamMap.get('utm_medium'),
  //     utmSource: this.params.snapshot.queryParamMap.get('utm_source'),
  //   };
  //   localStorage.setItem('utmSettings', JSON.stringify(utmSettings));
  // }

  // populateSocialSignupUserInfo() {
  //   if (this.shouldPopulateUserInfo()) {
  //     const newUser: User = this.auth0User;
  //     const utmInfo = this.getUTMInfo();
  //     const referrer = this.getReferrer();
  //     const userCountry = this.authStore.user()?.['https://example.com/country'] || '';
  //     // const userCountry = this.getUserCountry(newUser);

  //     const userInfo: ProfileDto = {
  //       agreedPrivacyPolicy: false,
  //       name: newUser.name,
  //       publicName: newUser.name,
  //       websiteUrl: undefined,
  //       country: userCountry?.toLowerCase(),
  //       umtCampaign: utmInfo.utmCampaign,
  //       umtMedium: utmInfo.utmMedium,
  //       umtSource: utmInfo.utmSource,
  //       referrer: referrer,
  //     };

  //     this.setGeneralInfoForSocialSignup(userInfo, newUser);
  //   }
  // }

  // private shouldPopulateUserInfo(): boolean {
  //   return !!(!this.user.name && !this.user.publicName && !this.user.agreedPrivacyPolicy && this.auth0User?.googleAuth);
  // }

  // private getUTMInfo(): UTMDto {
  //   const utmSettingsItem = localStorage.getItem('utmSettings');
  //   if (utmSettingsItem) {
  //     try {
  //       return JSON.parse(utmSettingsItem);
  //     } catch (error) {
  //       console.error('Error parsing JSON from localStorage:', error);
  //     }
  //   }
  //   return {};
  // }

  // private getReferrer(): string | undefined {
  //   return localStorage.getItem('referrer') || undefined;
  // }

  // private getUserCountry(newUser: User): string {
  //   return this.checkSupportForUserCountry(newUser['https://example.com/country'] || '')?.toUpperCase() || 'US';
  // }

  // checkSupportForUserCountry(userLocale: string) {
  //   const country = COUNTRIES_DB.filter((country) => country.name === userLocale);
  //   if (country.length === 1) {
  //     return country[0].alpha2Code;
  //   }
  //   return undefined;
  // }

  // setGeneralInfoForSocialSignup(userInfo: ProfileDto, newUser: User) {
  //   this.userService
  //     .editGeneralUserInfo(userInfo)
  //     .pipe(
  //       tap(async () => {
  //         const userImg: any = await convertImageUrlToFile(newUser.picture);
  //         this.checkedUserState = true;
  //         if (userImg) {
  //           // this.setSocialUserImage(userImg, newUser);
  //         } else {
  //           // this.setFirstSettlementAsset(newUser);
  //         }
  //       }),
  //       catchError(() => {
  //         this.checkedUserState = true;
  //         return EMPTY;
  //       }),
  //     )
  //     .subscribe();
  // }

  // async setSocialUserImage(userImg: any, newUser: User) {
  //   this.authStore.updateProfilePicture(userImg);

    // TODO: do we need this anymore?
    // TODO: Important!
    // this.userService
    //   .updateProfilePicture(userImg)
    //   .pipe(
    //     finalize(() => {
    //       this.setFirstSettlementAsset(newUser);
    //     }),
    //   )
    //   .subscribe();
  //  }

  // setFirstSettlementAsset(newUser: User) {
  //   // const userCountry = this.getUserCountry(newUser);
  //   const userCountry = this.authStore.user()?.['https://example.com/country'] || '';
  //   const asset = this.getUserFiatAsset(userCountry);

  //   this.updateAcceptedAssets(asset);
  // }

  // private getUserFiatAsset(userCountry: string): any {
  //   return this.fiatByCountry.getUserFiatByCountry(userCountry);
  // }

  // private updateAcceptedAssets(asset: any): void {
  //   this.userService
  //     .setAcceptedAssets(asset)
  //     .pipe(
  //       catchError((error: any) => {
  //         this.handleError(error);
  //         return EMPTY;
  //       }),
  //     )
  //     .subscribe();
  // }

  // private handleError(error: any): void {
  //   console.error('Error setting accepted assets:', error);
  // }

