import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { computed, inject } from '@angular/core';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { filter, pipe, switchMap, take, tap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { AuthService } from '@auth0/auth0-angular';
import { ProfileDto } from '../dtos/user-profile.dto';
import { UserService } from '../services/user.service';
import { Auth0UserProfile } from '../components/models/user.mode';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BalancesStore } from './balances.store';
import { ReportsStore } from './reports.store';
import { ContactsStore } from './contacts.store';
import { ToastrFactoryService } from '../services/toastr-factory.service';
import { environment } from 'src/environments/environment';

type AuthStoreState = {
  user: Auth0UserProfile | null;
  authenticated: boolean;
  profile: ProfileDto | null;
  profilePicture: SafeUrl | null;
};

const initialState: AuthStoreState = {
  user: null,
  authenticated: false,
  profile: null,
  profilePicture: null,
};

export const AuthStore = signalStore(
  {
    providedIn: 'root',
  },
  withState(initialState),
  withComputed(({ user }) => {
    const shouldPopulateUserInfo = computed(() => {
      // TODO: check what we should do here ???
      // return !!(!user()?.name && !user()?.publicName && !user()?.agreedPrivacyPolicy && user()?.googleAuth);
      return false;
    });

    return {
      shouldPopulateUserInfo,
    };
  }),
  withMethods((
      store,
      authService = inject(AuthService),
      userService = inject(UserService),
      sanitizer = inject(DomSanitizer),
      translate = inject(TranslateService),
      router = inject(Router),
      balancesStore = inject(BalancesStore),
      reportsStore = inject(ReportsStore),
      contactsStore = inject(ContactsStore),
      toastrFactory = inject(ToastrFactoryService)
    ) => ({
    currentUser: rxMethod<void>(
      pipe(
        switchMap((_) =>
          authService.isAuthenticated$.pipe(
            filter(Boolean),
            switchMap(() => authService.user$.pipe(take(1), filter(Boolean))),
            tapResponse({
              next: (user) => {
                patchState(store, {
                  user: user as Auth0UserProfile,
                  authenticated: true,
                });
              },
              error: (error) => {
                console.error(error);
              },
            }),
          ),
        ),
        switchMap((_) =>
          userService.getUser().pipe(
            tapResponse({
              next: (profile) => {
                translate.use(profile.language || navigator.language.toLowerCase());


                patchState(store, {
                  profile: profile,
                });

                // Initializes the balances, reports and contacts
                // Only after the user is loaded
                balancesStore.loadBalances();
                reportsStore.loadReports();
                contactsStore.loadContacts();

              },
              error: function (error: unknown): void {
                throw new Error('Function not implemented.');
              }
            }),
          ),
        ),
      ),
    ),
    updateProfileInfo: rxMethod<Partial<ProfileDto>>(
      pipe(
        switchMap((profileUpdate) =>
          userService.editGeneralUserInfo(profileUpdate).pipe(
            tapResponse({
              next: (profile) => {
                patchState(store, {
                  profile: profile,
                });
                toastrFactory.success(translate.instant('PROFILE.userInfoUpdatedSuccessfully'), '', {
                  timeOut: 3000,
                });
              },
              error: (error) => {
                console.error('Error updating profile:', error);
              }
            }),
          ),
        ),
      ),
    ),
    updateProfileLanguage: rxMethod<Partial<ProfileDto>>(
      pipe(
        switchMap((profileUpdate) =>
          userService.setUserPreferedLanguage(profileUpdate.language!).pipe(
            tapResponse({
              next: (profile) => {
                patchState(store, {
                  profile: profile,
                });
              },
              error: (error) => {
                console.error('Error updating profile:', error);
              }
            }),
          ),
        ),
      ),
    ),
    updateProfilePicture: rxMethod<File>(
      pipe(
        switchMap((userImg) => {
          const profile = store.profile();
          return userService.updateProfilePicture(userImg).pipe(
            switchMap(() =>
              userService.getUserImageById(profile?.id || '').pipe(
                tap((newImg) => {
                  const safeUrl = sanitizer.bypassSecurityTrustUrl(
                    URL.createObjectURL(newImg as unknown as Blob)
                  );
                  patchState(store, { profilePicture: safeUrl });
                })
              )
            ),
            tapResponse({
              next: () => {
                toastrFactory.success(
                  translate.instant('PROFILE.pictureUpdatedSuccessfully'),
                  '',
                  { timeOut: 3000 }
                );
              },
              error: (error) => {
                toastrFactory.error(
                  translate.instant('PROFILE.errorUpdatingProfilePicture'),
                );
                console.error('Error updating profile picture:', error);
              }
            })
          );
        })
      )
    ),
    getProfilePicture: rxMethod<void>(
      pipe(
        switchMap(() =>
          userService.getProfilePicture().pipe(
            tapResponse({
              next: (response) => {
                const safeUrl = sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(response as Blob));
                patchState(store, {
                  profilePicture: safeUrl,
                });
              },
              error: (error) => {
                console.error('Error getting profile picture:', error);
              }
            }),
          ),
        ),
      ),
    ),
    logout: rxMethod<void>(
      pipe(
        switchMap(() => authService.logout({ logoutParams: { returnTo: environment.websiteUrl }, })),
        tap(() => router.navigate(['/'])),
      ),
    )
  }))
);
