import { Directive, HostListener } from '@angular/core';
import FormatCurrency from './format-currency-utils';

@Directive({
    selector: '[appNumberFormat]'
})
export class NumberFormatDirective {

    constructor(
        public formatCurrency: FormatCurrency,
    ) { }

    @HostListener('input', ['$event'])
    onInput(event: any) {

        if (this.formatCurrency.commaDecimalCountries.includes(navigator.language)) {
            const isCommaDecimal=true;
            const input = event.target as HTMLInputElement;
            let value = input.value.replace(/[^0-9,]/g, '');
            const separatorIndex = value.indexOf(',');
            this.setCommaIndex(separatorIndex, value, input, isCommaDecimal)
        } else {
            const isCommaDecimal = false;
            const input = event.target as HTMLInputElement;
            let value = input.value.replace(/[^0-9.]/g, '');
            const separatorIndex = value.indexOf('.');
            this.setCommaIndex(separatorIndex, value, input, isCommaDecimal)
        }
    }
    setCommaIndex(separatorIndex: number, value: string, input: HTMLInputElement, isCommaDecimal:boolean) {
        if (separatorIndex >= 0) {
            const integerPart = isCommaDecimal ? value.substring(0, separatorIndex).replace(/\./g, '') : value.substring(0, separatorIndex).replace(/\,/g, '') ;
            const decimalPart = value.substring(separatorIndex);
            value = isCommaDecimal ? integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + decimalPart : integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + decimalPart;
        } else {
            value = isCommaDecimal ? value.replace(/\B(?=(\d{3})+(?!\d))/g, '.') : value.replace(/\B(?=(\d{3})+(?!\d))/g, ',') ;
        }
        input.value = value;
    }
}
