import { Component, ComponentRef, Input, OnInit, Type, ViewChild, ViewContainerRef, inject, ComponentFactoryResolver, OnDestroy, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WizardStore, WizardStep } from '../../store/wizard.store';
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentQueryParams } from  '../../models/payment-params.model';


export interface StepComponent {
  form?: FormGroup;
  isValidStep?(): boolean;
  onNextInner?: () => Promise<boolean> | boolean | void;
}

@Component({
  selector: 'app-wizard',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './wizard.component.html',
})
export class WizardComponent implements OnInit, OnDestroy {
  @Input() stepsConfig: WizardStep[] = [];
  protected store = inject(WizardStore);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  @Input() isPaymentRequest: boolean = false;
  @Input() isPaymentReport: boolean = false;

  @Input() showCancelButton: boolean = false;

  @ViewChild('componentOutlet', { read: ViewContainerRef, static: true }) 
  componentOutlet!: ViewContainerRef;
  
  private currentComponentRef: ComponentRef<any> | null = null;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    // Use effect to watch for step changes
    effect(() => {
      const currentStep = this.store.currentStep();
      this.loadComponent(currentStep);
      // Update URL when step changes
      this.updateUrlWithStep(currentStep);
    });
  }

  ngOnInit() {
    if (this.stepsConfig.length > 0) {
      // Handle step navigation from URL for all wizard types
      this.route.queryParams.subscribe(params => {
        const step = params['step'];
        if (step && this.stepsConfig.some(s => s.id === step)) {
          this.stepsConfig = this.stepsConfig.map(s => ({
            ...s,
            isCompleted: this.stepsConfig.findIndex(config => config.id === step) > 
              this.stepsConfig.findIndex(config => config.id === s.id)
          }));
        }
      });
      
      this.store.initialize(this.stepsConfig);
    }

    this.loadComponent(this.store.currentStep());
  }

  private loadComponent(stepId: string) {
    // Clear existing component
    if (this.currentComponentRef) {
      this.currentComponentRef.destroy();
    }
    this.componentOutlet.clear();

    // Create new component
    const componentType = this.getComponentForStep(stepId);
    if (componentType) {
      this.currentComponentRef = this.componentOutlet.createComponent(componentType);
      
      // Initialize form with URL parameters if component has a form
      const instance = this.currentComponentRef.instance;
      if (instance && instance.form) {
        this.route.queryParams.subscribe(params => {
          const formControls = Object.keys(instance.form.controls);
          const formValues = formControls.reduce((acc: Record<string, any>, key: string) => {
            if (params[key]) {
              acc[key] = params[key];
            }
            return acc;
          }, {});
          
          if (Object.keys(formValues).length > 0) {
            instance.form.patchValue(formValues, { emitEvent: false });
          }
        });
      }
      
      this.currentComponentRef.changeDetectorRef.detectChanges();
    }
  }

  getComponentForStep(stepId: string): Type<any> | null {
    const step = this.stepsConfig.find((s) => s.id === stepId);
    return step ? step.componentType : null;
  }

  canClickStep(step: WizardStep): boolean {
    return step.isCompleted;
  }

  onStepClick(step: WizardStep) {
    if (this.canClickStep(step)) {
      if (this.isPaymentReport) {
        const currentFormValues = this.currentComponentRef?.instance?.form?.value || {};
        this.updateUrlWithParams({
          ...currentFormValues,
          step: step.id
        });
      } else {
        this.updateUrlWithParams({ step: step.id });
      }
      this.store.setStep(step.id);
    }
  }

  async onNext() {
    const currentStepIndex = this.store.steps().findIndex(step => step.isActive);
    const nextStep = this.store.steps()[currentStepIndex + 1];
    const currentInstance = this.currentComponentRef?.instance;

    if (currentInstance) {
      try {
        const canProceed = 'onNextInner' in currentInstance ? 
          await Promise.resolve(currentInstance.onNextInner()) : 
          true;

        if (canProceed !== false && nextStep) {
          if (this.isPaymentReport) {
            // Get all form values for payment report
            const formValues = currentInstance.form?.value || {};
            
            // Update URL with all form values
            this.updateUrlWithParams({
              ...formValues,
              step: nextStep.id
            });
          } else {
            // For payment request, only update step
            this.updateUrlWithParams({ step: nextStep.id });
          }
          this.store.setStep(nextStep.id);
        }
      } catch (error) {
        this.toastr.error('Error processing step');
        console.error('Error in onNextInner:', error);
      }
    }
  }

  onBack() {
    const currentStepIndex = this.store.steps().findIndex((step) => step.isActive);
    const previousStep = this.store.steps()[currentStepIndex - 1];
    if (previousStep) {
      if (this.isPaymentReport) {
        // Get all form values for payment report
        const formValues = this.currentComponentRef?.instance?.form?.value || {};
        
        // Update URL with all form values
        this.updateUrlWithParams({
          ...formValues,
          step: previousStep.id
        });
      } else {
        this.updateUrlWithParams({ step: previousStep.id });
      }
      this.store.setStep(previousStep.id);
    }
  }

  isNextButtonDisabled(): boolean {
    // Check if it's the last step
    const isLastStep = this.store.currentStep() === this.stepsConfig[this.stepsConfig.length - 1]?.id;
    if (isLastStep) return true;

    // Get current component instance
    const currentInstance = this.currentComponentRef?.instance;
    
    // Check if component has a form and if it's valid
    if (currentInstance) {
      // Check for isValid method
      if ('isValidStep' in currentInstance && typeof currentInstance.isValidStep === 'function') {
        return !currentInstance.isValidStep();
      }
    }

    // Default to enabled if no validation is present
    return false;
  }

  onReset() {
    this.store.reset();
  }

  onCancel(event: Event) {
    this.store.onCancel(event);
  }

  getNextButtonLabel(): string {
    const activeStep = this.store.steps().find((step) => step.isActive);
    return activeStep?.nextLabel || 'Next';
  }

  getBackButtonLabel(): string {
    const activeStep = this.store.steps().find((step) => step.isActive);
    return activeStep?.backLabel || 'Back';
  }

  getResetButtonLabel(): string {
    const activeStep = this.store.steps().find((step) => step.isActive);
    return activeStep?.resetLabel || 'Reset';
  }

  isLastStep(): boolean {
    const currentStepIndex = this.store.steps().findIndex((step) => step.isActive);
    return currentStepIndex === this.store.steps().length - 1;
  }

  shouldShowFullWidthNext(): boolean {
    const activeStep = this.store.steps().find((step) => step.isActive);
    return !!activeStep?.fullWidthNext;
  }

  trackByStepId(index: number, step: WizardStep): string {
    return step.id;
  }

  ngOnDestroy() {
    if (this.currentComponentRef) {
      this.currentComponentRef.destroy();
    }
  }

  private updateUrlWithStep(stepId: string) {
    // Update URL without triggering navigation
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { step: stepId },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  // Helper method to normalize amount (handles both '.' and ',' as decimal separators)
  private normalizeAmount(amount: string): string {
    // Replace comma with dot for consistency
    return amount.replace(',', '.');
  }

  // Update navigation methods to preserve all parameters
  private updateUrlWithParams(newParams: Partial<PaymentQueryParams>) {
    if (this.isPaymentReport) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          ...this.route.snapshot.queryParams,
          ...newParams
        },
        queryParamsHandling: 'merge',
        replaceUrl: true
      });
    } else {
      // For payment request, only update step
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { step: newParams.step },
        queryParamsHandling: 'merge',
        replaceUrl: true
      });
    }
  }
}
