import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.scss']
})
export class ConfirmRegistrationComponent implements OnInit, AfterViewInit {

  @ViewChild('firstInputNumber') firstInputNumber: any;


  constructor(private router: Router){}


  ngAfterViewInit(): void {
    this.firstInputNumber.nativeElement.focus();
  }

  public form: FormGroup = new FormGroup({
    firstInputNumber: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9a-zA-Z]{1}$/)]),
    secondInputNumber: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9a-zA-Z]{1}$/)]),
    thirdInputNumber: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9a-zA-Z]{1}$/)]),
    fourthInputNumber: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9a-zA-Z]{1}$/)]),
    fifthInputNumber: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9a-zA-Z]{1}$/)]),
    sixthInputNumber: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9a-zA-Z]{1}$/)]),
  });
  ngOnInit(): void {

  }

  moveToNext(currentInput: AbstractControl, nextInput?: any) {
    if(this.form.valid){
      this.onSubmit()
    }
    let onlyOneDigit = currentInput.value.toString().length == 1
    if (nextInput || !onlyOneDigit) {
      if (currentInput.valid && onlyOneDigit) {
        nextInput.focus()
      } else {
        this.onPaste(currentInput);
      }
    } 
  }

  public goHome(){
    this.router.navigateByUrl('/home')
  }
  

  onSubmit() {
    alert("Submit : Form is valid ? " + this.form.valid)
  }

  public onPaste(currentInput?: any) {
    let i = 0;
    const controls = Object.values(this.form.controls);
    if (!currentInput) {
      currentInput = this.form.controls['firstInputNumber'].value;
    }
    for (let character of currentInput.value.toString()) {
      controls[i].setValue(character);
      i++;
    }
  }

}


