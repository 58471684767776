import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, AfterViewInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, debounceTime, EMPTY, firstValueFrom, Subject, tap } from 'rxjs';
import { FavoritesService } from 'src/app/services/favorites.service';
import { SearchService } from 'src/app/services/search.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.mode';
import { AvatarService } from 'src/app/services/avatar.service';
import { subscribe } from 'diagnostics_channel';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements AfterViewInit {
  @ViewChild('search') search!: ElementRef<HTMLInputElement>;
  @ViewChild('searchDesktop') searchDesktop!: ElementRef<HTMLInputElement>;
  @Input() isAuthenticated!: any;
  @Input() generalSearch!: boolean;
  @Input() favoritesSearch!: boolean;
  @Input() fromHome!: boolean;
  @Input() myNick!: string;
  @Input() JWTToken!: string;
  @Output() openNewPayment = new EventEmitter<any>();
  @Output() favoriteSearchList = new EventEmitter<any>();

  public searchResults!: any;
  public showModal = false;
  public modalMessage!: string;
  public modalTittle!: string;
  public dashBoardOptions = false;
  public modalNick!: string;
  public modalPublicName!: string;
  public fullEmailModal = false;
  public isMobile = false;
  public loading = false;
  public paymentFlow = false;
  public targetNick!: string;
  public userId!: string;
  public user: any;
  public targetCompany!: string;
  public companyWebsite!: string;
  public requestInfo: any;
  public assets: any;
  public userProfile = false;
  public environment = environment;
  public minimumCharMessage!: string;
  public target!: User;
  private searchInput$ = new Subject<string>();
  private debounceTimeInMs = 1150; 

  constructor(
    private favoritesService: FavoritesService,
    public auth: AuthService,
    private toastrService: ToastrService,
    private searchService: SearchService,
    private userService: UserService,
    public translate: TranslateService,
    private elementRef: ElementRef,
    private avatarService: AvatarService
  ) {}

  ngOnInit(): void {
    this.searchInput$.pipe(
      debounceTime(this.debounceTimeInMs)
    ).subscribe(searchString => this.onSearch(searchString));
  }

  ngAfterViewInit(): void {
    this.setSearchBehavior(); // Ensure search behavior is set after view initialization
  }

  onInputChange(searchString: string) {
    this.searchInput$.next(searchString);
  }

  onSearch(searchString: string, userClick?:boolean): void {
    searchString = searchString.trim();

    if (this.favoritesSearch) {
      this.handleFavoriteSearch(searchString);
      return;
    } 

    if ( this.validateSearchString(searchString) || userClick) {
      this.validateInput(searchString);
      this.startGeneralSearch(searchString);
    }
  }

  onFavoriteSearch(searchElement: string) {
    if (this.favoritesSearch) {
      this.searchInput$.next(searchElement);
    }
  }

  validateInput(searchString: string) {
    const minWidth = 3;
    const inputLength = searchString.replaceAll("@", "").length;
    const atLength = this.moreThanOneAt(searchString);
    this.minimumCharMessage = "";

    if (inputLength < minWidth) {
      const remainingChar = minWidth - inputLength;
      this.minimumCharMessage = `${this.translate.instant("HOME.youNeedToType")}${remainingChar}${this.translate.instant("HOME.moreChar")}${remainingChar === 1 ? '' : 's'}`;
      if (atLength > 1) {
        this.minimumCharMessage += this.translate.instant('HOME.doesNotCount');
      }
      this.searchResults = [];
    }
  }

  startGeneralSearch(searchString: string) {
    if (!searchString.includes('@')) {
      searchString = searchString.toUpperCase();
    }
    this.searchService.generalSearch(searchString).pipe(
      tap((response: any) => {
        this.handleGeneralSearchResponse(response, searchString)
      }),
      catchError((error:any) => {
        console.error(error);
        return EMPTY;
      })
    ).subscribe()
  }

  startFavoriteSearch(searchString: string) {
    this.searchService.favoritesSearch(searchString).pipe(
      tap((response: any) => {
        this.searchResults = response;
        this.favoriteSearchList.emit(this.searchResults);    
      }),
      catchError((error:any) => {
        console.error(error);
        return EMPTY;
      })
    ).subscribe();
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.searchResults = undefined;
    }
  }

  openPayment(target: any) {
    const openPayment = {
      domains: target.domains,
      userId: target.userId,
      nick: target.nick,
      publicName: target.publicName,
      websiteUrl: target.websiteUrl,
      target
    };
    this.openNewPayment.emit(openPayment);
  }
  public async addFavorite(nick: string) {
    const alreadyFavorite = await firstValueFrom(await this.favoritesService.favoriteByNick(nick));
    if (Array.isArray(alreadyFavorite) && alreadyFavorite.length > 0) {
      this.toastrService.success(this.translate.instant('userIsAlreadyInYourFavorites'), '', { timeOut: 3000 });
    } else {
      this.favoritesService.addFavorite(nick).pipe(
        tap((response: any) => {
          this.toastrService.success(this.translate.instant('userAddedToYourFavoritesSuccessfully'), '', { timeOut: 3000 });
          this.searchResults = response;
        }),
        catchError((error:any) => {      
          console.error(error)
          return EMPTY;
        })
      ).subscribe();
    }
  }

  handleImageError(event: any, userName?: string) {
    event.target.onerror = null;
    event.target.src = this.avatarService.getInitialAvatar(userName?.trim()?.charAt(0) || 'X');
  }

  public showEmailModal(fullMail: string) {
    this.showModal = true;
    this.modalMessage = fullMail;
    this.fullEmailModal = true;
  }

  moreThanOneAt(input: string) {
    return (input.match(/@/g) || []).length;
  }

  setSearchBehavior() {
    const searchHandler = (event: Event) => {
      const inputElement = event.target as HTMLInputElement;
      this.searchInput$.next(inputElement.value);
    };

    if (this.search && this.search.nativeElement) {
      this.search.nativeElement.addEventListener('input', searchHandler);
    }

    if (this.searchDesktop && this.searchDesktop.nativeElement) {
      this.searchDesktop.nativeElement.addEventListener('input', searchHandler);
    }
  }

  updateSearchResults() {
    this.searchResults = "";
  }

  private async handleFavoriteSearch(searchString: string) {
    if (!searchString) {
      this.searchResults = await firstValueFrom(this.favoritesService.getUserFavorites());
      this.favoriteSearchList.emit(this.searchResults);
    } else {
      this.startFavoriteSearch(searchString);
    }
  }

  private handleGeneralSearchResponse(response: any, searchString: string) {
    const formattedSearchString = searchString.includes('@') 
    ? searchString?.trim()
    : searchString?.toUpperCase().trim();
    this.searchResults = response;
    if (this.searchResults?.length === 1) {
      const result = this.searchResults[0];
      if (
        formattedSearchString === result?.nick || 
        formattedSearchString === result?.shortId || 
        this.isSearchDomain(result, formattedSearchString))
      {
        result.exactlyMatch = true;
      } else {
        this.searchResults = [];
      }
    } else {
      this.searchResults = [];
    }
  }

  isSearchDomain(result: any, searchString: string): boolean {
    return result.domains?.some((domain: any) => 
      domain.name.toLowerCase().trim() === searchString.toLowerCase().trim() && domain.isValid
    ) || false;
  }

  validateSearchString(searchString: string): boolean {
    // regex to test if the string contains characters before and after the . 
    const regex = /^[^.]+(\.[^.]+)+$/;

    // // Check if the search string is at least 5 characters long with at least one number
    const isShortId = searchString.length >= 5 && /\d/.test(searchString);

    // Check if the search string contains at least 4 characters with an '@'
    const isEmail = searchString.length >= 4 && searchString.includes('@');

    // check if the search string is a domain testing if there are characters before and after the dots.
    const isDomainNick = regex.test(searchString);
    
    console.log(isDomainNick)
    return isShortId || isEmail || isDomainNick;
  }
}
