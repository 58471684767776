import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { createHttpOptions } from '../utils/http-utils';

@Injectable({
  providedIn: 'root',
})
export class SearchService {

  private http = inject(HttpClient);
  public auth = inject(AuthService);

  private buildUrl(endpoint: string, queryParam: string, queryValue: string): string {
    return `${environment.api}${endpoint}?${queryParam}=${queryValue}`;
  }

  generalSearch(query: string) {
    return this.http.get(this.buildUrl('Nick/search', 'q', query), createHttpOptions());
  }

  favoritesSearch(query: string) {
    return this.http.get(this.buildUrl('FavoriteNick/search', 'q', query), createHttpOptions());
  }

  getPublicProfileByShortId(shortId: string) {
    return this.http.get(this.buildUrl('User/profile-public-shortid', 'shortId', shortId), createHttpOptions());
  }

  getPublicProfileByDomain(domain: string) {
    return this.http.get(this.buildUrl('User/profile-public-domain', 'domain', domain), createHttpOptions());
  }

  uuidPublicProfile(UUID: string) {
    return this.http.get(this.buildUrl('User/profile-public', 'userId', UUID), createHttpOptions());
  }
}
