import { HttpHeaders } from '@angular/common/http';

export function createHttpOptions(acceptType: string = 'text/plain', contentType: string = 'application/json', accessToken?:string) {
  let headersConfig: { [key: string]: string }  = {
    'Content-Type': contentType,
    'Accept': acceptType,
  };
  
  if (accessToken) {
    headersConfig['Authorization'] = `Bearer ${accessToken}`;
  }
  
  return {
    headers: new HttpHeaders(headersConfig)
  };
}