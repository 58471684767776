import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-user-profile-picture',
  imports: [CommonModule],
  standalone: true,
  templateUrl: './user-profile-picture.component.html',
})
export class UserProfilePictureComponent {
  @Input() imageUrl?: string | SafeUrl | null;
  @Input() name: string | undefined = '';
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() className?: string;

  get initials(): string {
    return this.name
      ?.split(' ')
      .map(n => n[0])
      .join('')
      .toUpperCase()
      .slice(0, 2) || '';
  }

  get sizeClasses(): string {
    const sizes = {
      sm: 'h-8 w-8 text-xs',
      md: 'h-10 w-10 text-sm',
      lg: 'h-16 w-16 text-base'
    };
    return sizes[this.size];
  }
} 
