import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { PaymentRequestCreateDto } from '../dtos/payment-request.dto';
import { createHttpOptions } from '../utils/http-utils';

@Injectable()
export class PaymentRequestService {

  constructor(
    public route: Router,
    private http: HttpClient,
    public auth: AuthService
  ) { }



  makeRequest(paymentRequest: PaymentRequestCreateDto) {
    return this.http.post(
      `${environment.api}PaymentRequest/create`,
      paymentRequest,
      createHttpOptions()
    );
  }

  getPaymentById(paymentId: string) {
    return this.http.get(
      `${environment.api}PaymentRequest/get-by-shortid?shortId=${paymentId}`,
      createHttpOptions()
    );
  }

  resendPaymentRequestEmail(shortId: string) {
    return this.http.post(
      `${environment.api}PaymentRequest/resend-email?shortId=${shortId}`,
      {},
      createHttpOptions()
    );
  }

  changeRequestStatus(paymentId: string, status: string) {
    const payload = `"${status}"`;
    return this.http.post(
      `${environment.api}PaymentRequest/set-status?shortId=${paymentId}`,
      payload,
      createHttpOptions()
    );
  }

  assignToPaymentRequest(paymentRequestId: string, paymentReportId: string) {
    return this.http.post(
      `${environment.api}PaymentReport/move-to-paymentrequest?id=${paymentReportId}&shortId=${paymentRequestId}`,
      '',
      createHttpOptions()
    );
  }
}










